import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  Combobox,
  Input,
  Checkbox,
  Popover,
} from "@salesforce/design-system-react";
import PCRTimePicker from "components/common/PCRTimePicker";
import { useTranslation } from "react-i18next";
// import "../css/comboBox.";
import PCRComboBox from "components/common/PCRComboBox";
import { PCRContext, evaluateError } from "../context/PCRContext";
import PCRTextInput from "components/common/PCRTextInput";
import { db } from "../storage/idb";
import { vitalItem as vitalItemBlank } from "../context/PCRContext";
import { PCRVitalItemGCSTooltipTable } from "./PCRVitalItemGCSTooltipTable";
import {
  pupilOptions,
  eyeOptions,
  verbalOptions,
  motorOptions,
  armOptions,
} from "data/constants";

const PCRVitalItemComponent = ({
  pcr,
  index,
  onRemove,
  onChange,
  onAdd,
  isLast,
  vitalFromParent,
  onVitalSelect,
}) => {
  const { t } = useTranslation();

  const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
  const { assesment, identification } = pcrData;

  const [vital, setVital] = useState(vitalFromParent);

  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(false);

  const requiredTextInputFields = [
    "respiration", "o2Sat", "etco2", "hr", "bp", "temperatureC", "pain", "glucose"
  ];

  const requiredComboBoxFields = ["rPupil", "lPupil"];

  useEffect(() => {
    setVital(vitalFromParent);
  }, [vitalFromParent]);

  useEffect(() => {
    const { eye, motor, verbal } = vital;

    if (eye === "NT" || verbal === "NT" || motor === "NT") {
      setVital({ ...vital, ...{ gcsTotal: "NT" } });
    }

    if (eye === "" || verbal === "" || motor === "") {
      setVital({ ...vital, ...{ gcsTotal: "" } });
    }

    if (
      eye &&
      eye !== "NT" &&
      verbal &&
      verbal !== "NT" &&
      motor &&
      motor !== "NT"
    ) {
      setVital({
        ...vital,
        ...{
          gcsTotal:
            parseInt(vital?.eye) +
            parseInt(vital?.verbal) +
            parseInt(vital?.motor),
        },
      });
    }
  }, [vital.eye, vital.verbal, vital.motor, isAnyFieldFilled]);

  const updateVitalPcrCtx = (updateAttr) => {
    
    const newVital = { ...vital, ...updateAttr };
    setVital(newVital);
    onChange(index, newVital);
  };

  const getFieldRequiredValue = (field) => {
    let fl = formValidationConfig.find((fvc) => fvc.name === field);
    return fl?.shouldDisplayError ? fl?.shouldDisplayError : false;
  };

  const ifRequiredFieldForNT = (field, value) => {
    return getFieldRequiredValue(field) &&
      requiredTextInputFields.includes(field) &&
      (value === "NT" || value === "nt");
  };

  const getUpdatedPupilOptions = (field, options) => {
    const shouldIncludeNT = getFieldRequiredValue(field);

    if (shouldIncludeNT) {
      return [...options, { key: "nt", id: "nt", label: "NT", value: "nt" }];
    }
    return options.filter((option) => option.value !== "nt");
  };

  const validateFieldsOnProtocolChangeForComboBox = () => {
    let updatedVital = { ...vital };

    requiredComboBoxFields.forEach((field) => {
      const fieldValue = updatedVital[field];
      if (!getFieldRequiredValue(field) && (fieldValue === "NT" || fieldValue === "nt")) {
        updatedVital[field] = "";
        updatedVital[`${field}Error`] = t("invalidFormat");
      }
    });

    setVital(updatedVital);
    onChange(index, updatedVital);
  };

  useEffect(() => {
    validateFieldsOnProtocolChangeForComboBox();
  }, [pcrData?.assesment?.protocolPrimary]);

  return (
    <div id="vital-item-main-cls">
      <div className="slds-grid slds-gutters">
        <div className="slds-col" style={{ marginTop: "2rem" }}>
          <Checkbox
            assistiveText={{
              label: "",
            }}
            id={"check-bx-id" + index}
            labels={{
              label: "",
            }}
            onChange={(e) => {
              onVitalSelect(index, e.target.checked);
            }}
          />
        </div>
        <div className="slds-col">
          <PCRTimePicker
            assistiveText={{ label: t("time") }}
            id="time-id"
            className="bor-rad"
            placeholder="HH:MM"
            value={vital?.vitalsTime}
            name="vitalsTime"
            label={t("time")}
            onChange={(newValue) => {
              updateVitalPcrCtx(newValue);
            }}
            disabled={false}
            required={getFieldRequiredValue("vitalsTime")}
            errorText={
              vital?.vitalsTimeError && (
                <div className="df">
                  <p className="">{vital?.vitalsTimeError}</p>
                </div>
              )
            }
          />
        </div>

        <div className="slds-col">
          <PCRTextInput
            onError={updateVitalPcrCtx}
            onChange={updateVitalPcrCtx}
            value={vital?.respiration}
            className=""
            label="Resp."
            name="respiration"
            required={getFieldRequiredValue("respiration")}
            errorText={
              vital?.respirationError && (
                <div className="df">
                  <p className="">{vital?.respirationError}</p>
                </div>
              )
            }
            regExValidationText={ifRequiredFieldForNT("respiration", vital?.respiration) ? "NUMERIC_OR_NT" : "ONLY_NUMERIC"}
            validateOnBlur={false}
          />
        </div>
        <div className="slds-col">
          <PCRTextInput
            onError={(val) => {
              updateVitalPcrCtx(val);
            }}
            onChange={updateVitalPcrCtx}
            value={vital?.o2Sat}
            className=""
            label={
              <>
                O<sub>2</sub> Sat
              </>
            }
            name="o2Sat"
            required={getFieldRequiredValue("o2Sat")}
            errorText={
              vital?.o2SatError && (
                <div className="df">
                  <p className="">{vital?.o2SatError}</p>
                </div>
              )
            }
            regExValidationText={ifRequiredFieldForNT("o2Sat", vital?.o2Sat) ? "ONLY_NUMERIC_MAX_100_OR_NT" : "ONLY_NUMERIC_MAX_100"}
            validateOnBlur={false}
          />
        </div>
        <div className="slds-col">
          <PCRTextInput
            onError={updateVitalPcrCtx}
            onChange={updateVitalPcrCtx}
            value={vital?.etco2}
            className=""
            label={
              <>
                ETCO<sub>2</sub>
              </>
            }
            name="etco2"
            errorText={
              vital?.etco2Error && (
                <div className="df">
                  <p className="">{vital?.etco2Error}</p>
                </div>
              )
            }
            regExValidationText={ifRequiredFieldForNT("etco2", vital?.etco2) ? "NUMERIC_OR_NT" : "ONLY_NUMERIC"}
            validateOnBlur={false}
            required={getFieldRequiredValue("etco2")}
          />
        </div>

        <div className="slds-col">
          <PCRTextInput
            onError={updateVitalPcrCtx}
            onChange={updateVitalPcrCtx}
            value={vital?.hr}
            className=""
            label="HR"
            name="hr"
            errorText={
              vital?.hrError && (
                <div className="df">
                  <p className="">{vital?.hrError}</p>
                </div>
              )
            }
            regExValidationText={ifRequiredFieldForNT("hr", vital?.hr) ? "NUMERIC_OR_NT" : "ONLY_NUMERIC"}
            validateOnBlur={false}
            required={getFieldRequiredValue("hr")}
          />
        </div>

        <div className="slds-col">
          <PCRTextInput
            onError={updateVitalPcrCtx}
            onChange={updateVitalPcrCtx}
            value={vital?.bp}
            className=""
            label="BP"
            name="bp"
            errorText={
              vital?.bpError && (
                <div className="df">
                  <p className="">{vital?.bpError}</p>
                </div>
              )
            }
            regExValidationText={ifRequiredFieldForNT("bp", vital?.bp) ? "BP_REG_OR_NT" : "BP_REG"}
            validateOnBlur={false}
            placeholder="    / "
            required={getFieldRequiredValue("bp")}
            // onBlur={e => handleBpBlur(e.target.value)}
          />
        </div>
        <div className="slds-col">
          <PCRComboBox
            id="arm-combo-box"
            options={armOptions}
            onChange={(value) => updateVitalPcrCtx({ arm: value })}
            onSelect={(value) => updateVitalPcrCtx(value)}
            name={"arm"}
            label={"Arm"}
            placeholder="--"
            value={vital?.arm}
            errorText={
              vital?.armError && (
                <div className="df">
                  <p className="margin-err-text"></p>
                  {vital?.armError}
                </div>
              )
            }
            lowerCaseRequired={true}
            selectAttribute={"label"}
            filterAttribute={"label"}
          />
        </div>

        <div className="slds-col">
          <PCRComboBox
            onChange={(value) => updateVitalPcrCtx({ eye: value })}
            onSelect={(value) => updateVitalPcrCtx(value)}
            id="eye-combo-box"
            options={eyeOptions}
            name={"eye"}
            label={"Eye"}
            placeholder="--"
            value={vital.eye}
            errorText={
              vital?.eyeError && (
                <div className="df">
                  <p className="margin-err-text"></p> {vital?.eyeError}
                </div>
              )
            }
            lowerCaseRequired={true}
            selectAttribute={"label"}
            filterAttribute={"label"}
          />
        </div>

        <div className="slds-col">
          <PCRComboBox
            id="verbal-combo-box"
            options={verbalOptions}
            onChange={(value) => updateVitalPcrCtx({ verbal: value })}
            onSelect={(value) => updateVitalPcrCtx(value)}
            name={"verbal"}
            label={"Verbal"}
            placeholder="--"
            value={vital.verbal}
            errorText={
              vital?.verbalError && (
                <div className="df">
                  <p className="margin-err-text"></p> {vital?.verbalError}
                </div>
              )
            }
            lowerCaseRequired={true}
            selectAttribute={"label"}
            filterAttribute={"label"}
          />
        </div>

        <div className="slds-col">
          <PCRComboBox
            id="motor-combo-box"
            onChange={(value) => updateVitalPcrCtx({ motor: value })}
            onSelect={(value) => updateVitalPcrCtx(value)}
            name={"motor"}
            label={"Motor"}
            placeholder="--"
            value={vital?.motor}
            errorText={
              vital?.motorError && (
                <div className="df">
                  <p className="margin-err-text"></p>
                  {vital?.motorError}
                </div>
              )
            }
            options={motorOptions}
            lowerCaseRequired={true}
            selectAttribute={"label"}
            filterAttribute={"label"}
          />
        </div>
        <div className="slds-col">
          <div className="df gcsDf" style={{ marginTop: "6px" }}>
            {((!pcrData?.assesment?.protocolPrimary?.includes("9992") &&
              !pcrData?.assesment?.protocolPrimary?.includes("9993") &&
              pcrData?.assesment?.protocolPrimary) ||
              pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT") && (
              <p className="gcs-mandate-star">* </p>
            )}
            <p>GCS</p>
            <>
              <Popover
                body={<PCRVitalItemGCSTooltipTable />}
                id="vital-signs-popover"
                align="top left"
              >
                <Button
                  assistiveText={{ icon: "Vital Signs info" }}
                  iconCategory="utility"
                  iconName="info"
                  iconSize="medium"
                  iconVariant="bare"
                  variant="icon"
                />
              </Popover>
            </>
          </div>

          <PCRTextInput
            onError={updateVitalPcrCtx}
            onChange={updateVitalPcrCtx}
            value={vital?.gcsTotal}
            className=""
            label=""
            name="gcsTotal"
            errorText={
              vital?.bpError && (
                <div className="df">
                  <p className="">{vital?.gcsTotalError}</p>
                </div>
              )
            }
            regExValidationText={"ONLY_NUMERIC"}
            validateOnBlur={false}
            required={
              (!pcrData?.assesment?.protocolPrimary?.includes("9992") &&
                !pcrData?.assesment?.protocolPrimary?.includes("9993") &&
                pcrData?.assesment?.protocolPrimary) ||
              pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT"
            }
            // required={getFieldRequiredValue("gcsTotal")}
          />
          {/* <Input label="GCS total" value={vital.gcsTotal} /> */}
        </div>
        <div className="slds-col">
          <PCRTextInput
            onError={updateVitalPcrCtx}
            onChange={updateVitalPcrCtx}
            value={vital?.temperatureC}
            className=""
            label="Temp(C)"
            name="temperatureC"
            errorText={
              vital?.temperatureCError && (
                <div className="df">
                  <p className="">{vital?.temperatureCError}</p>
                </div>
              )
            }
            regExValidationText={ifRequiredFieldForNT("temperatureC", vital?.temperatureC) ? "TEMPERATURE_REG_OR_NT" : "TEMPARATURE_REG"}
            validateOnBlur={false}
            required={getFieldRequiredValue("temperatureC")}
          />
        </div>
        <div className="slds-col">
          <PCRTextInput
            onError={updateVitalPcrCtx}
            onChange={updateVitalPcrCtx}
            value={vital?.pain}
            className=""
            label="Pain"
            name="pain"
            errorText={
              vital?.painError && (
                <div className="df">
                  <p className="">{vital?.painError}</p>
                </div>
              )
            }
            placeholder={"     /10"}
            regExValidationText={ifRequiredFieldForNT("pain", vital?.pain) ? "ONLY_NUMERIC_MAX_10_OR_NT" : "ONLY_NUMERIC_MAX_10"}
            validateOnBlur={false}
            required={getFieldRequiredValue("pain")}
          />
        </div>
        <div className="slds-col">
          <PCRTextInput
            onError={updateVitalPcrCtx}
            onChange={(val) => {
              val.glucose = val.glucose.toUpperCase();
              updateVitalPcrCtx(val);
            }}
            value={vital?.glucose}
            className=""
            label="Glucose"
            name="glucose"
            errorText={
              vital?.glucoseError && (
                <div className="df">
                  <p className="">{vital?.glucoseError}</p>
                </div>
              )
            }
            // GLUCOSE_REG
            regExValidationText={ifRequiredFieldForNT("glucose", vital?.glucose) ? "GLUCOSE_REG_OR_NT" : "GLUCOSE_REG"}
            validateOnBlur={false}
            required={getFieldRequiredValue("glucose")}
          />
        </div>

        <div className="slds-col">
          <PCRComboBox
            id="r-pupil-combo-box"
            options={getUpdatedPupilOptions("rPupil", pupilOptions)}
            onChange={(value) => {
              updateVitalPcrCtx({ rPupil: value });
            }}
            onSelect={(value) => {
              updateVitalPcrCtx(value);
            }}
            onRequestRemoveSelectedOption={null}
            name={"rPupil"}
            label={"R pupil"}
            placeholder="--"
            required={getFieldRequiredValue("rPupil")}
            value={vital.rPupil}
            errorText={
              vital?.rPupilError && (
                <div className="df">
                  <p className="margin-err-text">{pcrData?.rPupilError}</p>
                </div>
              )
            }
            lowerCaseRequired={true}
            selectAttribute={"label"}
            filterAttribute={"label"}
          />
        </div>

        <div className="slds-col">
          <PCRComboBox
            id="l-pupil-combo-box"
            options={getUpdatedPupilOptions("lPupil", pupilOptions)}
            onChange={(value) => updateVitalPcrCtx({ lPupil: value })}
            onSelect={(value) => updateVitalPcrCtx(value)}
            onRequestRemoveSelectedOption={null}
            name={"lPupil"}
            label={"L pupil"}
            placeholder="--"
            required={getFieldRequiredValue("lPupil")}
            value={vital.lPupil}
            errorText={
              pcrData?.lPupilError && (
                <div className="df">
                  <p className="margin-err-text">{vital?.lPupilError}</p>
                </div>
              )
            }
            lowerCaseRequired={true}
            selectAttribute={"label"}
            filterAttribute={"label"}
          />
        </div>
      </div>
    </div>
  );
};

export default PCRVitalItemComponent;
