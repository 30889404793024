import React from "react";
import { Icon } from "@salesforce/design-system-react";
import "../css/files.css";

export const Files = () => {
  return (
    <div className="slds-accordion__list-item filesContainer">
      <div className="slds-accordion__section df">
        <span className="fileIcon">
          <Icon
            assistiveText={{ label: "Clear Text" }}
            category="utility"
            name="file"
            size="small"
            style={{
              fill: "#1b96ff",
            }}
          />
        </span>
        <h3 className="slds-text-heading_small slds-accordion__summary-heading slds-has-flexi-truncate">
          Files
        </h3>
      </div>
    </div>
  );
};
