import React from "react";

const PCRDataTableColumnLabel = (props) => {
  return (
    <a href="#" className="slds-th__action slds-text-link_reset">
      {props.required && <span style={{ color: "red" }}>{"*"}</span>}
      <span>{props.label}</span>
    </a>
  );
};

export default PCRDataTableColumnLabel;
