import { get } from "./api.js";
import { getLanguage } from "../utils/util.js";
import { ERROR_CODES } from "../data/constants.js";

export const getTransferDetails = async (transferNumber, medicIds) => {
  const params = new URLSearchParams({
    transferNumber,
    medicIds: medicIds.join(","),
    language: getLanguage(),
  });
  const response = await get(`apexrest/ANB/fetchTransferDetails?${params}`);
  switch (response.status) {
    case 401:
      return {
        error: "Unauthorized",
        status: 401,
        data: null,
      };
    case 400:
      if (response?.data?.code) {
        return {
          error: response.data?.code,
          status: 400,
          data: response.data?.message,
        };
      }
      break;
    case 200:
      return {
        error: null,
        status: 200,
        data: response.data,
      };
    default:
      return {
        error:
          response?.data.code ||
          ERROR_CODES.MEDIC_NOT_FOUND_ON_TRANSFER_NUMBER,
        data: response?.data.message || "",
        status: response.status || 500,
      };
  }
};
