import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "@salesforce/design-system-react";
import { regExValidations } from "../../data/constants";
import moment from "moment";

const PCRTextInput = ({
  type,
  label,
  placeholder,
  id,
  value,
  onChange,
  onError = () => {},
  required="false",
  errorText = "",
  className,
  name = "",
  regExValidationText = "",
  validateOnBlur = true,
  ...rest
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const checkValidation = (val) => {
    var fieldRegex = "";

    if(regExValidationText){
      fieldRegex = new RegExp(regExValidations[regExValidationText]);
      if(!fieldRegex.test(value)){
        setError(t("invalidFormat"));
      }

      if(fieldRegex.test(value)){
        setError();
      }
    }
    
  
    if (!val && required) {
      setError(t("inputIsRequiredError"));
      onError({ [name+"Error"]: t("inputIsRequiredError") });
    } 

    if((!val && !required)) {
      setError();
    }
    
  };

  useEffect(() => {
    setError(errorText);

  }, [errorText]);

  const handleBlur = ({ target: { value } }) => {
    var fieldRegex = "";

    if(regExValidationText){
      
      fieldRegex = new RegExp(regExValidations[regExValidationText]);
      
      if(value && !fieldRegex.test(value)){
        setError(t("invalidFormat"));
        onError({ [name+"Error"]: t("invalidFormat") });
      }

      if(!value && !fieldRegex.test(value) &&  required){
        setError(t("inputIsRequiredError"));
        onError({ [name+"Error"]: t("inputIsRequiredError") });
      }

      if(value && fieldRegex.test(value)){
        setError("");
        onError({ [name+"Error"]: ""});
      }
    }
    
    if (required || regExValidationText) {
      checkValidation(value);
    }

    if(regExValidationText === 'BP_REG'){
     if (value?.length === 4) {
        value = value?.slice(0, 2) + '/' + value?.slice(2);
      } 
      if (value?.length === 5 && !value?.includes('/') ) {
        value = value?.slice(0, 3) + '/' + value?.slice(3);
      }
      onChange({ [name]: value });
    }

   
  };

  const handleChange = ({ target: { value } }) => {
    if (error) {
      checkValidation(value);
    }
    if (onChange) {
      if(regExValidationText && regExValidationText === 'DATE_REG'){
        var fieldRegex = new RegExp(regExValidations[regExValidationText]);
        if(value === ''){
          setError(t("inputIsRequiredError"));
        }

        if (!fieldRegex.test(value)) {
          setError(t("invalidFormat"));
        }

        if(fieldRegex.test(value)){
          setError("");
          onChange({ [name]: value });     
        }
        
      }

      if(regExValidationText && regExValidationText !== 'DATE_REG'){
        var fieldRegex = new RegExp(regExValidations[regExValidationText]);
        if (!fieldRegex.test(value)) {
          setError(t("invalidFormat"));
        }
        onChange({ [name]: value });
      }

      if(!regExValidationText){
        setError("");
        onChange({ [name]: value });
      }
      
    }
  };

  return (
    <Input
      type={type}
      assistiveText={{ label }}
      id={id || `${label}-input`}
      label={label}
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      errorText={error ? errorText || error : ""}
      required={required}
      // hasError={true}

      {...rest}
    />
  );
};

export default PCRTextInput;
