import React, { useEffect, useState } from "react";
import {
  Combobox,
  Icon,
  comboboxFilterAndLimit,
} from "@salesforce/design-system-react";
import { useTranslation } from "react-i18next";
import "../css/comboBox.css";

const PCRPredefinedList = ({
  id,
  onChange,
  onSelect,
  label,
  required=false,
  options,
  value,
  errorText = "",
  menuItemVisibleLength = 7,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [selection, setSelection] = useState([]);
  const [hasUnsavedChanges,setUnsavedChanges] = useState(null);

  const onBlur = () => {
    if(value?.length && !selection.length){
      setUnsavedChanges(true);
    }
    if (!selection.length && required) {
      setError(t("inputIsRequiredError"));
    } else {
      setError();
    }
  };

  useEffect(() => {
    onSelect(selection);
    if (selection.length) {
      setError("");
      setUnsavedChanges(false);
    }
  }, [selection]);

  const handleCustomSelection = () => {
    setSelection([...selection, { label: value, key: value, id: value }]);
    onChange("");
  };

  return (
    <div className="combo-box-wrapper">
      <Combobox
        id={id}
        menuItemVisibleLength={menuItemVisibleLength}
        events={{
          onChange: (event, { value }) => {
            onChange(value);
          },
          onRequestRemoveSelectedOption: (event, data) => {
            setSelection(data.selection);
          },
          onSelect: (event, data) => {
            setSelection(data.selection);
            onChange("");
          },
          onBlur,
        }}
        labels={{
          label,
        }}
        value={value}
        multiple
        
        options={comboboxFilterAndLimit({
          inputValue: value,
          limit: 1000,
          options: options.filter((op) => {
            const label = op.label || ''; 
            const val = value || '';
            return (typeof label === 'string' && typeof val === 'string') && label.toLowerCase().includes(val.toLowerCase());
          }),
          selection,
        })}
        selection={selection}
        required={required}
        errorText={errorText ? errorText || error : ""}
      />
      {value && (
        <>
          <div className="combo-box-close" onClick={() => { 
              onChange("");
              setUnsavedChanges(false);
          }}>
            <Icon
              assistiveText={{ label: "Clear Text" }}
              category="utility"
              name="close"
              size="xx-small"
            />
          </div>
          <div className="combo-box-add" onClick={handleCustomSelection}>
            <Icon
              assistiveText={{ label: "Add" }}
              category="utility"
              name="new"
              size="xx-small"
            />
          </div>
        </>
      )}
     {
      hasUnsavedChanges && <p className="unsaved_warning_text">This value has not been added!</p>
     }
    </div>
  );
};

export default PCRPredefinedList;
