import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Popover } from '@salesforce/design-system-react';
import PCRTimePicker from "components/common/PCRTimePicker";
import { useTranslation } from "react-i18next";
import "css/comboBox.css";
import "css/PCRMedicationItem.css";
import PCRComboBox from "components/common/PCRComboBox";
import { PCRContext, evaluateError } from "../../../../../context/PCRContext";
import PCRTextInput from "components/common/PCRTextInput";
import { db } from "../../../../../storage/idb";
import { medicationItem as medicationItemBlank } from "../../../../../context/PCRContext";
import { pupilOptions, eyeOptions, verbalOptions, motorOptions } from "data/constants";
import PCRMedicationsTooltipTable from "./PCRMedicationsTooltipTable";


const PCRMedicationItem = ({ pcr, index, onRemove, onChange, onAdd, isLast, medicationFromParent }) => {
  const { t } = useTranslation();

  const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);

  // console.log('medicationFromParent >>>', medicationFromParent);
  // console.log('medicationItemBlank >>>', medicationItemBlank);

  const [medication, setMedication] = useState(medicationFromParent || medicationItemBlank);

  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(false);

  useEffect(() => {
    setIsAnyFieldFilled(Object.values(medication).some(field => field !== ''));
  }, [medication]);


  const updateMedicationItemContext = (field, value) => {
    if (value !== undefined) {
      const newMedication = { ...medication, [field]: value };
      setMedication(newMedication);
      onChange(index, newMedication);
    } else {
      console.warn(`Received undefined value for field: ${field}`);
    }
  };

  const validateNumeric = (value, min, max) => {
    const num = parseFloat(value);
    return !isNaN(num) && (min === undefined || num >= min) && (max === undefined || num <= max);
  };

  const validateGCS = (value) => {
    return ['1', '2', '3', '4', '5', '6', 'NT', 'NE'].includes(value?.toUpperCase());
  };

  const flowRateUnitOptions = [
    { label: '--', value: '--' },
    { label: 'Right', value: 'Right' },
    { label: 'Left', value: 'Left' }
  ]

  const medicationNameOptions = [
    { label: '--', value: '--' },
    { label: 'ACETAMINOPHEN (Tylenol)', value: 'Acetaminophen' },
    { label: 'ADENOSINE', value: 'Adenosine' },
    { label: 'AMIODARONE', value: 'Amiodarone' },
    { label: 'ASA', value: 'Asa' },
    { label: 'ATROPINE', value: 'Atropine' },
    { label: 'CALCIUM GLUCONATE 10%', value: 'Calcium gluconate 10%' },
    { label: 'D10W', value: 'D10W' },
    { label: 'DILTIAZEM HYDROCHLORIDE', value: 'Diltiazem hydrochloride' },
    { label: 'DIMENHYDRINATE (Gravol)', value: 'Dimenhydrinate (Gravol)' },
    { label: 'DIPHENHYDRAMINE (Benadryl)', value: 'Diphenhydramine (Benadryl)' },
    { label: 'DOBUTAMINE', value: 'Dobutamine' },
    { label: 'DOPAMINE', value: 'Dopamine' },
    { label: 'EPINEPHRINE 1:1,000', value: 'Epinephrine 1:1,000' },
    { label: 'EPINEPHRINE 1:10,000 (ACP ONLY)', value: 'Epinephrine 1:10,000 (ACP ONLY)' },
    { label: 'FENTANYL', value: 'Fentanyl' },
    { label: 'FUROSEMIDE', value: 'Furosemide' },
    { label: 'GLUCAGON', value: 'Glucagon' },
    { label: 'HEPARIN, Hypertonic Saline 3%', value: 'Heparin, Hypertonic Saline 3%' },
    { label: 'IBUPROFEN (Advil)', value: 'Ibuprofen (Advil)' },
    { label: 'INSULIN REGULAR', value: 'Insulin regular' },
    { label: 'IPRATROPIUM BROMIDE (Atrovent)', value: 'Ipratropium bromide (Atrovent)' },
    { label: 'KETAMINE', value: 'Ketamine' },
    { label: 'KETOROLAC (Toradol)', value: 'Ketorolac (Toradol)' },
    { label: 'LABETALOL', value: 'Labetalol' },
    { label: 'LEVETIRACETAM (Keppra)', value: 'Levetiracetam (Keppra)' },
    { label: 'LIDOCAINE', value: 'Lidocaine' },
    { label: 'MAGNESIUM SULPHATE', value: 'Magnesium sulphate' },
    { label: 'METOPROLOL', value: 'Metoprolol' },
    { label: 'MIDAZOLAM (Versed)', value: 'Midazolam (Versed)' },
    { label: 'MILRINONE', value: 'Milrinone' },
    { label: 'N-ACETYLCYSTEINE (Mucomyst)', value: 'N-acetylcysteine (Mucomyst)' },
    { label: 'NALOXONE (Narcan)', value: 'Naloxone (Narcan)' },
    { label: 'NITROGLYCERIN', value: 'Nitroglycerin' },
    { label: 'NITROUS OXIDE (Entonox)', value: 'Nitrous oxide (Entonox)' },
    { label: 'NOREPINEPHRINE', value: 'Norepinephrine' },
    { label: 'ONDANSETRON (Zofran)', value: 'Ondansetron (Zofran)' },
    { label: 'ORAL GLUCOSE', value: 'Oral glucose' },
    { label: 'OXYTOCIN', value: 'Oxytocin' },
    { label: 'PANTOPRAZOLE (Protonix)', value: 'Pantoprazole (Protonix)' },
    { label: 'PHENYTOIN', value: 'Phenytoin' },
    { label: 'POTASSIUM CHLORIDE', value: 'Potassium chloride' },
    { label: 'SALBUTAMOL (Ventolin)', value: 'Salbutamol (Ventolin)' },
    { label: 'SODIUM BICARBONATE', value: 'Sodium bicarbonate' },
    { label: 'TETRACAINE', value: 'Tetracaine' },
    { label: 'TRANEXAMIC ACID', value: 'Tranexamic acid' },
  ];

  const unitOptions = [
    { label: '--', value: '--' },
    { label: 'mcg', value: 'mcg' },
    { label: 'mg', value: 'mg' },
    { label: 'g', value: 'g' },
  ];

  const routeOptions = [
    { label: '--', value: '--' },
    { label: 'PO, IV, IO, IM, NEB, ETT, IN, MDI, Inhalation, Eye Drops, Sublingual, IV Pump', value: 'PO, IV, IO, IM, NEB, ETT, IN, MDI, Inhalation, Eye Drops, Sublingual, IV Pump' },
  ];

  const effectOptions = [
    { label: '--', value: '--' },
    { label: 'No noted effect, condition improved, condition deteriorated, symptoms eliminated', value: 'No noted effect, condition improved, condition deteriorated, symptoms eliminated' },
  ];

  const doubleCheckOptions = [
    { label: '--', value: '--' },
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
  ];

  const EMPTY_SPACE_CHAR = String.fromCharCode(160);

  return (
    <div className="slds-grid slds-gutters">
      <div className="slds-col">
        <span>ID:</span>
        <span>{medication.id}</span>
      </div>
      <div className="slds-col medication-name-col">
        <div className="medication-name-wrapper slds-form-element bor-rad ml10">
          <div className="medication-name-label-wrapper">
            <p className="medication-name-label">
              Medication name
              {/* vital signs popover */}
              <>
                <Popover
                  body={<PCRMedicationsTooltipTable />}
                  id="medication-popover"
                  align="top left"
                >
                  <Button
                    assistiveText={{ icon: 'Medication Signs info' }}
                    iconCategory="utility"
                    iconName="info"
                    iconSize="medium"
                    iconVariant="bare"
                    variant="icon"
                  />
                </Popover>
              </>
            </p>
          </div>
          <PCRComboBox
            id="medication-name-combo-box"
            options={medicationNameOptions}
            onChange={(value) => updateMedicationItemContext('medicationName', value)}
            onRequestRemoveSelectedOption={null}
            onSelect={updateMedicationItemContext}            
            name={"medicationName"}
            value={medication.medicationName}
          />
        </div>
      </div>

      <div className="slds-col">
        <PCRTimePicker
          assistiveText={{ label: t("time") }}
          id="time-id"
          className="bor-rad ml10"
          placeholder="HH:MM"
          value={medication.time || ""}
          name="time"
          label={t("time")}
          onChange={(newValue) => {
            if (newValue && newValue.time !== undefined) {
              updateMedicationItemContext('time', newValue.time);
            }
          }}
          disabled={false}
          key={medication?.time}
        />
      </div>
      <div className="slds-col">
        <Input
          label="Dose"
          value={medication.dose}
          onChange={(e) => updateMedicationItemContext('dose', e.target.value)}
          errorText={!validateNumeric(medication.dose, 0) && 'Invalid format'}
        />
      </div>

      <div className="slds-col">
        <PCRComboBox
          id="unit-combo-box"
          options={unitOptions}
          onChange={(value) => updateMedicationItemContext('unit', value)}
          onRequestRemoveSelectedOption={null}
          onSelect={updateMedicationItemContext}
          label={"Unit"}
          required
          name={"unit"}
          value={medication.unit}
        />
      </div>

      <div className="slds-col">
        <PCRComboBox
          id="route-combo-box"
          options={routeOptions}
          onChange={(value) => updateMedicationItemContext('route', value)}
          onRequestRemoveSelectedOption={null}
          onSelect={updateMedicationItemContext}
          label={"Route"}
          required
          name={"route"}
          value={medication.route}
        />
      </div>

      <div className="slds-col amount-discarded-col">
        <Input
          label="Amount discarded"
          value={medication.amountDiscarded}
          onChange={(e) => updateMedicationItemContext('amountDiscarded', e.target.value)}
          errorText={!validateNumeric(medication.amountDiscarded, 0) && 'Invalid format'}
        />
      </div>

      <div className="slds-col">
        <Input
          label="Witness"
          placeholder="Initial"
          value={medication.amountDiscardedWitness}
          onChange={(e) => updateMedicationItemContext('amountDiscardedWitness', e.target.value)}
          errorText={!validateNumeric(medication.amountDiscardedWitness, 0) && 'Invalid format'}
        />
      </div>

      <div className="slds-col">
        <Input
          label={EMPTY_SPACE_CHAR}
          placeholder="ID#"
          value={medication.amountDiscardedIdNumber}
          onChange={(e) => updateMedicationItemContext('amountDiscardedIdNumber', e.target.value)}
          errorText={!validateNumeric(medication.amountDiscardedIdNumber, 0) && 'Invalid format'}
        />
      </div>

      <div className="slds-col">
        <PCRComboBox
          id="effect-combo-box"
          options={effectOptions}
          onChange={(value) => updateMedicationItemContext('effect', value)}
          onRequestRemoveSelectedOption={null}
          onSelect={updateMedicationItemContext}
          label={"Effect"}
          required
          name={"effect"}
          value={medication.effect}
        />
      </div>

      <div className="slds-col">
        <PCRComboBox
          id="double-check-combo-box"
          options={doubleCheckOptions}
          onChange={(value) => updateMedicationItemContext('doubleCheck', value)}
          onRequestRemoveSelectedOption={null}
          onSelect={updateMedicationItemContext}
          label={"Double check"}
          required
          name={"doubleCheck"}
          value={medication.doubleCheck}
        />
      </div>

      <div className="slds-col">
        <Input
          label="Initial"
          value={medication.initial}
          onChange={(e) => updateMedicationItemContext('initial', e.target.value)}
          errorText={!validateNumeric(medication.initial, 0) && 'Invalid format'}
        />
      </div>

      <div className="slds-col">
        <Input
          label="ID#"
          value={medication.idNumber}
          onChange={(e) => updateMedicationItemContext('idNumber', e.target.value)}
          errorText={!validateNumeric(medication.idNumber, 0) && 'Invalid format'}
        />
      </div>

      <div className="slds-col">
        <div className="medication-item-button-group slds-grid slds-gutters">
          {/* delete button */}

          <div className="slds-col">
            <Button
              iconCategory="utility"
              iconName="delete"
              variant="icon"
              className="medication-delete slds-icon-text-error"
              onClick={() => onRemove(index)}
              size="small"
            />
          </div>

          {/* add button */}
          {isLast && (
            <div className="slds-col">
              <Button
                iconCategory="utility"
                iconName="add"
                variant="icon"
                className="medication-add slds-icon-text-success"
                onClick={onAdd}
                size="small"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PCRMedicationItem;