import React from 'react';
import '../css/PCRVitalsTooltipTable.css';

const data = [
  { eyeOpening: "Spontaneous / Spontanée", eyeOpeningScore: 4, verbalResponse: "Oriented / Orientée", verbalResponseScore: 5, motorResponse: "Obeys Commands / Obéissance aux commandes", motorResponseScore: 6 },
  { eyeOpening: "To Sound / Au son", eyeOpeningScore: 3, verbalResponse: "Confused / Confuse", verbalResponseScore: 4, motorResponse: "Localising / Localise", motorResponseScore: 5 },
  { eyeOpening: "To Pressure / À la pression", eyeOpeningScore: 2, verbalResponse: "Words / Mots", verbalResponseScore: 3, motorResponse: "Normal flexion / Flexion normale", motorResponseScore: 4 },
  { eyeOpening: "None / Aucune", eyeOpeningScore: 1, verbalResponse: "Sounds / Sons", verbalResponseScore: 2, motorResponse: "Abnormal flexion / Flexion anormale", motorResponseScore: 3 },
  { eyeOpening: "Non-testable / Non évaluable", eyeOpeningScore: "NT/NE", verbalResponse: "None / Aucune", verbalResponseScore: "1", motorResponse: "Extension / Extension", motorResponseScore: "2" },
  { eyeOpening: "Total", eyeOpeningScore: "   /4   ", verbalResponse: "Non-testable / Non évaluable", verbalResponseScore: "NT/NE", motorResponse: "None / Aucune", motorResponseScore: "1" },
  { eyeOpening: "", eyeOpeningScore: "", verbalResponse: "Total", verbalResponseScore: "/5", motorResponse: "Non-testable / Non évaluable", motorResponseScore: "NT/NE" },
  { eyeOpening: "", eyeOpeningScore: "", verbalResponse: "", verbalResponseScore: "", motorResponse: "Total", motorResponseScore: "/6" },

  
];

export const PCRVitalItemGCSTooltipTable = () => {
  return (
    <table className="vitals-table">
      <thead>
        <tr className="header-row">
          <th colSpan="6" className="header-cell">
            GLASGOW COMA SCALE / SCORE DE GLASGOW
          </th>
        </tr>
        <tr className="header-row">
          <th colSpan="2" className="header-cell">Eye Opening / Ouverture des yeux</th>
          <th colSpan="2" className="header-cell">Verbal Response / Réponse verbale</th>
          <th colSpan="2" className="header-cell">Motor Response / Réponse motrice</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td className="body-cell">{row.eyeOpening}</td>
            <td className="body-cell">{row.eyeOpeningScore}</td>
            <td className="body-cell">{row.verbalResponse}</td>
            <td className="body-cell">{row.verbalResponseScore}</td>
            <td className="body-cell">{row.motorResponse}</td>
            <td className="body-cell">{row.motorResponseScore}</td>
          </tr>
        ))}
         {/* <tr>
          <td className="body-cell invisible-cell"></td>
          <td className="body-cell invisible-cell"></td>
          <td className="body-cell">Total</td>
          <td className="body-cell">/5</td>
          <td className="body-cell">NOn Testable/NonEvaluable</td>
          <td className="body-cell"></td>
        </tr> */}
        {/* <tr>
          <td colSpan="6" className="divider-line"></td>
        </tr> */}
      </tbody>
    </table>
  );
};
