import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import { regExValidations } from "../../data/constants";

export const PCRPhoneNumber = ({
  id,
  label,
  errorText,
  onChange,
  value,
  required = false,
  regExValidationText = "",
  name,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const checkValidation = (val) => {
    if (!val) {
      setError(t("inputIsRequiredError"));
    } else {
      setError();
    }
  };

  useEffect(() => {
    setError(errorText);
  }, [errorText]);

  const handleBlur = () => {
    if (value && !isValidPhoneNumber(value)) {
      setError(t("invalidFormat"));
      return;
    }
    if (required) {
      checkValidation(value);
    }
  };

  const handleChange = (value) => {
    if (error) {
      checkValidation(value);
    }
    if (onChange) {
      if(regExValidationText){
        var fieldRegex = new RegExp(regExValidations[regExValidationText]);
        if (!fieldRegex.test(value)) {
          setError(t("invalidFormat"));
        }
      }
      else if(!regExValidationText && value?.length){
       
        if(!isValidPhoneNumber(value))  {
          setError(t("invalidFormat"));
        }      
      }
      else{
        setError("")
      }
      onChange({ [name]: value });
    }
  };
  return (
    <div
      className={`${error ? "slds-has-error" : ""} slds-form-element bor-rad`}
    >
      <label class="slds-form-element__label" for="patient-details-telephone">
        {required && (
          <abbr class="slds-required" title="required">
            *
          </abbr>
        )}
        {label}
      </label>
      <div className="slds-form-element__control">
        <PhoneInput
          id={id}
          className="slds-input"
          country="CA"
          defaultCountry="CA"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {error && (
        <div className="slds-form-element__help">{error || errorText}</div>
      )}
    </div>
  );
};
