import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  RadioGroup,
  Radio,
  Checkbox,
  Datepicker,
  Icon,
  Modal,
  Input,
} from "@salesforce/design-system-react";
import "../../../../css/identification.css";
import "../../../../css/comboBox.css";
import {
  traumaDetails,
  transferOptions,
  uniqueIdentifierTypeOptions,
  genderIdentify,
  relationShipsOptions,
  interfacilityTransfer,
  provinceList,
  sexAtBirthDetails,
  countries,
  ageUnitOptions,
  regExValidations,
  softValidateOptions,
} from "../../../../data/constants";
import { convertKgToLbs, isValidMedicareNumber } from "../../../../utils/util";

import PCRComboBox from "../../../common/PCRComboBox";
import { useTranslation } from "react-i18next";
import PCRAddressCompleteComboBox from "../../../common/PCRAddressCompleteComboBox";
import { useAddressComplete } from "../../../../hooks/useAddressComplete";
import { PCRContext, evaluateError } from "../../../../context/PCRContext";
import PCRTextInput from "../../../common/PCRTextInput";
import PDFViewer from "../../../PDFViewer";
import moment from "moment";
import { PCRPhoneNumber } from "../../../common/PCRPhoneNumber";
// import { regExValidations } from "../data/constants";
import "moment/min/locales";

const IdentificationPatientDetails = ({ pcr, handleCancelConfirmation }) => {
  const { t } = useTranslation();
  const {
    pcrData,
    setPCRData,
    formValidationConfig,
    showSoftValidationModal,
    softValidateOtherErr,
    softValidationRequiredErr,
    setSoftValidationRequiredErr
  } = useContext(PCRContext);

  const { identificationDetails } = pcrData;
  const identification = identificationDetails;
  const patient = identificationDetails?.patient;

  const [ageUnit, setAgeUnit] = useState(patient?.ageUnit || "Y");

  const { getAddressDetails } = useAddressComplete();
  const [openModal, setOpenModal] = useState(false);
  const [openRefusalModal, setOpenRefusalModal] = useState(false);
  const [showRiskAssessmentTool, setShowRiskAssessmentTool] = useState(false);
  const [transferOps, setTransferOps] = useState(transferOptions);
  const [transferCheckErr, setTransferCheckErr] = useState("");

  const [dateChangeKey, setDateChangeKey] = useState(0);
  const [patientAge, setPatientAge] = useState(0);
  const [countryString, setCountryString] = useState("");
  const [provinceString, setProvinceString] = useState("");
  const [interFacilityString, setInterFacilityString] = useState("");
  const [localPatientDob, setLocalPatientDob] = useState(
    patient?.dateOfBirth || ""
  );
  const [dobError, setDobError] = useState("");
  const [locale, setLocale] = useState(localStorage.getItem("lang"));
  const [selectedSoftValidateOp, setSelectedSoftValidateOp] = useState(null);

  // moment.locale(localStorage.getItem("lang"));

  useEffect(() => {
    setLocale(localStorage.getItem("lang"));
  });

  useEffect(() => {
    if (patient?.uniqueIdentifierType === "Medicare Number") {
      const isValidMedic = isValidMedicareNumber(patient?.uniqueIdentifier);
      if (!isValidMedic) {
        setPCRData({
          ...pcrData,
          ...{ uniqueIdentifierError: t("invalidFormat") },
        });
      } else {
        setPCRData({ ...pcrData, ...{ uniqueIdentifierError: "" } });
      }
    }
  }, [patient]);

  // need to optimize
  useEffect(() => {
    if (patient?.weightKgs && patient?.weightLbs) {
      setPCRData({ ...pcrData, ...{ weightKgsError: "", weightLbsError: "" } });
    }
  }, [patient?.weightKgs]);

  useEffect(() => setAgeBasedOnDOB(patient), [ageUnit, patient?.dateOfBirth]);

  useEffect(() => {
    if (patient?.addressCountryCode) {
      setCountryString(
        countries.find((c) => c.value === patient?.addressCountryCode)?.label
      );
    }
    if (patient?.provinceSelected) {
      setProvinceString(
        provinceList.find(
          (province) => province.value === patient?.provinceSelected
        )?.label
      );
    }

    if (identification.interfacilityTransferReason) {
      setInterFacilityString(
        interfacilityTransfer.find(
          (rec) => rec.label === identification.interfacilityTransferReason
        )?.label
      );
    }
  }, []);

  const handleIdntifierBlur = () => {
    // placeholder function to handle
    // blur mandatory to display error
  };

  const onRadioChange = ({ target: { value } }) => {
    setAgeUnit(value);
    updatePCRContext({ ageUnit: value }, "Patient");
  };

  const handleDate = (date, e) => {
    var fieldRegex = new RegExp(regExValidations["DATE_REG"]);

    if (!fieldRegex.test(date)) {
      setLocalPatientDob(date);
      if (date.length === 8 && !date.includes("-")) {
        var dateObj = moment(date, "YYYY-MM-DD");
        var formattedDate = dateObj.format("YYYY-MM-DD");
        setLocalPatientDob(formattedDate);
        processDate(formattedDate);
        setDobError("");
        return;
      }
      if (date.length === 10 && date.includes("-")) {
        setDobError("");
        processDate(date);
        return;
      } else {
        setDobError(t("invalidFormat"));
      }
    }

    if (fieldRegex.test(date)) {
      setDobError("");
      processDate(date);
    }
  };

  const processDate = (date) => {
    if (date) {
      setLocalPatientDob(date);

      updatePCRContext({ dateOfBirth: date }, "Patient");

      const parsedDate = moment(date, "YYYY-MM-DD");
      const today = moment();
      const diffInMonths = today.diff(parsedDate, "months");
      if (diffInMonths < 12 && diffInMonths >= 1) {
        setAgeUnit("M");
      } else if (diffInMonths < 1) {
        setAgeUnit("D");
      } else {
        setAgeUnit("Y");
      }
      setAgeBasedOnDOB({ dateOfBirth: date });
    }
  };

  const setAgeBasedOnDOB = (value) => {
    const parsedDate = moment(value?.dateOfBirth, "YYYY-MM-DD");
    const today = moment();

    if (!value?.dateOfBirth) {
      return;
    }

    if (value?.dateOfBirth) {
      const calculatedAge = today.diff(parsedDate, ageUnit.toLocaleLowerCase());
      setPatientAge(calculatedAge);
      updatePCRContext(
        {
          age: today.diff(parsedDate, ageUnit.toLocaleLowerCase()),
        },
        "Patient"
      );
      // setTimeout(() => {

      // }, 400);
    }
  };

  const toggleOpen = () => setOpenModal(!openModal);

  const onChangeWeights = (value, weight) => {
    if (!value) {
      updatePCRContext(
        {
          weightKgs: "",
          weightLbs: "",
        },
        "Patient"
      );
    } else {
      if (weight === "lbs") {
        updatePCRContext(
          {
            weightLbs: isNaN(parseInt(value)) ? "" : parseInt(value),
            weightKgs: convertKgToLbs(
              isNaN(parseInt(value)) ? "" : parseInt(value),
              "lbs"
            ),
          },
          "Patient"
        );
      } else {
        updatePCRContext(
          {
            weightKgs: isNaN(parseInt(value)) ? "" : parseInt(value),
            weightLbs: isNaN(convertKgToLbs(parseInt(value)))
              ? ""
              : convertKgToLbs(parseInt(value)),
          },
          "Patient"
        );
      }
    }
  };

  const updatePCRContext = (updatedAttrObj, parentObj = "Identification") => {

    let error = {};
    const field = formValidationConfig.find(
      (f) => f.name === Object.keys(updatedAttrObj)[0]
    );

    if (
      field?.shouldDisplayError &&
      !updatedAttrObj[Object.keys(updatedAttrObj)[0]]
    ) {
      error[Object.keys(updatedAttrObj)[0] + "Error"] = t(
        "inputIsRequiredError"
      );
    }
    if (
      field?.shouldDisplayError &&
      updatedAttrObj[Object.keys(updatedAttrObj)[0]]
    ) {
      error[Object.keys(updatedAttrObj)[0] + "Error"] = "";
    }

    if (parentObj === "Identification") {
      setPCRData({
        ...pcrData,
        identificationDetails: {
          ...pcrData.identificationDetails,
          ...updatedAttrObj,
        },
        ...error,
      });
    }
    if (parentObj === "Patient") {
      setPCRData((prevData) => ({
        ...prevData,
        identificationDetails: {
          ...prevData.identificationDetails,
          patient: {
            ...prevData.identificationDetails.patient,
            ...updatedAttrObj,
          },
        },
        ...error,
      }));
    }
  };

  const handleCancelFromModal = () => {
    setOpenModal(false);
    handleCancelConfirmation();
  };

  const handleRAT = () => setShowRiskAssessmentTool(!showRiskAssessmentTool);

  // useEffect(() => {
  //   if (
  //     identification.interfacilityTransferReason === "" &&
  //     interFacilityString != ""
  //   ) {
  //     updatePCRContext({ interfacilityTransferReason: null });
  //     setInterFacilityString("");
  //   }
  // }, [identification]);

  useEffect(() => {
    if (!patient?.addressCountryCode && patient?.provinceSelected) {
      updatePCRContext({ provinceSelected: "" });
      setCountryString("");
      setProvinceString("");
    }
  }, [patient?.addressCountryCode]);

  const handleTransferOption = (data) => {
    let tempTransferOps = [...transferOps];
    tempTransferOps.forEach((op) => {
      if (op.id === data.id) op.value = !op.value;
    });
    setTransferOps(tempTransferOps);
    setTimeout(() => {
      handleTransferBlur();
    }, 300);
  };

  const handleTransferBlur = () => {
    // value to hold no of options selected min 2 should be selected
    let selectedOptions = 0;
    transferOps.forEach((op) => {
      if (op.value) {
        selectedOptions = selectedOptions + 1;
      }
    });

    let nameOps = transferOps.find((op) => op.id === "name");

    if (!nameOps.value && selectedOptions > 0) {
      setTransferCheckErr("Name must be selected!");
    }
    if (selectedOptions === 1) {
      setTransferCheckErr("Two values must be selected!");
    }

    if ((selectedOptions >= 2 && nameOps.value) || selectedOptions === 0) {
      setTransferCheckErr("");
    }
  };

  const handlePatientAge = (val) => {
    setPatientAge(val.age);
    updatePCRContext(val, "Patient");
    setLocalPatientDob("");
  };

  const getFormattedDate = (val) => {
    if (val) {
      var dateObj = moment(val, "YYYY-MM-DD");
      var shortMonthUppercase = dateObj.format("YYYY-MMM-DD").toUpperCase();
      return shortMonthUppercase;
    } else {
      return "";
    }
  };

  const getPatientAddress = () => {
    return [
      patient?.addressStreet,
      patient?.addressCity,
      patient?.addressStateCode,
      patient?.addressPostalCode,
      patient?.addressCountryCode,
    ]
      .filter(Boolean)
      .join(" ");
  };

  return (
    <>
      <PDFViewer
        showPDF={showRiskAssessmentTool}
        onDone={handleRAT}
        fileName={"Risk_Assessment_Tool.pdf"}
      />
      <div className="df border-bottom-styles">
        <span className="details-icon patient-details-icon">
          <Icon
            assistiveText={{ label: "Patient Details" }}
            category="utility"
            name="user"
            size="x-small"
          />
        </span>
        <span className="section-header">Patient Details</span>
      </div>
      <div className="m-t-b">
        <div className="df h-4half-r">
          <div className="w-20">
            <div className="slds-form-element">
              <PCRComboBox
                id="unique-combo-box"
                options={uniqueIdentifierTypeOptions}
                onChange={(value) => updatePCRContext(value, "Patient")}
                onRequestRemoveSelectedOption={null}
                onSelect={(value) => updatePCRContext(value, "Patient")}
                label={"Patient unique identifier type"}
                required
                name={"uniqueIdentifierType"}
                value={patient?.uniqueIdentifierType}
                errorText={
                  pcrData?.uniqueIdentifierTypeError && (
                    <div className="df">
                      <p className="margin-err-text-2">
                        {pcrData?.uniqueIdentifierTypeError}
                      </p>
                    </div>
                  )
                }
              />
            </div>
          </div>
          {identification?.unique !== "Not available" && (
            <div className="w-30">
              <PCRTextInput
                assistiveText={{ label: "My label" }}
                id="patient-unique-id"
                placeholder=""
                label="Patient unique identifier #"
                className="bor-rad"
                name="uniqueIdentifier"
                onChange={(e) => updatePCRContext(e, "Patient")}
                value={patient?.uniqueIdentifier}
                required={
                  identification?.noPatientsAssessedReason !==
                    "No patient found" &&
                  identification?.noPatientsAssessedReason !== "Patient refusal"
                }
                errorText={
                  pcrData?.uniqueIdentifierError && (
                    <div className="df">
                      <p className="margin-err-text">
                        {pcrData?.uniqueIdentifierError}
                      </p>
                    </div>
                  )
                }
                onBlur={handleIdntifierBlur}
              />
            </div>
          )}
        </div>
        <div className="df h-4half-r ">
          <div className="w-40">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="surname-id"
              placeholder=""
              label="Surname of patient"
              name="lastName"
              onChange={(e) => {
                updatePCRContext(e, "Patient");
              }}
              value={patient?.lastName}
              className="bor-rad"
              required={
                identification?.noPatientsAssessedReason !== "No patient found"
              }
              errorText={
                pcrData?.lastNameError && (
                  <div className="df">
                    <p className="margin-err-text">{pcrData?.lastNameError}</p>
                  </div>
                )
              }
            />
          </div>
          <div className="w-40">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="givenName-id"
              placeholder=""
              label="Given name"
              className="bor-rad"
              required={
                identification?.noPatientsAssessedReason !== "No patient found"
              }
              name={"firstName"}
              onChange={(e) => {
                updatePCRContext(e, "Patient");
              }}
              onError={(e) => {
                updatePCRContext(e, "Identification");
              }}
              value={patient?.firstName}
              errorText={
                pcrData?.firstNameError && (
                  <div className="df">
                    <p className="margin-err-text">{pcrData?.firstNameError}</p>
                  </div>
                )
              }
            />
          </div>
          <div className="w-20">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="givenNameInitial-id"
              placeholder=""
              label="Given initials name"
              className="bor-rad"
              name={"initial"}
              value={patient?.initial}
              required={false}
              onChange={(e) => {
                updatePCRContext(e, "Patient");
              }}
              onError={(e) => {
                updatePCRContext(e, "Identification");
              }}
            />
          </div>
        </div>
        <div className="df h-4half-r ">
          <div className="w-80 combo-box-wrapper">
            <PCRTextInput
              required={
                identification?.noPatientsAssessedReason !== "No patient found"
              }
              assistiveText={{ label: "My label" }}
              id="combobox-patient-address"
              placeholder=""
              label="Patient address"
              className="bor-rad"
              name={"addressStreet"}
              value={patient?.addressStreet}
              onChange={(e) => {
                updatePCRContext(e, "Patient");
              }}
              onError={(e) => {
                updatePCRContext(e, "Identification");
              }}
              errorText={
                pcrData?.addressStreetError && (
                  <div className="df">
                    <p className="margin-err-text">
                      {pcrData?.addressStreetError}
                    </p>
                  </div>
                )
              }
            />
          </div>
          <div className="w-20">
            <div className="slds-form-element">
              <PCRComboBox
                id={"countries"}
                options={countries.filter((country) =>
                  country?.label
                    ?.toLowerCase()
                    .includes(countryString?.toLowerCase())
                )}
                onChange={(val) => {
                  setCountryString(val);
                }}
                onSelect={(val) => {
                  updatePCRContext(val, "Patient");
                  setCountryString(
                    val.addressCountryCode != ""
                      ? countries.find(
                          (c) => c.value === val.addressCountryCode
                        )?.label
                      : ""
                  );
                }}
                label={"Country"}
                name={"addressCountryCode"}
                value={countryString}
                selectAttribute={"value"}
                required={
                  identification?.noPatientsAssessedReason !==
                  "No patient found"
                }
                errorText={
                  pcrData?.addressCountryCodeError && (
                    <div className="df">
                      <p className="margin-err-text-2">
                        {pcrData?.addressCountryCodeError}
                      </p>
                    </div>
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="df h-4half-r ">
          <div className="w-25">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="patient-details-city"
              placeholder=""
              label="Town"
              className="bor-rad"
              name="addressCity"
              onChange={(e) => {
                updatePCRContext(e, "Patient");
              }}
              onError={(e) => {
                updatePCRContext(e, "Identification");
              }}
              value={patient?.addressCity}
              required={
                identification?.noPatientsAssessedReason !== "No patient found"
              }
              errorText={
                pcrData?.addressCityError && (
                  <div className="df">
                    <p className="margin-err-text">
                      {" "}
                      {pcrData?.addressCityError}
                    </p>
                  </div>
                )
              }
            />
          </div>
          <div className="w-25">
            <div className="slds-form-element">
              <PCRComboBox
                id="province"
                required={
                  identification?.noPatientsAssessedReason !==
                  "No patient found"
                }
                options={provinceList.filter((province) =>
                  province?.label
                    ?.toLowerCase()
                    .includes(provinceString?.toLowerCase())
                )}
                label={"Province"}
                name={"addressStateCode"}
                onChange={(value) => setProvinceString(value)}
                onSelect={(val) => {
                  setProvinceString(
                    val.provinceSelected != ""
                      ? provinceList.find(
                          (province) => province.value === val.addressStateCode
                        )?.label
                      : ""
                  );
                  updatePCRContext(val, "Patient")
                }}
                value={provinceString}
                selectAttribute={"value"}
                errorText={
                  pcrData?.addressStateCodeError && (
                    <div className="df">
                      <p className="margin-err-text">
                        {pcrData?.addressStateCodeError}
                      </p>
                    </div>
                  )
                }
                saveOnBlur={false}

              />
            </div>
          </div>
          <div className="w-25">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="postalCode"
              placeholder=""
              label="Postal code"
              className="bor-rad"
              onChange={(e) => {
                updatePCRContext(e, "Patient");
              }}
              onError={(e) => {
                updatePCRContext(e, "Identification");
              }}
              name={"addressPostalCode"}
              value={patient?.addressPostalCode}
              required={
                identification?.noPatientsAssessedReason !== "No patient found"
              }
              regExValidationText={
                patient?.addressCountryCode === "CA" ? "POSTALCODE_CA_REG" : ""
              }
              errorText={
                pcrData?.addressPostalCodeError && (
                  <div className="df">
                    <p className="margin-err-text">
                      {pcrData?.addressPostalCodeError}
                    </p>
                  </div>
                )
              }
              validateOnBlur={false}
            />
          </div>
          <div className="w-25">
            <PCRPhoneNumber
              required={
                identification?.noPatientsAssessedReason !== "No patient found"
              }
              id={"patient-details-telephone"}
              label={t("Telephone")}
              value={patient?.telephone}
              onChange={(e) => updatePCRContext(e, "Patient")}
              regExValidationText={
                patient?.addressCountryCode === "CA" ? "TELEPHONE_CA_REG" : ""
              }
              name="telephone"
              errorText={
                identification?.noPatientsAssessedReason !==
                  "No patient found" &&
                pcrData?.telephoneError && (
                  <div className="df">
                    <p className="margin-err-text">{pcrData?.telephoneError}</p>
                  </div>
                )
              }
            />
          </div>
        </div>
        <div className="disp-align-end h-4half-r ">
          <div className="w-25 date">
            <Input
              assistiveText={{ label: "Date Of Birth" }}
              id="patientDob"
              placeholder="YYYY-MM-DD"
              label="Date Of Birth"
              className="bor-rad"
              onChange={(e) => {
                if (!e.target.value) {
                  setPatientAge("");
                }
                setLocalPatientDob(e.target.value);
                handleDate(e.target.value, e);
              }}
              name={"dateOfBirth"}
              required={
                identification?.noPatientsAssessedReason !== "No patient found"
              }
              value={localPatientDob}
              // hasError={dobError.length ? true : false}
              errorText={
                pcrData?.dateOfBirthError && (
                  <div className="df">
                    <p className="margin-err-text">
                      {pcrData?.dateOfBirthError}
                    </p>
                  </div>
                )
              }
              onBlur={(e) => {
                if (!e.target.value) {
                  setPCRData({
                    ...pcrData,
                    ...{ dateOfBirthError: t("inputIsRequiredError") },
                  });
                }
              }}
            />
            {patient?.dateOfBirthError && patientAge ? (
              <p className="text-right-date-lb" key={patient?.dateOfBirthError}>
                {getFormattedDate(patient?.dateOfBirth)}
              </p>
            ) : (
              " "
            )}
          </div>
          <div className="w-25 df">
            <div className="w-50">
              <PCRTextInput
                assistiveText={{ label: "My label" }}
                id="age-id"
                placeholder=""
                label="Age"
                className="bor-rad"
                required={
                  identification?.noPatientsAssessedReason !==
                  "No patient found"
                }
                name={"age"}
                onChange={(e) => {
                  handlePatientAge(e);
                }}
                onBlur={() => {
                  updatePCRContext({ age: null }, "Patient");
                  setDateChangeKey(dateChangeKey + 1);
                }}
                onError={(e) => {
                  updatePCRContext(e, "Identification");
                }}
                value={patientAge}
                errorText={
                  pcrData?.ageError && (
                    <div className="df">
                      <p className="margin-err-text">{pcrData?.ageError}</p>
                    </div>
                  )
                }
              />
            </div>
            <div className="w-50">
              <RadioGroup
                labels={{ label: "Age unit" }}
                onChange={onRadioChange}
                required={
                  identification?.noPatientsAssessedReason !==
                  "No patient found"
                }
                name="age-unit-radio-group"
                className="disp-align-end"
              >
                {ageUnitOptions.map((ele) => {
                  return (
                    <Radio
                      id={`age-unit-${ele?.id}`}
                      labels={{ label: ele.label }}
                      value={ele?.value}
                      checked={ageUnit === ele?.value}
                      variant="base"
                    />
                  );
                })}
              </RadioGroup>
            </div>
          </div>
          <div className="w-25 df">
            <div className="w-50">
              {
                <label className="slds-form-element__label" for="vehicleTypes">
                  {identification?.noPatientsAssessedReason !==
                    "No patient found" && (
                    <abbr class="slds-required" title="required">
                      *
                    </abbr>
                  )}
                  Weight (kg/lb)
                </label>
              }
              <div className="df">
                <PCRTextInput
                  assistiveText={{ label: "My label" }}
                  id="weightkgs-id"
                  placeholder="kg"
                  required={
                    identification?.noPatientsAssessedReason !==
                    "No patient found"
                  }
                  value={patient?.weightKgs}
                  label=""
                  name="weightKgs"
                  className="bor-rad w-50"
                  onChange={({ weightKgs }) =>
                    onChangeWeights(weightKgs, "kgs")
                  }
                  onError={(e) => updatePCRContext(e, "Identification")}
                  errorText={
                    pcrData?.weightKgsError && (
                      <div className="df">
                        <p className="margin-err-text">
                          {" "}
                          {pcrData?.weightKgsError}
                        </p>
                      </div>
                    )
                  }
                />
                <PCRTextInput
                  assistiveText={{ label: "My label" }}
                  id="weightlbs-id"
                  placeholder="lb"
                  label=""
                  value={patient?.weightLbs}
                  name="weightLbs"
                  className="bor-rad w-50 pad-r-25"
                  onChange={({ weightLbs }) =>
                    onChangeWeights(weightLbs, "lbs")
                  }
                  onError={(e) => updatePCRContext(e, "Identification")}
                  required={
                    identification?.noPatientsAssessedReason !==
                    "No patient found"
                  }
                  errorText={
                    pcrData?.weightKgsError && (
                      <div className="df">
                        <p className="margin-err-text">
                          {" "}
                          {pcrData?.weightKgsError}
                        </p>
                      </div>
                    )
                  }
                />
              </div>
            </div>
            <div className="w-50">
              <RadioGroup
                labels={{ label: "Sex at birth" }}
                onChange={(e) =>
                  updatePCRContext({ sexAtBirth: e.target.value }, "Patient")
                }
                required={
                  identification?.noPatientsAssessedReason !==
                  "No patient found"
                }
                name="sex-at-birth-group"
                className="disp-align-end"
              >
                {sexAtBirthDetails.map((ele) => {
                  return (
                    <Radio
                      id={`isPatientTransported-${ele.label}`}
                      labels={{ label: ele.label }}
                      value={ele?.value}
                      checked={patient?.sexAtBirth === ele?.value}
                      variant="base"
                      className={ele?.cls || ""}
                    />
                  );
                })}
              </RadioGroup>
              <div className="slds-form-element__help selectionError">
                {pcrData?.sexAtBirthError && <>{pcrData?.sexAtBirthError}</>}
              </div>
            </div>
          </div>

          <div className="w-25 m-r-25">
            <div className="slds-form-element">
              <PCRComboBox
                id="genderIdentity"
                required={false}
                label={"Gender identify"}
                name={"genderIdentity"}
                options={genderIdentify}
                onSelect={updatePCRContext}
                onChange={(value) =>
                  updatePCRContext({ genderIdentity: value }, "Patient")
                }
                value={patient?.genderIdentity}
              />
            </div>
          </div>
        </div>
        <div className="df h-4half-r ">
          <div className="w-25">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="nextToKin-id"
              placeholder=""
              label="Next to kin"
              className="bor-rad"
              required={
                ((patientAge < 16 && patientAge >= 1 && ageUnit === "Years") ||
                  (ageUnit && ageUnit !== "Years")) &&
                identification?.noPatientsAssessedReason !== "No patient found"
              }
              name="nextOfKin"
              value={patient?.nextOfKin}
              onChange={(e) => updatePCRContext(e, "Patient")}
              onError={(e) => updatePCRContext(e, "Identification")}
              errorText={
                pcrData?.nextOfKinError && (
                  <div className="df">
                    <p className="margin-err-text-2">
                      {pcrData?.nextOfKinError}
                    </p>
                  </div>
                )
              }
            />
          </div>
          <div className="w-25">
            <div className="slds-form-element">
              <PCRComboBox
                id="nextTorelationShip-combo-box"
                options={relationShipsOptions}
                onRequestRemoveSelectedOption={null}
                label={"Relationship"}
                required={
                  ((patientAge < 16 &&
                    patientAge >= 1 &&
                    ageUnit === "Years") ||
                    (ageUnit && ageUnit !== "Years")) &&
                  identification?.noPatientsAssessedReason !==
                    "No patient found"
                }
                value={patient?.nextOfKinRelationship}
                name="nextOfKinRelationship"
                onChange={(value) => updatePCRContext(value, "Patient")}
                onSelect={(e) => updatePCRContext(e, "Patient")}
                errorText={
                  pcrData?.nextOfKinRelationshipError && (
                    <div className="df">
                      <p className="margin-err-text-2">
                        {pcrData?.nextOfKinRelationshipError}
                      </p>
                    </div>
                  )
                }
              />
            </div>
          </div>
          <div className="w-25">
            <PCRPhoneNumber
              required={
                ((patientAge < 16 && patientAge >= 1 && ageUnit === "Years") ||
                  (ageUnit && ageUnit !== "Years")) &&
                identification?.noPatientsAssessedReason !== "No patient found"
              }
              id={"kin-telephone"}
              label={t("telephone")}
              value={patient?.nextOfKinTelephone}
              onChange={(e) => updatePCRContext(e, "Patient")}
              name={"nextOfKinTelephone"}
              regExValidationText={
                identification.addressCountryCode === "CA"
                  ? "TELEPHONE_CA_REG"
                  : ""
              }
              errorText={
                pcrData?.nextOfKinTelephoneError && (
                  <div className="df">
                    <p className="margin-err-text-2">
                      {pcrData?.nextOfKinTelephoneError}
                    </p>
                  </div>
                )
              }
            />
          </div>
        </div>
        <div className="disp-align-end just-cont h-4half-r">
          <div className="patient-alignment pad-l-r-20">
            <label className="slds-form-element__label" for="vehicleTypes">
              Transfers: Check two patient identifier
              <Button
                assistiveText={{ icon: "info" }}
                iconCategory="utility"
                iconName="info"
                iconSize="medium"
                variant="icon"
                onClick={handleRAT}
              />
            </label>
            <div className="align-end">
              {transferOptions.map((ele) => {
                return (
                  <Checkbox
                    assistiveText={{
                      label: "Default",
                    }}
                    id={`checkbox-example-${ele.id}`}
                    labels={{
                      label: ele.label,
                    }}
                    onChange={() => handleTransferOption(ele)}
                    onBlur={() => handleTransferBlur()}
                    value={ele.value}
                  />
                );
              })}
            </div>
            <div>
              {transferCheckErr === "Two values must be selected!" && (
                <div className="df">
                  <div className="m-2-r-2">
                    <Icon
                      assistiveText={{ label: "warning" }}
                      category="utility"
                      name="warning"
                      size="xx-small"
                      colorVariant="warning"
                    />
                  </div>
                  <div className="multiple-pcrs">
                    <p className="transfer-check-warn"> {transferCheckErr} </p>
                  </div>
                </div>
              )}
            </div>
            <div>
              {transferCheckErr === "Name must be selected!" && (
                <p className="transfer-check-err"> {transferCheckErr} </p>
              )}
            </div>
          </div>
          <div className="w-25">
            <div className="slds-form-element">
              <PCRComboBox
                id="interfacility-combo-box"
                options={interfacilityTransfer.filter(
                  (rec) =>
                    rec.value
                      ?.toLowerCase()
                      .includes(interFacilityString?.toLocaleLowerCase()) ||
                    interfacilityTransfer
                )}
                onChange={(val) =>
                  setInterFacilityString(val, "Identification")
                }
                onRequestRemoveSelectedOption={null}
                onSelect={(val) => {
                  updatePCRContext(val, "Identification");
                  setInterFacilityString(
                    interfacilityTransfer.find(
                      (rec) => rec.label == val.interfacilityTransferReason
                    )?.label
                  );
                }}
                label={"Interfacility transfer reason - diagnosis"}
                required={false}
                selectAttribute={"label"}
                name={"interfacilityTransferReason"}
                value={interFacilityString}
              />
            </div>
          </div>
          <div className="radio-alignment w-45 m-r-26">
            {identification?.interfacilityTransferReason === "14- Trauma" ? (
              <RadioGroup
                labels={{ label: "Trauma details, please specify " }}
                required={
                  ((identification?.noPatientsAssessedReason ===
                    "Patient refusal" ||
                    identification?.patientTransported === "Yes") &&
                    identification?.interfacilityTransferReason ===
                      "14- Trauma") ||
                  (identification?.interfacilityTransferReason ===
                    "14- Trauma" &&
                    !identification?.numberOfPatientsAssessed)
                }
                name="traumaDetails"
                className="disp-align-end"
                onChange={(e) => {
                  updatePCRContext({ traumaDetails: e?.target?.value });
                  setPCRData({
                    ...pcrData,
                    ...{
                      traumaDetailsError: "",
                      reasonSpecificationError: "",
                    },
                  });
                }}
              >
                {traumaDetails.map((ele) => {
                  return (
                    <Radio
                      id={`trauma-details-${ele.id}`}
                      labels={{ label: ele.label }}
                      variant="base"
                      value={ele?.value}
                    />
                  );
                })}
              </RadioGroup>
            ) : null}
            {identification?.interfacilityTransferReason === "14- Trauma" &&
              pcrData?.traumaDetailsError && (
                <div className="df">
                  <p className="margin-err-text">
                    {pcrData?.traumaDetailsError}
                  </p>
                </div>
              )}
            {identification?.interfacilityTransferReason === "15- Other" ? (
              <PCRTextInput
                id="patient-inter"
                label="Please specify reason"
                required={
                  ((identification?.noPatientsAssessedReason ===
                    "Patient refusal" ||
                    identification?.patientTransported === "Yes") &&
                    identification?.interfacilityTransferReason ===
                      "15- Other") ||
                  (identification?.interfacilityTransferReason ===
                    "15- Other" &&
                    !identification?.numberOfPatientsAssessed)
                }
                className=""
                value={identification?.reasonSpecification}
                name="reasonSpecification"
                onChange={updatePCRContext}
                errorText={
                  pcrData?.reasonSpecificationError && (
                    <div className="df">
                      <p className="margin-err-text">
                        {" "}
                        {pcrData?.reasonSpecificationError}
                      </p>
                    </div>
                  )
                }
              />
            ) : null}
          </div>
        </div>
        {showSoftValidationModal && (
          <div class="mb30">
            <div id="soft-validate-sec-id ">
              <div className="df border-bottom-styles mb10 pb10">
                <Icon
                  assistiveText={{ label: "warning" }}
                  category="utility"
                  name="fallback"
                  size="small"
                  style={{
                    fill: "#f3a005",
                  }}
                />
                <p className="warning-softValidate-title">Warning</p>
              </div>
              <PCRComboBox
                id="softvalidate-combo-box"
                options={softValidateOptions}
                onChange={(val) => setSelectedSoftValidateOp(val)}
                // onRequestRemoveSelectedOption={null}
                name={"softValidateOption"}
                onSelect={(val) => {
                  if(val?.softValidateOption){
                    setSoftValidationRequiredErr("");
                  }
                  setSelectedSoftValidateOp(val);
                  updatePCRContext(val,"Identification");
                 
                }}
                label={t("softValidateOptionLabel")}
                value={identification?.softValidateOption}
                placeholder="Select"
                selectAttribute={"value"}
                lowerCaseRequired={false}
                saveOnBlur={false}
                required={true}
                errorText={
                  softValidationRequiredErr && (
                    <div className="df">
                      <p className="margin-err-text">
                        {softValidationRequiredErr}
                      </p>
                    </div>
                  )
                }
              />

              {identification?.softValidateOption === "Other" && (
                <>
                  <PCRTextInput
                    id="softValidate_op_Other"
                    label="Please specify reason"
                    required={
                      selectedSoftValidateOp?.softValidateOption === "Other"
                    }
                    className=""
                    name="softValidateOtherReason"
                    onChange={updatePCRContext}
                    value={identification.softValidateOtherReason}
                    errorText={
                      softValidateOtherErr && (
                        <div className="df">
                          <p className="margin-err-text">
                            {softValidateOtherErr}
                          </p>
                        </div>
                      )
                    }
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        footer={[
          <Button key="promptBtn" label="save" onClick={toggleOpen} />,
          <Button label="Don't Save" onClick={toggleOpen} />,
          <Button label="Cancel" onClick={handleCancelFromModal} />,
        ]}
        isOpen={openModal}
        onRequestClose={toggleOpen}
        prompt="warning"
        size="medium"
        title={<span>Warning</span>}
        header={"Warning"}
      >
        <div className="slds-m-around_medium modalDesc">
          <div>
            You have unsaved changes. Closing now will discard the changes.
          </div>
          <div>How would you like to proceed?</div>
        </div>
      </Modal>
      <Modal
        footer={[
          <Button
            label="Create Refusal Form"
            onClick={() => setOpenRefusalModal(false)}
          />,
          <Button
            label="Do it later"
            onClick={() => setOpenRefusalModal(false)}
          />,
        ]}
        isOpen={openRefusalModal}
        onRequestClose={() => setOpenRefusalModal(false)}
        prompt="warning"
        size="medium"
        title={<span>Warning : Refusal Form missing </span>}
      >
        <div className="slds-m-around_medium modal-desc">
          <div>A refusal form must be completed for this PCR</div>
        </div>
      </Modal>
    </>
  );
};

export default IdentificationPatientDetails;
