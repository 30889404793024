export const APP_CONFIG = {
    PWA_REDIRECT_URL: process.env.REACT_APP_PWA_REDIRECT_URL,
    SF_URL: process.env.REACT_APP_SF_URL,
    SF_CLIENT_ID: process.env.REACT_APP_SF_CLIENT_ID,
    AD_TOKEN_URL: process.env.REACT_APP_AD_TOKEN_URL,
    AD_TENANT_ID: process.env.REACT_APP_AD_TENANT_ID,
    AD_SSO_PROVIDER: process.env.REACT_APP_AD_SSO_PROVIDER,
    AD_APP_CLIENT_ID: process.env.REACT_APP_AD_APP_CLIENT_ID,
    AD_SCOPE: process.env.REACT_APP_AD_SCOPE,
    TOKEN_HANDLER: process.env.REACT_APP_TOKEN_HANDLER
  };