import React, { useContext, useEffect, useState } from "react";
import { Popover, Button, Modal,Icon } from "@salesforce/design-system-react";
import { useTranslation } from "react-i18next";
import "../css/pcrVitals.css";
import PCRVitalItemComponent from "./PCRVitalItemComponent";
import { PCRContext, evaluateError } from "context/PCRContext.js";
import { PCRVitalsTooltipTable } from "./PCRVitalsTooltipTable";
import { vitalItem } from "../context/PCRContext";
import { db } from "storage/idb.js";
import { VALIDATION_TYPES } from "../data/constants";
import { PCRVitalItemGCSTooltipTable } from "./PCRVitalItemGCSTooltipTable";

const PCRVitals = ({ pcr,validateVitals,setHasVitalErrors }) => {
  const { t } = useTranslation();
  const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
  const { assessment } = pcrData || {}; // Ensure pcrData is defined
  const patientVitals = assessment?.patientVitals || []; // Ensure assessment and vitals are defined

  // if patientVitals are not empty, use them, otherwise use an empty vitalItem with id 0
  const [vitalItems, setVitalItems] = useState(
    patientVitals.length > 0 ? patientVitals : [{ ...vitalItem, id: 0 }]
  );
  const [selectedVital, setSelectedVital] = useState([]);
  const [showDeleteModal,setShowDeleteModal] = useState(false);
  const [refreshVitals,setRefreshVitals] = useState(0);

  useEffect(() => {
    setPCRData({
      ...pcrData,
      assessment: {
        ...assessment,
        patientVitals: { ...vitalItems },
      },
    });
  }, [vitalItems]);

  useEffect(() => {
    if(validateVitals){
       handleValidate();
    }
  },[validateVitals])


  const addVitalItem = () => {
    const newId = vitalItems.length > 0 ? vitalItems[vitalItems.length - 1].id + 1 : 0;
    let newVitalItem = {
      id: newId,
      vitalsTime: "",
      respiration: "",
      o2Sat: "",
      etco2: "",
      hr: "",
      bp: "",
      arm: "",
      eye: "",
      verbal: "",
      motor: "",
      gcsTotal: "",
      temperatureC: "",
      pain: "",
      glucose: "",
      rPupil: "",
      lPupil: "",
    };
    let tempVitals = [...vitalItems];
    tempVitals.push(newVitalItem)
    setVitalItems(tempVitals);
  };

  const removeVitalItem = () => {
    if(vitalItems.length > 1){
      setShowDeleteModal(true)
    }
  };

  const handleVitalItemChange = (index, newValues) => {
    
    const newVitalItems = [...vitalItems];
    Object.entries(newValues).forEach(([key, value], index) => {
      let field = formValidationConfig.find((f) => f.name === key);
      if (field && field?.shouldDisplayError && value) {
        newValues[`${field.name}Error`] = "";
      }
    });
    newVitalItems[index] = { ...newVitalItems[index], ...newValues };
    
    setVitalItems(newVitalItems);
  };


  const handleVitalSelect = (index, checked) => {
    let tempVitals = [...selectedVital];
    if (index && checked) {
      tempVitals.push(index)
    }
    else{
      tempVitals = tempVitals.filter(rec => rec != index);
    }
    setSelectedVital(tempVitals);
  };


  const isObjectEmptyExceptId = (obj) => {
      return Object.keys(obj).every(key => key === 'id' || obj[key] === "" || obj[key] === 0);
  };

  const handleDelete = () => {
    let tempVitals = [...vitalItems];
    tempVitals = tempVitals.filter(tmp => !selectedVital.includes(tmp.id));
    setVitalItems(tempVitals);
    setShowDeleteModal(false);
  }

  const handleValidate = () => {
   let tempVitals = [...vitalItems];
   let hasErrors = false;

   Object.entries(tempVitals[0]).forEach(([key, value], index) => {    
    let field = formValidationConfig.find((f) => f.name === key);

    if (field && value) {
      if(tempVitals[0][`${field.name}Error`] && (`${field.name}Error` === t("inputIsRequiredError") ||
        `${field.name}Error` === t("inputSelectionRequiredError"))){
          tempVitals[0][`${field.name}Error`] = "";
      }
    }
    else{
      if (field && field?.shouldDisplayError && !value) {        
        hasErrors = true;
        switch (field.validations[0]) {
          case VALIDATION_TYPES.VALUE_REQUIRED:
            tempVitals[0][`${field.name}Error`] = t("inputIsRequiredError");
            break;

          case VALIDATION_TYPES.SELECTION_REQUIRED:
            tempVitals[0][`${field.name}Error`] = t("inputSelectionRequiredError");
            break;

          default:
            break;
        }
      }
    }
  });

  
  if(tempVitals.length > 1){
    tempVitals = tempVitals.filter(item => !isObjectEmptyExceptId(item));
  }
  
  setVitalItems(tempVitals);
  
  setHasVitalErrors(hasErrors);
  setTimeout(() => {
    setRefreshVitals(refreshVitals+1);
  }, 100);
  };

  return (
    <div className="slds-card">
      <div className="main-pcr-vitals">
        <section className="slds-card_header">
          <div className="df vitals-heading-section">
            <h2 className="slds-card__header-title vital-signs-header">
              Vital Signs{" "}
            </h2>
            {/* vital signs popover */}
            <>
              <Popover
                body={<PCRVitalsTooltipTable />}
                id="vital-signs-popover"
                align="top left"
              >
                <Button
                  assistiveText={{ icon: "Vital Signs info" }}
                  iconCategory="utility"
                  iconName="info"
                  iconSize="medium"
                  iconVariant="bare"
                  variant="icon"
                />
              </Popover>
            </>
          </div>
          <div className="df">
            <div style={{ width: "92%" }}></div>
            <div className="slds-col w-10-actn-menu">
              <div className="vital-item-button-group slds-grid slds-gutters">
                {/* delete button */}

                <Button
                  iconCategory="utility"
                  iconName="delete"
                  variant="icon"
                  className="vitals-delete slds-icon-text-error"
                  onClick={removeVitalItem}
                  size="x-small"
                />

                {/* add button */}
                {vitalItems.length >= 1 && (
                  <Button
                    iconCategory="utility"
                    iconName="add"
                    variant="icon"
                    className="vitals-add slds-icon-text-success"
                    onClick={addVitalItem}
                    size="small"
                  />
                )}
                <Button
                  iconCategory="utility"
                  iconName="graph"
                  variant="icon"
                  className="vitals-chart slds-icon-text-warning"
                  onClick={handleValidate}
                  size="small"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="slds-card__body">
        {vitalItems.map((item, index) => (
          <div key={item.id} className={"grid_row_vital"}>
            <PCRVitalItemComponent
              key={refreshVitals}
              index={index}
              onRemove={removeVitalItem}
              onVitalSelect={handleVitalSelect}
              onChange={handleVitalItemChange}
              onAdd={addVitalItem}
              isLast={index === vitalItems.length - 1}
              vitalFromParent={item}
            />
          </div>
        ))}
      </div>
      <Modal
        footer={[
          <Button
            label="Confirm"
            onClick={() => handleDelete()}
          />,
          <Button
            label="Cancel"
            onClick={() => setShowDeleteModal(false)}
          />,
        ]}
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        prompt=""
        size="medium"
        title={   
                  <span> 
                    <Icon
                      assistiveText={{ label: "warning" }}
                      category="utility"
                      name="warning"
                      size="medium"
                      style={{
                        fill: "#fe9339",
                      }}
                    />
                  <span className="prompt_warn_text_h">
                      Warning : Data Deletion
                    </span>
                  </span>
              }
        className=""
      >
        <div className="slds-m-around_medium modal-desc">
          <div className="prompt_warn_text_p">You are about to remove the seleceted records </div>
          <div className="prompt_warn_text_p">are you sure you want to proceed</div>
        </div>
      </Modal>
    </div>
  );
};

export default PCRVitals;
