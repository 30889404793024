import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Icon,
  RadioGroup,
  Radio,
  Input,
  Textarea,
  Combobox,
  Button,
} from "@salesforce/design-system-react";
import {
  VALIDATION_TYPES,
  yesOrNoRadioOptions,
} from "../../../../data/constants";
import PCRComboBox from "../../../common/PCRComboBox";
import PCRTextInput from "../../../common/PCRTextInput";
import "../../../../css/assessment.css";
import { protocalGuideline } from "../../../../data/constants";
import PCRPredefinedList from "../../../PCRPredefinedList";
import {
  protocolNums,
  allergies,
  pastMedicalHx,
  medications,
  estTimeOfInjuryProtocols,
} from "../../../../data/constants";

import { PCRContext, evaluateError } from "../../../../context/PCRContext";
import PCRVitals from "../../../PCRVitals";
import { PCRTraumaAssessment } from "./PCRTraumaAssessment";

export const PCRAssessment = ({ pcr, id, handleCancelConfirmation }) => {
  const {
    pcrData,
    setPCRData,
    formValidationConfig,
    setToggleAccordian,
    setShowEtoiError,
    showEtoiError,
  } = useContext(PCRContext);
  const { assesment, identification } = pcrData;
  const { t } = useTranslation();

  const [offerMade, setOfferMade] = useState(false);
  const [primaryProtocol, setPrimaryProtocol] = useState("");
  const [secondaryProtocol, setSecondaryProtocol] = useState("");
  const [allergiesSearch, setAllergiesSearch] = useState("");
  const [patientMedicationSearch, setPatientMedicationSearch] = useState("");
  const [pastMedicalHxSearch, setPastMedicalHxSearch] = useState("");
  const [notesAndBgInfo, setNotesAndBginfo] = useState("");
  const [cheifComplaint, setCheifComplaint] = useState("");
  const [filteredProtocols, setFilteredProtocalls] = useState([]);
  const [showCallTypeErr, setShowCallTypeErr] = useState(false);
  const [validateVitals, setValidateVitals] = useState(false);
  const [hasVitalErrors, setHasVitalErrors] = useState(false);

  useEffect(() => {
    // filtering protocol list based on calltype availability
    if (
      pcrData.callType !== "Transfer" &&
      pcrData.callType === "Emergency" &&
      pcrData.callType !== ""
    ) {
      let filteredData = protocolNums.filter(
        (pc) => !pc.value?.includes("9992") && !pc.value?.includes("9993")
      );
      setFilteredProtocalls(filteredData);
    } else {
      setFilteredProtocalls(protocolNums);
    }
  }, []);

  useEffect(() => {
    const validateProtoCols = ["9992", "9993"];
    let showclTypeErr = false;
    validateProtoCols.forEach((proto) => {
      if (primaryProtocol.includes(proto) || secondaryProtocol.includes(proto))
        if (pcrData.callType === "") {
          showclTypeErr = true;
        }
    });
    setShowCallTypeErr(showclTypeErr);

    estTimeOfInjuryProtocols.forEach((proto) => {
      if (
        primaryProtocol.includes(proto) ||
        secondaryProtocol.includes(proto)
      ) {
        let errorStates = {};
        if (!identification.estimatedTimeOfInjuryDate) {
          errorStates["estimatedTimeOfInjuryDateError"] = t(
            "inputIsRequiredError"
          );
          setShowEtoiError(true);
        }
        if (!identification?.injuryTime) {
          errorStates["injuryTimeError"] = t("inputIsRequiredError");
          setShowEtoiError(true);
        }
        setPCRData({
          ...pcrData,
          ...errorStates,
        });
        return;
      }
    });

    if (
      identification?.estimatedTimeOfInjuryDate &&
      identification?.injuryTime
    ) {
      setShowEtoiError(false);
      let errorState = {
        estimatedTimeOfInjuryDateError: "",
        injuryTimeError: "",
      };
      setPCRData({
        ...pcrData,
        ...errorState,
      });
    }
  }, [primaryProtocol, secondaryProtocol, identification]);

  const primaryProtocolList = useRef(null);
  const secondaryProtocolList = useRef(null);
  const allergiesSelected = useRef(null);
  const patientMedication = useRef(null);
  const pastMedicalHxRef = useRef(null);

  const updateAllergies = (selection) => {
    allergiesSelected.current = selection;
    updatePCRContext({ allergies: selection });
  };

  const updatePrimaryProtocol = (selection) => {
    primaryProtocolList.current = selection;
    updatePCRContext({ protocolPrimary: selection });
  };

  const updateSecondaryProtocol = (selection) => {
    secondaryProtocolList.current = selection;
    updatePCRContext({ protocolSecondary: selection });
  };

  const updatePatientMedication = (selection) => {
    patientMedication.current = selection;
    updatePCRContext({ patientMedication: selection });
  };

  const updatePastMedicalHx = (selection) => {
    pastMedicalHxRef.current = selection;
    updatePCRContext({ patientMedicalHistory: selection });
  };

  const updatePCRContext = (inputObj) => {
    const field = formValidationConfig.find(
      (f) => f.name === Object.keys(inputObj)[0]
    );
    let errorState = {};
    if((Object.keys(inputObj)[0]).includes('Error')){
      errorState = {...errorState,...inputObj};
    }
   else{
    Object.entries(inputObj).forEach(([key, value], index) => {
      field?.validations.forEach((type) => {
        if (field?.shouldDisplayError && value?.length === 0) {
          switch (type) {
            case VALIDATION_TYPES.VALUE_REQUIRED:
              errorState[`${field.name}Error`] = t("inputIsRequiredError");
              break;

            case VALIDATION_TYPES.SELECTION_REQUIRED:
              errorState[`${field.name}Error`] = t(
                "inputSelectionRequiredError"
              );
              break;

            default:
              break;
          }
        }
        if (field?.shouldDisplayError && value?.length > 0) {
          errorState[`${field.name}Error`] = "";
        }
      });
    });
   }
    

    setPCRData({
      ...pcrData,
      assesment: { ...assesment, ...inputObj },
      ...errorState,
    });
  };

  const handleValidate = () => {
    setValidateVitals(true);
    setTimeout(() => {
      setValidateVitals(false);
    }, 500);

    const assessment = {
      wasActiveOfferMade: null,
      cheifComplaint: null,
      protocolPrimary: null,
      protocolSecondary: null,
      allergies: null,
      patientMedication: null,
      patientMedicalHistory: null,
      notesAndBackgroundInfo: null,
      assesmentInitials: null,
      assesmentId: null,
    };

    let canToggle = true;
    let errorState = {};

    Object.entries(assessment).forEach(([key, value], index) => {
      const field = formValidationConfig.find((f) => f.name === key);

      if (field.shouldDisplayError && !pcrData["assesment"][key]) {
        switch (field.validations[0]) {
          case VALIDATION_TYPES.VALUE_REQUIRED:
            errorState[`${field.name}Error`] = t("inputIsRequiredError");
            break;

          case VALIDATION_TYPES.SELECTION_REQUIRED:
            errorState[`${field.name}Error`] = t("inputSelectionRequiredError");
            break;

          default:
            break;
        }
      }
    });

    setPCRData({
      ...pcrData,
      ...errorState,
    });

    Object.entries(errorState).forEach(([key, value], index) => {
      if (value) {
        canToggle = false;
      }
    });

    setTimeout(() => {
      if (canToggle && !hasVitalErrors) handleCancelConfirmation(id);
    }, 500);
  };

  return (
    <div>
      <div className="df border-bottom-styles">
        <span className="details-icon ga-details-icon">
          <Icon
            assistiveText={{ label: t("generalAssessment") }}
            category="utility"
            name="record_alt"
            size="x-small"
          />
        </span>
        <span className="section-header">{t("generalAssessment")}</span>
      </div>
      <div className="radio m05 m-l-r-1">
        <RadioGroup
          labels={{ label: t("offerMade") }}
          required={
            pcrData?.identification?.noPatientsAssessedReason !== "No patient found"
          }
          name="offer-details-radio-group"
          className="df"
          variant="button-group"
          onChange={(e) => {
            setOfferMade(e.target.value);
            updatePCRContext({ wasActiveOfferMade: e.target.value });
          }}
        >
          {yesOrNoRadioOptions.map((ele) => {
            return (
              <Radio
                id={`${ele.label}`}
                labels={{ label: ele.label }}
                value={ele.value}
                checked={assesment?.wasActiveOfferMade === ele.value}
                variant="base"
                className={ele?.cls || ""}
              />
            );
          })}
        </RadioGroup>
        <div className="slds-form-element__help selectionError df mt-20">
          {pcrData?.wasActiveOfferMadeError && (
            <>{pcrData?.wasActiveOfferMadeError}</>
          )}
        </div>
      </div>
      <div className="w-100 df h-4half-r">
        <div className="w-33">
        <PCRTextInput
            onError={updatePCRContext}
            id="assistiveLabel-id"
            label="Chief complaint"
            placeholder=""
            className="bor-rad"
            selection
            required
            value={cheifComplaint}
            name={"cheifComplaint"}
            onChange={(e) => {            
              updatePCRContext(e);
              setCheifComplaint(e['cheifComplaint']);
            }}
            errorText={
              pcrData?.cheifComplaintError && (
                <div className="slds-form-element__help selectionError df">
                  {pcrData?.cheifComplaintError && (
                    <>{pcrData?.cheifComplaintError}</>
                  )}
                </div>
              )
            }
            validateOnBlur={false}
          />
        </div>
        <div className="w-33">
          <PCRComboBox
            id="primary-protocol-combo-box"
            label={t("primaryProtocol")}
            options={filteredProtocols.filter((p) =>
              p.label
                ?.toLowerCase()
                .includes(primaryProtocol?.toLocaleLowerCase())
            )}
            value={primaryProtocol}
            onChange={(val) => {
              setPrimaryProtocol(val);
            }}
            onSelect={(val) => {
              updatePrimaryProtocol(val.primaryProtocol);
              setPrimaryProtocol(val.primaryProtocol);
            }}
            required
            lowerCaseRequired={true}
            selectAttribute={"label"}
            name={"primaryProtocol"}
            filterAttribute={"label"}
            placeholder={t("primaryProtocolPlaceholder")}
            errorText={
              pcrData?.protocolPrimaryError && (
                <div className="slds-form-element__help selectionError df">
                  {pcrData?.protocolPrimaryError && (
                    <>{pcrData?.protocolPrimaryError}</>
                  )}
                </div>
              )
            }
          />
          <div>
            {(showEtoiError || showCallTypeErr) && (
              <div>
                {showEtoiError && (
                  <>
                    <div className="df">
                      <Icon
                        assistiveText={{ label: "warning" }}
                        category="utility"
                        name="warning"
                        size="xx-small"
                        style={{
                          fill: "#f3a005",
                        }}
                      />
                      <p className="warning_etoi_assesmnt">
                        Est.Time of injury/incident is required!
                      </p>
                    </div>
                  </>
                )}
                {showCallTypeErr && (
                  <div className="df">
                    <Icon
                      assistiveText={{ label: "warning" }}
                      category="utility"
                      name="warning"
                      size="xx-small"
                      style={{
                        fill: "#f3a005",
                      }}
                    />
                    <p className="warning_etoi_assesmnt">
                      Protocol for transfer calls only!
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="w-33 secondary-protocol-list">
          <PCRComboBox
            id="secondary-protocol-combo-box"
            label=""
            options={filteredProtocols.filter((p) =>
              p.label
                ?.toLowerCase()
                .includes(secondaryProtocol?.toLocaleLowerCase())
            )}
            value={secondaryProtocol}
            onChange={(val) => {
              setSecondaryProtocol(val);
            }}
            onSelect={(val) => {
              updateSecondaryProtocol(val.secondaryProtocol);
              setSecondaryProtocol(val.secondaryProtocol);
            }}
            lowerCaseRequired={true}
            selectAttribute={"label"}
            name={"secondaryProtocol"}
            filterAttribute={"label"}
            required={false}
            placeholder={t("secondaryProtocolPlaceholder")}
          />
        </div>
      </div>
      <div className="w-100 df h-4half-r">
        <div className="w-33">
          <PCRPredefinedList
            id="allergies-combo-box"
            label={t("allergies")}
            options={allergies}
            value={allergiesSearch}
            onChange={setAllergiesSearch}
            onSelect={updateAllergies}
            required={
              pcrData?.assesment?.protocolPrimary?.includes("1020") ||
              pcrData?.assesment?.protocolPrimary?.includes("1025")
            }
            errorText={
              pcrData?.allergiesError && (
                <div className="slds-form-element__help selectionError df">
                  {pcrData?.allergiesError && <>{pcrData?.allergiesError}</>}
                </div>
              )
            }
          />
        </div>
        <div className="w-33">
          <PCRPredefinedList
            id="patient-medication-combo-box"
            label={t("patientMedication")}
            options={medications}
            value={patientMedicationSearch}
            onChange={setPatientMedicationSearch}
            onSelect={updatePatientMedication}
            required={false}
          />
        </div>
        <div className="w-33">
          <PCRPredefinedList
            id="dest-location-change-combo-box"
            label="Past medical history"
            options={pastMedicalHx}
            value={pastMedicalHxSearch}
            onChange={setPastMedicalHxSearch}
            onSelect={updatePastMedicalHx}
            required={false}
          />
        </div>
      </div>
      <div className="m-t-2">
        <Textarea
          className="assesment_text_area_cls"
          label="Notes and background information"
          value={notesAndBgInfo}
          onChange={(e) => {
            setNotesAndBginfo(e.target.value);
            updatePCRContext({ notesAndBackgroundInfo: e.target.value });
          }}
            required={true}
            errorText={
              pcrData?.notesAndBackgroundInfoError && (
                <div className="slds-form-element__help selectionError df">
                  {pcrData?.notesAndBackgroundInfoError && (
                    <>{pcrData?.notesAndBackgroundInfoError}</>
                  )}
                </div>
              )
            }
            onBlur={(e) => {
              if(!e.target.value){
                updatePCRContext({notesAndBackgroundInfoError: "This value is required!"})
              }
            }}
          
        />
      </div>
      <div className="df">
        <div className="w-85"></div>
        <div className="df w-14">
          <div>
          <PCRTextInput
              assistiveText={{ label: "" }}
              placeholder="ID#"
              className="bor-rad"
              selection
              required={
                assesment?.notesAndBackgroundInfo?.length ? true : false
              }
              onChange={updatePCRContext}
              errorText={
                pcrData?.assesmentIdError && (
                  <div className="slds-form-element__help selectionError df">
                    {pcrData?.assesmentIdError && (
                      <>{pcrData?.assesmentIdError}</>
                    )}
                  </div>
                )
              }
               name="assesmentId"
            />
          </div>
          <div style={{ marginLeft: "5px", paddingRight: "3px" }}>
           
            <PCRTextInput
              assistiveText={{ label: "" }}
              id="assistiveLabel-id"
              placeholder="Initials"
              className="bor-rad"
              onChange={updatePCRContext}
              required={
                assesment?.notesAndBackgroundInfo?.length ? true : false
              }
              errorText={
                pcrData?.assesmentInitialsError && (
                  <div className="slds-form-element__help selectionError df">
                    {pcrData?.assesmentInitialsError && (
                      <>{pcrData?.assesmentInitialsError}</>
                    )}
                  </div>
                )
              }
              name="assesmentInitials"
              regExValidationText={"ALPHABETICAL_REG"}
              validateOnBlur={true}
              onError={updatePCRContext}
            />
          </div>
        </div>
      </div>
      <div className="df">
        <PCRVitals
          validateVitals={validateVitals}
          setHasVitalErrors={setHasVitalErrors}
        />
      </div>
      <div className="df validate-btn-assesment">
        <Button className="" variant="brand" onClick={handleValidate}>
          {t("validate")}
        </Button>
      </div>
      
       {/* Invoke the PCRTraumaAssessment component here */}
       <PCRTraumaAssessment 
        pcr={pcr} 
        id={id} 
        handleCancelConfirmation={handleCancelConfirmation} 
      />
    </div>
  );
};
