import React, { useContext, useLayoutEffect, useMemo, useState } from "react";
import mockData from "../data/pcrSummary-transfer.json";
import { Button, Icon, Popover } from "@salesforce/design-system-react";
import "../css/summaryView.css";
import { useNetworkStatus } from "../hooks/useNetworkStatus";
import { useTranslation } from "react-i18next";
import { getFormattedPhoneNo } from "../utils/util";
import { getFormattedDateString, getPatientAge,getFormattedDateMMMString } from "../utils/date";
import { PCRContext } from "../context/PCRContext";
import moment from "moment";


function PCRSummaryView({ data = mockData, onClosePCR }) {
  console.log(data,"<======= From summary =====>");
  
  const patient = data.identificationDetails?.patient;
  
  const status = useNetworkStatus();
  const { pcrData } = useContext(PCRContext);
  const { t } = useTranslation();

  const landOptions = [
    t("settingsClosePCR"),
    t("settingsPrintableView"),
    t("settingsCreateRefusalForm"),
    t("settingsCreatedInError"),
  ];
  const airOptions = [
    t("settingsClosePCR"),
    t("settingsPrintableView"),
    t("settingsCreatedInError"),
  ];

  const [isNetworkAvailable, setIsNetworkAvailable] = useState(status);

  useLayoutEffect(() => {
    setIsNetworkAvailable(status);
  }, [status]);

  const handleOptionSelect = (option) => {
    if (option === t("settingsClosePCR")) {
      onClosePCR(null);
    }
  };

  const getOptions = () => {
    const options =
      data.dispatchType === "air"
        ? airOptions
        : pcrData?.identification?.noPatientsAssessedReason === "Patient refusal"
        ? landOptions
        : landOptions.filter((lo) => lo !== t("settingsCreateRefusalForm"));
    return (
      <ul>
        {options.map((option) => (
          <li onClick={() => handleOptionSelect(option)}>{option}</li>
        ))}
      </ul>
    );
  };

  const DataLabel = ({ children }) => (
    <div className="slds-form-element__label">{children}</div>
  );

  const patientAge = useMemo(() => {
    if (!patient?.dateOfBirth || patient?.dateOfBirth === null) {
      return "UNK";
    }
    const { years, months } = getPatientAge(patient?.dateOfBirth);
    return `${years ? `${years} ${t("summaryYears")}` : ""} ${
      months ? `${months} ${t("summaryMonths")}` : ""
    }`;
  }, [patient?.dateOfBirth, t]);


  
  const getFormattedAddress = () => {
    return [
      patient?.addressStreet,
      patient?.addressCity,
      patient?.addressStateCode,
      patient?.addressPostalCode,
      patient?.addressCountryCode
    ].filter(Boolean).join(" ");
  };

  return (
    <div className="summaryViewWrapper">
      <div className="sectionWrapper">
        <div
          className={
            data.dispatchType === "Land" ? "icon ambulance" : "icon aircraft"
          }
        ></div>
        <div className="firstSection">
          <DataLabel>{t("summaryTransferNumber")}</DataLabel>
          <div className="dataValue">{data?.transferNumber}</div>
        </div>
        <div>
          <DataLabel>
            {data.dispatchType === "Land"
              ? t("summaryVehicleNumber")
              : t("summaryAircraftNumber")}
          </DataLabel>
          <div className="dataValue">{data?.vehicleNumber}</div>
        </div>
        <div>
          <DataLabel>
            {data.dispatchType === "Land"
              ? t("summaryVehicleType")
              : t("summaryAircraftType")}
          </DataLabel>
          <div className="dataValue">{data?.vehicleType}</div>
        </div>
        <div className="settingsWrapper">
          {/* <div className="icon settings"></div> */}

          <Popover
            body={getOptions()}
            id="settings-popover"
            align="bottom right"
          >
            <Button
              assistiveText={{ icon: "Icon More large" }}
              iconCategory="utility"
              iconName="settings"
              iconSize="medium"
              iconVariant="more"
              variant="icon"
            />
          </Popover>
        </div>
      </div>
      <div className="sectionWrapper greyBG">
        <div className="icon medic"></div>
        {data.viewType === "attending" ? (
          <div>
            <DataLabel>{t("summaryAttendingPractioner")}</DataLabel>
            <div className="dataValue">{data.attendingPractitioner}</div>
          </div>
        ) : (
          <div>
            <DataLabel>{t("summarySupportingPractioner")}</DataLabel>
            <div className="dataValue">{data.attendingPractitioner}</div>
          </div>
        )}
        <Button
          assistiveText={{ icon: "edit" }}
          iconCategory="utility"
          iconName="edit"
          iconSize="large"
          variant="icon"
          onClick={() => {}}
          className="editStyles"
        />
      </div>
      <div className="sectionWrapper patientDetailWrapper">
        <div className="icon patient"></div>
        <div className="firstSection">
          <DataLabel>{t("summaryPatientAccount")}</DataLabel>
          <div className="dataValue">
            {data.callType === "Emergency" || !isNetworkAvailable
              ? "UNK"
              : data.patientName || "UNK"}
          </div>
        </div>
        {/* Moved to Release 2 */}
        {/* {data.callType !== "Emergency" && (
          <Button
            disabled={!isNetworkAvailable}
            className="patientLookup"
            variant="outline-brand"
          >
            {t("patientLookup")}
          </Button>
        )} */}
      </div>
      <div className="sectionWrapper greyBG">
        <div className="detailsSection">
          <DataLabel>{t("summaryDateOfBirth")}</DataLabel>
          <div className="dataValue">
            {data.callType === "Emergency" || !isNetworkAvailable || !patient?.dateOfBirth
              ? "UNK"
              : moment(patient?.dateOfBirth).format('YYYY-MMM-DD')|| "UNK"}
          </div>
        </div>
        <div>
          <DataLabel>{t("summaryAge")}</DataLabel>
          <div className="dataValue">
            {data.callType === "Emergency" || !isNetworkAvailable
              ? "UNK"
              : patientAge || "UNK"}
          </div>
        </div>
        <div>
          <DataLabel>{t("summarySexAtBirth")}</DataLabel>
          <div className="dataValue">
            {data.callType === "Emergency" || !isNetworkAvailable
              ? "UNK"
              : data.patientSex || "UNK"}
          </div>
        </div>
        <div>
          <DataLabel>{t("summaryIdentifier")}</DataLabel>
          <div className="dataValue">
            {data.callType === "Emergency" || !isNetworkAvailable
              ? "UNK"
              : patient?.uniqueIdentifier || "UNK"}
          </div>
        </div>
        <div>
          <DataLabel>{t("summaryPrimaryAddress")}</DataLabel>
          <div className="dataValue">
            {data.callType === "Emergency" || !isNetworkAvailable
              ? "UNK"
              : getFormattedAddress() || "UNK"}
          </div>
        </div>
        <div>
          <DataLabel>{t("summaryPrimaryPhone")}</DataLabel>
          <div className="dataValue">
            {data.callType === "Emergency" || !isNetworkAvailable
              ? "UNK"
              : patient?.telephone
              ? getFormattedPhoneNo(patient?.telephone)
              : "UNK"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PCRSummaryView;
