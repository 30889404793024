import moment from "moment";

export const getPatientAge = (dob) => {

  const currentDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  const dobDate = new Date(
    moment(dob, "YYYY-MM-DD").format("YYYY"),
    moment(dob, "YYYY-MM-DD").format("MM"),
    moment(dob, "YYYY-MM-DD").format("DD")
  );
  var diff = new Date(currentDate.getTime() - dobDate.getTime());
  return { years: diff.getUTCFullYear() - 1970, months: diff.getUTCMonth() };
  // diff.getUTCDate() - 1 // Gives day count of difference
};

export const getMonths = (dobString) => {
  let months;
  months =
    (new Date().getFullYear() -
      parseInt(moment(dobString, "DD/MM/YYYY").format("YYYY"))) *
    12;
  months -= new Date().getMonth();
  months += parseInt(moment(dobString, "DD/MM/YYYY").format("MM"));
  return months <= 0 ? 0 : months;
};

export const getNoOfDays = (dobString) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date();
  const secondDate = parseInt(moment(dobString, "DD/MM/YYYY").format("MM"));

  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};

export const getFormattedDateString = (data) =>{
  if(data){
    const date = moment(data);
    return date.format('DD/MM/YYYY');
  }
  else{
    return "";
  }
}

export const getFormattedDateMMMString = (data) => {
  if(data){
    const date = moment(data);
    return date.format('YYYY-MMM-DD');
  }
  else{
    return "";
  }
}
