import React, { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import "css/PCRVasculars.css";
import PCRVascularItem from 'components/PCR/PCR-AV/Treatment/Vasculars/PCRVascularItem';
import { PCRContext, evaluateError } from "context/PCRContext.js";
import { vascularItem } from "context/PCRContext";
import { db } from "storage/idb.js";
import PCRMedicationsTooltipTable from "components/PCR/PCR-AV/Treatment/Medications/PCRMedicationsTooltipTable";


const PCRVasculars = ({ pcr }) => {
  const { t } = useTranslation();
  const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
  const { treatment } = pcrData || {}; // Ensure pcrData is defined
  const patientVasculars = treatment?.vasculars || []; // Ensure treatment and vasculars are defined
  // console.log('patientVasculars >>>', patientVasculars);

  // if patientVasculars are not empty, use them, otherwise use an empty vascularItem with id 0
  const [vascularItems, setVascularItems] = useState(patientVasculars.length > 0 ? patientVasculars : [{ ...vascularItem, id: 0 }]);

  
  const updatePCRContext = async (updatedAttrObj) => {
    const field = formValidationConfig.find(
      (f) => f.name === Object.keys(updatedAttrObj)[0]
    );

    const error = evaluateError(
      pcrData,
      { treatment: { vasculars: updatedAttrObj } },
      field,
      t
    );

    setPCRData({
      ...pcrData,
      treatment: { ...treatment, vasculars: { ...patientVasculars, ...updatedAttrObj } },
      ...error,
    });

    const records = await db.pcrs.where({ id: pcr?.pcrId }).toArray();
    let record = records[0];
    const timestamp = new Date().toISOString();
    let json = JSON.parse(record.json);
    // patientVasculars array is in the treatment object so pcr.treatment.vasculars
    json = JSON.stringify({
      ...json,
      treatment: { ...treatment, vasculars: { ...patientVasculars, ...updatedAttrObj } },
    });
    await db.pcrs.update(pcr?.pcrId, {
      json,
      timestamp,
      status: "In Progress",
    });
  };

  const addVascularItem = () => {
    const newId = vascularItems.length > 0 ? vascularItems[vascularItems.length - 1].id + 1 : 0;
    setVascularItems([...vascularItems, { id: newId }]);
  };

  const removeVascularItem = (index) => {
    if (window.confirm("Warning: Row Deletion\n\nYou are about to remove a row. Are you sure you want to proceed?")) {
      if (vascularItems.length === 1) {
        // If there's only one row, replace it with a new empty row
        setVascularItems([{ id: vascularItems[0].id + 1 }]);
      } else {
        // Remove the row
        const newVascularItems = vascularItems.filter((_, i) => i !== index);
        setVascularItems(newVascularItems);
      }
    }
  };

  const handleVascularItemChange = (index, newValues) => {
    const newVascularItems = [...vascularItems];
    newVascularItems[index] = { ...newVascularItems[index], ...newValues };
    setVascularItems(newVascularItems);
  };

  

  return (
    <div className="slds-card">

      <div className="slds-card__header">
        <h2 className="slds-card__header-title vascular-access-header">
          {t('vascularAccess')}
        </h2>
      </div>

      

      <div className="slds-card__body">
        {vascularItems.map((item, index) => (
          <div key={item.id}>
            <PCRVascularItem
              key={item.id}
              index={index}
              onRemove={removeVascularItem}
              onChange={handleVascularItemChange}
              onAdd={addVascularItem}
              isLast={index === vascularItems.length - 1}
              vascularFromParent={item}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PCRVasculars;