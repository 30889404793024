import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Input } from "@salesforce/design-system-react";

const REGEX = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
const regex = new RegExp(REGEX);

const PCRTimePicker = ({
  type,
  label,
  placeholder,
  id,
  value,
  onChange,
  required = false,
  errorText = "",
  className,
  name = "",
  ...rest
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const checkValidation = (val) => {
    if (!val && required) {
      setError(t("inputIsRequiredError"));
    } else {
      setError();
    }
  };

  useEffect(() => {
    setError(errorText);
  }, [errorText]);

  const handleBlur = ({ target: { value } }) => {

    if (value?.length === 4) {
      value = value?.slice(0, 2) + ':' + value?.slice(2);
      if (name) {
        onChange({ [name]: value });
      }
    }
    if(value && value?.length > 5){
      setError(t("invalidFormat"));
    }
    if (required) {
      checkValidation(value);
    }
    if (value && !regex.test(value)) {
      setError(t("invalidFormat"));
    }
  };

  const handleChange = ({ target: { value } }) => {
    if (error) {
      checkValidation(value);
    }
    if (onChange) {
      if (name) {
        onChange({ [name]: value });
      }
    }
  };

  return (
    <Input
      type={type}
      assistiveText={{ label }}
      id={id || `${label}-input`}
      label={label}
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      errorText={errorText ? errorText : error}
      required={required}
      {...rest}
    />
  );
};

export default PCRTimePicker;
