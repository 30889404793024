import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Input,
  DataTable,
  DataTableColumn,
  Button,
  RadioGroup,
  Radio,
  Icon,
  Modal,
  Popover,
  DatePicker,
  Checkbox,
} from "@salesforce/design-system-react";

import PCRAddressCompleteComboBox from "../../../components/common/PCRAddressCompleteComboBox";
import {
  sceneLocationOptions,
  agencyNameList,
  yesOrNoRadioOptions,
  zeroPatientOptions,
  codeToFromOptions,
  zeroPatientAssessedOptions,
  estTimeOfInjuryProtocols,
} from "../../../data/constants.js";
import PCRComboBox from "../../../components/common/PCRComboBox";
import PCRInputError from "../../../components/common/PCRInputError";
import PCRTextInput from "../../../components/common/PCRTextInput.jsx";
import { PCRContext, evaluateError } from "../../../context/PCRContext.js";
import PDFViewer from "../../PDFViewer.jsx";
import { db } from "../../../storage/idb.js";
import moment from "moment";
import PCRTimePicker from "../../common/PCRTimePicker.jsx";
import { validateNumericValue } from "../../../utils/util.js";
import "../../../css/identificationCallDetails.css";
import PCRDataTableColumnLabel from "../../PCRDataTableColumnLabel.jsx";
import { useNetworkStatus } from "../../../hooks/useNetworkStatus.js";

const IdentificationCallDetails = ({ pcr }) => {
  const {
    pcrData,
    setPCRData,
    formValidationConfig,
    setShowEtoiError,
    showEtoiError,
  } = useContext(PCRContext);

  
  const { identificationDetails } = pcrData;
  const identification = identificationDetails;
  
  const { t } = useTranslation();
  const [openRefusalModal, setOpenRefusalModal] = useState(false);
  const [noPatientsAssessedReason, setNoOfPatientOption] = useState("");
  const [numberOfPatientsAssessedOption, setNoOfPatientAssessedOption] =
    useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [agencyName, setAgencyName] = useState("");
  const [otherAgency, setAgencySpecifyName] = useState("");
  const [edit, setEdit] = useState(false);
  const [temp, setTemp] = useState({});
  const [openPHEWDocument, setOpenPHEWDocument] = useState(false);
  const [dateChangeKey, setDateChangeKey] = useState(0);
  const [sceneLocAddr,setSceneLocAddr] = useState("");
  const [destLocAddr,setDestLocAddr] = useState("");

  const beforeEdit = useRef(null);
  const isOnline = useNetworkStatus();

  const {
    logisArrivedAtScene,
    logisArrivedAtPatient,
    logisCallDate,
    logisEnRoute ,
    logisDepartScene,
    logisAtDestination,
    logisCallReceived
  } = identification || {};
  
  const [tableDetail, setTableDetails] = React.useState({
    logisArrivedAtScene,
    logisArrivedAtPatient,
    logisCallDate,
    logisEnRoute,
    logisDepartScene,
    logisAtDestination,
    logisCallReceived,
  });


  const [initialLoadDone, setInitialDateChange] = React.useState(false);

  const parseDateTimeStamp = (dateTimestamp, parseType) => {
    
    if (!dateTimestamp) {
      return "";
    } else {
      var datetimeString = dateTimestamp;
      var datetime = "";
      var formattedDateOrTime = "";

      if (parseType === "date") {
        datetime = moment(datetimeString, "YYYY-MM-DD, h:mm a");
        formattedDateOrTime = datetime.format("YYYY-MMM-DD");
      } else {
        datetime = moment(datetimeString);
        formattedDateOrTime = datetime.format("HH:mm");
      }
      return formattedDateOrTime;
    }
  };

  const isTimeEmpty = (time) => !time || (time && time?.length === 0);

  const checkForUpdateTimes = () => {
    let emptyFileds = "";
    emptyFileds =
      isTimeEmpty(identification?.arrivedAtPatient) ||
      isTimeEmpty(identification?.arrivedAtScene) ||
      isTimeEmpty(identification?.callReceived) ||
      isTimeEmpty(identification?.enrouteTime) ||
      isTimeEmpty(identification?.departSceneTime) ||
      isTimeEmpty(identification?.destinationTime) ||
      isTimeEmpty(identification?.callDate);
    // show button only if onlinemode and atleast one field is empty
    if (emptyFileds && isOnline) {
      return true;
    } else {
      return false;
    }
  };

  const updatePCRContext = async (updatedAttrObj, from="input") => {
    let error = {};
    const field = formValidationConfig.find(
      (f) => f.name === Object.keys(updatedAttrObj)[0]
    );

    if(field.shouldDisplayError && (!updatedAttrObj[Object.keys(updatedAttrObj)[0]] && updatedAttrObj[Object.keys(updatedAttrObj)[0]]) != 0){
      if(from === 'select'){
        error[Object.keys(updatedAttrObj)[0]+"Error"] =  t("inputSelectionRequiredError");
      }
      else{
        error[Object.keys(updatedAttrObj)[0]+"Error"] =  t("inputIsRequiredError");
      }
    }

    if(field.shouldDisplayError && updatedAttrObj[Object.keys(updatedAttrObj)[0]]){
      error[Object.keys(updatedAttrObj)[0]+"Error"] =  "";
      
    }


    setPCRData({
      ...pcrData,
      identificationDetails: { ...pcrData.identificationDetails, ...updatedAttrObj },
      ...error
    });

    const records = await db.pcrs.where({ id: pcr?.pcrId }).toArray();
    let record = records[0];
    const timestamp = new Date().toISOString();
    let json = JSON.parse(record.json);
    json = JSON.stringify({
      ...json,
      identificationDetails: { ...pcrData.identificationDetails, ...updatedAttrObj },
      ...error
    });
    await db.pcrs.update(pcr?.pcrId, {
      json,
      timestamp,
      status: "In Progress",
    });

  };

  useEffect(() => {

    setTableDetails({
      logisArrivedAtScene,
      logisArrivedAtPatient,
      logisCallDate,
      logisEnRoute,
      logisDepartScene,
      logisAtDestination,
      logisCallReceived,
    });

    setTemp({});
  }, [identification]);

  const getTableData = () => {
    let callDate = tableDetail.logisCallDate ? tableDetail.logisCallDate : temp?.logisCallDate ;
    let callTime = tableDetail.logisCallReceived ? tableDetail.logisCallReceived : temp?.logisCallReceived ;
    callDate = moment(callDate).format('YYYY-MMM-DD');
    callTime = moment(callTime).utc().format('HH:mm');
     

    const tableData = !edit
      ? [
          {
            id: 2,
            callDate: tableDetail?.logisCallDate ? (
              <div>{callDate}</div>
            ) : (
              <div>{callDate} </div>
            ),
            callReceived: temp?.logiscallReceived ? (
              <>
                <div>{callTime}</div>
                <div>{temp?.logiscallReceived} (edited)</div>
              </>
            ) : (
              callTime
            ),
            enroute: temp?.logisEnRoute ? (
              <>
                <div>{parseDateTimeStamp(tableDetail?.logisEnRoute)}</div>
                <div>{temp?.epcrEnRoute} (edited)</div>
              </>
            ) : (
              parseDateTimeStamp(tableDetail?.logisEnRoute, "time")
            ),
            arrivedAtScene: temp?.logisArrivedAtScene ? (
              <>
                <div>
                  {parseDateTimeStamp(tableDetail?.logisArrivedAtScene, "time")}
                </div>
                <div>{temp?.epcrArrivedAtScene} (edited)</div>
              </>
            ) : (
              parseDateTimeStamp(tableDetail?.logisArrivedAtScene, "time")
            ),
            arrivedAtPatient: temp?.logisArrivedAtPatient ? (
              <>
                <div>
                  {parseDateTimeStamp(tableDetail?.logisArrivedAtPatient, "time")}
                </div>
                <div>{temp?.epcrArrivedAtPatient} (edited)</div>
              </>
            ) : (
              <div>
                {parseDateTimeStamp(tableDetail?.logisArrivedAtPatient, "time")}
              </div>
            ),
            departScene: temp?.departScene ? (
              <>
                <div>
                  {parseDateTimeStamp(tableDetail?.logisDepartScene, "time")}
                </div>
                <div>{temp?.epcrDepartScene} (edited)</div>
              </>
            ) : (
              <div>{parseDateTimeStamp(tableDetail?.logisDepartScene, "time")}</div>
            ),
            atDestination: temp?.logisAtDestination ? (
              <>
                <div>
                  {parseDateTimeStamp(tableDetail?.logisAtDestination, "time")}
                </div>
                <div>{temp?.epcrAtDestination} (edited)</div>
              </>
            ) : (
              parseDateTimeStamp(tableDetail?.logisAtDestination, "time")
            ),
          },
        ]
      : [
          {
            id: 3,
            callDate: (
              <div class="slds-datepicker-trigger dp-intable-cust-width">
                <DatePicker
                  placeholder="YYYY-MMM-DD"
                  formatter={(date) => {
                    return date ? moment(date).format("YYYY-MMM-DD") : "";
                  }}
                  parser={(dateString) => {
                    return moment(dateString, "YYYY-MMM-DD").toDate();
                  }}
                  relativeYearFrom={-100}
                  onChange={(event, data) => {
                    onTableChange({ epcrCallDate: data?.formattedDate });
                  }}
                  className="slds-datepicker bor-rad d3"
                  onCalendarFocus={(event, data) => {}}
                  disabled={isOnline}
                  value={
                    temp?.logisCallDate
                      ? moment(temp?.logisCallDate, "YYYY-MMM-DD").toDate()
                      : ""
                  }
                />
              </div>
            ),
            callReceived: (
              <PCRTimePicker
                assistiveText={{ label: "callReceived" }}
                id="callReceived-id"
                className="bor-rad m10 pad-1 w120p"
                placeholder="HH:MM"
                value={temp?.logisCallReceived ? temp?.logisCallReceived : ""}
                name="logisCallReceived"
                onChange={(val) => {
                  onTableChange(val);
                }}
              />
            ),
            enroute: (
              <PCRTimePicker
                assistiveText={{ label: "enroute" }}
                id="enroute-id"
                className="bor-rad m10 w120p"
                placeholder="HH:MM"
                value={temp?.logisEnroute ? temp?.logisEnroute : ""}
                name="logisEnroute"
                onChange={(val) => {
                  onTableChange(val);
                }}
              />
            ),
            arrivedAtScene: (
              <PCRTimePicker
                assistiveText={{ label: "arrivedAtScene" }}
                id="arrivedAtScene-id"
                className="bor-rad m10 w120p"
                placeholder="HH:MM"
                value={temp?.logisArrivedAtScene ? temp?.logisArrivedAtScene : ""}
                name="logisArrivedAtScene"
                onChange={(val) => {
                  onTableChange(val);
                }}
              />
            ),
            arrivedAtPatient: (
              <PCRTimePicker
                assistiveText={{ label: "arrivedAtPatient" }}
                id="arrivedAtPatient-id"
                className="bor-rad m10 w120p"
                placeholder="HH:MM"
                value={temp?.logisArrivedAtPatient ? temp?.logisArrivedAtPatient : ""}
                name="logisArrivedAtPatient"
                onChange={(val) => {
                  onTableChange(val);
                }}
              />
            ),
            departScene: (
              <PCRTimePicker
                assistiveText={{ label: "departScene" }}
                id="departScene-id"
                className="bor-rad m10 w120p"
                placeholder="HH:MM"
                value={temp?.logisDepartScene ? temp?.logisDepartScene : ""}
                name="logisDepartScene"
                onChange={(val) => {
                  onTableChange(val);
                }}
              />
            ),
            atDestination: (
              <PCRTimePicker
                assistiveText={{ label: "atDestination" }}
                id="atDestination-id"
                className="bor-rad m10 w120p"
                placeholder="HH:MM"
                value={temp?.logisAtDestination ? temp?.logisAtDestination : ""}
                name="logisAtDestination"
                onChange={(val) => {
                  onTableChange(val);
                }}
              />
            ),
          },
        ];
    const columns = [
      <DataTableColumn
        key="callDate"
        className="pad-0 pad-0-1"
        label={<PCRDataTableColumnLabel required label="Date" />}
        property="callDate"
      />,

      <DataTableColumn
        key="callReceived"
        label={<PCRDataTableColumnLabel required label="Call received" />}
        property="callReceived"
        className=""
      />,

      <DataTableColumn
        key="enroute"
        label={<PCRDataTableColumnLabel required label="En route" />}
        property="enroute"
      />,

      <DataTableColumn
        key="arrivedAtScene"
        label={<PCRDataTableColumnLabel required label={"Arrived at scene"} />}
        property="arrivedAtScene"
      />,

      <DataTableColumn
        key="arrivedAtPatient"
        label={
          <PCRDataTableColumnLabel required label={"Arrived at patient"} />
        }
        property="arrivedAtPatient"
      />,

      <DataTableColumn
        key="departScene"
        label={<PCRDataTableColumnLabel required label={"Depart scene"} />}
        property="departScene"
      />,

      <DataTableColumn
        key="atDestination"
        label={<PCRDataTableColumnLabel required label={"At destination"} />}
        property="atDestination"
      />,
    ];
    return (
      <DataTable
        items={tableData}
        id="pcr-identification-data-table"
        className="pcr-identification-clTab"
      >
        {columns}
      </DataTable>
    );
  };


  useEffect(() => {
    setPCRData({
      ...pcrData,
      identificationDetails: { ...identification, ...{ cspId: "", ctasLevel: "" } },
    });
  }, [identification?.cspContacted]);

  useEffect(() => {
    setPCRData({
      ...pcrData,
      identificationDetails: {
        ...identificationDetails,
        ...{ prePHEW: "", postPHEW: "" },
      },
    });
  }, [identificationDetails?.noPatientsAssessedReason]);


  const onTableChange = (obj) => {
    setTemp({ ...temp, [Object.keys(obj)[0]]: obj[Object.keys(obj)[0]] });
  };


  const handleNoOfPatientsAssesdNum = ({ target: { value } }) => {
    const updatedValue = validateNumericValue(value);    
    if (updatedValue === "") {
      updatePCRContext({
        numberOfPatientsAssessed: "",
      });
      return;
    }

    updatePCRContext({
      numberOfPatientsAssessed: isNaN(updatedValue)
        ? identification?.numberOfPatientsAssessed
        : Number(updatedValue),
    });
  };

  const resetTemp = () => {
    setTemp(beforeEdit.current);
    beforeEdit.current = {};
  };

  const reAssesTemp = () => {
    setTemp({
      callDate: temp?.callDate === tableDetail.callDate ? "" : temp.callDate,
      arrivedAtScene:
        temp?.arrivedAtScene === tableDetail.arrivedAtScene
          ? ""
          : temp.arrivedAtScene,
      arrivedAtPatient:
        temp?.arrivedAtPatient === tableDetail.arrivedAtPatient
          ? ""
          : temp?.arrivedAtPatient,
      callReceived:
        temp?.callReceived === tableDetail.callReceived
          ? ""
          : temp?.callReceived,
      enroute: temp.enroute === tableDetail.enroute ? "" : temp.enroute,
      departScene:
        temp.departScene === tableDetail.departScene ? "" : temp.departScene,
      atDestination:
        temp.atDestination === tableDetail.atDestination
          ? ""
          : temp.atDestination,
    });
    beforeEdit.current = {};
  };

  const getTimesData = () => {
    setInitialDateChange(true);
    const token = localStorage.getItem("sf_access_token");
    const url = `https://ccnb-scnb--epcrdev.sandbox.my.salesforce.com/services/apexrest/ANB/fetchTransferTime?
        language=ENG&transferNumber=${pcrData.transferNumber}&medicIds=${pcrData.medicIds}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        if (data.status === 200) {
          const res = data.json();
          res.then((content) => {
            let respObj = {
              callDate: content?.callDate
                ? parseDateTimeStamp(content.callDate, "date")
                : "",
              arrivedAtScene: content?.arrivedAtSceneTimeFromLogis
                ? parseDateTimeStamp(
                    content.arrivedAtSceneTimeFromLogis,
                    "time"
                  )
                : "",
              arrivedAtPatient: content?.arrivedAtPatientTimeFromLogis
                ? parseDateTimeStamp(
                    content.arrivedAtPatientTimeFromLogis,
                    "time"
                  )
                : "",
              callReceived: content?.callReceivedTime
                ? parseDateTimeStamp(content.callReceivedTime, "time")
                : "",
              enroute: content?.enrouteTime
                ? parseDateTimeStamp(content?.enrouteTime, "time")
                : "",
              departScene: content?.departSceneTimeFromLogis
                ? parseDateTimeStamp(content?.departSceneTimeFromLogis, "time")
                : "",
              atDestination: content?.destinationTimeFromLogis
                ? parseDateTimeStamp(content?.destinationTimeFromLogis, "time")
                : "",
            };
            setTemp(respObj);
            setTableDetails(respObj);
          });
        }
        if (data.status !== 200) {
          setOpenErrorModal(true);
        }
      })
      .catch((err) => {
        setOpenErrorModal(true);
      });
  };

  useEffect(() => {
    if (identification.estimatedTimeOfInjuryUnknown) {
      setDateChangeKey(dateChangeKey + 1);
    }
  }, [identification?.estimatedTimeOfInjuryUnknown]);

 useEffect(() => {

  if(Object.keys(identificationDetails)?.length){

    let val1 = identificationDetails.sceneLocationAddress;
    let val2 = identificationDetails.destinationLocationAddress;

    let sceneJointAddr =  [
      val1?.streetAddress1,
      val1?.streetAddress2,
      val1?.city,
      val1?.stateCode,
      val1?.postalCode,
      val1?.addressCountryCode
    ].filter(Boolean).join(" ");
  
    let destJointAddr =  [
      val2?.streetAddress1,
      val2?.streetAddress2,
      val2?.city,
      val2?.stateCode,
      val2?.postalCode,
      val2?.addressCountryCode
    ].filter(Boolean).join(" ");

    setSceneLocAddr(sceneJointAddr);
    setDestLocAddr(destJointAddr);

    //  setPCRData({
    //   ...pcrData,
    //   identificationDetails: { ...pcrData.identificationDetails, 
    //     ...{sceneLocationAddress : sceneJointAddr,
    //     destinationLocationAddress: destJointAddr} },
    // })

  }
 
 },[]);


  return (
    <>
      <div className="df border-bottom-styles">
        <span className="details-icon call-details-icon">
          <Icon
            assistiveText={{ label: t("callDetails") }}
            category="utility"
            name="call"
            size="x-small"
          />
        </span>
        <span className="section-header">{t("callDetails")}</span>
      </div>
      <div className="m-t-b">
        <div className="df h-4half-r">
          <div className="w-15 radio-alignment codeScene">
            <RadioGroup
              labels={{ label: t("codeToScene") }}
              onChange={({ target: { value } }) =>
                updatePCRContext({ codeToScene: value })
              }
              required
              name="codeToScene-radio-group"
              className="disp-align-end"
            >
              {codeToFromOptions.map((ele) => {
                return (
                  <Radio
                    id={`cts-codeToScene-${ele?.value}`}
                    labels={{ label: ele.label }}
                    value={ele?.value}
                    checked={identification?.codeToScene === ele?.value}
                    variant="base"
                  />
                );
              })}
            </RadioGroup>
            <div className="slds-form-element__help selectionError df">
              {pcrData?.codeToSceneError && <>{pcrData?.codeToSceneError}</>}
            </div>
          </div>
          <div className="w-60 combo-box-wrapper">
            <PCRTextInput
              assistiveText={t("sceneLocationAddress")}
              id="combobox-inline-scene"
              label={t("sceneLocationAddress")}
              className="bor-rad"
              name="sceneLocationAddress"
              required
              value={sceneLocAddr}
              onChange={(e) =>{
                updatePCRContext(e);
                setSceneLocAddr(e.sceneLocationAddress)
              }}
              errorText={
                pcrData?.sceneLocationAddressError && (
                  <div className="df">
                    <p className="margin-err-text">
                      {pcrData?.sceneLocationAddressError}
                    </p>
                  </div>
                )
              }
              // onBlur={(e) => {
              //   if (!e.target.value) {
              //     setPCRData({
              //       ...pcrData,
              //       ...{ sceneLocationAddressError: t("inputIsRequiredError") },
              //     });
              //   }
              // }}
            />
          </div>
          <div className="w-25">
            <PCRComboBox
              id="scene-location-change-combo-box"
              options={sceneLocationOptions}
              onChange={(value) => updatePCRContext({ sceneLocationCode: value })}
              onRequestRemoveSelectedOption={null}
              name={"sceneLocationCode"}
              onSelect={(e) => updatePCRContext(e,"select")}
              label={t("sceneLocationCode")}
              required
              value={identification?.sceneLocationCode}
              errorText={
                pcrData?.sceneLocationCodeError && (
                  <div className="df">
                    <p className="margin-err-text"></p>{" "}
                    {pcrData?.sceneLocationCodeError}
                  </div>
                )
              }
            />
          </div>
        </div>
        <div className="df h-4half-r">
          <div className="w-15 radio-alignment codeScene">
            <RadioGroup
              labels={{ label: t("codeFromScene") }}
              onChange={({ target: { value } }) =>
                updatePCRContext({ codeFromScene: value })
              }
              required={identification?.patientTransported === "Yes"}
              name="codeFromScene-radio-group"
              className="disp-align-end"
            >
              {codeToFromOptions.map((ele) => {
                return (
                  <Radio
                    id={`cfs-codeFromScene-${ele?.label}`}
                    labels={{ label: ele.label }}
                    value={ele?.value}
                    checked={identification?.codeFromScene === ele?.value}
                    variant="base"
                    className="disp-align-end"
                  />
                );
              })}
            </RadioGroup>
            <div className="slds-form-element__help selectionError">
              {identification?.patientTransported === "Yes" &&
                pcrData?.codeFromSceneError && (
                  <>
                    <Icon
                      assistiveText={{ label: "warning" }}
                      category="utility"
                      name="warning"
                      size="xx-small"
                      style={{
                        fill: "#ea001e",
                      }}
                    />
                    {pcrData?.codeFromSceneError}
                  </>
                )}
            </div>
          </div>
          <div className="w-60 combo-box-wrapper">
            {/* ntbc */}
            <PCRTextInput
              assistiveText={t("destinationLocationAddress")}
              id="combobox-inline-destination"
              label={t("destinationLocationAddress")}
              className="bor-rad"
              required={identification?.patientTransported === "Yes"}
              value={destLocAddr}
              name="destinationLocationAddress"
              onChange={(e) => {
                updatePCRContext(e);
                setDestLocAddr(e.destinationLocationAddress);
              }}
              errorText={
                identification?.patientTransported === "Yes" &&
                pcrData?.destinationLocationAddressError && (
                  <div className="df">
                    <p className="margin-err-text"></p>{" "}
                    {pcrData?.destinationLocationAddressError}
                  </div>
                )
              }
              // onBlur={(e) => {
              //   if (!e.target.value) {
              //     setPCRData({
              //       ...pcrData,
              //       ...{
              //         destinationLocationAddressError: t(
              //           "inputIsRequiredError"
              //         ),
              //       },
              //     });
              //   }
              // }}
            />
          </div>
          <div className="w-25">
            {/* ntbc */}
            <PCRComboBox
              id="dest-location-change-combo-box"
              options={sceneLocationOptions}
              onChange={(value) => updatePCRContext({ destinationLocationCode: value })}
              onRequestRemoveSelectedOption={null}
              onSelect={(e) => updatePCRContext(e,"select")}
              label={t("destinationLocationCode")}
              name={"destinationLocationCode"}
              required={identification?.patientTransported === "Yes"}
              value={identification?.destinationLocationCode}
              errorText={
                identification?.patientTransported === "Yes" &&
                pcrData?.destinationLocationCodeError && (
                  <div className="df">
                    <p className="margin-err-text"></p>{" "}
                    {pcrData?.destinationLocationCodeError}
                  </div>
                )
              }
            />
          </div>
        </div>
        <div className="df h-4half-r-min">
          <div className="w-75 m-t-1 ">{getTableData()}</div>
          <div className="w-25">
            <div className="slds-form-element__label">
              {showEtoiError && <span className="clr-red">*</span>}{" "}
              {t("estimatedTimeofInjury")}
            </div>
            <div
              className="datetimeWrapper df ait w100"
              style={{ marginBottom: "10px" }}
            >
              <div>
                <DatePicker
                  labels={{
                    label: "",
                  }}
                  placeholder="YYYY-MMM-DD"
                  key={dateChangeKey}
                  onChange={(event, data) => {
                    updatePCRContext({
                      estimatedTimeOfInjury: data.date,
                    });
                  }}
                  formatter={(date) => {
                    return date ? moment(date).format("YYYY-MMM-DD") : "";
                  }}
                  parser={(dateString) => {
                    return moment(dateString, "YYYY-MMM-DD").toDate();
                  }}
                  value={
                    identification?.estimatedTimeOfInjury
                      ? identification?.estimatedTimeOfInjury
                      : ""
                  }
                  disabled={identification?.estimatedTimeOfInjuryUnknown}
                  required={showEtoiError}
                  hasError={
                    pcrData?.estimatedTimeOfInjuryError ? true : false
                  }
                />
                <div>
                  {pcrData?.estimatedTimeOfInjuryError && (
                    <>
                      <span style={{ color: "red" }}>
                        {pcrData?.estimatedTimeOfInjuryError}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <PCRTimePicker
                assistiveText={{ label: t("estimatedTimeofInjury") }}
                id="estimatedTimeOfJourney-id"
                className="bor-rad ml10"
                placeholder="HH:MM"
                value={
                  identification?.estimatedTimeOfInjuryUnknown
                    ? null
                    : identification?.injuryTime
                }
                name="injuryTime"
                onChange={updatePCRContext}
                disabled={identification?.estimatedTimeOfInjuryUnknown}
                key={identification?.estimatedTimeOfInjuryUnknown}
                required={showEtoiError}
                errorText={
                  pcrData.injuryTimeError && (
                    <>
                      <span>{pcrData?.injuryTimeError}</span>
                    </>
                  )
                }
              />
            </div>
            <Checkbox
              assistiveText={{
                label: "Unknown",
              }}
              className="mt-10"
              value={identification?.estimatedTimeOfInjuryUnknown}
              id="unknown-checkbox"
              labels={{
                label: t("unknown"),
              }}
              onChange={(e) => {
                updatePCRContext({ estimatedTimeOfInjuryUnknown: e.target.checked });
              }}
            />
          </div>
        </div>
        <div className="align-end">
          <div className="w-75 edit-section">
            {!edit ? (
              <div
                className="edit-icon-details"
                onClick={() => {
                  setEdit(true);
                  beforeEdit.current = { ...temp };
                  setTemp({
                    callDate: temp?.callDate || tableDetail?.callDate,
                    arrivedAtScene:
                      temp?.arrivedAtScene || tableDetail?.arrivedAtScene,
                    arrivedAtPatient:
                      temp?.arrivedAtPatient || tableDetail?.arrivedAtPatient,
                    callReceived:
                      temp?.callReceived || tableDetail?.callReceived,
                    enroute: temp?.enroute || tableDetail?.enroute,
                    departScene: temp?.departScene || tableDetail?.departScene,
                    atDestination:
                      temp?.atDestination || tableDetail?.atDestination,
                  });
                }}
              >
                <Icon
                  assistiveText={{ label: "edit button" }}
                  category="utility"
                  name="edit"
                  size="small"
                />
              </div>
            ) : (
              <div>
                <Button
                  label={t("cancel")}
                  onClick={() => {
                    setEdit(!edit);
                    resetTemp();
                  }}
                  variant="outline-brand"
                />
                <Button
                  label={t("save")}
                  onClick={() => {
                    setEdit(!edit);
                    reAssesTemp();
                  }}
                  variant="brand"
                />
              </div>
            )}
            {checkForUpdateTimes() && !edit && (
              <Button
                label={t("updateTimes")}
                className="update-times"
                onClick={() => {
                  // TODO - Make api call to get call times from SF
                  getTimesData();
                  setEdit(!edit);
                  setTemp({ ...tableDetail });
                }}
                variant="outline-brand"
              />
            )}
          </div>
        </div>
        <div className="df m-l-r-1">
          <div className="patient-options-section-1 patients-assessed pr">
            {/* identification?.noPatientsAssessedReason */}
            <span>
              {" "}
              <span style={{ color: "red" }}>* &nbsp;</span>
              {t("noOfPatientsAssessed")}
            </span>{" "}
            <Popover
              body={t("noOfpatientsassessedvalue")}
              id="settings-popover"
              align="bottom right"
            >
              <Button
                assistiveText={{ icon: "info" }}
                iconCategory="utility"
                iconName="info"
                iconSize="medium"
                variant="icon"
              />
            </Popover>
            <Input
              assistiveText={{ label: "" }}
              id="noOfPatientsAssessed-id"
              label={""}
              className="bor-rad"
              required={true}
              type="number"
              value={identification?.numberOfPatientsAssessed}
              onChange={handleNoOfPatientsAssesdNum}
              onBlur={(e) => {                
                if (!e.target.value && e.target.value != 0) {
                  setPCRData({
                    ...pcrData,
                    ...{ numberOfPatientsAssessedError: t("inputIsRequiredError") },
                  });
                }
              }}
            />
            {identification?.numberOfPatientsAssessed >= 2 && (
              <div className="df">
                <div className="m-2-r-2">
                  <Icon
                    assistiveText={{ label: "warning" }}
                    category="utility"
                    name="warning"
                    size="xx-small"
                    colorVariant="warning"
                  />
                </div>
                <div className="multiple-pcrs">
                  This call requires at least{" "}
                  {identification?.numberOfPatientsAssessed} PCRs
                </div>
              </div>
            )}
            {pcrData?.numberOfPatientsAssessedError && (
              <div className="slds-form-element__help selectionError">
                <div className="df">
                  <p className="margin-err-text-4">
                    {" "}
                    {pcrData?.numberOfPatientsAssessedError}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="patient-options-section-2 df">
            {identification?.numberOfPatientsAssessed === 0 ? (
              <>
                <div style={{ width: "100%" }}>
                  <RadioGroup
                    variant="button-group"
                    onChange={(e) => {
                      setNoOfPatientOption(e.target.value);
                      updatePCRContext({
                        noPatientsAssessedReason: e.target.value,
                      });
                      if (e.target.value === "Patient refusal") {
                        setOpenRefusalModal(true);
                      } else {
                        setOpenRefusalModal(false);
                      }
                    }}
                  >
                    {zeroPatientAssessedOptions.map((ele) => {
                      return (
                        <Radio
                          id={`settings-popover-${ele.label}`}
                          labels={{
                            label: (
                              <>
                                <span>{ele.label}</span>
                                <Popover
                                  body={t(ele.info)}
                                  id="settings-popover"
                                  align="bottom right"
                                >
                                  <Button
                                    assistiveText={{ icon: "info" }}
                                    iconCategory="utility"
                                    iconName="info"
                                    iconSize="medium"
                                    variant="icon"
                                  />
                                </Popover>
                              </>
                            ),
                          }}
                          value={ele?.value}
                          checked={
                            identification?.noPatientsAssessedReason === ele.value
                          }
                          variant="base"
                          className={ele?.cls || ""}
                        />
                      );
                    })}
                  </RadioGroup>
                </div>
              </>
            ) : null}
          </div>
          <div className="df">
            {identification?.numberOfPatientsAssessed === 0 && (
              <div>
                {identification?.noPatientsAssessedReason ===
                  "Transported by other" && (
                  <div className="patient-options-section-3-other section-3-alignment">
                    <div
                      className="slds-form-element"
                      style={{ textAlign: "left" }}
                    >
                      <label className="slds-form-element__label w-r-7">
                        {identification?.patientTransported == "No" && (
                          <abbr class="slds-required" title="required">
                            *
                          </abbr>
                        )}

                        {t("agencyName")}
                      </label>
                      <select
                        name="agencyName"
                        className="slds-input w-r-7"
                        onChange={(e) =>
                          updatePCRContext({ agencyName: e.target.value })
                        }
                        value={identification.agencyName}
                      >
                        {agencyNameList.map((ele) => {
                          return (
                            <option key={ele?.label} value={ele?.label}>
                              {ele.label}
                            </option>
                          );
                        })}
                      </select>
                      {pcrData?.agencyNameError && (
                        <div className="slds-form-element__help selectionError">
                          <div className="df">
                            <p className="margin-err-text-4">
                              {pcrData?.agencyNameError}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {identification.agencyName === "Other" && (
                      <div>
                        <Input
                          className="p-l-1"
                          name="otherAgency"
                          placeholder={t("pleaseSpecify")}
                          value={otherAgency}
                          onChange={(e) =>
                            updatePCRContext({
                              otherAgency: e.target.value,
                            })
                          }
                          required={
                            identification?.noPatientsAssessedReason ===
                            "Transported by other"
                          }
                          onBlur={(e) => {
                            if (
                              !e.target.value &&
                              identification?.noPatientsAssessedReason ===
                                "Transported by other"
                            ) {
                              setPCRData({
                                ...pcrData,
                                ...{
                                  otherAgencyError: t(
                                    "inputIsRequiredError"
                                  ),
                                },
                              });
                            }
                          }}
                        />
                        <div>
                          {pcrData?.otherAgencyError && (
                            <div className="slds-form-element__help selectionError">
                              <div className="df">
                                <p className="margin-err-text-4">
                                  {pcrData?.otherAgencyError}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {identification?.numberOfPatientsAssessed >= 1 && (
          <div
            className="df m-l-r-1"
            style={{ paddingBottom: "1rem", minHeight: "6rem" }}
          >
            <div className="patient-options-section-1">
              <span>
                {" "}
                <span style={{ color: "red" }}>* &nbsp;</span>
                {t("isPatientTransported")}
              </span>
              <Popover
                body={t("isPatientTransportedValue")}
                id="settings-popover"
                align="bottom right"
              >
                <Button
                  assistiveText={{ icon: "info" }}
                  iconCategory="utility"
                  iconName="info"
                  iconSize="medium"
                  variant="icon"
                />
              </Popover>
              <RadioGroup
                labels={""}
                onChange={(e) => {
                  updatePCRContext({ patientTransported: e.target.value });
                }}
                variant="button-group"
              >
                {yesOrNoRadioOptions.map((ele) => {
                  return (
                    <Radio
                      id={`isPatientTransported-${ele.label}`}
                      labels={{ label: ele.label }}
                      value={ele?.value}
                      checked={identification.patientTransported === ele.value}
                      variant="base"
                      className={ele?.cls || ""}
                    />
                  );
                })}
              </RadioGroup>

              <div className="slds-form-element__help selectionError">
                {pcrData?.patientTransportedError}
              </div>
            </div>
            <div className="patient-options-section-2">
              {identification?.patientTransported === "No" ? (
                <RadioGroup
                  variant="button-group"
                  onChange={(e) => {
                    setNoOfPatientOption(e.target.value);
                    updatePCRContext({ noPatientsAssessedReason: e.target.value });
                    if (e.target.value === "Patient refusal") {
                      setOpenRefusalModal(true);
                    } else {
                      setOpenRefusalModal(false);
                    }
                  }}
                >
                  {zeroPatientOptions.map((ele) => {
                    return (
                      <Radio
                        id={`zeroPatient-${ele.label}`}
                        labels={{
                          label: (
                            <>
                              <span>{ele.label}</span>
                              <Popover
                                body={t(ele.info)}
                                id="settings-popover"
                                align="bottom right"
                              >
                                <Button
                                  assistiveText={{ icon: "info" }}
                                  iconCategory="utility"
                                  iconName="info"
                                  iconSize="medium"
                                  variant="icon"
                                />
                              </Popover>
                            </>
                          ),
                        }}
                        value={ele?.value}
                        checked={
                          identification?.noPatientsAssessedReason === ele.value
                        }
                        variant="base"
                        className={ele?.cls || ""}
                      />
                    );
                  })}
                </RadioGroup>
              ) : null}
            </div>
            {identification?.noPatientsAssessedReason === "Pre-ALRT" &&
              identification?.patientTransported === "No" && (
                <div className="patient-options-section-3 df">
                  <div>
                    <div className="pr">
                      <PCRTextInput
                        assistiveText={t("prePHEWScore")}
                        value={identification?.prePHEW}
                        label={t("Initial PHEW ")}
                        name="prePHEW"
                        key={identification?.noPatientsAssessedReason}
                        className="w-r-7"
                        onChange={updatePCRContext}
                        // Pre-ALRT
                        required={
                          identification?.noPatientsAssessedReason === "Pre-ALRT"
                        }
                        errorText={
                          identification?.noPatientsAssessedReason === "Pre-ALRT" &&
                          pcrData?.prePHEWError && (
                            <div className="df">
                              <p className=""></p> {pcrData?.prePHEWError}
                            </div>
                          )
                        }
                        regExValidationText={"ONLY_NUMERIC"}
                        validateOnBlur={false}
                      />
                      <Button
                        onClick={() => setOpenPHEWDocument(true)}
                        assistiveText={{ icon: "phew-modal" }}
                        iconCategory="utility"
                        iconName="info"
                        iconSize="medium"
                        className="pa phewButton"
                        variant="icon"
                      />
                      <PDFViewer
                        showPDF={openPHEWDocument}
                        onDone={() => setOpenPHEWDocument(false)}
                        fileName={"./PHEW_v3.pdf"}
                      />
                    </div>
                    <div className="pr">
                      <PCRTextInput
                        assistiveText={t("postPHEWScore")}
                        value={identification?.postPHEW}
                        label={t("Final PHEW")}
                        name="postPHEW"
                        className="w-r-7"
                        onChange={updatePCRContext}
                        required={false}
                        regExValidationText={"ONLY_NUMERIC"}
                        validateOnBlur={false}
                      />
                      <Button
                        onClick={() => setOpenPHEWDocument(true)}
                        assistiveText={{ icon: "phew-modal" }}
                        iconCategory="utility"
                        iconName="info"
                        iconSize="medium"
                        variant="icon"
                        className="pa phewButton2"
                      />
                      <PDFViewer
                        showPDF={openPHEWDocument}
                        onDone={() => setOpenPHEWDocument(false)}
                        fileName={"./PHEW_v3.pdf"}
                      />
                    </div>
                  </div>
                  <div className="w-16 radio-alignment">
                    <div className="df">
                      <div>
                        <span style={{ color: "red", marginRight: "5px" }}>
                          *
                        </span>
                        <span> {t("CSP contacted")}</span>
                      </div>
                      <div>
                        <Button
                          onClick={() => setOpenPHEWDocument(true)}
                          assistiveText={{ icon: "phew-modal" }}
                          iconCategory="utility"
                          iconName="info"
                          iconSize="medium"
                          className=""
                          variant="icon"
                        />
                        <PDFViewer
                          showPDF={openPHEWDocument}
                          onDone={() => setOpenPHEWDocument(false)}
                          fileName={"./ALRT_Medical_v3_trauma.pdf"}
                        />
                      </div>
                    </div>
                    <RadioGroup
                      labels={{ label: "" }}
                      onChange={(e) => {
                        updatePCRContext({ cspContacted: e.target.value });
                      }}
                      className="p-l-1"
                    >
                      {yesOrNoRadioOptions.map((ele) => {
                        return (
                          <Radio
                            id={`csp-contant-${ele.label}`}
                            labels={{ label: ele.label }}
                            value={ele?.value}
                            variant="base"
                            checked={
                              identification?.cspContacted === ele?.value
                            }
                          />
                        );
                      })}
                    </RadioGroup>

                    <div className="slds-form-element__help selectionError mt-1-err">
                      {identification?.noPatientsAssessedReason === "Pre-ALRT" &&
                        pcrData?.cspContactedError && (
                          <>{pcrData?.cspContactedError}</>
                        )}
                    </div>
                  </div>
                  {identification?.cspContacted === "Yes" && (
                    <>
                      <PCRTextInput
                        onChange={updatePCRContext}
                        value={identification?.cspId}
                        className="w-r-7 p-l-1"
                        label="ID#"
                        required={identification?.cspContacted === "Yes"}
                        name="cspId"
                        errorText={
                          identification?.cspContacted === "Yes" &&
                          pcrData?.cspIdError && (
                            <div className="df">
                              <p className=""></p>
                              {pcrData?.cspIdError}
                            </div>
                          )
                        }
                        regExValidationText={"ONLY_NUMERIC"}
                        validateOnBlur={false}
                      />

                      <PCRTextInput
                        onChange={updatePCRContext}
                        value={identification?.ctasLevel}
                        className="w-r-7 p-l-1"
                        label="CTAS level"
                        required={identification?.cspContacted === "Yes"}
                        name="ctasLevel"
                        errorText={
                          identification?.cspContacted === "Yes" &&
                          pcrData?.ctasLevelError && (
                            <div className="df">
                              <p className=""></p> {pcrData?.ctasLevelError}
                            </div>
                          )
                        }
                        regExValidationText={"CTAS_REG"}
                        validateOnBlur={false}
                      />
                    </>
                  )}
                </div>
              )}
            {identification?.noPatientsAssessedReason === "Transported by other" &&
              identification?.numberOfPatientsAssessed &&
              identification?.patientTransported == "No" && (
                <div className="patient-options-section-3 section-3-alignment">
                  <div
                    className="slds-form-element"
                    style={{ textAlign: "left" }}
                  >
                    <label className="slds-form-element__label w-r-7">
                      {identification?.patientTransported == "No" && (
                        <abbr class="slds-required" title="required">
                          *
                        </abbr>
                      )}

                      {t("agencyName")}
                    </label>
                    <select
                      name="agencyName"
                      className="slds-input w-r-7"
                      onChange={(e) =>
                        updatePCRContext({ agencyName: e.target.value })
                      }
                      value={identification.agencyName}
                    >
                      {agencyNameList.map((ele) => {
                        return (
                          <option key={ele?.label} value={ele?.label}>
                            {ele?.label}
                          </option>
                        );
                      })}
                    </select>
                    {pcrData?.agencyNameError && (
                      <div className="slds-form-element__help selectionError">
                        <div className="df">
                          <p className="margin-err-text-4">
                            {pcrData?.agencyNameError}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  {identification.agencyName === "Other" && (
                    <div>
                      <Input
                        className="p-l-1"
                        name="otherAgency"
                        placeholder={t("pleaseSpecify")}
                        value={otherAgency}
                        onChange={(e) =>
                          updatePCRContext({
                            otherAgency: e.target.value,
                          })
                        }
                        required={
                          identification?.noPatientsAssessedReason ===
                          "Transported by other"
                        }
                        onBlur={(e) => {
                          if (
                            !e.target.value &&
                            identification?.noPatientsAssessedReason ===
                              "Transported by other"
                          ) {
                            setPCRData({
                              ...pcrData,
                              ...{
                                otherAgencyError: t(
                                  "inputIsRequiredError"
                                ),
                              },
                            });
                          }
                        }}
                      />
                      <div>
                        {pcrData?.otherAgencyError && (
                          <div className="slds-form-element__help selectionError">
                            <div className="df">
                              <p className="margin-err-text-4">
                                {pcrData?.otherAgencyError}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </div>
      <Modal
        footer={[
          <Button
            label="Create Refusal Form"
            onClick={() => setOpenRefusalModal(false)}
          />,
          <Button
            label="Do it later"
            onClick={() => setOpenRefusalModal(false)}
          />,
        ]}
        isOpen={openRefusalModal}
        onRequestClose={() => setOpenRefusalModal(false)}
        prompt="warning"
        size="medium"
        title={<span>Warning : Refusal Form missing </span>}
        className="identificationCustModal"
      >
        <div className="slds-m-around_medium modal-desc">
          <div>A refusal form must be completed for this PCR</div>
        </div>
      </Modal>

      {/* <Modal
        footer={[
          <Button label="Close" onClick={() => setOpenErrorModal(false)} />,
        ]}
        isOpen={openErrorModal}
        onRequestClose={() => setOpenErrorModal(false)}
        size="medium"
        title={
          <span style={{ float: "left" }}>Data retrieval Unsuccessful </span>
        }
      > */}
      <div id="error-mdl-calldet">
        <Modal
          isOpen={openErrorModal}
          onRequestClose={() => setOpenErrorModal(false)}
          title={
            <span style={{ float: "left" }}>Data retrieval Unsuccessful </span>
          }
          footer={[
            <Button label="Close" onClick={() => setOpenErrorModal(false)} />,
          ]}
          className="identificationCustModal"
        >
          <div className="serverErrorWrapper wrap-error-box">
            <div className="serverErrorWarning pa"></div>
            <div className="serverErrorWarningText">
              <div style={{ marginLeft: "5px" }}>
                <p>Sorry no data found for provided transfer number</p>
                <p>Please proceed with manual entry</p>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default IdentificationCallDetails;
