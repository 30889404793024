import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./theme.css";
import AppRouter from "./routes.js";
import "./i18n.js";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
import reportWebVitals from "./reportWebVitals.js";
import { APP_CONFIG } from "./hooks/useAuth.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Set the document title based on the environment
const environment = APP_CONFIG.REACT_APP_ENVIRONMENT;
document.title = `ANB | ePCR - ${environment}`;

root.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
const config = {
  onUpdate: (registration) => {
    console.log('A new version is available.');
  },
  onSuccess: (registration) => {
    console.log('Service worker registered successfully.');
  }
};
serviceWorkerRegistration.register(config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();