import { Combobox, Icon, Input } from "@salesforce/design-system-react";
import "../../css/comboBox.css";
import { useRef, useState, useEffect } from "react";
import { useAddressComplete } from "../../hooks/useAddressComplete";
import { useNetworkStatus } from "../../hooks/useNetworkStatus";

const PCRAddressCompleteComboBox = ({
  id,
  onChange,
  label,
  required,
  options,
  value,
  onSelect,
  errorText = "",
}) => {
  const lastIdRef = useRef(null);
  const [searchLocIsOpen, setSearchLocIsOpen] = useState(false);
  const [cbOptions, setOptions] = useState(options || []);
  const { getAddressesList } = useAddressComplete();
  const isOnline = useNetworkStatus();

  const onBlur = () => {
    setSearchLocIsOpen(false);
  };

  useEffect(() => {
    if (value) {
      getLocations();
    } else {
      setOptions([
        {
          id: "noResults",
          label: "Enter address to search",
        },
      ]);
    }
  }, [value]);

  const getLocations = async () => {
    if (isOnline) {
      let res = await getAddressesList(value, lastIdRef.current);
      if (res?.Items) {
        const resultSet = res.Items?.slice(0, 10);
        setOptions(getOptions(resultSet));
      }
    }
  };

  const getOptions = (items) => {
    let optionsForAuto = [];
    if (items?.length > 0) {
      items?.forEach((element) => {
        let obj = {
          id: element?.Id,
          label: `${element?.Text || ""} ${element?.Description}`,
          next: element?.Next,
        };
        optionsForAuto = [...optionsForAuto, obj];
      });
    }

    return optionsForAuto;
  };

  const handleSceneLocSearchOnSelect = (data) => {
    if (data?.selection?.[0].next === "Find") {
      lastIdRef.current = data?.selection?.[0].id;
      getLocations();
    } else {
      lastIdRef.current = null;
      setSearchLocIsOpen(false);
      onChange(data?.selection?.[0]?.label || "");
    }
    if (onSelect) {
      onSelect(data);
      return;
    }
  };

  if (!isOnline) {
    return (
      <Input
        assistiveText={{ label }}
        label={label}
        id={`ac-${label}`}
        className="bor-rad"
        value={value}
        required={required}
      />
    );
  }

  return (
    <div className="combo-box-wrapper">
      {
        isOnline ? ( <Input
          assistiveText={{ label }}
          label={label}
          id={`ac-${label}`}
          className="bor-rad"
          value={value}
          required={required}
        />) : (
        
       <>
           <Combobox
          id={id}
          isOpen={searchLocIsOpen}
          events={{
            onChange: (event, { value }) => {
              if (value && !searchLocIsOpen) {
                setSearchLocIsOpen(true);
              }
              if (!value) {
                lastIdRef.current = null;
              }
              onChange(value);
            },
            onRequestRemoveSelectedOption: (event, data) => {
              onChange("");
              lastIdRef.current = null;
            },
            onSelect: (event, data) => {
              handleSceneLocSearchOnSelect(data);
            },
            onFocus: () => setSearchLocIsOpen(true),
            onBlur,
          }}
          labels={{
            label,
          }}
          options={cbOptions}
          value={value}
          variant="inline-listbox"
          required={required}
          errorText={errorText}
        />
        {value && (
          <div
            className="combo-box-close"
            onClick={() => handleSceneLocSearchOnSelect("")}
          >
            <Icon
              assistiveText={{ label: "Clear Text" }}
              category="utility"
              name="close"
              size="xx-small"
            />
          </div>
        )}
       </>
          
      )
      }
      
     
    </div>
  );
};

export default PCRAddressCompleteComboBox;
