import { supportedLocales } from "../data/constants.js";

const ONLY_POSITIVE_INTEGERS_REGEX = new RegExp(/^0$|^[1-9]\d*$/);

export const convertKgToLbs = (weight, key = "") => {
  let num = 0;
  if (key === "lbs") {
    num = weight / 2.2046;
  } else {
    num = weight * 2.2046;
  }
  return parseFloat(num.toPrecision(4));
};

export const renderInitials = (name) => {
  if (!name) {
    return "";
  }
  const [firstName, lastName] = name.split(" ");
  return `${firstName?.split("")[0]}${lastName.split("")[0]}`;
};

export const getLanguage = () => {
  return localStorage.getItem("lang") === supportedLocales.EN
    ? "ENG"
    : "French";
};
export const getFormattedPhoneNo = (phNumber) => {
  let phoneNo = phNumber;
  phoneNo = phoneNo.replace("+1", "");
  const firstThree = phoneNo.slice(0, 3);
  const nextThree = phoneNo.slice(3, 6);
  const lastFour = phoneNo.slice(6, 10);
  return `(${firstThree}) ${nextThree}-${lastFour}`;
};

export const validateNumericValue = (value) => {
  let updatedValue = value;
  if (updatedValue.includes(".")) {
    updatedValue = updatedValue.replace(".", "");
  }
  if (
    updatedValue &&
    !ONLY_POSITIVE_INTEGERS_REGEX.test(updatedValue) &&
    !isNaN(updatedValue)
  ) {
    updatedValue = "";
  }
  return updatedValue;
};

export const isValidMedicareNumber = (medicareNumber) => {
  // Check if the Medicare Number is not blank
  if (!medicareNumber || medicareNumber.trim().length === 0) {
    return false;
  }

  // Check if the Medicare Number contains only numeric characters
  const numericRegex = /^\d+$/;
  if (!numericRegex.test(medicareNumber)) {
    return false;
  }

  // Check if the length of the Medicare Number is exactly 9 characters
  if (medicareNumber.length !== 9) {
    return false;
  }

  // Implement the Luhn algorithm for checksum validation
  function luhnCheck(number) {
    let sum = 0;
    let shouldDouble = false;
    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number.charAt(i), 10);
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }
    return (sum % 10 === 0);
  }

  return luhnCheck(medicareNumber);
};
