import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { RouterProvider } from "react-router";
import AzureCallback from "./AzureCallback.js";
import Home from "./pages/Home.jsx";
import Landing from "./pages/Landing.jsx";
import PageLayout from "./layouts/PageLayout.jsx";
import DebugPage from "./pages/DebugPage.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageLayout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/az-callback",
        element: <AzureCallback />,
      },
      {
        path: "/debug",
        element: <DebugPage />,
      }
    ],
  },
]);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
