import React, { useContext } from 'react';
import { useUsers } from '../context/UserContext.js';

function UsersTable() {
  const { state } = useUsers();
  const { users } = state;

  if (!users || users.length === 0) {
    return <div>No users available.</div>;
  }

  console.log('users in UsersTable >>>', users);

  return (
    <table>
      <thead>
        <tr>
          <th>Full Name</th>
          <th>Employee ID</th>
          <th>Medic ID</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr key={index}>
            <td>{user.fullName}</td>
            <td>{user.employeeId}</td>
            <td>{user.medicId}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default UsersTable;