import React from "react";
import "../../css/progressBar.css";
import { useTranslation } from "react-i18next";

const PCRProgressBar = ({ status }) => {
  const { t } = useTranslation();
  return (
    <ul id="progress">
      <li className={status === "New" ? "active" : ""}>
        {t("progressBarNew")}
      </li>
      <li className={status === "In Progress" ? "active" : ""}>
        {t("progressBarInProgress")}
      </li>
      <li className={status === "Finished" ? "active" : ""}>
        {t("progressBarFinished")}
      </li>
    </ul>
  );
};

export default PCRProgressBar;
