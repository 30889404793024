export const getMedicIds = () => {
  const users = localStorage.getItem("users");
  if (users) {
    return JSON.parse(users).reduce(
      (acc, user) =>
        (acc = acc ? acc + "," + user.employeeId : user.employeeId),
      ""
    );
  }
  return "";
};
