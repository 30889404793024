import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@salesforce/design-system-react';
import '../../../../css/assessment.css';
import PCRMedications from 'components/PCR/PCR-AV/Treatment/Medications/PCRMedications';
import PCRVasculars from 'components/PCR/PCR-AV/Treatment/Vasculars/PCRVasculars';
import PCRAirwayAndBreathing from './AirwayAndBreathing/PCRAirwayAndBreathing';

export const PCRTreatment = () => {
    const { t } = useTranslation();

    return (
        <div>
            {/* Airway and Breathing section */}
            <div className='df border-bottom-styles'>
                <span className='details-icon ga-details-icon'>
                    <Icon
                        assistiveText={{ label: t('airwayAndBreathingDetails') }}
                        category='utility'
                        name='record_alt'
                        size='x-small'
                    />
                </span>
                <span className='section-header'>{t('airwayAndBreathingDetails')}</span>
            </div>

            {/* Airway and Breathing component */}
            <PCRAirwayAndBreathing />

            <div className='df border-bottom-styles'>
                <span className='details-icon ga-details-icon'>
                    <Icon
                        assistiveText={{ label: t('vascularAccessAndMedications') }}
                        category='utility'
                        name='record_alt'
                        size='x-small'
                    />
                </span>
                <span className='section-header'>{t('vascularAccessAndMedications')}</span>
            </div>


            {/* PCR Vasculars component */}
            <PCRVasculars />
            {/* end of - PCR Vasculars component */}

            {/* PCR Treatment component */}
            <PCRMedications />
            {/* end of - PCR Treatment component */}
        </div>
    );
};
