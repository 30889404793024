import React, { useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "../css/PDFViewer.css";
import { Button, Modal } from "@salesforce/design-system-react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const maxWidth = 800;

const PDFViewer = ({ fileName, onDone, showPDF }) => {
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  return (
    <Modal size="large" disableClose isOpen={showPDF}>
      <div className="pdf__wrapper">
        <div className="pdf__done">
          <Button variant="brand" className="pdf__done__btn" onClick={onDone}>
            Done
          </Button>
        </div>
        <div className="Example">
          <div className="Example__container">
            <div className="Example__container__document">
              <Document
                file={`${fileName}`}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={maxWidth}
                  />
                ))}
              </Document>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PDFViewer;
