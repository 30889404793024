import { Button } from "@salesforce/design-system-react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/useAuth";
import { useNetworkStatus } from "../hooks/useNetworkStatus";
import "../css/landing.css";
import { useUsers } from "../context/UserContext";
import AddCrewMember from "../components/AddCrewMember";


const Landing = () => {
  const [isTokenAvailable, setIsTokenAvailable] = useState(false);
  const { state } = useUsers();
  const { authorize } = useAuth();
  const isOnline = useNetworkStatus();
  const { t } = useTranslation();

  const imagePath = require("../assets/landing.jpg");

  useEffect(() => {
    const checkTokenAvailability = () => {
      setIsTokenAvailable(!!localStorage.getItem("sf_access_token"));
    };

    checkTokenAvailability();
    window.addEventListener('storage', checkTokenAvailability);

    return () => {
      window.removeEventListener('storage', checkTokenAvailability);
    };
  }, []);

  const handleLogin = () => {
    authorize();
  };
  return (
    <>
      {/* header - green bar */}
      <div className="top-header df">
        <div className="landingWelcome">{t("welcome")}</div>
        <div className="anb-image" />
      </div>


      {/* main-content */}
      <div className="main-content slds-m-top_small">
        <div class="slds-grid slds-wrap">

          {/* left image */}
          <div class="slds-col slds-size_1-of-2">
            <img src={require("../assets/landing.jpg")} alt="landing" />
          </div>

          {/* right content */}
          <div class="slds-col slds-size_1-of-2 slds-align_absolute-center">
            <div className="right-content">
              <p>{t("landingPara1")}</p>
              {/* add crew member */}
              
                {isTokenAvailable && (
                  <div className="slds-m-top_medium">
                    <AddCrewMember />
                  </div>
                )}
              {/* end add crew member */}

              {/* login */}
              {!isTokenAvailable && (
                <>
                  <br />
                  <p>{t("landingPara2")}</p>
                  <div className="login-button-container slds-m-top_medium slds-grid slds-wrap">
                    <div className="slds-col slds-size_1-of-1">
                      <div className="slds-align_absolute-center">
                      <Button
                        disabled={!isOnline}
                        variant="outline-brand"
                        onClick={handleLogin}
                      >
                        {t("login")}
                      </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* end login */}
            </div>
          </div>
          {/* end right content */}
        </div>
      </div>
      {/* end main-content */}


    </>
  );
};

export default Landing;