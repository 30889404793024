import React, { useEffect, useState } from "react";
import "../css/userProfile.css";
import Profile from "./Profile";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@salesforce/design-system-react";

const UserProfiles = () => {
  const { t } = useTranslation();
  const localStorageUsers = localStorage.getItem("users");
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const { revokeSFToken, logoutFromAD } = useAuth();
  // display error modal if user is not removed
  const [showErrorModal, setShowErrorModal] = useState(false);

  // get remove crew member error modal footer buttons
  const getFooterButtons = () => {
    const footerButtonList = [];
    // close button
    footerButtonList.push(
      <Button
        id="remove-crew-member-close-btn"
        variant="brand"
        onClick={handleCloseClick}
      >
        {t("removeCrewMember_closeButtonLabel")}
      </Button>
    );
    return footerButtonList;
  };

  // close the modal
  const handleCloseClick = async () => {
    setShowErrorModal(false);
  }

  useEffect(() => {
    if (localStorageUsers) {
      setUsers(JSON.parse(localStorageUsers));
    }
  }, [localStorageUsers]);

  const handleLogout = async (user) => {
    try {
      // Revoke Salesforce token
      const isSFAccessTokenRevoked = await revokeSFToken(user.sf_access_token);
      console.log("isSFAccessTokenRevoked >>>", isSFAccessTokenRevoked);

      if (isSFAccessTokenRevoked) {
        // Clear AD access token
        const isADAccessTokenCleared = await clearADAccessTokenIfUser(user);
        console.log("isADAccessTokenCleared >>>", isADAccessTokenCleared);

        // Logout from AD
        const isADLogout = await logoutFromAD(user);
        console.log("isADLogout >>>", isADLogout);

        // Remove user from list
        const isUserRemoved = await removeUserFromList(user.employeeId);
        console.log("isUserRemoved >>>", isUserRemoved);

        if (!isUserRemoved) {
          throw new Error("Failed to remove user from list");
        }
      } else {
        throw new Error("Failed to revoke Salesforce token");
      }
    } catch (error) {
      console.error("Logout error:", error.message);
    } finally {
      // Always navigate to the home page, regardless of success or failure
      navigate("/");
    }
  };

  // clear ad_access_token if user's ad_access_token is the same as localstorage's ad_access_token
  const clearADAccessTokenIfUser = async (user) => {
    const ad_access_token = localStorage.getItem("ad_access_token");
    if (ad_access_token === user.ad_access_token) {
      localStorage.setItem("ad_access_token", "");
    }
    return localStorage.getItem("ad_access_token") === "";
  }

  const removeUserFromList = async (employeeId) => {
    // Check if the users list has the user in the list, return true user is not there
    const users = JSON.parse(localStorage.getItem("users"));
    const usersList = users.filter((u) => u.employeeId !== employeeId);
    localStorage.setItem("users", JSON.stringify(usersList));
    if (!usersList.length) {
      localStorage.setItem("sf_access_token", "");
    }
    setUsers(usersList);
    // double check : using employeeId check if the user is in the usersList return true user is not there
    const isUserRemoved = usersList.find((u) => u.employeeId === employeeId);
    return !isUserRemoved;
  }

  const handleRemoveCrewMember = async (user) => {
    let isUserRemoved = false;

    isUserRemoved = await removeUserFromList(user.employeeId);

    console.log("handleRemoveCrewMember > isUserRemoved >>>", isUserRemoved);
    // show error modal if user is not removed
    if (!isUserRemoved) {
      setShowErrorModal(true);
    }
  }

  return (
    <>
      <div className="df pr10 mt-20">
        {users.map((user, index) => (
          <Profile user={user} index={index} handleLogout={handleLogout} handleRemoveCrewMember={handleRemoveCrewMember} />
        ))}
      </div>

      {/* error modal  show when showErrorModal is true */}
      <>
        <div className="removeCrewMemberErrorModalWrapper">
          <Modal
            isOpen={showErrorModal}
            onRequestClose={handleCloseClick}
            header={t("removeCrewMember_modalHeader")}
            footer={getFooterButtons()}
            id="removeCrewMemberErrorModal"
            className="removeCrewMemberErrorModal"
          >
            <section id="removeCrewMemberErrorModalSection" className="slds-p-around_medium">
              {/* server error and no network */}
              <div className="errorWrapper">
                <div className="errorWarning pa"></div>
                <div className="errorWarningText">{t("removeCrewMember_modalDescription")}</div>
              </div>
            </section>
          </Modal>
        </div>
      </>
    </>
  );
};

export default UserProfiles;