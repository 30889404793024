import { useState } from 'react';

// Define the PcrData class as provided earlier
class PcrData {
    constructor(data) {
        // Initialize basic properties
        this.callType = data?.callType || '';
        this.treatment = data?.treatment || {};
        this.transferOfCare = data?.transferOfCare || {};
        this.pcrType = data?.pcrType || '';
        this.pcrTransferNumber = data?.pcrTransferNumber || '';
        this.pcrFormStatus = data?.pcrFormStatus || '';
        this.pcrFormSFId = data?.pcrFormSFId || '';
        this.pcrFormId = data?.pcrFormId || '';
        this.pcrDate = data?.pcrDate || new Date();
        this.pcrCause = data?.pcrCause || '';
        this.pcrCallTime = data?.pcrCallTime || new Date();
        this.pcrAttendPractitioner = data?.pcrAttendPractitioner || '';
        this.patientSex = data?.patientSex || '';
        this.patientName = data?.patientName || '';
        this.patientAge = data?.patientAge || '';
        this.identificationDetails = {
            vehicleType: data?.identificationDetails?.vehicleType || null,
            vehicleNumber: data?.identificationDetails?.vehicleNumber || null,
            traumaDetails: data?.identificationDetails?.traumaDetails || null,
            sceneLocationCode: data?.identificationDetails?.sceneLocationCode || '',
            sceneLocationAddress: {
                streetAddress1: data?.identificationDetails?.sceneLocationAddress?.streetAddress1 || '',
                streetAddress2: data?.identificationDetails?.sceneLocationAddress?.streetAddress2 || null,
                stateCode: data?.identificationDetails?.sceneLocationAddress?.stateCode || '',
                postalCode: data?.identificationDetails?.sceneLocationAddress?.postalCode || '',
                countryCode: data?.identificationDetails?.sceneLocationAddress?.countryCode || '',
                city: data?.identificationDetails?.sceneLocationAddress?.city || ''
            },
            prePHEW: data?.identificationDetails?.prePHEW || '',
            postPHEW: data?.identificationDetails?.postPHEW || '',
            patientTransported: data?.identificationDetails?.patientTransported || null,
            patient: {
                weightLbs: data?.identificationDetails?.patient?.weightLbs || '',
                weightKgs: data?.identificationDetails?.patient?.weightKgs || '',
                uniqueIdentifierType: data?.identificationDetails?.patient?.uniqueIdentifierType || '',
                uniqueIdentifier: data?.identificationDetails?.patient?.uniqueIdentifier || '',
                telephone: data?.identificationDetails?.patient?.telephone || '',
                sexAtBirth: data?.identificationDetails?.patient?.sexAtBirth || '',
                nextOfKinTelephone: data?.identificationDetails?.patient?.nextOfKinTelephone || '',
                nextOfKinRelationship: data?.identificationDetails?.patient?.nextOfKinRelationship || '',
                nextOfKin: data?.identificationDetails?.patient?.nextOfKin || '',
                lastName: data?.identificationDetails?.patient?.lastName || '',
                initial: data?.identificationDetails?.patient?.initial || '',
                genderIdentity: data?.identificationDetails?.patient?.genderIdentity || '',
                firstName: data?.identificationDetails?.patient?.firstName || '',
                enteredUniqueId: data?.identificationDetails?.patient?.enteredUniqueId || null,
                enteredName: data?.identificationDetails?.patient?.enteredName || null,
                enteredDOB: data?.identificationDetails?.patient?.enteredDOB || null,
                enteredAddress: data?.identificationDetails?.patient?.enteredAddress || null,
                dateOfBirth: data?.identificationDetails?.patient?.dateOfBirth || '',
                ageUnit: data?.identificationDetails?.patient?.ageUnit || 'Y',
                age: data?.identificationDetails?.patient?.age || '',
                addressStreet: data?.identificationDetails?.patient?.addressStreet || '',
                addressStateCode: data?.identificationDetails?.patient?.addressStateCode || '',
                addressPostalCode: data?.identificationDetails?.patient?.addressPostalCode || '',
                addressCountryCode: data?.identificationDetails?.patient?.addressCountryCode || 'CA',
                addressCity: data?.identificationDetails?.patient?.addressCity || ''
            },
            overrideReason: data?.identificationDetails?.overrideReason || null,
            overrideMandatoryValues: data?.identificationDetails?.overrideMandatoryValues || null,
            otherAgency: data?.identificationDetails?.otherAgency || null,
            numberOfPatientsTransported: data?.identificationDetails?.numberOfPatientsTransported || '',
            numberOfPatientsAssessed: data?.identificationDetails?.numberOfPatientsAssessed || '',
            noPatientTransportedReason: data?.identificationDetails?.noPatientTransportedReason || null,
            noPatientsAssessedReason: data?.identificationDetails?.noPatientsAssessedReason || null,
            logisEnRoute: data?.identificationDetails?.logisEnRoute || null,
            logisDepartScene: data?.identificationDetails?.logisDepartScene || null,
            logisCallReceived: data?.identificationDetails?.logisCallReceived || null,
            logisCallDate: data?.identificationDetails?.logisCallDate || null,
            logisAtDestination: data?.identificationDetails?.logisAtDestination || null,
            logisArrivedAtScene: data?.identificationDetails?.logisArrivedAtScene || null,
            logisArrivedAtPatient: data?.identificationDetails?.logisArrivedAtPatient || null,
            interfacilityTransferReasonDiagnosis: data?.identificationDetails?.interfacilityTransferReasonDiagnosis || null,
            interfacilityTransferReason: data?.identificationDetails?.interfacilityTransferReason || null,
            estimatedTimeOfInjuryUnknown: data?.identificationDetails?.estimatedTimeOfInjuryUnknown || null,
            estimatedTimeOfInjury: data?.identificationDetails?.estimatedTimeOfInjury || null,
            epcrPatientCareRecordSFId: data?.identificationDetails?.epcrPatientCareRecordSFId || '',
            epcrFormID: data?.identificationDetails?.epcrFormID || '',
            epcrEnRoute: data?.identificationDetails?.epcrEnRoute || '',
            epcrDepartScene: data?.identificationDetails?.epcrDepartScene || '',
            epcrCallReceived: data?.identificationDetails?.epcrCallReceived || '',
            epcrCallDate: data?.identificationDetails?.epcrCallDate || '',
            epcrAtDestination: data?.identificationDetails?.epcrAtDestination || '',
            epcrArrivedAtScene: data?.identificationDetails?.epcrArrivedAtScene || '',
            epcrArrivedAtPatient: data?.identificationDetails?.epcrArrivedAtPatient || '',
            destinationLocationCode: data?.identificationDetails?.destinationLocationCode || '',
            destinationLocationAddress: {
                streetAddress1: data?.identificationDetails?.destinationLocationAddress?.streetAddress1 || '',
                streetAddress2: data?.identificationDetails?.destinationLocationAddress?.streetAddress2 || null,
                stateCode: data?.identificationDetails?.destinationLocationAddress?.stateCode || '',
                postalCode: data?.identificationDetails?.destinationLocationAddress?.postalCode || '',
                countryCode: data?.identificationDetails?.destinationLocationAddress?.countryCode || '',
                city: data?.identificationDetails?.destinationLocationAddress?.city || ''
            },
            ctasLevel: data?.identificationDetails?.ctasLevel || '',
            cspId: data?.identificationDetails?.cspId || '',
            cspContacted: data?.identificationDetails?.cspContacted || null,
            codeToScene: data?.identificationDetails?.codeToScene || '',
            codeFromScene: data?.identificationDetails?.codeFromScene || '',
            agencyName: data?.identificationDetails?.agencyName || ''
        };
    
        // Initialize new properties for assessment
        this.assessment = {
            wounds: data?.assessment?.wounds || null,
            wasTheActiveOfferMade: data?.assessment?.wasTheActiveOfferMade || null,
            unknownRestraint: data?.assessment?.unknownRestraint || null,
            typeOfBurn: data?.assessment?.typeOfBurn || '',
            truckSpeedKmH: data?.assessment?.truckSpeedKmH || null,
            truck: data?.assessment?.truck || null,
            traumaPatient: data?.assessment?.traumaPatient || null,
            traumaAssessmentNotes: data?.assessment?.traumaAssessmentNotes || [],
            transportTruckSpeedKmH: data?.assessment?.transportTruckSpeedKmH || null,
            transportTruck: data?.assessment?.transportTruck || null,
            traction: data?.assessment?.traction || null,
            tpod: data?.assessment?.tpod || null,
            tourniquetTime: data?.assessment?.tourniquetTime || '',
            tourniquet: data?.assessment?.tourniquet || null,
            stabbing: data?.assessment?.stabbing || null,
            splintingAndImobOther: data?.assessment?.splintingAndImobOther || null,
            snowmobileSpeedKmH: data?.assessment?.snowmobileSpeedKmH || null,
            snowmobile: data?.assessment?.snowmobile || null,
            seatbelt: data?.assessment?.seatbelt || null,
            rangeM: data?.assessment?.rangeM || '',
            range: data?.assessment?.range || null,
            protocolGuidelineSecondary: data?.assessment?.protocolGuidelineSecondary || '',
            protocolGuideline: data?.assessment?.protocolGuideline || '',
            pressure: data?.assessment?.pressure || null,
            pointOfImpact: data?.assessment?.pointOfImpact || '',
            pleaseSpecifyTypeOfBurn: data?.assessment?.pleaseSpecifyTypeOfBurn || '',
            patientVitals: data?.assessment?.patientVitals || [],
            patientMedications: data?.assessment?.patientMedications || null,
            patientLocationAtTimeOfInjury: data?.assessment?.patientLocationAtTimeOfInjury || '',
            patientAllergies: data?.assessment?.patientAllergies || null,
            pastMedicalHistory: data?.assessment?.pastMedicalHistory || null,
            otherVehiclePleaseSpecify: data?.assessment?.otherVehiclePleaseSpecify || '',
            otherVehicle: data?.assessment?.otherVehicle || null,
            otherTypeOfInjury: data?.assessment?.otherTypeOfInjury || '',
            otherPatientLocation: data?.assessment?.otherPatientLocation || null,
            otherPatientImmobilization: data?.assessment?.otherPatientImmobilization || '',
            otherMechanism: data?.assessment?.otherMechanism || '',
            otherBleedingText: data?.assessment?.otherBleedingText || null,
            otherBleedingCheck: data?.assessment?.otherBleedingCheck || null,
            offLadderLandingSurface: data?.assessment?.offLadderLandingSurface || '',
            numberOfWounds: data?.assessment?.numberOfWounds || '',
            noRestraints: data?.assessment?.noRestraints || null,
            motorycleSpeedKmH: data?.assessment?.motorycleSpeedKmH || null,
            motorcycle: data?.assessment?.motorcycle || null,
            metersOffLadder: data?.assessment?.metersOffLadder || '',
            metersEjected: data?.assessment?.metersEjected || '',
            longGun: data?.assessment?.longGun || null,
            ked: data?.assessment?.ked || null,
            helmet: data?.assessment?.helmet || null,
            handgun: data?.assessment?.handgun || null,
            gsw: data?.assessment?.gsw || null,
            generalAssessmentNotes: data?.assessment?.generalAssessmentNotes || [],
            extremity: data?.assessment?.extremity || null,
            ejected: data?.assessment?.ejected || null,
            dressing: data?.assessment?.dressing || null,
            downstairsNumberOfSteps: data?.assessment?.downstairsNumberOfSteps || '',
            downstairsLandingSurface: data?.assessment?.downstairsLandingSurface || '',
            chiefComplaint: data?.assessment?.chiefComplaint || '',
            cervicalCollar: data?.assessment?.cervicalCollar || null,
            carSeat: data?.assessment?.carSeat || null,
            carOrSuvSpeedKmH: data?.assessment?.carOrSuvSpeedKmH || '',
            carOrSuv: data?.assessment?.carOrSuv || null,
            booster: data?.assessment?.booster || null,
            bicylcleSpeedKmH: data?.assessment?.bicylcleSpeedKmH || null,
            bicycle: data?.assessment?.bicycle || null,
            backboard: data?.assessment?.backboard || null,
            atvSpeedKmH: data?.assessment?.atvSpeedKmH || null,
            atv: data?.assessment?.atv || null,
            assault: data?.assessment?.assault || null,
            airbagDeployed: data?.assessment?.airbagDeployed || null
        };
    }
    
}



export const useHandlePcrData = () => {
    const [pcrData, setPcrData] = useState(null);

    function setPcrRoot(data) {
        const newPcrData = new PcrData(data);  // Assuming PcrData is a class to create a new instance
        setPcrData(newPcrData);
        return newPcrData;  // Return the newly created instance if needed
    }

    return {
        pcrData,
        setPcrRoot
    };
};

