import React, { useEffect, useState } from "react";
import { useAuth } from "./hooks/useAuth.js";

const AzureCallback = () => {
  const { fetchADToken } = useAuth();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      const route = window.location.href;
      if (route.includes("code")) {
        const code = new URLSearchParams(window.location.search).get("code");
        if (code) {
          try {
            await fetchADToken(code);
          } catch (err) {
            setError(err.message);
          } finally {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    };
    initialize();
  }, [fetchADToken]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div>Loading...</div>
    </React.Fragment>
  );
};

export default AzureCallback;