import React, { useContext, useEffect, useState } from "react";
import { Button, Icon } from "@salesforce/design-system-react";
import { useLiveQuery } from "dexie-react-hooks";
import { useTranslation } from "react-i18next";
import "../css/pageLayout.css";
import PCRProgressBar from "../components/common/PCRProgressBar";
import CreateNewPCR from "./CreateNewPCR";
import { PCRAccordionList } from "./PCRAccordionList";
import { Files } from "./Files";
import { useNetworkStatus } from "../hooks/useNetworkStatus";
import { useAuth } from "../hooks/useAuth";
import { getMedicIds } from "../storage/localStorage";
import { fetchPCRList, getAllPCRData, savePCRData } from "utils/pcrDbUtil"; // Corrected import path
import { db } from "../storage/idb";
import PCRGrid from "./PCRGrid";
import PCRSummaryView from "./PCRSummaryView";
import { PCRContext } from "../context/PCRContext";
import moment from "moment";


const PCRPageLayout = () => {
  const [selectedPCR, setSelectedPCR] = useState(null);
  const { pcrData, setPCRData } = useContext(PCRContext);
  //const [items, setItems] = useState([]); // Use a local state for items
  const [createPCRTransferNo, setCreatePCRTransferNo] = useState("");
  const medicIds = getMedicIds();
  // const [pcrList, setPcrList] = useState([]);
  const { t } = useTranslation();
  const isOnline = useNetworkStatus();
  const { authorize } = useAuth();

    const items = useLiveQuery(async () => {
    const records = await db.pcrs.where({ medicIds }).toArray();
    
    return records.map((r) => {
      let json = JSON.parse(r.json);
      json = {
        ...json,
        // ...json.identification,
      };
      let record = {
        pcrId: r.id,
        ...r,
        ...json,
        status: r.status,
      };
      delete record.json;
      return record;
    });
  }, [medicIds]); 

  /* 71
  // Fetch the items from IndexedDB
  useEffect(() => {
    const loadData = async () => {
      //await fetchPCRList(); // Fetch the PCR list from the server and update the IndexedDB
      const data = await getAllPCRData(); // Fetch all PCR data from IndexedDB
      let updatedData = [];
      for (let d of data) {
        updatedData.push({
          ...d,
          callDate: d.callDate ? moment(d.callDate).format('DD/MM/YYYY') : "",
          identification: {
            ...d.identification,
          },
        });
      }
      setItems(updatedData);
    };

    loadData();
  }, []);
  */

   useEffect(() => {
    
    if (items && items.length && !selectedPCR) {
      if (pcrData) {
        setPCRData({
          ...pcrData,
          isAttendingCrew: items[0].isAttendingCrew,
          identification: {
            ...pcrData.identification,
            ...items[0].identification,
          },
        });
      }
    }
    if (items && items.length && createPCRTransferNo) {
      const activePCR = items.find(
        (t) => t.transferNumber === createPCRTransferNo
      );
      if (activePCR) {
        setSelectedPCR(activePCR);
        setPCRData({
          ...activePCR,
          isAttendingCrew: activePCR.isAttendingCrew,
          identification: {
            ...activePCR.identification,
          },
        });
      }
      setCreatePCRTransferNo("");
    }
  }, [items]); 

  /* 71
  useEffect(() => {

    if (items && items.length && !selectedPCR) {
      if (!pcrData || Object.keys(pcrData).length === 0) {
        console.log("!pcrData items[0]", items[0]);
        setPCRData({
          ...items[0], // Set the first item in PCR data          
        });
      }
    }
  }, [items, selectedPCR, pcrData, setPCRData]);
  */

  //const handleUpdateOnPCR = () => {};

  
  const handlePCRSelection = (data) => {
    setSelectedPCR(data);
    setPCRData({
      ...data,
      isAttendingCrew: data?.isAttendingCrew,
      identification: {
        ...data?.identification,
      },
    });
  };
  

  /* 71
  const handlePCRSelection = (data) => {
    setSelectedPCR(data);
    setPCRData(data);
  };
  */

  const onCreatePCR = (transferNumber) => {
    setCreatePCRTransferNo(transferNumber);
  };

  /* 71
  const onCreatePCR = async (transferNumber) => {
    const updatedItems = await getAllPCRData();
    setItems(updatedItems);

    const newPCR = updatedItems.find(pcr => pcr.transferNumber === transferNumber);
    if (newPCR) {
      setSelectedPCR(newPCR);
      setPCRData(newPCR);
    }
  }; 
  */

  const handleUpdateOnPCR = () => {};
  /* 
  const handleUpdateOnPCR = async (updatedData) => {
    await savePCRData(updatedData); // Save updated data to IndexedDB
    const updatedItems = await getAllPCRData(); // Reload items from IndexedDB
    setItems(updatedItems);
    setPCRData(updatedData); // Update the current PCR data
  }; 
  */

  return (
    <div className="root">
      <div className="pageLayoutWrapper">
        <div className="flexWrapper">
          <div className="pcrIconWrapper">
            <div className="pcrIcon">
              <Icon
                assistiveText={{ label: "PCR" }}
                category="utility"
                name="identity"
                size="medium"
                style={{
                  fill: "#3ba755",
                }}
              />
            </div>
            <div className="pcrText">PCR</div>
          </div>
          <div className="positionRight">
            <CreateNewPCR onPCRSelection={onCreatePCR} />
          </div>
        </div>
        {items?.length ? (
          <PCRGrid data={items || []} onPCRSelection={handlePCRSelection} />
        ) : (
          <div>{t("noPCRsAvailable")}</div>
        )}
      </div>
      {selectedPCR && (
        <PCRSummaryView data={selectedPCR} onClosePCR={handlePCRSelection} />
      )}
      {selectedPCR && <PCRProgressBar status={selectedPCR.status} />}
      {selectedPCR && (
        <PCRAccordionList pcr={selectedPCR} updatePcr={handleUpdateOnPCR} />
      )}
      {selectedPCR && <Files />}
    </div>
  );
};

export default PCRPageLayout;
