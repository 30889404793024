import { Popover } from "@salesforce/design-system-react";
import React, { useState } from "react";
import { renderInitials } from "../utils/util";
import { useTranslation } from "react-i18next";
import { profileColors } from "../data/constants";

const Profile = ({ user, index, handleLogout, handleRemoveCrewMember }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handlePopoverClose = () => setIsOpen(false);

  const handleAvatarClick = () => setIsOpen(!isOpen);

  const handleLogoutClick = (user) => {
    setIsOpen(false);
    handleLogout(user);
  };

  const handleRemoveCrewMemberClick = (user) => {
    setIsOpen(false);
    handleRemoveCrewMember(user);
  };

  const renderProfileContent = (user, index) => {
    return (
      <div>
        <div className="popoverHeader">{t("accountCap")}</div>
        <div className="df">
          <div
            className="profileAvatarWrapper ml0 mr5"
            style={{ backgroundColor: profileColors[index] }}
          >
            <div className="profileAvatar">
              {renderInitials(user?.fullName)}
            </div>
          </div>
          <div className='user-info-container'>
            <div>
              <div className="profile-fullname">{user?.fullName}</div>
              <div className="profile-title">{user?.title}</div>
            </div>
          </div>
        </div>        
        {user?.sf_access_token ? (
          <div className="logout" onClick={() => handleLogoutClick(user)}>
            {t("logoutSeparated")}
          </div>
        ) : (
          <div className="removeCrewMember" onClick={() => handleRemoveCrewMemberClick(user)}>
            {t("removeCrewMember")}
          </div>
        )}

      </div>
    );
  };

  return (
    <Popover
      body={renderProfileContent(user, index)}
      id="profile-popover"
      align="bottom right"
      isOpen={isOpen}
      onRequestClose={handlePopoverClose}
    >
      <div
        className="profileAvatarWrapper mt10"
        style={{ backgroundColor: profileColors[index] }}
        onClick={handleAvatarClick}
      >
        <div className="profileAvatar">
          <div>{renderInitials(user?.fullName)}</div>
        </div>
      </div>
    </Popover>
  );
};

export default Profile;
