import React, { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RadioGroup, Radio, Button, Input, Popover, Checkbox, Icon } from "@salesforce/design-system-react";
import {
    VALIDATION_TYPES,
    yesOrNoRadioOptions,
    belongingsOptions,
    belongingsOptionsGivenTo,
    attendingPractitionerPrimaryStations,
    verbalReportPositions,
    accessGivenTo
} from "../../../../data/constants";
import { PCRContext } from "../../../../context/PCRContext";
import PCRComboBox from "../../../common/PCRComboBox";
import PCRPredefinedList from "../../../PCRPredefinedList";
import TOCAssistingPersonnelItem from "./TOCAssistingPersonnelItem";
import "css/transferOfCare.css";

export const PCRTransferOfCare = ({ pcr, id, handleCancelConfirmation }) => {
    const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
    const { transferOfCare } = pcrData;
    const { t } = useTranslation();

    const [properHandHygiene, setProperHandHygiene] = useState(transferOfCare?.wasProperHandHygieneCompleted || "");
    const [arePatientBelongingsPresent, setArePatientBelongingsPresent] = useState(transferOfCare?.arePatientBelongingsPresent || "");
    const [patientBelongingsSearch, setPatientBelongingsSearch] = useState("");
    const [patientBelongingsGivenTo, setPatientBelongingsGivenTo] = useState(transferOfCare?.patientBelongingsGivenTo || "Select");
    const [otherSpecify, setOtherSpecify] = useState(transferOfCare?.otherSpecify || "");
    const [numberOfPatients, setNumberOfPatients] = useState(transferOfCare?.numberOfPatients || "");
    const [patientOffloadDelay, setPatientOffloadDelay] = useState(transferOfCare?.patientOffloadDelay || "");
    const [attendingPractitionerId, setAttendingPractitionerId] = useState(transferOfCare?.attendingPractitionerId || "");
    const [attendingPractitionerStation, setAttendingPractitionerStation] = useState(transferOfCare?.attendingPractitionerStation || "Select");
    const [supportingPractitionerId, setSupportingPractitionerId] = useState(transferOfCare?.supportingPractitionerId || "");
    const [supportingParamedicId, setSupportingParamedicId] = useState(transferOfCare?.supportingParamedicId || "");
    const [assistingPersonnelItems, setAssistingPersonnelItems] = useState(transferOfCare?.assistingPersonnel || [{ id: 0, assistingPersonnelName: "", assistingPersonnelPosition: "" }]);
    const [verbalReportGivenToName, setVerbalReportGivenToName] = useState(transferOfCare?.verbalReportGivenToName || "");
    const [verbalReportGivenToPosition, setVerbalReportGivenToPosition] = useState(transferOfCare?.verbalReportGivenToPosition || "--");
    const [verbalReportGivenToSignature, setVerbalReportGivenToSignature] = useState(transferOfCare?.verbalReportGivenToSignature || "");
    const [PCRAccessGivenTo, setPCRAccessGivenTo] = useState(transferOfCare?.PCRAccessGivenTo || "Select");
    const [empReferral, setEmpReferral] = useState(transferOfCare?.EMPReferral || false);
    const [reviewFlag, setReviewFlag] = useState(transferOfCare?.ReviewFlag || false);
    const [verbalReportOtherSpecify, setVerbalReportOtherSpecify] = useState(transferOfCare?.verbalReportOtherSpecify || "");

    const patientBelongingsSelected = useRef(null);

    useEffect(() => {
        setProperHandHygiene(transferOfCare?.wasProperHandHygieneCompleted || "");
        setArePatientBelongingsPresent(transferOfCare?.arePatientBelongingsPresent || "");
        setPatientBelongingsSearch("");
        setPatientBelongingsGivenTo(transferOfCare?.patientBelongingsGivenTo || "");
        setOtherSpecify(transferOfCare?.otherSpecify || "");
        setNumberOfPatients(transferOfCare?.numberOfPatients || "");
        setPatientOffloadDelay(transferOfCare?.patientOffloadDelay || "");
        setAttendingPractitionerId(transferOfCare?.attendingPractitionerId || "");
        setAttendingPractitionerStation(transferOfCare?.attendingPractitionerStation || "");
        setSupportingPractitionerId(transferOfCare?.supportingPractitionerId || "");
        setSupportingParamedicId(transferOfCare?.supportingParamedicId || "");
        setAssistingPersonnelItems(transferOfCare?.assistingPersonnel || [{ id: 0, assistingPersonnelName: "", assistingPersonnelPosition: "" }]);
        setVerbalReportGivenToName(transferOfCare?.verbalReportGivenToName || "");
        setVerbalReportGivenToPosition(transferOfCare?.verbalReportGivenToPosition || "");
        setVerbalReportGivenToSignature(transferOfCare?.verbalReportGivenToSignature || "");
        setPCRAccessGivenTo(transferOfCare?.PCRAccessGivenTo || "");
        setEmpReferral(transferOfCare?.EMPReferral || false);
        setReviewFlag(transferOfCare?.ReviewFlag || false);
        setVerbalReportOtherSpecify(transferOfCare?.verbalReportOtherSpecify || "");
    }, [transferOfCare]);

    const updatePatientBelongings = (selection) => {
        patientBelongingsSelected.current = selection;
        updatePCRContext({ patientBelongings: selection });
    };

    const updatePCRContext = (inputObj) => {
        const field = formValidationConfig.find(
            (f) => f.name === Object.keys(inputObj)[0]
        );
        let errorState = {};
        Object.entries(inputObj).forEach(([key, value]) => {
            field?.validations.forEach((type) => {
                if (field?.shouldDisplayError && !value) {
                    switch (type) {
                        case VALIDATION_TYPES.VALUE_REQUIRED:
                            errorState[`${field.name}Error`] = t("inputIsRequiredError");
                            break;

                        case VALIDATION_TYPES.SELECTION_REQUIRED:
                            errorState[`${field.name}Error`] = t("inputSelectionRequiredError");
                            break;

                        default:
                            break;
                    }
                }
                if (field?.shouldDisplayError && value) {
                    errorState[`${field.name}Error`] = "";
                }
            });
        });

        setPCRData({
            ...pcrData,
            transferOfCare: { ...transferOfCare, ...inputObj },
            ...errorState,
        });
    };

    const handleValidate = () => {
        let canToggle = true;
        let errorState = {};

        // Check if numberOfPatients is greater than 0
        if (parseInt(numberOfPatients, 10) <= 0 || isNaN(parseInt(numberOfPatients, 10))) {
            errorState.numberOfPatientsError = t("Number of patients must be greater than 0");
            canToggle = false;
        } else {
            errorState.numberOfPatientsError = "";
        }

        const transferOfCareData = {
            wasProperHandHygieneCompleted: properHandHygiene,
            arePatientBelongingsPresent: arePatientBelongingsPresent,
            patientBelongings: patientBelongingsSelected.current,
            patientBelongingsGivenTo: patientBelongingsGivenTo,
            otherSpecify: otherSpecify,
            numberOfPatients: numberOfPatients,
            patientOffloadDelay: patientOffloadDelay,
            attendingPractitionerId: attendingPractitionerId,
            attendingPractitionerStation: attendingPractitionerStation,
            supportingPractitionerId: supportingPractitionerId,
            supportingParamedicId: supportingParamedicId,
            assistingPersonnel: assistingPersonnelItems,
            verbalReportGivenToName: verbalReportGivenToName,
            verbalReportGivenToPosition: verbalReportGivenToPosition,
            verbalReportGivenToSignature: verbalReportGivenToSignature,
            PCRAccessGivenTo: PCRAccessGivenTo,
            EMPReferral: empReferral,
            ReviewFlag: reviewFlag,
        };

        Object.entries(transferOfCareData).forEach(([key, value]) => {
            const field = formValidationConfig.find((f) => f.name === key);

            if (field?.shouldDisplayError && !value && !errorState[`${field.name}Error`]) {
                switch (field.validations[0]) {
                    case VALIDATION_TYPES.VALUE_REQUIRED:
                        errorState[`${field.name}Error`] = t("inputIsRequiredError");
                        break;

                    case VALIDATION_TYPES.SELECTION_REQUIRED:
                        errorState[`${field.name}Error`] = t("inputSelectionRequiredError");
                        break;

                    default:
                        break;
                }
                canToggle = false;
            }
        });

        setPCRData({
            ...pcrData,
            ...errorState,
        });

        if (canToggle) handleCancelConfirmation(id);
    };

    const handlePersonnelItemChange = (index, updatedItem) => {
        const newItems = [...assistingPersonnelItems];
        newItems[index] = { ...newItems[index], ...updatedItem };
        setAssistingPersonnelItems(newItems);
        updatePCRContext({ assistingPersonnel: newItems });
    };

    const addPersonnelItem = () => {
        const newId = assistingPersonnelItems.length > 0
            ? assistingPersonnelItems[assistingPersonnelItems.length - 1].id + 1
            : 0;
        const newItems = [
            ...assistingPersonnelItems,
            { id: newId, assistingPersonnelName: "", assistingPersonnelPosition: "" }
        ];
        setAssistingPersonnelItems(newItems);
        updatePCRContext({ assistingPersonnel: newItems });
    };

    const removePersonnelItem = (index) => {
        if (assistingPersonnelItems.length > 1) {
            const newItems = assistingPersonnelItems.filter((_, i) => i !== index);
            setAssistingPersonnelItems(newItems);
            updatePCRContext({ assistingPersonnel: newItems });
        }
    };

    return (
        <>
            <div className="df border-bottom-styles">
                <span className="details-icon call-details-icon">
                    <Icon
                        assistiveText={{ label: t("transferofCareDetails") }}
                        category="utility"
                        name="call"
                        size="x-small"
                    />
                </span>
                <span className="section-header">{t("transferofCareDetails")}</span>
            </div>

            <div className="m-t-b">
                <div className="df h-4half-r">
                    {/* Proper Hand Hygiene RadioGroup */}
                    <div className="w-30 radio-alignment codeScene">
                        <RadioGroup
                            labels={{ label: t("properHandHygiene") }}
                            required={pcrData?.identification?.noPatientsAssessedReason !== "No patient found"}
                            name="properHandHygiene-radio-group"
                            className="disp-align-end"
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                setProperHandHygiene(selectedValue);
                                updatePCRContext({ wasProperHandHygieneCompleted: selectedValue });
                            }}
                        >
                            {yesOrNoRadioOptions.map((ele) => (
                                <Radio
                                    key={ele.value}
                                    id={`properHandHygiene-radio-${ele.value}`}
                                    labels={{ label: ele.label }}
                                    value={ele.value}
                                    checked={properHandHygiene === ele.value}
                                    variant="base"
                                />
                            ))}
                        </RadioGroup>
                    </div>

                    {/* Patient Belongings RadioGroup */}
                    <div className="w-20 radio-alignment codeScene">
                        <RadioGroup
                            labels={{ label: t("patientBelongingsPresent") }}
                            name="patientBelongings-radio-group"
                            className="disp-align-end"
                            onChange={(e) => {
                                setArePatientBelongingsPresent(e.target.value);
                                updatePCRContext({ arePatientBelongingsPresent: e.target.value });
                            }}
                        >
                            {yesOrNoRadioOptions.map((ele) => (
                                <Radio
                                    key={ele.value}
                                    id={`patientBelongings-${ele.value}`}
                                    labels={{ label: ele.label }}
                                    value={ele.value}
                                    checked={arePatientBelongingsPresent === ele.value}
                                    variant="base"
                                />
                            ))}
                        </RadioGroup>
                    </div>

                    {arePatientBelongingsPresent === "Yes" && (
                        <>
                            {/* Patient Belongings PCRPredefinedList */}
                            <div className="w-30">
                                <PCRPredefinedList
                                    id="patient-belongings-list"
                                    label={t("patientBelongings")}
                                    options={belongingsOptions}
                                    value={patientBelongingsSearch}
                                    onChange={setPatientBelongingsSearch}
                                    onSelect={updatePatientBelongings}
                                    errorText={
                                        pcrData?.patientBelongingsError && (
                                            <div className="slds-form-element__help selectionError df">
                                                {pcrData?.patientBelongingsError && <>{pcrData?.patientBelongingsError}</>}
                                            </div>
                                        )
                                    }
                                />
                            </div>

                            {/* Patient Belongings Given To Combobox */}
                            <div className="w-30">
                                <PCRComboBox
                                    id="patient-belongings-given-to-combo-box"
                                    label={t("patientBelongingsGivenTo")}
                                    placeholder="Select"
                                    options={belongingsOptionsGivenTo}
                                    value={patientBelongingsGivenTo}
                                    onChange={(val) => setPatientBelongingsGivenTo(val)}
                                    onSelect={(val) => {
                                        const selectedValue = val.patientBelongingsGivenTo || Object.values(val)[0];
                                        setPatientBelongingsGivenTo(selectedValue);
                                        updatePCRContext({ patientBelongingsGivenTo: selectedValue });
                                    }}
                                    selection={patientBelongingsGivenTo ? [{ id: patientBelongingsGivenTo, label: patientBelongingsGivenTo }] : []}
                                    name="patientBelongingsGivenTo"
                                />

                                {patientBelongingsGivenTo === "Other" && (
                                    <Input
                                        id="other-specify"
                                        placeholder={t("PleaseSpecify")}
                                        value={otherSpecify}
                                        required
                                        onChange={(e) => {
                                            setOtherSpecify(e.target.value);
                                            updatePCRContext({ otherSpecify: e.target.value });
                                        }}
                                        errorText={pcrData?.otherSpecifyError}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="m-t-b">
                <div className="df h-4half-r">
                    {/* # of patients transported Input Field */}
                    <div className="w-18 patients-assessed margin-right">
                        <span className="transferOfCare_span">
                            {" "}
                            {t("noOfPatientsTransported")}
                        </span>{" "}
                        <Popover
                            body={t("noOfPatientsTransportedInfo")}
                            id="settings-popover"
                            align="bottom right"
                        >
                            <Button
                                assistiveText={{ icon: "info" }}
                                iconCategory="utility"
                                iconName="info"
                                iconSize="medium"
                                variant="icon"
                            />
                        </Popover>
                        <Input
                            assistiveText={{ label: "" }}
                            id="number-of-patients"
                            label={""}
                            type="number"
                            value={numberOfPatients}
                            onChange={(e) => {
                                setNumberOfPatients(e.target.value);
                                updatePCRContext({ numberOfPatients: e.target.value });
                            }}
                            errorText={pcrData?.numberOfPatientsError}
                        />
                    </div>

                    {/* Patient Offload Delay RadioGroup */}
                    <div className="w-35 radio-alignment codeScene">
                        <RadioGroup
                            labels={{ label: t("patientOffloadDelay") }}
                            name="patientOffloadDelay-radio-group"
                            className="disp-align-end"
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                setPatientOffloadDelay(selectedValue);
                                updatePCRContext({ patientOffloadDelay: selectedValue });
                            }}
                        >
                            {yesOrNoRadioOptions.map((ele) => (
                                <Radio
                                    key={ele.value}
                                    id={`patientOffloadDelay-radio-${ele.value}`}
                                    labels={{ label: ele.label }}
                                    value={ele.value}
                                    checked={patientOffloadDelay === ele.value}
                                    variant="base"
                                />
                            ))}
                        </RadioGroup>
                    </div>
                </div>
            </div>

            <div className="m-t-b">
                <div className="df h-4half-r">
                    {/* Attending Practitioner Id Non-Editable Field */}
                    <div className="df margin-right">
                        <Input
                            id="attending-practitioner-id"
                            label={t("attendingPractitionerId")}
                            value={attendingPractitionerId}
                            required
                            disabled
                        />
                    </div>

                    {/* Attending Practitioner Primary Station Dropdown */}
                    <div className="df m-t-3">
                        <PCRComboBox
                            id="attending-practitioner-primary-station"
                            label={t("attendingPractitionerStation")}
                            options={attendingPractitionerPrimaryStations}
                            value={attendingPractitionerStation}
                            placeholder="Select"
                            onChange={(val) => updatePCRContext({ attendingPractitionerStation: val })}
                            onSelect={(val) => {
                                const selectedValue = val.attendingPractitionerStation || Object.values(val)[0];
                                updatePCRContext({ attendingPractitionerStation: selectedValue });
                            }}
                            selection={transferOfCare?.attendingPractitionerStation ? [{ id: transferOfCare.attendingPractitionerStation, label: transferOfCare.attendingPractitionerStation }] : []}
                            name="attendingPractitionerStation"
                            required
                        />
                    </div>
                </div>
            </div>

            {/* Supporting Practitioner Id Non-Editable Field */}
            <div className="m-t-b">
                <div className="df h-4half-r">
                    <div className="df">
                        <Input
                            id="supporting-practitioner-id"
                            label={t("supportingPractitionerId")}
                            value={supportingPractitionerId}
                            required
                            disabled
                        />
                    </div>
                </div>
            </div>

            {/* Supporting Paramedic Id Non-Editable Field */}
            <div className="m-t-b">
                <div className="df h-4half-r">
                    <div className="df">
                        <Input
                            id="supporting-paramedic-id"
                            label={t("supportingParamedicId")}
                            value={supportingParamedicId}
                            required
                            disabled
                        />
                    </div>
                </div>
            </div>

            {/* Assisting Personnel Section */}
            <div className="assisting-personnel-container">
                <h3>{t("assistingPersonnelLabel")}</h3>
                {assistingPersonnelItems.map((item, index) => (
                    <div className="slds-grid slds-gutters">
                        <div className="slds-col slds-size_2-of-3">
                            <div key={item.id} className="assisting-personnel-row">
                                <TOCAssistingPersonnelItem
                                    key={item.id}
                                    index={index}
                                    item={item}
                                    onChange={handlePersonnelItemChange}
                                    onRemove={removePersonnelItem}
                                    onAdd={addPersonnelItem}
                                    isLast={index === assistingPersonnelItems.length - 1}
                                    disableRemove={assistingPersonnelItems.length === 1}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Verbal Report Given To Section */}
            <div className="m-t-b">
                <div className="w-18 patients-assessed margin-right">
                    <span className="transferOfCare_span">
                        {t("verbalReportGivenTo")}
                    </span>
                    <Popover
                        body={t("verbalReportGivenToInfo")}
                        id="settings-popover"
                        align="bottom right"
                    >
                        <Button
                            assistiveText={{ icon: "info" }}
                            iconCategory="utility"
                            iconName="info"
                            iconSize="medium"
                            variant="icon"
                        />
                    </Popover>
                </div>
                <div className="slds-grid slds-gutters">
                    <div className="slds-col slds-size_2-of-3">
                        <div className="slds-grid slds-gutters">
                            <div className="slds-col slds-size_1-of-3">
                                <Input
                                    id="verbal-report-name"
                                    label={t("verbalReportGivenToName")}
                                    value={verbalReportGivenToName}
                                    onChange={(e) => setVerbalReportGivenToName(e.target.value)}
                                />
                            </div>
                            <div className="slds-col slds-size_1-of-3">
                                <PCRComboBox
                                    id="verbal-report-position"
                                    label={t("verbalReportGivenToPosition")}
                                    options={verbalReportPositions}
                                    value={verbalReportGivenToPosition}
                                    placeholder="--"
                                    onChange={(val) => setVerbalReportGivenToPosition(val)}
                                    onSelect={(val) => {
                                        const selectedValue = val.verbalReportGivenToPosition || Object.values(val)[0];
                                        setVerbalReportGivenToPosition(selectedValue);
                                        if (selectedValue !== "Other") {
                                            setVerbalReportOtherSpecify("");
                                        }
                                    }}
                                    selection={verbalReportGivenToPosition ? [{ id: verbalReportGivenToPosition, label: verbalReportGivenToPosition }] : []}
                                    name="verbalReportGivenToPosition"
                                />
                            </div>
                            {verbalReportGivenToPosition === "Other" && (
                                <div className="slds-col slds-size_1-of-3">
                                    <Input
                                        id="verbal-report-other-specify"
                                        label=" "
                                        placeholder={t("PleaseSpecify")}
                                        value={verbalReportOtherSpecify}
                                        onChange={(e) => {
                                            setVerbalReportOtherSpecify(e.target.value);
                                            updatePCRContext({ verbalReportOtherSpecify: e.target.value });
                                        }}
                                    />
                                </div>
                            )}
                            <div className="slds-col slds-size_1-of-3">
                                <Input
                                    id="verbal-report-signature"
                                    label={t("verbalReportGivenToSignature")}
                                    value={verbalReportGivenToSignature}
                                    onChange={(e) => setVerbalReportGivenToSignature(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* PCR Access Given To Dropdown */}
            <div className="slds-grid slds-gutters">
                <div className="slds-col slds-size_1-of-4">
                    <PCRComboBox
                        id="access-given-to"
                        label={t("PCRAccessGivenTo")}
                        options={accessGivenTo}
                        value={PCRAccessGivenTo}
                        placeholder="Select"
                        onChange={(val) => updatePCRContext({ PCRAccessGivenTo: val })}
                        onSelect={(val) => {
                            const selectedValue = val.PCRAccessGivenTo || Object.values(val)[0];
                            updatePCRContext({ PCRAccessGivenTo: selectedValue });
                        }}
                        selection={transferOfCare?.PCRAccessGivenTo ? [{ id: transferOfCare.PCRAccessGivenTo, label: transferOfCare.PCRAccessGivenTo }] : []}
                        name="PCRAccessGivenTo"
                        required={true}
                    />
                </div>
            </div>

            {/* EMP Referral Checkbox */}
            <div className="slds-m-top_medium transferOfCare_span">
                <Checkbox
                    id="emp-referral"
                    labels={{ label: t("EMPReferral") }}
                    checked={empReferral}
                    onChange={(e) => {
                        setEmpReferral(e.target.checked);
                        updatePCRContext({ EMPReferral: e.target.checked });
                    }}
                />
            </div>

            {/* Review Flag Checkbox */}
            <div className="slds-m-top_medium">
                <div className="df align-center compact-align">
                    <Checkbox
                        id="review-flag"
                        labels={{ label: t("ReviewFlag") }}
                        checked={reviewFlag}
                        onChange={(e) => {
                            setReviewFlag(e.target.checked);
                            updatePCRContext({ ReviewFlag: e.target.checked });
                        }}
                    />
                    <Popover
                        body={t("flagForReviewInfo")}
                        id="review-flag-popover"
                        align="bottom right"
                        className="info-popover"
                    >
                        <Button
                            assistiveText={{ icon: "info" }}
                            iconCategory="utility"
                            iconName="info"
                            iconSize="small"
                            variant="icon"
                        />
                    </Popover>
                </div>
            </div>

            {/* Validate and Submit buttons */}
            <div className="df transfer-validate-btn-assesment">
                <Button variant="brand" onClick={handleValidate}>
                    {t("validate")}
                </Button>
                <Button variant="brand">
                    {t("submitPCR")}
                </Button>
            </div>
        </>
    );
};