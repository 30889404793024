import React from "react";
import { Outlet } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import { IconSettings } from "@salesforce/design-system-react";
import utilitySprite from "@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg";
import Header from "../components/Header";
import UserTabs from "../components/UserTabs";
import Landing from "../pages/Landing";
import "../App.css";

const PageLayout = () => {
  const isTokenAvailable = !!localStorage.getItem("sf_access_token");
  const location = useLocation();

  return (
    <div>
      <IconSettings utilitySprite={utilitySprite} iconPath="/icons">
        <Header />
        <div className="outlet-container">
          {isTokenAvailable && location?.pathname !== "/az-callback" ? (
            <UserTabs />
          ) : !isTokenAvailable && location?.pathname !== "/az-callback" ? (
            <Landing />
          ) : (
            <Outlet />
          )}
        </div>
      </IconSettings>
    </div>
  );
};

export default PageLayout;
