import React, { createContext, useReducer, useContext } from 'react';


const initialState = {
    users: [
      {
        firstName: '',
        lastName: '',
        fullName: '',
        employeeId: '',
        medicId: '',
        federationId: '',
        adAccessToken: '',
        sf_access_token: '',
        adRefreshToken: '',
        sfRefreshToken: '',
        adLoginHint: ''
      }
    ]
  };

// Create a Context
const UserContext = createContext({ state: initialState, dispatch: () => {} });

function userReducer(state, action) {
  switch (action.type) {
    case 'ADD_USER':
      return { ...state, users: [...state.users, action.payload] };
    case 'REMOVE_USER':
      return { ...state, users: state.users.filter(user => user.federationId !== action.payload.federationId) };
    case 'UPDATE_USER_USING_FEDERATION_ID':
      return { ...state, users: state.users.map(user => user.federationId === action.payload.federationId ? action.payload : user) };
    case 'UPDATE_USER_USING_MEDIC_ID':
      return { ...state, users: state.users.map(user => user.medicId === action.payload.medicId ? action.payload : user) };
    default:
      return state;
  }
}


export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUsers = () => useContext(UserContext);