import { FORM_TYPES, VALIDATION_TYPES } from "../data/constants.js";

export const useAssesmentValidations = () => {
  function getAssesmentValidations(pcrData) {
    let validationSchema = [
      {
        name: "wasActiveOfferMade",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.SELECTION_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          pcrData?.identification?.noPatientsAssessedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "cheifComplaint",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: true,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "protocolPrimary",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: true,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "protocolSecondary",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "allergies",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          pcrData?.assesment?.protocolPrimary?.includes("1020") ||
          pcrData?.assesment?.protocolPrimary?.includes("1025"),
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "patientMedication",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "patientMedicalHistory",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "notesAndBackgroundInfo",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: true,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "assesmentInitials",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: pcrData?.assesment?.notesAndBackgroundInfo?.length,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "assesmentId",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: pcrData?.assesment?.notesAndBackgroundInfo?.length,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "vitalsTime",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: pcrData?.assesment?.protocolPrimary ||
        pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT",
        softValidateAttending: false,
        softValidateSV: false,
      },      
      {
        name: "respiration",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          (!pcrData?.assesment?.protocolPrimary?.includes("9992") &&
            !pcrData?.assesment?.protocolPrimary?.includes("9993") &&
            pcrData?.assesment?.protocolPrimary) ||
          pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT",
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "o2Sat",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
        (!pcrData?.assesment?.protocolPrimary?.includes("9992") &&
        !pcrData?.assesment?.protocolPrimary?.includes("9993") &&
        pcrData?.assesment?.protocolPrimary) ||
      pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT",
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "etco2",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          pcrData?.assesment?.protocolPrimary &&
          ["1030", "1075", "1080"].some(val => pcrData?.assesment?.protocolPrimary.includes(val)),
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "hr",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
        (!pcrData?.assesment?.protocolPrimary?.includes("9992") &&
        !pcrData?.assesment?.protocolPrimary?.includes("9993") &&
        pcrData?.assesment?.protocolPrimary) ||
      pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT",
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "bp",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
        (!pcrData?.assesment?.protocolPrimary?.includes("9992") &&
        !pcrData?.assesment?.protocolPrimary?.includes("9993") &&
        pcrData?.assesment?.protocolPrimary) ||
      pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT",
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "gcsTotal",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
        (!pcrData?.assesment?.protocolPrimary?.includes("9992") &&
        !pcrData?.assesment?.protocolPrimary?.includes("9993") &&
        pcrData?.assesment?.protocolPrimary) ||
      pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT",
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "temperatureC",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError://1120, 1125, 1230, 1235, 1255, 1260, 1270, 1280, 1425,1050, 1081, 1220,1030 
          (pcrData?.assesment?.protocolPrimary &&
            [
              "1030",
              "1081",
              "1220",
              "1050",
              "1120",
              "1125",
              "1230",
              "1235",
              "1255",
              "1260",
              "1270",
              "1280",
              "1425",
            ].some(value => pcrData?.assesment?.protocolPrimary.includes(value)) ||
          pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT"),
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "pain",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          (pcrData?.assesment?.protocolPrimary &&
            [
              "1055",
              "1060",
              "1065",
              "1140",
              "1165",
              "1355",
              "1360",
              "1365",
              "1370",
              "1375",
              "1380",
              "1400",
            ].some(value => pcrData?.assesment?.protocolPrimary.includes(value)) ||
          pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT"),
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "glucose",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          (pcrData?.assesment?.protocolPrimary &&
            [
              "1070",
              "1035",
              "1040",
              "1045",
              "1170",
              "1175",
              "1180",
              "1200",
              "1205",
              "1050",
              "1081",
              "1220",
            ].some(value => pcrData?.assesment?.protocolPrimary.includes(value))||
          pcrData?.identification?.noPatientsAssessedReason === "Pre-ALRT"),
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "lPupil",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          pcrData?.assesment?.protocolPrimary &&
          [
            "1015",
            "1070",
            "1035",
            "1040",
            "1045",
            "1170",
            "1175",
            "1180",
            "1200",
            "1205",
            "1050",
            "1081",
            "1220",
          ].some(value => pcrData?.assesment?.protocolPrimary.includes(value)),
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "rPupil",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          pcrData?.assesment?.protocolPrimary &&
          [
            "1015",
            "1070",
            "1035",
            "1040",
            "1045",
            "1170",
            "1175",
            "1180",
            "1200",
            "1205",
            "1050",
            "1081",
            "1220",
          ].some(value => pcrData?.assesment?.protocolPrimary.includes(value)),
        softValidateAttending: false,
        softValidateSV: false,
      },
    ];
    return validationSchema;
  }

  return {
    getAssesmentValidations,
  };
};
