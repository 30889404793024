import React from 'react';
import '../css/PCRVitalsTooltipTable.css';

const data = [
  { age: "Newborn / nouveau-né", pulse: "120-160 / min", respirations: "40-60 / min", bp: "80/40 mmHg" },
  { age: "1 year / an", pulse: "80-140 / min", respirations: "30-40 / min", bp: "82/44 mmHg" },
  { age: "3 years / ans", pulse: "80-120 / min", respirations: "25-30 / min", bp: "90/50 mmHg" },
  { age: "5 years / ans", pulse: "70-115 / min", respirations: "20-25 / min", bp: "90/52 mmHg" },
  { age: "7 years / ans", pulse: "70-110 / min", respirations: "18-24 / min", bp: "95/58 mmHg" },
  { age: "10 years / ans", pulse: "70-115 / min", respirations: "15-20 / min", bp: "100/60 mmHg" },
  { age: "15 years / ans", pulse: "60-100 / min", respirations: "15-20 / min", bp: "110/64 mmHg" },
];

export const PCRVitalsTooltipTable = () => {
  return (
    <table className="vitals-table">
      <thead>
        <tr className="header-row">
          <th colSpan="4" className="header-cell">
            Vital Signs by Age Group/<br />Signes vitaux par groupe d'âge
          </th>
        </tr>
        <tr className="header-row">
          <th className="header-cell">Age / Âge</th>
          <th className="header-cell">Pulse / Pouls</th>
          <th className="header-cell">Respirations</th>
          <th className="header-cell">Blood Pressure /<br />Tension artérielle</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td className="body-cell">{row.age}</td>
            <td className="body-cell">{row.pulse}</td>
            <td className="body-cell">{row.respirations}</td>
            <td className="body-cell">{row.bp}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};