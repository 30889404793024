import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'css/PCRMedications.css';
import MedicationItem from 'components/PCR/PCR-AV/Treatment/Medications/PCRMedicationItem';
import { PCRContext, evaluateError } from 'context/PCRContext.js';
import { medicationItem } from 'context/PCRContext';
import { db } from 'storage/idb.js';
import PCRMedicationsTooltipTable from 'components/PCR/PCR-AV/Treatment/Medications/PCRMedicationsTooltipTable';

const PCRMedications = ({ pcr }) => {
  const { t } = useTranslation();
  const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
  const { assessment } = pcrData || {}; // Ensure pcrData is defined
  const patientMedications = assessment?.patientMedications || []; // Ensure assessment and medications are defined
  // console.log('patientMedications >>>', patientMedications);

  // if patientMedications are not empty, use them, otherwise use an empty medicationItem with id 0
  const [medicationItems, setMedicationItems] = useState(patientMedications.length > 0 ? patientMedications : [{ ...medicationItem, id: 0 }]);


  const updatePCRContext = async (updatedAttrObj) => {
    const field = formValidationConfig.find(
      (f) => f.name === Object.keys(updatedAttrObj)[0]
    );

    const error = evaluateError(
      pcrData,
      { assessment: { patientMedications: updatedAttrObj } },
      field,
      t
    );

    setPCRData({
      ...pcrData,
      assessment: { ...assessment, patientMedications: { ...patientMedications, ...updatedAttrObj } },
      ...error,
    });

    const records = await db.pcrs.where({ id: pcr?.pcrId }).toArray();
    let record = records[0];
    const timestamp = new Date().toISOString();
    let json = JSON.parse(record.json);
    // patientMedications array is in the assessment object so pcr.assessment.patientMedications
    json = JSON.stringify({
      ...json,
      assessment: { ...assessment, patientMedications: { ...patientMedications, ...updatedAttrObj } },
    });
    await db.pcrs.update(pcr?.pcrId, {
      json,
      timestamp,
      status: 'In Progress',
    });
  };

  const addMedicationItem = () => {
    const newId = medicationItems.length > 0 ? medicationItems[medicationItems.length - 1].id + 1 : 0;
    setMedicationItems([...medicationItems, { id: newId }]);
  };

  const removeMedicationItem = (index) => {
    if (window.confirm('Warning: Row Deletion\n\nYou are about to remove a row. Are you sure you want to proceed?')) {
      if (medicationItems.length === 1) {
        // If there's only one row, replace it with a new empty row
        setMedicationItems([{ id: medicationItems[0].id + 1 }]);
      } else {
        // Remove the row
        const newMedicationItems = medicationItems.filter((_, i) => i !== index);
        setMedicationItems(newMedicationItems);
      }
    }
  };

  const handleMedicationItemChange = (index, newValues) => {
    const newMedicationItems = [...medicationItems];
    newMedicationItems[index] = { ...newMedicationItems[index], ...newValues };
    setMedicationItems(newMedicationItems);
  };



  return (
    <div className='slds-card'>

      <div className='slds-card__header'>
        <h2 className='slds-card__header-title medication-signs-header'>
          {t('medications')}
        </h2>
      </div>

      <div className='slds-card__body'>
        {medicationItems.map((item, index) => (
          <div key={item.id}>
            <MedicationItem
              key={item.id}
              index={index}
              onRemove={removeMedicationItem}
              onChange={handleMedicationItemChange}
              onAdd={addMedicationItem}
              isLast={index === medicationItems.length - 1}
              medicationFromParent={item}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PCRMedications;