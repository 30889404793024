import React, { useContext, useEffect } from "react";
import { Accordion, AccordionPanel } from "@salesforce/design-system-react";
import PCRIdentification from "./PCRIdentification";
import { useTranslation } from "react-i18next";
import { PCRContext } from "../context/PCRContext";
import { PCRAssessment } from "./PCR/PCR-AV/Assesment/PCRAssessment";
import { PCRTreatment } from "./PCR/PCR-AV/Treatment/PCRTreatment";
import { PCRTransferOfCare } from './PCR/PCR-AV/Transfer/PCRTransferOfCare';

export const PCRAccordionList = ({ pcr }) => {
  const { setCurrentPcrTab } = useContext(PCRContext);
  const [expanded, setExpanded] = React.useState({});
  const { t } = useTranslation();

  const togglePanel = (data) => {
    setCurrentPcrTab(data.summary);
    setExpanded({
      [data.id]: !expanded[data.id],
    });
  };

  const handleCancelConfirmation = (id) => {
    setExpanded({
      [id]: false,
      [id + 1]: true,
    });
  };

  const itemsList = {
    items: [
      {
        id: 1,
        summary: t("identification"),
        details: (
          <PCRIdentification
            pcr={pcr}
            id={1}
            handleCancelConfirmation={handleCancelConfirmation}
          />
        ),
      },
      {
        id: 2,
        summary: t("assessment"),
        details: <PCRAssessment pcr={pcr} id={2}
          handleCancelConfirmation={handleCancelConfirmation}
        />,
      },
      {
        id: 3,
        summary: t("treatment"),
        details: <PCRTreatment pcr={pcr} id={3}
          handleCancelConfirmation={handleCancelConfirmation}
        />,
      },
      {
        id: 4,
        summary: t("transferOfCare"),
        details: <PCRTransferOfCare pcr={pcr} id={4}
          handleCancelConfirmation={handleCancelConfirmation}
        />,
      },
    ],
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <Accordion id="base-example-accordion">
        {itemsList?.items?.map((ele, index) => {
          return (
            <AccordionPanel
              expanded={!!expanded[ele.id]}
              id={ele.id}
              key={ele.id}
              onTogglePanel={() => togglePanel(ele)}
              summary={ele.summary}
            >
              {ele.details}
            </AccordionPanel>
          );
        })}
      </Accordion>
    </div>
  );
};