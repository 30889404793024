import React from 'react';
import 'css/PCRMedicationsTooltipTable.css';

const PCRMedicationsTooltipTable = () => {
  return (
    < table className="medications-table" >
      <thead>
        <tr>
          <th className="red">Age</th>
          <th className="red">Dose</th>
          <th className="red" colSpan="8">Under 1</th>
          <th className="red" colSpan="2">1</th>
          <th className="red" colSpan="2">2</th>
          <th className="red" colSpan="2">3</th>
          <th className="red" colSpan="2">4</th>
          <th className="red" colSpan="3">5</th>
          <th className="red">6</th>
          <th className="red">7</th>
          <th className="red">8</th>
          <th className="red">9</th>
          <th className="red">10</th>
          <th className="red">11</th>
          <th className="red">Adult</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td className="lightred">Broselow Color</td>
          <td className="darkgrey"></td>
          <td className="grey" colSpan="3">Grey</td>
          <td className="pink" colSpan="2">Pink</td>
          <td className="black"></td>
          <td className="red" colSpan="2">Red</td>
          <td className="purple" colSpan="2">Purple</td>
          <td className="yellow" colSpan="3">Yellow</td>
          <td className="white" colSpan="4">White</td>
          <td className="black"></td>
          <td className="blue" colSpan="3">Blue</td>
          <td className="orange" colSpan="3">Orange</td>
          <td className="green">Green</td>
          <td className="black">N/A</td>
        </tr>
        <tr>
          <td className="lightred">Estimated Weight (kg)</td>
          <td className="darkgrey"></td>
          <td className="grey">3 kg</td>
          <td className="grey">4 kg</td>
          <td className="grey">5 kg</td>
          <td className="pink">6 kg</td>
          <td className="pink">7 kg</td>
          <td className="black"></td>
          <td className="red">8 kg</td>
          <td className="red">9 kg</td>
          <td className="purple">10 kg</td>
          <td className="purple">11 kg</td>
          <td className="yellow">12 kg</td>
          <td className="yellow">13 kg</td>
          <td className="yellow">14 kg</td>
          <td className="white">15 kg</td>
          <td className="white">16 kg</td>
          <td className="white">17 kg</td>
          <td className="white">18 kg</td>
          <td className="black"></td>
          <td className="blue">19 kg</td>
          <td className="blue">20 kg</td>
          <td className="blue">22 kg</td>
          <td className="orange">24 kg</td>
          <td className="orange">26 kg</td>
          <td className="orange">28 kg</td>
          <td className="green">30 kg</td>
          <td className="black">N/A</td>
        </tr>
        <tr>
          <td className="lightred">1st Shock (J)</td>
          <td className="lightred">2 j/kg</td>
          <td className="grey">6 j</td>
          <td className="grey">8 j</td>
          <td className="grey">10 j</td>
          <td className="pink">10 j</td>
          <td className="pink">15 j</td>
          <td className="black"></td>
          <td className="red">15 j</td>
          <td className="red">20 j</td>
          <td className="purple">20 j</td>
          <td className="purple">20 j</td>
          <td className="yellow">20 j</td>
          <td className="yellow">30 j</td>
          <td className="yellow">30 j</td>
          <td className="white">30 j</td>
          <td className="white">30 j</td>
          <td className="white">30 j</td>
          <td className="white">30 j</td>
          <td className="black"></td>
          <td className="blue">30 j</td>
          <td className="blue">50 j</td>
          <td className="blue">50 j</td>
          <td className="orange">150 j</td>
          <td className="orange">150 j</td>
          <td className="orange">150 j</td>
          <td className="green">150 j</td>
          <td className="black">150 j</td>
        </tr>
        <tr>
          <td className="lightred">Subsequent Shocks (J)</td>
          <td className="lightred">4 j/kg</td>
          <td className="grey">12 j</td>
          <td className="grey">15 j</td>
          <td className="grey">20 j</td>
          <td className="pink">20 j</td>
          <td className="pink">30 j</td>
          <td className="black"></td>
          <td className="red">30 j</td>
          <td className="red">30 j</td>
          <td className="purple">50 j</td>
          <td className="purple">50 j</td>
          <td className="yellow">50 j</td>
          <td className="yellow">50 j</td>
          <td className="yellow">50 j</td>
          <td className="white">70 j</td>
          <td className="white">70 j</td>
          <td className="white">70 j</td>
          <td className="white">70 j</td>
          <td className="black"></td>
          <td className="blue">70 j</td>
          <td className="blue">70 j</td>
          <td className="blue">100 j</td>
          <td className="orange">150 j</td>
          <td className="orange">150 j</td>
          <td className="orange">150 j</td>
          <td className="green">150 j</td>
          <td className="black">150 j</td>
        </tr>
        <tr>
          <td className="lightred">Advil (PO)</td>
          <td className="lightred">10 mg/kg, Max 600 mg</td>
          <td className="grey">0.75 mL</td>
          <td className="grey">1 mL</td>
          <td className="grey">1.25 mL</td>
          <td className="pink">1.50 mL</td>
          <td className="pink">1.75 mL</td>
          <td className="black"></td>
          <td className="red">2 mL</td>
          <td className="red">2.25 mL</td>
          <td className="purple">2.5 mL</td>
          <td className="purple">2.75 mL</td>
          <td className="yellow">3 mL</td>
          <td className="yellow">3.25 mL</td>
          <td className="yellow">3.5 mL</td>
          <td className="white">3.75 mL</td>
          <td className="white">4 mL</td>
          <td className="white">4.25 mL</td>
          <td className="white">4.5 mL</td>
          <td className="black"></td>
          <td className="blue">4.75 mL</td>
          <td className="blue">5 mL</td>
          <td className="blue">5.5 mL</td>
          <td className="orange">6 mL</td>
          <td className="orange">6.5 mL</td>
          <td className="orange">7 mL</td>
          <td className="green">7.5 mL</td>
          <td className="black">15 mL</td>
        </tr>
        <tr>
          <td className="lightred">ASA (PO)</td>
          <td className="lightred">160 mg</td>
          <td className="darkgrey" colSpan="5"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="11"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="7"></td>
          <td className="black">160 mg</td>
        </tr>
        <tr>
          <td className="lightred">Atrovent (MDI)</td>
          <td className="lightred">40-120 mcg</td>
          <td className="darkgrey" colSpan="5"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="4"></td>
          <td className="yellow">2 puffs</td>
          <td className="yellow">2 puffs</td>
          <td className="yellow">2 puffs</td>
          <td className="white">2 puffs</td>
          <td className="white">2 puffs</td>
          <td className="white">2 puffs</td>
          <td className="white">2 puffs</td>
          <td className="black"></td>
          <td className="blue">2 puffs</td>
          <td className="blue">2 puffs</td>
          <td className="blue">2 puffs</td>
          <td className="orange">2 puffs</td>
          <td className="orange">2 puffs</td>
          <td className="orange">2 puffs</td>
          <td className="green">2 puffs</td>
          <td className="black">6 puffs</td>
        </tr>
        <tr>
          <td className="lightred">Atrovent (Aerosol)</td>
          <td className="lightred">125-500 mcg</td>
          <td className="darkgrey" colSpan="5"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="4"></td>
          <td className="yellow">0.5 mL</td>
          <td className="yellow">0.5 mL</td>
          <td className="yellow">0.5 mL</td>
          <td className="white">0.5 mL</td>
          <td className="white">0.5 mL</td>
          <td className="white">0.5 mL</td>
          <td className="white">0.5 mL</td>
          <td className="black"></td>
          <td className="blue">0.5 mL</td>
          <td className="blue">0.5 mL</td>
          <td className="blue">0.5 mL</td>
          <td className="orange">1 mL</td>
          <td className="orange">1 mL</td>
          <td className="orange">1 mL</td>
          <td className="green">1 mL</td>
          <td className="black">2 mL</td>
        </tr>
        <tr>
          <td className="lightred">Benadryl (IV/IM)</td>
          <td className="lightred">2 mg/kg, Max 50 mg</td>
          <td className="grey">0.12 mL</td>
          <td className="grey">0.16 mL</td>
          <td className="grey">0.2 mL</td>
          <td className="pink">0.24 mL</td>
          <td className="pink">0.28 mL</td>
          <td className="black"></td>
          <td className="red">0.32 mL</td>
          <td className="red">0.36 mL</td>
          <td className="purple">0.4 mL</td>
          <td className="purple">0.44 mL</td>
          <td className="yellow">0.48 mL</td>
          <td className="yellow">0.52 mL</td>
          <td className="yellow">0.56 mL</td>
          <td className="white">0.6 mL</td>
          <td className="white">0.64 mL</td>
          <td className="white">0.68 mL</td>
          <td className="white">0.72 mL</td>
          <td className="black"></td>
          <td className="blue">0.76 mL</td>
          <td className="blue">0.8 mL</td>
          <td className="blue">0.88 mL</td>
          <td className="orange">0.96 mL</td>
          <td className="orange">1 mL</td>
          <td className="orange">1 mL</td>
          <td className="green">1 mL</td>
          <td className="black">1 mL</td>
        </tr>
        <tr>
          <td className="lightred">Benadryl Elixir (PO)</td>
          <td className="lightred">2 mg/kg, Max 50 mg</td>
          <td className="grey">2.4 mL</td>
          <td className="grey">3.2 mL</td>
          <td className="grey">4 mL</td>
          <td className="pink">4.8 mL</td>
          <td className="pink">5.6 mL</td>
          <td className="black"></td>
          <td className="red">6.4 mL</td>
          <td className="red">7.2 mL</td>
          <td className="purple">8 mL</td>
          <td className="purple">8.8 mL</td>
          <td className="yellow">9.6 mL</td>
          <td className="yellow">10.4 mL</td>
          <td className="yellow">11.2 mL</td>
          <td className="white">12 mL</td>
          <td className="white">12.8 mL</td>
          <td className="white">13.6 mL</td>
          <td className="white">14.4 mL</td>
          <td className="black"></td>
          <td className="blue">15.2 mL</td>
          <td className="blue">16 mL</td>
          <td className="blue">17.6 mL</td>
          <td className="orange">19.2 mL</td>
          <td className="orange">20 mL</td>
          <td className="orange">20 mL</td>
          <td className="green">20 mL</td>
          <td className="black">20 mL</td>
        </tr>
        {/* D 10 W (IV) 0.5 g/kg                   120 mL    130 mL    140 mL    150 mL    250 mL
 */}
        <tr>
          <td className="lightred">D 10 W (IV)</td>
          <td className="lightred">0.5 g/kg</td>
          <td className="darkgrey" colSpan="5"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="11"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="3"></td>
          <td className="orange">120 mL</td>
          <td className="orange">130 mL</td>
          <td className="orange">140 mL</td>
          <td className="green">150 mL</td>
          <td className="black">250 mL</td>
        </tr>

        {/* Epinephrine (IM) 0.01 mg/kg, Max 0.3-0.5 mg 0.03 mL   0.04 mL   0.05 mL   0.06 mL   0.07 mL   0.08 mL   0.09 mL   0.1 mL    0.11 mL   0.12 mL   0.13 mL   0.14 mL   0.15 mL   0.16 mL   0.17 mL   0.18 mL   0.19 mL   0.2 mL    0.22 mL   0.24 mL   0.26 mL   0.28 mL   0.3 mL    0.3-0.5 mL
 */}
        <tr>
          <td className="lightred">Epinephrine (IM)</td>
          <td className="lightred">0.01 mg/kg, Max 0.3-0.5 mg</td>
          <td className="grey">0.03 mL</td>
          <td className="grey">0.04 mL</td>
          <td className="grey">0.05 mL</td>
          <td className="pink">0.06 mL</td>
          <td className="pink">0.07 mL</td>
          <td className="black"></td>
          <td className="red">0.08 mL</td>
          <td className="red">0.09 mL</td>
          <td className="purple">0.1 mL</td>
          <td className="purple">0.11 mL</td>
          <td className="yellow">0.12 mL</td>
          <td className="yellow">0.13 mL</td>
          <td className="yellow">0.14 mL</td>
          <td className="white">0.15 mL</td>
          <td className="white">0.16 mL</td>
          <td className="white">0.17 mL</td>
          <td className="white">0.18 mL</td>
          <td className="black"></td>
          <td className="blue">0.19 mL</td>
          <td className="blue">0.2 mL</td>
          <td className="blue">0.22 mL</td>
          <td className="orange">0.24 mL</td>
          <td className="orange">0.26 mL</td>
          <td className="orange">0.28 mL</td>
          <td className="green">0.3 mL</td>
          <td className="black">0.3-0.5 mL</td>
        </tr>
        
        {/* Glucagon (IM) 0.5-1 mg/kg             0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    0.5 mL    1 mL      1 mL      1 mL      1 mL      1 mL      1 mL      1 mL
 */}
        <tr>
          <td className="lightred">Glucagon (IM)</td>
          <td className="lightred">0.5-1 mg/kg</td>
          <td className="grey">0.5 mL</td>
          <td className="grey">0.5 mL</td>
          <td className="grey">0.5 mL</td>
          <td className="pink">0.5 mL</td>
          <td className="pink">0.5 mL</td>
          <td className="black"></td>
          <td className="red">0.5 mL</td>
          <td className="red">0.5 mL</td>
          <td className="purple">0.5 mL</td>
          <td className="purple">0.5 mL</td>
          <td className="yellow">0.5 mL</td>
          <td className="yellow">0.5 mL</td>
          <td className="yellow">0.5 mL</td>
          <td className="white">0.5 mL</td>
          <td className="white">0.5 mL</td>
          <td className="white">0.5 mL</td>
          <td className="white">0.5 mL</td>
          <td className="black"></td>
          <td className="blue">0.5 mL</td>
          <td className="blue">1 mL</td>
          <td className="blue">1 mL</td>
          <td className="orange">1 mL</td>
          <td className="orange">1 mL</td>
          <td className="orange">1 mL</td>
          <td className="green">1 mL</td>
          <td className="black">1 mL</td>
        </tr>
        
        {/* Narcan (IV/IM/IO) P- 0.1 mg/kg, A- 0.8 mg 0.75 mL   1 mL      1.25 mL   1.5 mL    1.75 mL   2 mL      2.25 mL   2.5 mL    2.75 mL   3 mL      3.25 mL   3.5 mL    3.75 mL   4 mL      4.25 mL   4.5 mL    4.75 mL   5 mL      5 mL      5 mL      5 mL      5 mL      5 mL      2 mL
 */}
        <tr>
          <td className="lightred">Narcan (IV/IM/IO)</td>
          <td className="lightred">P- 0.1 mg/kg, A- 0.8 mg</td>
          <td className="grey">0.75 mL</td>
          <td className="grey">1 mL</td>
          <td className="grey">1.25 mL</td>
          <td className="pink">1.5 mL</td>
          <td className="pink">1.75 mL</td>
          <td className="black"></td>
          <td className="red">2 mL</td>
          <td className="red">2.25 mL</td>
          <td className="purple">2.5 mL</td>
          <td className="purple">2.75 mL</td>
          <td className="yellow">3 mL</td>
          <td className="yellow">3.25 mL</td>
          <td className="yellow">3.5 mL</td>
          <td className="white">3.75 mL</td>
          <td className="white">4 mL</td>
          <td className="white">4.25 mL</td>
          <td className="white">4.5 mL</td>
          <td className="black"></td>
          <td className="blue">4.75 mL</td>
          <td className="blue">5 mL</td>
          <td className="blue">5 mL</td>
          <td className="orange">5 mL</td>
          <td className="orange">5 mL</td>
          <td className="orange">5 mL</td>
          <td className="green">5 mL</td>
          <td className="black">2 mL</td>
        </tr>
        
        {/* Nitroglycerin (SL) 0.4 mg             1 Spray
 */}
        <tr>
          <td className="lightred">Nitroglycerin (SL)</td>
          <td className="lightred">0.4 mg</td>
          <td className="darkgrey" colSpan="5"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="11"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="7"></td>
          <td className="black">1 Spray</td>
        </tr>
        
        {/* Tetracaine (gtt) 2-3 gtts             2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops  2-3 Drops
 */}
        <tr>
          <td className="lightred">Tetracaine (gtt)</td>
          <td className="lightred">2-3 gtts</td>
          <td className="grey">2-3 Drops</td>
          <td className="grey">2-3 Drops</td>
          <td className="grey">2-3 Drops</td>
          <td className="pink">2-3 Drops</td>
          <td className="pink">2-3 Drops</td>
          <td className="black"></td>
          <td className="red">2-3 Drops</td>
          <td className="red">2-3 Drops</td>
          <td className="purple">2-3 Drops</td>
          <td className="purple">2-3 Drops</td>
          <td className="yellow">2-3 Drops</td>
          <td className="yellow">2-3 Drops</td>
          <td className="yellow">2-3 Drops</td>
          <td className="white">2-3 Drops</td>
          <td className="white">2-3 Drops</td>
          <td className="white">2-3 Drops</td>
          <td className="white">2-3 Drops</td>
          <td className="black"></td>
          <td className="blue">2-3 Drops</td>
          <td className="blue">2-3 Drops</td>
          <td className="blue">2-3 Drops</td>
          <td className="orange">2-3 Drops</td>
          <td className="orange">2-3 Drops</td>
          <td className="orange">2-3 Drops</td>
          <td className="green">2-3 Drops</td>
          <td className="black">2-3 Drops</td>
        </tr>      
        

        {/* Toradol (IV/IM/IO) 0.4 mg             1 Spray
 */}
 <tr>
          <td className="lightred">Toradol (IV/IM/IO)</td>
          <td className="lightred">10 mg (IV/IM/IO)</td>
          <td className="darkgrey" colSpan="5"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="11"></td>
          <td className="black"></td>
          <td className="darkgrey" colSpan="7"></td>
          <td className="black">1 mL</td>
        </tr>
        
        {/* Tylenol (PO) 15 mg/kg, Max 650 mg     1.4 mL    1.8 mL    2.3 mL    2.8 mL    3.2 mL    3.7 mL    4.2 mL    4.6 mL    5.1 mL    5.6 mL    6 mL      6.5 mL    7 mL      7.5 mL    7.9 mL    8.4 mL    8.9 mL    9.3 mL    10.3 mL   11.2 mL   12.1 mL   13.1 mL   14 mL     20 mL
 */}
        <tr>
          <td className="lightred">Tylenol (PO)</td>
          <td className="lightred">15 mg/kg, Max 650 mg</td>
          <td className="grey">1.4 mL</td>
          <td className="grey">1.8 mL</td>
          <td className="grey">2.3 mL</td>
          <td className="pink">2.8 mL</td>
          <td className="pink">3.2 mL</td>
          <td className="black"></td>
          <td className="red">3.7 mL</td>
          <td className="red">4.2 mL</td>
          <td className="purple">4.6 mL</td>
          <td className="purple">5.1 mL</td>
          <td className="yellow">5.6 mL</td>
          <td className="yellow">6 mL</td>
          <td className="yellow">6.5 mL</td>
          <td className="white">7 mL</td>
          <td className="white">7.5 mL</td>
          <td className="white">7.9 mL</td>
          <td className="white">8.4 mL</td>
          <td className="black"></td>
          <td className="blue">8.9 mL</td>
          <td className="blue">9.3 mL</td>
          <td className="blue">10.3 mL</td>
          <td className="orange">11.2 mL</td>
          <td className="orange">12.1 mL</td>
          <td className="orange">13.1 mL</td>
          <td className="green">14 mL</td>
          <td className="black">20 mL</td>
        </tr>
        
        {/* Ventolin (MDI) 100-600 mcg           1 puff    1 puff    2 puffs    2 puffs    2 puffs    2 puffs    2 puffs    3 puffs    3 puffs    3 puffs    4 puffs    4 puffs    4 puffs    5 puffs    5 puffs    5 puffs    5 puffs    6 puffs    6 puffs    6 puffs    6 puffs    6 puffs    6 puffs    6 puffs    4-6 puffs
 */}
        <tr>
          <td className="lightred">Ventolin (MDI)</td>
          <td className="lightred">100-600 mcg</td>
          <td className="grey">1 puff</td>
          <td className="grey">1 puff</td>
          <td className="grey">2 puffs</td>
          <td className="pink">2 puffs</td>
          <td className="pink">2 puffs</td>
          <td className="black"></td>
          <td className="red">2 puffs</td>
          <td className="red">3 puffs</td>
          <td className="purple">3 puffs</td>
          <td className="purple">3 puffs</td>
          <td className="yellow">4 puffs</td>
          <td className="yellow">4 puffs</td>
          <td className="yellow">4 puffs</td>
          <td className="white">5 puffs</td>
          <td className="white">5 puffs</td>
          <td className="white">5 puffs</td>
          <td className="white">5 puffs</td>
          <td className="black"></td>
          <td className="blue">6 puffs</td>
          <td className="blue">6 puffs</td>
          <td className="blue">6 puffs</td>
          <td className="orange">6 puffs</td>
          <td className="orange">6 puffs</td>
          <td className="orange">6 puffs</td>
          <td className="green">6 puffs</td>
          <td className="black">4-6 puffs</td>
        </tr>
        
        {/* Ventolin
(Aerosol) 0.15 mg/kg 0.45
mL
0.6
mL
0.75
mL
0.9
mL
1.05
mL
1.2
mL
1.35
mL
1.5
mL
1.65
mL
1.8
mL
1.95
mL
2.1
mL
2.25
mL
2.4
mL
2.5
mL
2.5
mL
2.5
mL
2.5
mL
2.5
mL
2.5
mL
2.5
mL
2.5
mL
2.5 mL 5 mL */}
        <tr>
          <td className="lightred">Ventolin (Aerosol)</td>
          <td className="lightred">0.15 mg/kg</td>
          <td className="grey">0.45 mL</td>
          <td className="grey">0.6 mL</td>
          <td className="grey">0.75 mL</td>
          <td className="pink">0.9 mL</td>
          <td className="pink">1.05 mL</td>
          <td className="black"></td>
          <td className="red">1.2 mL</td>
          <td className="red">1.35 mL</td>
          <td className="purple">1.5 mL</td>
          <td className="purple">1.65 mL</td>
          <td className="yellow">1.8 mL</td>
          <td className="yellow">1.95 mL</td>
          <td className="yellow">2.1 mL</td>
          <td className="white">2.25 mL</td>
          <td className="white">2.4 mL</td>
          <td className="white">2.5 mL</td>
          <td className="white">2.5 mL</td>
          <td className="black"></td>
          <td className="blue">2.5 mL</td>
          <td className="blue">2.5 mL</td>
          <td className="blue">2.5 mL</td>
          <td className="orange">2.5 mL</td>
          <td className="orange">2.5 mL</td>
          <td className="orange">2.5 mL</td>
          <td className="green">2.5 mL</td>
          <td className="black">5 mL</td>
        </tr>
        
        {/* Zofran
(IV/IM/IO) 0.1 mg/kg 0.15
mL
0.2
mL
0.25
mL
0.3
mL
0.35
mL
0.4
mL
0.45
mL
0.5
mL
0.55
mL
0.6
mL
0.65
mL
0.7
mL
0.75
mL
0.8
mL
0.85
mL
0.9
mL
0.95
mL
1 mL
1.1
mL
1.2
mL
1.3
mL
1.4
mL
1.5 mL 2 mL
 */}
        <tr>
          <td className="lightred">Zofran (IV/IM/IO)</td>
          <td className="lightred">0.1 mg/kg</td>
          <td className="grey">0.15 mL</td>
          <td className="grey">0.2 mL</td>
          <td className="grey">0.25 mL</td>
          <td className="pink">0.3 mL</td>
          <td className="pink">0.35 mL</td>
          <td className="black"></td>
          <td className="red">0.4 mL</td>
          <td className="red">0.45 mL</td>
          <td className="purple">0.5 mL</td>
          <td className="purple">0.55 mL</td>
          <td className="yellow">0.6 mL</td>
          <td className="yellow">0.65 mL</td>
          <td className="yellow">0.7 mL</td>
          <td className="white">0.75 mL</td>
          <td className="white">0.8 mL</td>
          <td className="white">0.85 mL</td>
          <td className="white">0.9 mL</td>
          <td className="black"></td>
          <td className="blue">0.95 mL</td>
          <td className="blue">1 mL</td>
          <td className="blue">1.1 mL</td>
          <td className="orange">1.2 mL</td>
          <td className="orange">1.3 mL</td>
          <td className="orange">1.4 mL</td>
          <td className="green">1.5 mL</td>
          <td className="black">2 mL</td>
        </tr>
      </tbody>
    </table>

  );
};

export default PCRMedicationsTooltipTable;