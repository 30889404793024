import React, { useState, useEffect } from 'react';
import { Input } from '@salesforce/design-system-react';
import PCRComboBox from "../../../../common/PCRComboBox";
import { successOptions, viewOptions, airwayDeviceOptions, iGelSizes, etTubeSizes } from "../../../../../data/constants";
import { useTranslation } from "react-i18next";

const AdvancedAirway = ({ index, isSelected, onAirwaySelect, updatePCRContext }) => {
    const { t } = useTranslation();
    const [selectedAirwayDevice, setSelectedAirwayDevice] = useState("");
    const [sizeOptions, setSizeOptions] = useState([]);
    const [selectedSize, setSelectedSize] = useState("");
    const [time, setTime] = useState("");
    const [selectedSuccess, setSelectedSuccess] = useState("");
    const [selectedView, setSelectedView] = useState("");
    const [viewRequired, setViewRequired] = useState(false);
    const [depthAtGums, setDepthAtGums] = useState("");
    const [depthAtGumsRequired, setDepthAtGumsRequired] = useState(false);
    const [etco2, setEtco2] = useState("");
    const [selectedGastricTube, setSelectedGastricTube] = useState("");
    const [gastricTubeRequired, setGastricTubeRequired] = useState(false);
    const [initials, setInitials] = useState("");
    const [id, setId] = useState("");
    const [selectedRLung, setSelectedRLung] = useState("");
    const [selectedLLung, setSelectedLLung] = useState("");
    const [selectedEpigastrium, setSelectedEpigastrium] = useState("");

    // Handle airway device selection
    const handleAirwayDeviceChange = (selected) => {
        const airwayDeviceValue = selected.label || selected;
        setSelectedAirwayDevice(airwayDeviceValue);
        updatePCRContext({ [`airwayDevice-${index}`]: airwayDeviceValue });
        if (airwayDeviceValue === 'IGel') {
            setSizeOptions(iGelSizes);
            setViewRequired(false);
            setDepthAtGumsRequired(false);
            setGastricTubeRequired(true);
        } else if (airwayDeviceValue === 'ET tube') {
            setSizeOptions(etTubeSizes);
            setViewRequired(true);
            setDepthAtGumsRequired(true);
            setGastricTubeRequired(false);
        } else {
            setSizeOptions([]);
            setViewRequired(false);
            setDepthAtGumsRequired(false);
            setGastricTubeRequired(false);
        }
        setSelectedSize("");
        setSelectedView("");
        setDepthAtGums("");
        setSelectedGastricTube("");
    };

    // Handle size selection
    const handleSizeChange = (selected) => {
        const sizeValue = selected.label || selected;
        setSelectedSize(sizeValue);
        updatePCRContext({ [`size-${index}`]: sizeValue });
    };

    // Handle time change
    const handleTimeChange = (e) => {
        setTime(e.target.value);
        updatePCRContext({ [`time-${index}`]: e.target.value });
    };

    // Handle Successful dropdown change
    const handleSuccessChange = (selected) => {
        const successValue = selected.label || selected;
        setSelectedSuccess(successValue);
        updatePCRContext({ [`successful-${index}`]: successValue });
    };

    // Handle View dropdown change
    const handleViewChange = (selected) => {
        const viewValue = selected.label || selected;
        setSelectedView(viewValue);
        updatePCRContext({ [`view-${index}`]: viewValue });
    };

    // Handle Depth at Gums change with validation
    const handleDepthAtGumsChange = (e) => {
        const value = e.target.value;
        setDepthAtGums(value);
        updatePCRContext({ [`depthAtGums-${index}`]: value });
    };

    // Handle ETCO₂ change
    const handleEtco2Change = (e) => {
        const value = e.target.value;
        setEtco2(value);
        updatePCRContext({ [`etco2-${index}`]: value });
    };

    // Handle Gastric Tube dropdown change
    const handleGastricTubeChange = (selected) => {
        const gastricTubeValue = selected.label || selected;
        setSelectedGastricTube(gastricTubeValue);
        updatePCRContext({ [`gastricTube-${index}`]: gastricTubeValue });
    };

    // Handle Initial change
    const handleInitialsChange = (e) => {
        const value = e.target.value;
        setInitials(value);
        updatePCRContext({ [`initials-${index}`]: value });
    };

    // Handle Id change
    const handleIdChange = (e) => {
        setId(e.target.value);
        updatePCRContext({ [`id-${index}`]: e.target.value });
    };

    // Handle RLung change
    const handleRLungChange = (selected) => {
        const rLungValue = selected.label || selected;
        setSelectedRLung(rLungValue);
        updatePCRContext({ [`rLung-${index}`]: rLungValue });
    };

    // Handle LLung change
    const handleLLungChange = (selected) => {
        const lLungValue = selected.label || selected;
        setSelectedLLung(lLungValue);
        updatePCRContext({ [`lLung-${index}`]: lLungValue });
    };

    // Handle Epigastrium change
    const handleEpigastriumChange = (selected) => {
        const epigastriumValue = selected.label || selected;
        setSelectedEpigastrium(epigastriumValue);
        updatePCRContext({ [`epigastrium-${index}`]: epigastriumValue });
    };

    useEffect(() => {
        if (selectedAirwayDevice === 'IGel') {
            setSizeOptions(iGelSizes);
        } else if (selectedAirwayDevice === 'ET tube') {
            setSizeOptions(etTubeSizes);
        }
    }, [selectedAirwayDevice]);

    return (
        <div className="slds-grid slds-gutters" >
            <div className="slds-col"></div>

            {/* Airway device */}
            <label className="slds-form-element__label" htmlFor="airwayDevice">
                {t("airwayDevice")}
                <PCRComboBox
                    id="airwayDevice-combo-box"
                    label=""
                    options={airwayDeviceOptions}
                    value={selectedAirwayDevice}
                    placeholder="--"
                    onChange={handleAirwayDeviceChange}
                    onSelect={(val) => handleAirwayDeviceChange(val.airwayDevice)}
                    selection={selectedAirwayDevice ? [{ id: selectedAirwayDevice, label: selectedAirwayDevice }] : []}
                    name="airwayDevice"
                />
            </label>

            {/* Size */}
            <label className="slds-form-element__label" htmlFor="size">
                {t("size")}
                <PCRComboBox
                    id="size-combo-box"
                    label=""
                    options={sizeOptions}
                    value={selectedSize}
                    placeholder="--"
                    onChange={handleSizeChange}
                    onSelect={(val) => handleSizeChange(val.size)}
                    selection={selectedSize ? [{ id: selectedSize, label: selectedSize }] : []}
                    name="size"
                />
            </label>

            {/* Time */}
            <label className="slds-form-element__label" htmlFor="size">
                {t("time")}
                <Input
                    id="time"
                    placeholder="HH:MM"
                    value={time}
                    onChange={handleTimeChange}
                    pattern="([01][0-9]|2[0-3]):[0-5][0-9]"
                    errorText={
                        time && !/^([01][0-9]|2[0-3]):[0-5][0-9]$/.test(time)
                            ? "Invalid time format. Use HH:MM."
                            : ""
                    }
                />
            </label>

            {/* Successful */}
            <label className="slds-form-element__label" htmlFor="successful">
                {t("successful")}
                <PCRComboBox
                    id="successful-combo-box"
                    label=""
                    options={successOptions}
                    value={selectedSuccess}
                    placeholder="--"
                    onChange={handleSuccessChange}
                    onSelect={(val) => handleSuccessChange(val.successful)}
                    selection={selectedSuccess ? [{ id: selectedSuccess, label: selectedSuccess }] : []}
                    name="successful"
                />
            </label>

            {/* View */}
            <label className="slds-form-element__label" htmlFor="view">
                {t("view")}
                <PCRComboBox
                    id="view-combo-box"
                    label=""
                    options={viewOptions}
                    value={selectedView}
                    placeholder="--"
                    onChange={handleViewChange}
                    onSelect={(val) => handleViewChange(val.view)}
                    selection={selectedView ? [{ id: selectedView, label: selectedView }] : []}
                    name="view"
                    required={viewRequired}
                />
            </label>

            {/* Depth at Gums */}
            <label className="slds-form-element__label" htmlFor="depthAtGums">
                {t("depthAtGums")}
                <Input
                    id="depthAtGums"
                    value={depthAtGums}
                    onChange={handleDepthAtGumsChange}
                    type="number"
                    min="0"
                    step="1"
                    errorText={depthAtGums && depthAtGums < 0 ? "Depth must be greater than or equal to 0" : ""}
                    required={depthAtGumsRequired}
                />
            </label>

            {/* ETCO₂ */}
            <label className="slds-form-element__label" htmlFor="etco2">
                {t("etco2")}
                <Input
                    id="etco2"
                    value={etco2}
                    onChange={handleEtco2Change}
                    type="number"
                    min="0"
                    step="1"
                    errorText={etco2 && etco2 < 0 ? "ETCO₂ must be greater than or equal to 0" : ""}
                />
            </label>

            {/* Gastric tube */}
            <label className="slds-form-element__label" htmlFor="gastricTube">
                {t("gastricTube")}
                <PCRComboBox
                    id="gastricTube-combo-box"
                    label=""
                    options={successOptions}
                    value={selectedGastricTube}
                    placeholder="--"
                    onChange={handleGastricTubeChange}
                    onSelect={(val) => handleGastricTubeChange(val.gastricTube)}
                    selection={selectedGastricTube ? [{ id: selectedGastricTube, label: selectedGastricTube }] : []}
                    required={gastricTubeRequired}
                    name="gastricTube"
                />
            </label>

            {/* Initial */}
            <label className="slds-form-element__label" htmlFor="initials">
                {t("initial")}
                <Input
                    id="initials"
                    value={initials}
                    onChange={handleInitialsChange}
                    errorText={
                        initials && !/^[A-Za-zÀ-ÿ]*$/.test(initials)
                            ? "Initials must contain only alphabetical characters."
                            : ""
                    }
                />
            </label>

            {/* ID# */}
            <label className="slds-form-element__label" htmlFor="id">
                {t("id")}
                <Input
                    id="id"
                    value={id}
                    onChange={handleIdChange}
                />
            </label>

            {/* R lung */}
            <label className="slds-form-element__label" htmlFor="rLung">
                {t("rLung")}
                <PCRComboBox
                    id="rLung-combo-box"
                    label=""
                    options={successOptions}
                    value={selectedRLung}
                    placeholder="--"
                    onChange={handleRLungChange}
                    onSelect={(val) => handleRLungChange(val.rLung)}
                    selection={selectedRLung ? [{ id: selectedRLung, label: selectedRLung }] : []}
                    name="rLung"
                />
            </label>

            {/* L lung */}
            <label className="slds-form-element__label" htmlFor="lLung">
                {t("lLung")}
                <PCRComboBox
                    id="lLung-combo-box"
                    label=""
                    options={successOptions}
                    value={selectedLLung}
                    placeholder="--"
                    onChange={handleLLungChange}
                    onSelect={(val) => handleLLungChange(val.lLung)}
                    selection={selectedLLung ? [{ id: selectedLLung, label: selectedLLung }] : []}
                    name="lLung"
                />
            </label>

            {/* Epigastrium */}
            <label className="slds-form-element__label" htmlFor="epigastrium">
                {t("epigastrium")}
                <PCRComboBox
                    id="epigastrium-combo-box"
                    label=""
                    options={successOptions}
                    value={selectedEpigastrium}
                    placeholder="--"
                    onChange={handleEpigastriumChange}
                    onSelect={(val) => handleEpigastriumChange(val.epigastrium)}
                    selection={selectedEpigastrium ? [{ id: selectedEpigastrium, label: selectedEpigastrium }] : []}
                    name="epigastrium"
                />
            </label>
        </div >

    );
};

export default AdvancedAirway;