const API_KEY = "";
const BASE_URL =
  "http://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/";
const FIND_END_POINT = "Find/v2.10/json3.ws";
const RETRIEVE_END_POINT = "Retrieve/v2.11/json3.ws";
const COUNTRY_CODE = "CAN";
const LANG = "en";

export const useAddressComplete = () => {
  const getAddressesList = async (searchTerm, lastId) => {
    var params = {
      SearchTerm: searchTerm,
      Key: API_KEY,
      Country: COUNTRY_CODE,
      LanguagePreference: LANG,
      MaxSuggestions: 10,
    };
    if (lastId) {
      params.LastId = lastId;
    }
    const paramsString = new URLSearchParams(params);
    const response = await fetch(
      `${BASE_URL}${FIND_END_POINT}?${paramsString}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.json();
  };

  const getAddressDetails = async (id) => {
    var params = {
      Key: API_KEY,
      Id: id,
    };
    const paramsString = new URLSearchParams(params);
    const response = await fetch(
      `${BASE_URL}${RETRIEVE_END_POINT}?${paramsString}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.json();
  };

  return {
    getAddressesList,
    getAddressDetails,
  };
};
