import { get } from "./api.js";
import { getLanguage } from "../utils/util.js";
import { ERROR_CODES } from "../data/constants.js";

export const getMedicDetailsUsingMedicId = async (medicId) => {
  const params = new URLSearchParams({
    medicId: medicId,
    language: getLanguage()
  });
  console.log("calling getMedicDetailsUsingMedicId with params: ", params);
  const response = await get(`apexrest/ANB/getUserDetails?${params}`);

  console.log("medicApi.js > getMedicDetailsUsingMedicId response >>>", response);

  switch (response.status) {
    case 401:
      return {
        error: response?.data?.[0]?.errorCode || "Unauthorized",
        status: response?.status || 401,
        data: response?.data?.[0]?.message || null
      };
    case 400:
      if (response?.data?.code) {
        return {
          error: response.data?.code,
          status: 400,
          data: response.data.message,
        };
      }
      break;
      // 500
    case 500:
      // 2004
      if (response?.data?.code && response?.data?.code === ERROR_CODES.MEDIC_GET_USER_DETAILS_SERVER_ERROR) {
        return {
          error: response.data?.code,
          status: response.status,
          data: `${response?.data?.details || ''}${response?.data?.message ? '; ' + response.data.message : ''}`,
        };
      } 
      // other 500
      else {
        return {
          error:
            response?.data?.[0]?.code ||
            ERROR_CODES.MEDIC_NOT_FOUND_ON_MEDIC_ID,
          data: response?.data?.[0]?.message || "",
          status: response.status || 500,
        };
      }
    case 200:
      return {
        error: null,
        status: 200,
        data: response.data,
      };
    default:
      return {
        error:
          response?.data?.[0]?.code ||
          ERROR_CODES.MEDIC_NOT_FOUND_ON_MEDIC_ID,
        data: response?.data?.[0]?.message || "",
        status: response.status || 500,
      };
  }
};
