import React from "react";
import PCRPageLayout from "../components/PCRPageLayout";
import { PCRContextProvider } from "../context/PCRContext";

const Home = () => {
  return (
    <>
      <PCRContextProvider>
        <PCRPageLayout></PCRPageLayout>
      </PCRContextProvider>
    </>
  );
};

export default Home;
