import React, { useLayoutEffect, useState } from "react";
import { useNetworkStatus } from "../hooks/useNetworkStatus";
import "../css/network.css";
import { useTranslation } from "react-i18next";

const NetworkIndicator = () => {
  const isOnline = useNetworkStatus();
  const { t } = useTranslation();

  const [isNetworkAvailable, setIsNetworkAvailable] = useState(isOnline);

  useLayoutEffect(() => {
    setIsNetworkAvailable(isOnline);
  }, [isOnline]);

  return (
    <>
      {/* <div className="version">Version 1.0.0</div> */}
      {isNetworkAvailable ? (
        <div className="networkWrapper zI10">

          <div>{t("online")}</div>
          <div className="indicatorWrapper">
            <div className="indicator online"></div>
          </div>

        </div>
      ) : (
        <div className="networkWrapper zI10">
          <div>{t("offline")}</div>
          <div className="indicatorWrapper">
            <div className="indicator offline"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default NetworkIndicator;
