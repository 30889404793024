import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal
} from "@salesforce/design-system-react";
import "../css/addCrewMember.css";
import { getMedicDetailsUsingMedicId } from "../services/medicApi";
import { useTranslation } from "react-i18next";
import { ERROR_CODES } from "../data/constants";
import { db } from "../storage/idb.js";
import { useNetworkStatus } from "../hooks/useNetworkStatus.js";
import { fetchPCRList } from "utils/pcrDbUtil";

const AddCrewMember = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const isOnline = useNetworkStatus();
  
  const [medicIdError, setMedicIdError] = useState(null);
  const [medicId, setMedicId] = useState("");
  const [serverError, setServerError] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isSearchButtonVisible, setIsSearchButtonVisible] = useState(true);
  //const [isTryAgainButtonVisible, setIsTryAgainButtonVisible] = useState(false);
  const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState(false);

  const [medicData, setMedicData] = useState({});

  const toggleModalOpen = () => {
    if (showModal) {
      setErrorCode("");
      setServerError();
      setMedicIdError(null);
      setMedicId("");
      setIsSearchButtonVisible(true);
      //setIsTryAgainButtonVisible(false);
      setIsSubmitButtonVisible(false);
      setConfirmationMessage("");
    }
    setShowModal(!showModal);
  };

  const handleMedicIdInputChange = async ({ target: { value } }) => {
    setErrorCode("");
    setServerError("");
    setMedicIdError("");
    setConfirmationMessage("");
    hideSubmitButton();
    showSearchButton();
    setMedicId(value);
    await validateMedicId(value); // Pass the value directly
  };

  // local validation of medic id
  const validateMedicId = async (medicIdValue) => {
    console.log("validating medic id: ", medicIdValue);
    // empty medic id check
    if (!medicIdValue) {
      setMedicIdError(t("inputIsRequiredError"));
      return false;
    }
    // numeric check
    if (!new RegExp(/^\d+$/).test(medicIdValue)) {
      setMedicIdError("Only numbers are allowed.");
      return false;
    }
    // medic id exist
    else if (await isMedicExists(medicIdValue)) {
      setMedicIdError(t("addCrewMember_medicAlreadyExist"));
      return false;
    }
    else {
      setMedicIdError("");
      return true;
    }
  };

  const insertMedicInfoToLocalStorage = async (data) => {
    const users = localStorage.getItem('users');
    if (users) {
      const userList = JSON.parse(users);
      const isRecordExists = userList.some(user => user.employeeId === data.employeeId);
      if (isRecordExists) {
        const index = userList.findIndex(user => user.employeeId === data.employeeId);
        userList[index] = data;
      } else {
        userList.push(data);
      }
      localStorage.setItem('users', JSON.stringify(userList));
      /*
      // 71
      const medicIdList = [data.employeeId];
      const pcrList = await fetchPCRList('ENG', medicIdList);
      */

      return localStorage.getItem('users');
    }
  }

  const checkAndCloseModal = (usersList, employeeId) => {

    // user is a list so some will not work
    const users = JSON.parse(usersList);
    const isMedicExists = users.some(user => user.employeeId === employeeId);
    if (isMedicExists) {
      toggleModalOpen();
      return true;
    }
    return false;
  }

  const addCrewMemberToList = async (data) => {
    console.log("addCrewMemberToList data >>>", data);
    if (!validatingFetchedMedicInfo(data)) return;
    console.log("showSuccessMedicInfo data >>>", data);
    const users = await insertMedicInfoToLocalStorage(data);
    const isCrewMemberAdded = checkAndCloseModal(users, data.employeeId);
    if (isCrewMemberAdded) {
      window.location.reload();
    }
  };

  const validatingFetchedMedicInfo = (data) => {
    if (!data.title) {
      setMedicIdError("Title is empty.");
      return false;
    }
    if (!data.fullName) {
      setMedicIdError("Full name is empty.");
      return false;
    }
    if (!data.federationId) {
      setMedicIdError("Federation ID is empty.");
      return false;
    }
    if (!data.employeeId) {
      setMedicIdError("Employee ID is empty.");
      return false;
    }
    return true;
  }

  const isMedicExists = async (medicId) => {
    const users = localStorage.getItem('users');
    if (users) {
      try {
        const userList = JSON.parse(users);
        if (Array.isArray(userList)) {
          // filter the user record that has employee id = medic id
          const isRecordExists = userList.some(user => user.employeeId === medicId);
          console.log("isMedicExists isRecordExists >>>", isRecordExists);
          return isRecordExists;
        }
      } catch (error) {
        console.error("Error parsing users from localStorage:", error);
      }
    }
    console.log("isMedicExists > users >>>", users);
    return false;
  }

  /////////// validateAndFetchMedicInfo ///////////
  const validateAndFetchMedicInfo = async () => {
    let validateResult = {
      success: false,
      error: "",
      errorCode: ""
    };
    // client medic id validations
    const isValidMedicId = await validateMedicId(medicId);
    if (!isValidMedicId) {
      validateResult.success = false;
      validateResult.error = "Invalid medic id";
      return validateResult;
    }

    // get medic details
    const response = await getMedicDetailsUsingMedicId(medicId);
    console.log("handleProceedClick > validateAndFetchMedicInfo > response >>>", response);

    // unknown error
    if (!response || !response.status) {
      setErrorCode("Unknown error");
      setServerError("An unknown error occurred. Please try again.");
      validateResult.success = false;
      validateResult.error = "An unknown error occurred. Please try again.";
      validateResult.errorCode = "Unknown error";
      return validateResult;
    }
    // 400
    if (response.status === ERROR_CODES.UNAUTHORIZED) {
      setErrorCode(response?.status);
      setServerError(response?.data);
      validateResult.success = false;
      validateResult.error = response?.data;
      validateResult.errorCode = response?.error;
      hideSearchButton();
      //hideTryAgainButton();
      hideSubmitButton();
      return validateResult;
    }
    // 400, 404
    if (
      response.status === ERROR_CODES.BAD_REQUEST ||
      response.status === ERROR_CODES.RESOURCE_NOT_FOUND
    ) {
      setErrorCode(response?.error);
      setServerError(response?.data);
      validateResult.success = false;
      validateResult.error = response?.data;
      validateResult.errorCode = response?.error;
      return validateResult;
    }
    // 500
    if (
      response.status === ERROR_CODES.INTERNAL_SERVER_ERROR
    ) {
      setErrorCode(response?.error);
      setServerError(response?.data);
      validateResult.success = false;
      validateResult.error = response?.data;
      validateResult.errorCode = response?.error;
      return validateResult;
    }
    else {
      // fill medic data instance
      await setMedicData(response.data);
      // got medic data. set confirmation message from the data
      await processConfirmationMessage(response.data);
      validateResult.success = true;
      validateResult.error = "";
      validateResult.errorCode = "";
      return validateResult;
    }
    console.log("validateAndFetchMedicInfo 5th !");
  };

  const handleSubmitClick = async () => {
    console.log("handleSubmitClick");
    const addCrewMemberResult = await addCrewMemberToList(medicData);
    if (addCrewMemberResult) {
      // reset the form
      await resetForNewFormSubmit();
    }
    // close the modal
    toggleModalOpen();
  }

  const processConfirmationMessage = async (data) => {
    console.log("processConfirmationMessage data >>>", data);
    let confirmationMessage;
    if (data.fullName) {
      // make full name and show title bold
      confirmationMessage = "You are adding " + "<b>" + data.fullName + "</b>" + ", " + "<b>" + data.title + "</b>" + " to the application. Select Proceed to continue." + "<br/> <br/>" + "If this is the incorrect account, enter a new ID# and try again.";
      console.log("confirmationMessage >>>", confirmationMessage);
    }
    setConfirmationMessage(confirmationMessage);
    hideSearchButton();
    //showTryAgainButton();
  }

  const handleSearchClick = async () => {
    // validate and fetch medic id
    const validateResult = await validateAndFetchMedicInfo();
    console.log(" handleSearchClick() > validateResult >>>", validateResult);
    if (validateResult?.success) {
      hideSearchButton();
      //showTryAgainButton();
      showSubmitButton();
    } else if (validateResult?.errorCode === "INVALID_SESSION_ID") {
      hideSearchButton();
    } else {
      console.log(" handleSearchClick() > validateResult >>>", validateResult);
      showSearchButton();
    }
  }

  const showSearchButton = () => {
    setIsSearchButtonVisible(true);
  }

  const hideSearchButton = () => {
    setIsSearchButtonVisible(false);
  }

  /* 
  const showTryAgainButton = () => {
    setIsTryAgainButtonVisible(true);
  }

  const hideTryAgainButton = () => {
    setIsTryAgainButtonVisible(false);
  } 
  */

  const showSubmitButton = () => {
    setIsSubmitButtonVisible(true);
  }

  const hideSubmitButton = () => {
    setIsSubmitButtonVisible(false);
  }

  const resetForNewFormSubmit = async () => {
    setErrorCode("");
    setServerError("");
    setMedicIdError("");
    setMedicId("");
    showSearchButton();
    //hideTryAgainButton();
    hideSubmitButton();
  }


  // get footer buttons
  const getFooterButtons = () => {
    const footerButtonList = [];

    /*
    if (isTryAgainButtonVisible) {
      // try again button
      footerButtonList.push(
        <Button
          className="addCrewMember_tryAgainBtn"
          id="addCrewMember_tryAgainBtn"
          variant="brand"
          onClick={handleSearchClick}
          disabled={!isOnline}
        >
          {t("try")}
        </Button>
      );
    }
    */

    if (isSearchButtonVisible) {
      // search button
      footerButtonList.push(
        <Button
          className="addCrewMember_searchBtn"
          id="addCrewMember_searchBtn"
          variant="brand"
          onClick={handleSearchClick}
          disabled={!isOnline}
          label={t("search")}
          iconCategory="utility"
          iconName="search"
          iconPosition="left"
        />
      );
    }

    if (isSubmitButtonVisible) {
      // submit button
      footerButtonList.push(
        <Button
          className="addCrewMember_submitBtn"
          id="addCrewMember_submitBtn"
          variant="brand"
          onClick={handleSubmitClick}
          disabled={!isOnline}
          label={t("proceed")}
          iconCategory="utility"
          iconName="right"
          iconPosition="right"
        />
      );
    }

    return footerButtonList;
  };

  return (
    <>
      <div>

        {isOnline ? (
          <Button variant="outline-brand" onClick={toggleModalOpen} className="addCrewMember_addCrewMemberBtn">
            {t("addCrewMember_buttonLabel")}
          </Button>
        ) : (
          <Button variant="outline-brand" disabled className="addCrewMember_addCrewMemberBtn">
            {t("addCrewMember_buttonLabel")}
          </Button>
        )}

        <div className="addCrewMemberModalWrapper">
          <Modal
            isOpen={showModal}
            onRequestClose={toggleModalOpen}
            header={t("addCrewMember_modalHeader")}
            footer={getFooterButtons()}
            id="addCrewMemberModal"
            className="addCrewMemberModal"
          >
            <section id="addCrewMemberModalSection" className="slds-p-around_medium">
              <div className="modalDesc">
                {t("addCrewMember_modalDescription")}
              </div>
              <>
                <div className="medicIdInputWrapper">
                  <Input
                    fixedTextLeft
                    id="medic-id-input"
                    label={t("addCrewMember_medicIdInputLabel")}
                    onChange={handleMedicIdInputChange}
                    required
                    type="number"
                    errorText={
                      medicIdError && (
                        <div className="aic df">
                          <div className="inputErrorIcon"></div>
                          <div className="inputErrorText">{medicIdError}</div>
                        </div>
                      )
                    }
                  />
                </div>
              </>
              {/* no error and success message available and with network */}
              {confirmationMessage && !errorCode && !serverError && (
                <div className="confirmationMessageWrapper">
                  <div
                    className="confirmationMessageText"
                    dangerouslySetInnerHTML={{ __html: confirmationMessage }}
                  ></div>
                </div>
              )}
              {/* server error and no network */}
              {serverError && isOnline && (
                <div className="serverErrorWrapper">
                  <div className="serverErrorWarning pa"></div>
                  <div className="serverErrorWarningText">{serverError}</div>
                </div>
              )}
            </section>
          </Modal>
        </div>

      </div>
    </>
  );
};

export default AddCrewMember;