export const FORM_TYPES = {
  IDENTIFICATION: "identification",
  ASSESSMENT: "assessment",
  TREATMENT: "treatment",
  TRANSFEROFCARE: "transferOfCare",
};

export const ERROR_CODES = {
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  RESOURCE_NOT_FOUND: 404,
  PCR_EXISTS: 402,
  INTERNAL_SERVER_ERROR: 500,
  MEDIC_NOT_FOUND_ON_TRANSFER_NUMBER: "2001",
  NO_TRANFER_NUMBER_FOUND: "2000",
  NOT_ALL_CREW_MEMBERS_ASSIGNED_TO_DU: "2001",
  NO_CREW_MEMBERS_ASSIGNED_TO_DU: "2001",
  PCR_SERVER_ERROR: "2002",
  MEDIC_NOT_FOUND_ON_MEDIC_ID: "9999",
  MEDIC_GET_USER_DETAILS_SERVER_ERROR: "2004",
};

export const supportedLocales = {
  EN: "en",
  FR: "fr",
};

export const profileColors = ["#9b59b6", "#1abc9c", "#3498db"];

export const VALIDATION_TYPES = {
  SELECTION_REQUIRED: "selection_required",
  VALUE_REQUIRED: "value_required",
  VALUE_REQUIRED_WITH_FORMAT: "value_required"
};

export const uniqueIdentifierTypeOptions = [
  {
    id: "Medicare Number",
    label: "Medicare Number",
    key: "Medicare Number",
    value: "Medicare Number",
  },
  {
    id: "Alberta Health Number",
    label: "Alberta Health Number",
    key: "Alberta Health Number",
    value: "Alberta Health Number",
  },
  {
    id: "British Columbia Health Number",
    label: "British Columbia Health Number",
    key: "British Columbia Health Number",
    value: "British Columbia Health Number",
  },
  {
    id: "Manitoba Health Number",
    label: "Manitoba Health Number",
    key: "Manitoba Health Number",
    value: "Manitoba Health Number",
  },
  {
    id: "Newfoundland and Labrador Health Number",
    label: "Newfoundland and Labrador Health Number",
    key: "Newfoundland and Labrador Health Number",
    value: "Newfoundland and Labrador Health Number",
  },
  {
    id: "Nova Scotia Health Number",
    label: "Nova Scotia Health Number",
    key: "Nova Scotia Health Number",
    value: "Nova Scotia Health Number",
  },
  {
    id: "Northwest Territories Health Number",
    label: "Northwest Territories Health Number",
    key: "Northwest Territories Health Number",
    value: "Northwest Territories Health Number",
  },
  {
    id: "Nunavut Health Number",
    label: "Nunavut Health Number",
    key: "Nunavut Health Number",
    value: "Nunavut Health Number",
  },
  {
    id: "Ontario Health Number",
    label: "Ontario Health Number",
    key: "Ontario Health Number",
    value: "Ontario Health Number",
  },
  {
    id: "Prince Edvard Island Health Number",
    label: "Prince Edvard Island Health Number",
    key: "Prince Edvard Island Health Number",
    value: "Prince Edvard Island Health Number",
  },
  {
    id: "Quebec Health Number",
    label: "Quebec Health Number",
    key: "Quebec Health Number",
    value: "Quebec Health Number",
  },
  {
    id: "Saskatchewan Health Number",
    label: "Saskatchewan Health Number",
    key: "Saskatchewan Health Number",
    value: "Saskatchewan Health Number",
  },
  {
    id: "Yukon Health Number",
    label: "Yukon Health Number",
    key: "Yukon Health Number",
    value: "Yukon Health Number",
  },
  {
    id: "Veterans Affairs Canada Health Number",
    label: "Veterans Affairs Canada Health Number",
    key: "Veterans Affairs Canada Health Number",
    value: "Veterans Affairs Canada Health Number",
  },
  {
    id: "Correctional Service Canada Health Number",
    label: "Correctional Service Canada Health Number",
    key: "Correctional Service Canada Health Number",
    value: "Correctional Service Canada Health Number",
  },
  {
    id: "Indigenous and Northern Affairs Canada Health Number",
    label: "Indigenous and Northern Affairs Canada Health Number",
    key: "Indigenous and Northern Affairs Canada Health Number",
    value: "Indigenous and Northern Affairs Canada Health Number",
  },
  {
    id: "Royal Canadian Mounted Police Health Number",
    label: "Royal Canadian Mounted Police Health Number",
    key: "Royal Canadian Mounted Police Health Number",
    value: "Royal Canadian Mounted Police Health Number",
  },
  {
    id: "Canadian Armed Forces Health Number",
    label: "Canadian Armed Forces Health Number",
    key: "Canadian Armed Forces Health Number",
    value: "Canadian Armed Forces Health Number",
  },
  {
    id: "Newborn",
    label: "Newborn",
    key: "Newborn",
    value: "Newborn",
  },
  {
    id: "Not available",
    label: "Not available",
    key: "Not available",
    value: "Not available",
  },
];

export const countries = [
  { id: "Unknown", key: "Unknown", label: "Unknown", value: "Unknown" },
  { id: "Afghanistan", key: "Afghanistan", label: "Afghanistan", value: "AF" },
  { id: "Albania", key: "Albania", label: "Albania", value: "AL" },
  { id: "Algeria", key: "Algeria", label: "Algeria", value: "DZ" },
  {
    id: "American Samoa",
    key: "American Samoa",
    label: "American Samoa",
    value: "AS",
  },
  { id: "Andorra", key: "Andorra", label: "Andorra", value: "AD" },
  { id: "Angola", key: "Angola", label: "Angola", value: "AO" },
  { id: "Anguilla", key: "Anguilla", label: "Anguilla", value: "AI" },
  { id: "Antarctica", key: "Antarctica", label: "Antarctica", value: "AQ" },
  {
    id: "Antigua and Barbuda",
    key: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
    value: "AG",
  },
  { id: "Argentina", key: "Argentina", label: "Argentina", value: "AR" },
  { id: "Armenia", key: "Armenia", label: "Armenia", value: "AM" },
  { id: "Aruba", key: "Aruba", label: "Aruba", value: "AW" },
  { id: "Australia", key: "Australia", label: "Australia", value: "AU" },
  { id: "Austria", key: "Austria", label: "Austria", value: "AT" },
  { id: "Azerbaijan", key: "Azerbaijan", label: "Azerbaijan", value: "AZ" },
  { id: "Bahamas", key: "Bahamas", label: "Bahamas", value: "BS" },
  { id: "Bahrain", key: "Bahrain", label: "Bahrain", value: "BH" },
  { id: "Bangladesh", key: "Bangladesh", label: "Bangladesh", value: "BD" },
  { id: "Barbados", key: "Barbados", label: "Barbados", value: "BB" },
  { id: "Belarus", key: "Belarus", label: "Belarus", value: "BY" },
  { id: "Belgium", key: "Belgium", label: "Belgium", value: "BE" },
  { id: "Belize", key: "Belize", label: "Belize", value: "BZ" },
  { id: "Benin", key: "Benin", label: "Benin", value: "BJ" },
  { id: "Bermuda", key: "Bermuda", label: "Bermuda", value: "BM" },
  { id: "Bhutan", key: "Bhutan", label: "Bhutan", value: "BT" },
  { id: "Bolivia", key: "Bolivia", label: "Bolivia", value: "BO" },
  { id: "Bonaire", key: "Bonaire", label: "Bonaire", value: "BQ" },
  {
    id: "Bosnia and Herzegovina",
    key: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
    value: "BA",
  },
  { id: "Botswana", key: "Botswana", label: "Botswana", value: "BW" },
  {
    id: "Bouvet Island",
    key: "Bouvet Island",
    label: "Bouvet Island",
    value: "BV",
  },
  { id: "Brazil", key: "Brazil", label: "Brazil", value: "BR" },
  {
    id: "British Indian Ocean Territory",
    key: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
    value: "IO",
  },
  {
    id: "Brunei Darussalam",
    key: "Brunei Darussalam",
    label: "Brunei Darussalam",
    value: "BN",
  },
  { id: "Bulgaria", key: "Bulgaria", label: "Bulgaria", value: "BG" },
  {
    id: "Burkina Faso",
    key: "Burkina Faso",
    label: "Burkina Faso",
    value: "BF",
  },
  { id: "Burundi", key: "Burundi", label: "Burundi", value: "BI" },
  { id: "Cambodia", key: "Cambodia", label: "Cambodia", value: "KH" },
  { id: "Cameroon", key: "Cameroon", label: "Cameroon", value: "CM" },
  { id: "Canada", key: "Canada", label: "Canada", value: "CA" },
  { id: "Cape Verde", key: "Cape Verde", label: "Cape Verde", value: "CV" },
  {
    id: "Cayman Islands",
    key: "Cayman Islands",
    label: "Cayman Islands",
    value: "KY",
  },
  {
    id: "Central African Republic",
    key: "Central African Republic",
    label: "Central African Republic",
    value: "CF",
  },
  { id: "Chad", key: "Chad", label: "Chad", value: "TD" },
  { id: "Chile", key: "Chile", label: "Chile", value: "CL" },
  { id: "China", key: "China", label: "China", value: "CN" },
  {
    id: "Christmas Island",
    key: "Christmas Island",
    label: "Christmas Island",
    value: "CX",
  },
  {
    id: "Cocos (Keeling) Islands",
    key: "Cocos (Keeling) Islands",
    label: "Cocos (Keeling) Islands",
    value: "CC",
  },
  { id: "Colombia", key: "Colombia", label: "Colombia", value: "CO" },
  { id: "Comoros", key: "Comoros", label: "Comoros", value: "KM" },
  { id: "Congo", key: "Congo", label: "Congo", value: "CG" },
  {
    id: "Congo, The Democratic Republic of the",
    key: "Congo, The Democratic Republic of the",
    label: "Congo, The Democratic Republic of the",
    value: "CD",
  },
  {
    id: "Cook Islands",
    key: "Cook Islands",
    label: "Cook Islands",
    value: "CK",
  },
  { id: "Costa Rica", key: "Costa Rica", label: "Costa Rica", value: "CR" },
  {
    id: 'Cote D"Ivoire',
    key: 'Cote D"Ivoire',
    label: 'Cote D"Ivoire',
    value: "CI",
  },
  { id: "SelCroatiaect", key: "Croatia", label: "Croatia", value: "HR" },
  { id: "Cuba", key: "Cuba", label: "Cuba", value: "CU" },
  { id: "Curacao", key: "Curacao", label: "Curacao", value: "CW" },
  { id: "Cyprus", key: "Cyprus", label: "Cyprus", value: "CY" },
  {
    id: "Czech Republic",
    key: "Czech Republic",
    label: "Czech Republic",
    value: "CZ",
  },
  { id: "Denmark", key: "Denmark", label: "Denmark", value: "DK" },
  { id: "Djibouti", key: "Djibouti", label: "Djibouti", value: "DJ" },
  { id: "Dominica", key: "Dominica", label: "Dominica", value: "DM" },
  {
    id: "Dominican Republic",
    key: "Dominican Republic",
    label: "Dominican Republic",
    value: "DO",
  },
  { id: "Ecuador", key: "Ecuador", label: "Ecuador", value: "EC" },
  { id: "Egypt", key: "Egypt", label: "Egypt", value: "EG" },
  { id: "El Salvador", key: "El Salvador", label: "El Salvador", value: "SV" },
  {
    id: "Equatorial Guinea",
    key: "Equatorial Guinea",
    label: "Equatorial Guinea",
    value: "GQ",
  },
  { id: "Eritrea", key: "Eritrea", label: "Eritrea", value: "ER" },
  { id: "Estonia", key: "Estonia", label: "Estonia", value: "EE" },
  { id: "Eswatini", key: "Eswatini", label: "Eswatini", value: "SZ" },
  { id: "Ethiopia", key: "Ethiopia", label: "Ethiopia", value: "ET" },
  {
    id: "Falkland Islands (Malvinas)",
    key: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
    value: "FK",
  },
  {
    id: "Faroe Islands",
    key: "Faroe Islands",
    label: "Faroe Islands",
    value: "FO",
  },
  { id: "Fiji", key: "Fiji", label: "Fiji", value: "FJ" },
  { id: "Finland", key: "Finland", label: "Finland", value: "FI" },
  { id: "France", key: "France", label: "France", value: "FR" },
  {
    id: "French Guiana",
    key: "French Guiana",
    label: "French Guiana",
    value: "GF",
  },
  {
    id: "French Polynesia",
    key: "French Polynesia",
    label: "French Polynesia",
    value: "PF",
  },
  {
    id: "French Southern Territories",
    key: "French Southern Territories",
    label: "French Southern Territories",
    value: "TF",
  },
  { id: "Gabon", key: "Gabon", label: "Gabon", value: "GA" },
  { id: "Gambia", key: "Gambia", label: "Gambia", value: "GM" },
  { id: "Georgia", key: "Georgia", label: "Georgia", value: "GE" },
  { id: "Germany", key: "Germany", label: "Germany", value: "DE" },
  { id: "Ghana", key: "Ghana", label: "Ghana", value: "GH" },
  { id: "Gibraltar", key: "Gibraltar", label: "Gibraltar", value: "GI" },
  { id: "Greece", key: "Greece", label: "Greece", value: "GR" },
  { id: "Greenland", key: "Greenland", label: "Greenland", value: "GL" },
  { id: "Grenada", key: "Grenada", label: "Grenada", value: "GD" },
  { id: "Guadeloupe", key: "Guadeloupe", label: "Guadeloupe", value: "GP" },
  { id: "Guam", key: "Guam", label: "Guam", value: "GU" },
  { id: "Guatemala", key: "Guatemala", label: "Guatemala", value: "GT" },
  { id: "Guernsey", key: "Guernsey", label: "Guernsey", value: "GG" },
  { id: "Guinea", key: "Guinea", label: "Guinea", value: "GN" },
  {
    id: "Guinea-Bissau",
    key: "Guinea-Bissau",
    label: "Guinea-Bissau",
    value: "GW",
  },
  { id: "Guyana", key: "Guyana", label: "Guyana", value: "GY" },
  { id: "Haiti", key: "Haiti", label: "Haiti", value: "HT" },
  {
    id: "Heard Island and Mcdonald Islands",
    key: "Heard Island and Mcdonald Islands",
    label: "Heard Island and Mcdonald Islands",
    value: "HM",
  },
  {
    id: "Holy See (Vatican City State)",
    key: "Holy See (Vatican City State)",
    label: "Holy See (Vatican City State)",
    value: "VA",
  },
  { id: "Honduras", key: "Honduras", label: "Honduras", value: "HN" },
  { id: "Hong Kong", key: "Hong Kong", label: "Hong Kong", value: "HK" },
  { id: "Hungary", key: "Hungary", label: "Hungary", value: "HU" },
  { id: "Iceland", key: "Iceland", label: "Iceland", value: "IS" },
  { id: "India", key: "India", label: "India", value: "IN" },
  { id: "Indonesia", key: "Indonesia", label: "Indonesia", value: "ID" },
  {
    id: "Iran, Islamic Republic Of",
    key: "Iran, Islamic Republic Of",
    label: "Iran, Islamic Republic Of",
    value: "IR",
  },
  { id: "Iraq", key: "Iraq", label: "Iraq", value: "IQ" },
  { id: "Ireland", key: "Ireland", label: "Ireland", value: "IE" },
  { id: "Isle of Man", key: "Isle of Man", label: "Isle of Man", value: "IM" },
  { id: "Israel", key: "Israel", label: "Israel", value: "IL" },
  { id: "Italy", key: "Italy", label: "Italy", value: "IT" },
  { id: "Ivory Coast", key: "Ivory Coast", label: "Ivory Coast", value: "CI" },
  { id: "Jamaica", key: "Jamaica", label: "Jamaica", value: "JM" },
  { id: "Japan", key: "Japan", label: "Japan", value: "JP" },
  { id: "Jersey", key: "Jersey", label: "Jersey", value: "JE" },
  { id: "Jordan", key: "Jordan", label: "Jordan", value: "JO" },
  { id: "Kazakhstan", key: "Kazakhstan", label: "Kazakhstan", value: "KZ" },
  { id: "Kenya", key: "Kenya", label: "Kenya", value: "KE" },
  { id: "Kiribati", key: "Kiribati", label: "Kiribati", value: "KI" },
  {
    id: 'Korea, Democratic People"S Republic of',
    key: 'Korea, Democratic People"S Republic of',
    label: 'Korea, Democratic People"S Republic of',
    value: "KP",
  },
  {
    id: "Korea, Republic of",
    key: "Korea, Republic of",
    label: "Korea, Republic of",
    value: "KR",
  },
  { id: "Kuwait", key: "Kuwait", label: "Kuwait", value: "KW" },
  { id: "Kyrgyzstan", key: "Kyrgyzstan", label: "Kyrgyzstan", value: "KG" },
  {
    id: 'Lao People"S Democratic Republic',
    key: 'Lao People"S Democratic Republic',
    label: 'Lao People"S Democratic Republic',
    value: "LA",
  },
  { id: "Laos", key: "Laos", label: "Laos", value: "LA" },
  { id: "Latvia", key: "Latvia", label: "Latvia", value: "LV" },
  { id: "Lebanon", key: "Lebanon", label: "Lebanon", value: "LB" },
  { id: "Lesotho", key: "Lesotho", label: "Lesotho", value: "LS" },
  { id: "Liberia", key: "Liberia", label: "Liberia", value: "LR" },
  {
    id: "Libyan Arab Jamahiriya",
    key: "Libyan Arab Jamahiriya",
    label: "Libyan Arab Jamahiriya",
    value: "LY",
  },
  {
    id: "Liechtenstein",
    key: "Liechtenstein",
    label: "Liechtenstein",
    value: "LI",
  },
  { id: "Lithuania", key: "Lithuania", label: "Lithuania", value: "LT" },
  { id: "Luxembourg", key: "Luxembourg", label: "Luxembourg", value: "LU" },
  { id: "Macao", key: "Macao", label: "Macao", value: "MO" },
  {
    id: "Macedonia, The Former Yugoslav Republic of",
    key: "Macedonia, The Former Yugoslav Republic of",
    label: "Macedonia, The Former Yugoslav Republic of",
    value: "MK",
  },
  { id: "Madagascar", key: "Madagascar", label: "Madagascar", value: "MG" },
  { id: "Malawi", key: "Malawi", label: "Malawi", value: "MW" },
  { id: "Malaysia", key: "Malaysia", label: "Malaysia", value: "MY" },
  { id: "Maldives", key: "Maldives", label: "Maldives", value: "MV" },
  { id: "Mali", key: "Mali", label: "Mali", value: "ML" },
  { id: "Malta", key: "Malta", label: "Malta", value: "MT" },
  {
    id: "Marshall Islands",
    key: "Marshall Islands",
    label: "Marshall Islands",
    value: "MH",
  },
  { id: "Martinique", key: "Martinique", label: "Martinique", value: "MQ" },
  { id: "Mauritania", key: "Mauritania", label: "Mauritania", value: "MR" },
  { id: "Mauritius", key: "Mauritius", label: "Mauritius", value: "MU" },
  { id: "Mayotte", key: "Mayotte", label: "Mayotte", value: "YT" },
  { id: "Mexico", key: "Mexico", label: "Mexico", value: "MX" },
  {
    id: "Micronesia, Federated States of",
    key: "Micronesia, Federated States of",
    label: "Micronesia, Federated States of",
    value: "FM",
  },
  {
    id: "Moldova, Republic of",
    key: "Moldova, Republic of",
    label: "Moldova, Republic of",
    value: "MD",
  },
  { id: "Monaco", key: "Monaco", label: "Monaco", value: "MC" },
  { id: "Mongolia", key: "Mongolia", label: "Mongolia", value: "MN" },
  { id: "Montenegro", key: "Montenegro", label: "Montenegro", value: "ME" },
  { id: "Montserrat", key: "Montserrat", label: "Montserrat", value: "MS" },
  { id: "Morocco", key: "Morocco", label: "Morocco", value: "MA" },
  { id: "Mozambique", key: "Mozambique", label: "Mozambique", value: "MZ" },
  { id: "Myanmar", key: "Myanmar", label: "Myanmar", value: "MM" },
  { id: "Namibia", key: "Namibia", label: "Namibia", value: "NA" },
  { id: "Nauru", key: "Nauru", label: "Nauru", value: "NR" },
  { id: "Nepal", key: "Nepal", label: "Nepal", value: "NP" },
  { id: "Netherlands", key: "Netherlands", label: "Netherlands", value: "NL" },
  {
    id: "Netherlands Antilles",
    key: "Netherlands Antilles",
    label: "Netherlands Antilles",
    value: "AN",
  },
  {
    id: "New Caledonia",
    key: "New Caledonia",
    label: "New Caledonia",
    value: "NC",
  },
  { id: "New Zealand", key: "New Zealand", label: "New Zealand", value: "NZ" },
  { id: "Nicaragua", key: "Nicaragua", label: "Nicaragua", value: "NI" },
  { id: "Niger", key: "Niger", label: "Niger", value: "NE" },
  { id: "Nigeria", key: "Nigeria", label: "Nigeria", value: "NG" },
  { id: "Niue", key: "Niue", label: "Niue", value: "NU" },
  {
    id: "Norfolk Island",
    key: "Norfolk Island",
    label: "Norfolk Island",
    value: "NF",
  },
  {
    id: "Northern Mariana Islands",
    key: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
    value: "MP",
  },
  { id: "Norway", key: "Norway", label: "Norway", value: "NO" },
  { id: "Oman", key: "Oman", label: "Oman", value: "OM" },
  { id: "Pakistan", key: "Pakistan", label: "Pakistan", value: "PK" },
  { id: "Palau", key: "Palau", label: "Palau", value: "PW" },
  {
    id: "Palestinian Territory, Occupied",
    key: "Palestinian Territory, Occupied",
    label: "Palestinian Territory, Occupied",
    value: "PS",
  },
  { id: "Panama", key: "Panama", label: "Panama", value: "PA" },
  {
    id: "Papua New Guinea",
    key: "Papua New Guinea",
    label: "Papua New Guinea",
    value: "PG",
  },
  { id: "Paraguay", key: "Paraguay", label: "Paraguay", value: "PY" },
  { id: "Peru", key: "Peru", label: "Peru", value: "PE" },
  { id: "Philippines", key: "Philippines", label: "Philippines", value: "PH" },
  { id: "Pitcairn", key: "Pitcairn", label: "Pitcairn", value: "PN" },
  { id: "Poland", key: "Poland", label: "Poland", value: "PL" },
  { id: "Portugal", key: "Portugal", label: "Portugal", value: "PT" },
  { id: "Puerto Rico", key: "Puerto Rico", label: "Puerto Rico", value: "PR" },
  { id: "Qatar", key: "Qatar", label: "Qatar", value: "QA" },
  { id: "RWANDA", key: "RWANDA", label: "RWANDA", value: "RW" },
  { id: "Reunion", key: "Reunion", label: "Reunion", value: "RE" },
  { id: "Romania", key: "Romania", label: "Romania", value: "RO" },
  {
    id: "Russian Federation",
    key: "Russian Federation",
    label: "Russian Federation",
    value: "RU",
  },
  {
    id: "Saint Barthelemy",
    key: "Saint Barthelemy",
    label: "Saint Barthelemy",
    value: "BL",
  },
  {
    id: "Saint Helena",
    key: "Saint Helena",
    label: "Saint Helena",
    value: "SH",
  },
  {
    id: "Saint Kitts and Nevis",
    key: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
    value: "KN",
  },
  { id: "Saint Lucia", key: "Saint Lucia", label: "Saint Lucia", value: "LC" },
  {
    id: "Saint Martin",
    key: "Saint Martin",
    label: "Saint Martin",
    value: "MF",
  },
  {
    id: "Saint Pierre and Miquelon",
    key: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
    value: "PM",
  },
  {
    id: "Saint Vincent and the Grenadines",
    key: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
    value: "VC",
  },
  { id: "Samoa", key: "Samoa", label: "Samoa", value: "WS" },
  { id: "San Marino", key: "San Marino", label: "San Marino", value: "SM" },
  {
    id: "Sao Tome and Principe",
    key: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
    value: "ST",
  },
  {
    id: "Saudi Arabia",
    key: "Saudi Arabia",
    label: "Saudi Arabia",
    value: "SA",
  },
  { id: "Senegal", key: "Senegal", label: "Senegal", value: "SN" },
  { id: "Serbia", key: "Serbia", label: "Serbia", value: "RS" },
  { id: "Seychelles", key: "Seychelles", label: "Seychelles", value: "SC" },
  {
    id: "Sierra Leone",
    key: "Sierra Leone",
    label: "Sierra Leone",
    value: "SL",
  },
  { id: "Singapore", key: "Singapore", label: "Singapore", value: "SG" },
  {
    id: "Sint Maarten",
    key: "Sint Maarten",
    label: "Sint Maarten",
    value: "SX",
  },
  { id: "Slovakia", key: "Slovakia", label: "Slovakia", value: "SK" },
  { id: "Slovenia", key: "Slovenia", label: "Slovenia", value: "SI" },
  {
    id: "Solomon Islands",
    key: "Solomon Islands",
    label: "Solomon Islands",
    value: "SB",
  },
  { id: "Somalia", key: "Somalia", label: "Somalia", value: "SO" },
  {
    id: "South Africa",
    key: "South Africa",
    label: "South Africa",
    value: "ZA",
  },
  {
    id: "South Georgia and the South Sandwich Islands",
    key: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
    value: "GS",
  },
  { id: "South Sudan", key: "South Sudan", label: "South Sudan", value: "SS" },
  { id: "Spain", key: "Spain", label: "Spain", value: "ES" },
  { id: "Sri Lanka", key: "Sri Lanka", label: "Sri Lanka", value: "LK" },
  { id: "Sudan", key: "Sudan", label: "Sudan", value: "SD" },
  { id: "Surilabel", key: "Surilabel", label: "Surilabel", value: "SR" },
  {
    id: "Svalbard and Jan Mayen",
    key: "Svalbard and Jan Mayen",
    label: "Svalbard and Jan Mayen",
    value: "SJ",
  },
  { id: "Swaziland", key: "Swaziland", label: "Swaziland", value: "SZ" },
  { id: "Sweden", key: "Sweden", label: "Sweden", value: "SE" },
  { id: "Switzerland", key: "Switzerland", label: "Switzerland", value: "CH" },
  {
    id: "Syrian Arab Republic",
    key: "Syrian Arab Republic",
    label: "Syrian Arab Republic",
    value: "SY",
  },
  { id: "Taiwan", key: "Taiwan", label: "Taiwan", value: "TW" },
  { id: "Tajikistan", key: "Tajikistan", label: "Tajikistan", value: "TJ" },
  {
    id: "Tanzania, United Republic of",
    key: "Tanzania, United Republic of",
    label: "Tanzania, United Republic of",
    value: "TZ",
  },
  { id: "Thailand", key: "Thailand", label: "Thailand", value: "TH" },
  { id: "Timor-Leste", key: "Timor-Leste", label: "Timor-Leste", value: "TL" },
  { id: "Togo", key: "Togo", label: "Togo", value: "TG" },
  { id: "Tokelau", key: "Tokelau", label: "Tokelau", value: "TK" },
  { id: "Tonga", key: "Tonga", label: "Tonga", value: "TO" },
  {
    id: "Trinidad and Tobago",
    key: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
    value: "TT",
  },
  { id: "Tunisia", key: "Tunisia", label: "Tunisia", value: "TN" },
  { id: "Turkey", key: "Turkey", label: "Turkey", value: "TR" },
  {
    id: "Turkmenistan",
    key: "Turkmenistan",
    label: "Turkmenistan",
    value: "TM",
  },
  {
    id: "Turks and Caicos Islands",
    key: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
    value: "TC",
  },
  { id: "Tuvalu", key: "Tuvalu", label: "Tuvalu", value: "TV" },
  { id: "Uganda", key: "Uganda", label: "Uganda", value: "UG" },
  { id: "Ukraine", key: "Ukraine", label: "Ukraine", value: "UA" },
  {
    id: "United Arab Emirates",
    key: "United Arab Emirates",
    label: "United Arab Emirates",
    value: "AE",
  },
  {
    id: "United Kingdom",
    key: "United Kingdom",
    label: "United Kingdom",
    value: "GB",
  },
  {
    id: "United States",
    key: "United States",
    label: "United States",
    value: "US",
  },
  {
    id: "United States Minor Outlying Islands",
    key: "United States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
    value: "UM",
  },
  { id: "Uruguay", key: "Uruguay", label: "Uruguay", value: "UY" },
  { id: "Uzbekistan", key: "Uzbekistan", label: "Uzbekistan", value: "UZ" },
  { id: "Vanuatu", key: "Vanuatu", label: "Vanuatu", value: "VU" },
  { id: "Venezuela", key: "Venezuela", label: "Venezuela", value: "VE" },
  { id: "Viet Nam", key: "Viet Nam", label: "Viet Nam", value: "VN" },
  { id: "Vietnam", key: "Vietnam", label: "Vietnam", value: "VN" },
  {
    id: "Virgin Islands, British",
    key: "Virgin Islands, British",
    label: "Virgin Islands, British",
    value: "VG",
  },
  {
    id: "Virgin Islands, U.S.",
    key: "Virgin Islands, U.S.",
    label: "Virgin Islands, U.S.",
    value: "VI",
  },
  {
    id: "Wallis and Futuna",
    key: "Wallis and Futuna",
    label: "Wallis and Futuna",
    value: "WF",
  },
  {
    id: "Western Sahara",
    key: "Western Sahara",
    label: "Western Sahara",
    value: "EH",
  },
  { id: "Yemen", key: "Yemen", label: "Yemen", value: "YE" },
  { id: "Zambia", key: "Zambia", label: "Zambia", value: "ZM" },
  { id: "Zimbabwe", key: "Zimbabwe", label: "Zimbabwe", value: "ZW" },
  {
    id: "Åland Islands",
    key: "Åland Islands",
    label: "Åland Islands",
    value: "AX",
  },
];

export const genderIdentify = [
 
  {
    id: "M",
    key: "Male",
    label: "Male",
    value: "M",
  },
  {
    id: "F",
    key: "Female",
    label: "Female",
    value: "F",
  },
  {
    id: "NB",
    key: "Non-binary",
    label: "Non-binary",
    value: "NB",
  },
];

export const relationShipsOptions = [
 
  {
    key: "Spouse/Partner",
    id: "Spouse/Partner",
    label: "Spouse/Partner",
    value: "Spouse/Partner",
  },
  {
    key: "Child/Grandchild",
    id: "Child/Grandchild",
    label: "Child/Grandchild",
    value: "Child/Grandchild",
  },
  {
    key: "Parent/Guardian",
    id: "Parent/Guardian",
    label: "Parent/Guardian",
    value: "Parent/Guardian",
  },
  {
    key: "Friend",
    id: "Friend",
    label: "Friend",
    value: "Friend",
  },
  {
    key: "Other",
    id: "Other",
    label: "Other",
    value: "Other",
  },
];

export const transferOptions = [
  {
    id: "name",
    label: "Name",
    value: false,
  },
  {
    id: "dateOfBirth",
    label: "Date of birth",
    value: false,
  },
  {
    id: "address",
    label: "Address",
    value: false,
  },
  {
    id: "medicare",
    label: "Medicare #",
    value: false,
  },
];

export const traumaDetails = [
  {
    id: "head",
    label: "Head",
    value: "head",
  },
  {
    id: "chest",
    label: "Chest",
    value: "chest",
  },
  {
    id: "abodmen",
    label: "Abodmen",
    value: "adodmen",
  },
  {
    id: "extremity",
    label: "Extremity",
    value: "extremity",
  },
  {
    id: "multiSystem",
    label: "Multi - System",
    value: "multiSystem",
  },
];

export const interfacilityTransfer = [

  {
    id: "Healthcare Appointment",
    label: "01- Healthcare Appointment",
    key: "Healthcare Appointment",
    value: "Healthcare Appointment",
  },
  {
    id: "Diagnostic Test",
    label: "02- Diagnostic Test",
    key: "Diagnostic Test",
    value: "Diagnostic Test",
  },
  { id: "EENT", label: "03- EENT", key: "EENT", value: "EENT" },
  {
    id: "Psychiatric",
    label: "04- Psychiatric",
    key: "Psychiatric",
    value: "Psychiatric",
  },
  {
    id: "Infectious",
    label: "05- Infectious",
    key: "Infectious",
    value: "Infectious",
  },
  {
    id: "Metabolic",
    label: "06- Metabolic",
    key: "Metabolic",
    value: "Metabolic",
  },
  {
    id: "Gastrointestinal",
    label: "07- Gastrointestinal",
    key: "Gastrointestinal",
    value: "Gastrointestinal",
  },
  {
    id: "Gynecology",
    label: "08- Gynecology",
    key: "Gynecology",
    value: "Gynecology",
  },
  {
    id: "SeObstetricslect",
    label: "09- Obstetrics",
    key: "Obstetrics",
    value: "Obstetrics",
  },
  {
    id: "Cardiovascular",
    label: "10- Cardiovascular",
    key: "Cardiovascular",
    value: "Cardiovascular",
  },
  {
    id: "Respiratory",
    label: "11- Respiratory",
    key: "Respiratory",
    value: "Respiratory",
  },
  {
    id: "Neurology",
    label: "12- Neurology",
    key: "Neurology",
    value: "Neurology",
  },
  {
    id: "Orthopedics (non-trauma)",
    label: "13- Orthopedics (non-trauma)",
    key: "Orthopedics (non-trauma)",
    value: "Orthopedics (non-trauma)",
  },
  { id: "Trauma", label: "14- Trauma", key: "Trauma", value: "Trauma" },
  { id: "Other", label: "15- Other", key: "Other", value: "Other" },
];

export const provinceList = [
  {
    id: "AB- Alberta",
    label: "AB- Alberta",
    key: "AB- Alberta",
    value: "AB",
  },
  {
    id: "BC- British Columbia",
    label: "BC- British Columbia",
    key: "BC- British Columbia",
    value: "BC",
  },
  {
    id: "MB- Manitob",
    label: "MB- Manitob",
    key: "MB- Manitoba",
    value: "MB",
  },
  {
    id: "NB- New Brunswick",
    label: "NB- New Brunswick",
    key: "NB- New Brunswick",
    value: "NB",
  },
  {
    id: "NL- Newfoundland and Labrador",
    label: "NL- Newfoundland and Labrador",
    key: "NL- Newfoundland and Labrador",
    value: "NL",
  },
  {
    id: "NS- Nova Scotia",
    label: "NS- Nova Scotia",
    key: "NS- Nova Scotia",
    value: "NS",
  },
  {
    id: "ON- Ontario",
    label: "ON- Ontario",
    key: "ON- Ontario",
    value: "ON",
  },

  {
    id: "PE- Prince Edward Island",
    label: "PE- Prince Edward Island",
    key: "PE- Prince Edward Island",
    value: "PE",
  },
  {
    id: "QC- Quebec",
    label: "QC- Quebec",
    key: "QC- Quebec",
    value: "QC",
  },
  {
    id: "SK- Saskatchewan",
    label: "SK- Saskatchewan",
    key: "SK- Saskatchewan",
    value: "SK",
  },
  {
    id: "YT- Yukon",
    label: "YT- Yukon",
    key: "YT- Yukon",
    value: "YT",
  },
  {
    id: "NU- Nunavut",
    label: "NU- Nunavut",
    key: " NU- Nunavut",
    value: "NU",
  },
  {
    id: "NT- Northwest Territories",
    label: "NT- Northwest Territories",
    key: "NT- Northwest Territories",
    value: "NT",
  },
  {
    id: "Unknown",
    label: "Unknown",
    key: "Unknown",
    value: "Unknown",
  },
];

export const pupilOptions = [
  {
    key: "r",
    id: "r",
    label: "R",
    value: "r",
  },
  {
    key: "nr",
    id: "nr",
    label: "NR",
    value: "nr",
  },
];

export const eyeOptions = [
  {
    key: "nt",
    id: "nt",
    label: "NT",
    value: "nt",
  },
  {
    key: "1",
    id: "1",
    label: "1",
    value: "1",
  },
  {
    key: "2",
    id: "2",
    label: "2",
    value: "2",
  },
  {
    key: "3",
    id: "3",
    label: "3",
    value: "3",
  },
  {
    key: "4",
    id: "4",
    label: "4",
    value: "4",
  },
]

export const armOptions = [
  {id: 'Left',key: 'Left',label: 'Left',value: 'Left'},
  {id: 'Right',key: 'Right',label: 'Right',value: 'Right'}
]

export const verbalOptions = [
  {
    key: "nt",
    id: "nt",
    label: "NT",
    value: "nt",
  },
  {
    key: "1",
    id: "1",
    label: "1",
    value: "1",
  },
  {
    key: "2",
    id: "2",
    label: "2",
    value: "2",
  },
  {
    key: "3",
    id: "3",
    label: "3",
    value: "3",
  },
  {
    key: "4",
    id: "4",
    label: "4",
    value: "4",
  },
  {
    key: "5",
    id: "5",
    label: "5",
    value: "5",
  },
]

export const motorOptions = [
  {
    key: "nt",
    id: "nt",
    label: "NT",
    value: "nt",
  },
  {
    key: "1",
    id: "1",
    label: "1",
    value: "1",
  },
  {
    key: "2",
    id: "2",
    label: "2",
    value: "2",
  },
  {
    key: "3",
    id: "3",
    label: "3",
    value: "3",
  },
  {
    key: "4",
    id: "4",
    label: "4",
    value: "4",
  },
  {
    key: "5",
    id: "5",
    label: "5",
    value: "5",
  },
  {
    key: "6",
    id: "6",
    label: "6",
    value: "6",
  },
]

export const sexAtBirthDetails = [
  {
    key: "Male",
    id: "Male",
    label: "Male",
    value: "Male",
  },
  {
    key: "Female",
    id: "Female",
    label: "Female",
    value: "Female",
  },
];

export const softValidateOptions = [

  {
    key: "Patient unable to locate information",
    id: "Patient unable to locate information",
    label: "Patient unable to locate information",
    value: "Patient unable to locate information"
  },
  {
    key: "Patient unable to provide (example: unresponsive)",
    id: "Patient unable to provide (example: unresponsive)",
    label: "Patient unable to provide (example: unresponsive)",
    value: "Patient unable to provide (example: unresponsive)"
  },
  {
    key: "Practitioner error",
    id: "Practitioner error",
    label: "Practitioner error",
    value: "Practitioner error"
  },
  {
    key: "Information does't exist",
    id: "Information does't exist",
    label: "Information does't exist",
    value: "Information doesn't exist"
  },
  {
    label: "Other",
    value: "Other",
    key: "Other",
    id: "Other"
  }
];

export const sceneLocationOptions = [
  {
    key: "01- Hospital",
    id: "01- Hospital",
    label: "01- Hospital",
    value: "01- Hospital",
  },
  {
    key: "02- Extra-mural",
    id: "02- Extra-mural",
    label: "02- Extra-mural",
    value: "02- Extra-mural",
  },
  {
    key: "03- Nursing home",
    id: "03- Nursing home",
    label: "03- Nursing home",
    value: "03- Nursing home",
  },
  {
    key: "04- Doctor's office",
    id: "04- Doctor's office",
    label: "04- Doctor's office",
    value: "04- Doctor's office",
  },
  {
    key: "05- Airport",
    id: "05- Airport",
    label: "05- Airport",
    value: "05- Airport",
  },
  {
    key: "06- Jobsite/Industrial",
    id: "06- Jobsite/Industrial",
    label: "06- Jobsite/Industrial",
    value: "06- Jobsite/Industrial",
  },
  {
    key: "07- Highway/road",
    id: "07- Highway/road",
    label: "07- Highway/road",
    value: "07- Highway/road",
  },
  {
    key: "08- Residence",
    id: "08- Residence",
    label: "08- Residence",
    value: "08- Residence",
  },
  {
    id: "09- Recreation facility/site",
    key: "09- Recreation facility/site",
    label: "09- Recreation facility/site",
    value: "09- Recreation facility/site",
  },
  {
    key: "10- Farm",
    id: "10- Farm",
    label: "10- Farm",
    value: "10- Farm",
  },
  {
    key: "11- Public Building",
    id: "11- Public Building",
    label: "11- Public Building",
    value: "11- Public Building",
  },
  {
    key: "12- School/Daycare",
    id: "12- School/Daycare",
    value: "12- School/Daycare",
    label: "12- School/Daycare",
  },
  {
    key: "13- Other",
    id: "13- Other",
    value: "13- Other",
    label: "13- Other",
  },
];

export const ageUnitOptions = [
  {
    label: "Y",
    key: "Years",
    id: "Years",
    value: "Y",
  },
  {
    label: "M",
    key: "Months",
    id: "Months",
    value: "M",
  },
  {
    label: "D",
    key: "Days",
    id: "Days",
    value: "D",
  },
];

export const zeroPatientAssessedOptions = [
  {
    id: "Patient refusal",
    label: "Patient refusal ",
    value: "Patient refusal",
    info: "patientAssessedpr",
  },
  {
    id: "Transported by other",
    label: "Transported by other",
    value: "Transported by other",
    info: "patientAssessedtbo",
  },

  {
    id: "No patient found",
    label: "No patient found",
    value: "No patient found",
    info: "patientAssessednpf",
  },
  {
    id: "Supporting Crew Intercept",
    label: "Supporting Crew Intercept",
    value: "Supporting Crew Intercept",
    info: "patientAssessedsci",
  },
];

export const zeroPatientOptions = [
  {
    id: "No patient found",
    label: "No patient found",
    value: "No patient found",
    info: "npPatient",
  },

  {
    id: "DOA",
    label: "DOA",
    value: "DOA",
    info: "doa",
  },
  {
    id: "Supporting crew intercept",
    label: "Supporting crew intercept",
    value: "Supporting crew intercept",
    info: "supportingCrew",
  },
  {
    id: "Patient refusal",
    label: "Patient refusal ",
    value: "Patient refusal",
    info: "patientRefusal",
  },
  {
    id: "CoRE",
    label: "CoRE",
    value: "CoRE",
    info: "core",
    cls: "m-l-14",
  },

  {
    id: "Transported by other",
    label: "Transported by other",
    value: "Transported by other",
    info: "transportother",
    cls: "",
  },
  {
    id: "Pre-ALRT",
    label: "Pre-ALRT",
    value: "Pre-ALRT",
    info: "prealrt",
  },

];

export const agencyNameList = [
  {
    label: "RCMP",
    value: "RCMP",
  },
  {
    label: " Police",
    value: "Police",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const yesOrNoRadioOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const codeToFromOptions = [
  {
    label: "Code 1",
    value: "1",
  },
  {
    label: "Code 2",
    value: "2",
  },
];

export const protocalGuideline = [...sceneLocationOptions];

export const yesOrNoOptions = ["Yes", "No"];

export const regExValidations = {
  POSTALCODE_CA_REG: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] \d[ABCEGHJ-NPRSTV-Z]\d$/i,
  TELEPHONE_CA_REG: /^(?:\+?1)?[-.\s]?\(?(?:[2-9][0-9]{2})\)?[-.\s]?[2-9][0-9]{2}[-.\s]?[0-9]{4}$/,
  TELEPHONE_REG: /^\d{1,10}$/,
  //works for YYYY-MM-DD Format
  DATE_REG: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2]\d|3[01])$/,
  //both english and french
  ALPHABETICAL_REG: /^[A-Za-zÀ-ÿ]+$/,
  ONLY_NUMERIC:/^[1-9]\d*$/,
  CTAS_REG:  /^[1-5]$/,
  ONLY_NUMERIC_MAX_100: /^(100|[1-9]?[0-9])$/,
  ONLY_NUMERIC_MAX_10:/^([0-9]|10)$/,
  BP_REG: /^\d{2,3}(\/\d{2,3})?$/,
  GLUCOSE_REG: /^(?:\d+(\.\d{1})?|low|high)$/i,
  TEMPARATURE_REG: /^(0|[1-9]\d*)(\.\d+)?$/,
  // New regex constants that include NT/nt alongside existing validations
  NUMERIC_OR_NT: /^(NT|nt|[1-9]\d*)$/i,
  ONLY_NUMERIC_MAX_100_OR_NT: /^(100|[1-9]?[0-9]|NT|nt)$/i,
  ONLY_NUMERIC_MAX_10_OR_NT: /^([0-9]|10|NT|nt)$/i,
  BP_REG_OR_NT: /^(NT|nt|\d{2,3}(\/\d{2,3})?)$/i,
  TEMPERATURE_REG_OR_NT: /^(NT|nt|0|[1-9]\d*)(\.\d+)?$/i,
  GLUCOSE_REG_OR_NT: /^(NT|nt|\d+(\.\d{1})?|low|high)$/i
}

export const protocolNums = [
  {
    "id": "1000- Airway Management (Adult/Pediatric)",
    "label": "1000- Airway Management (Adult/Pediatric)",
    "value": "1000- Airway Management (Adult/Pediatric)"
  },
  {
    "id": "1005- Cardiac Arrest (Pediatric)",
    "label": "1005- Cardiac Arrest (Pediatric)",
    "value": "1005- Cardiac Arrest (Pediatric)"
  },
  {
    "id": "1010- Foreign Body Obstruction (Adult/Pediatric)",
    "label": "1010- Foreign Body Obstruction (Adult/Pediatric)",
    "value": "1010- Foreign Body Obstruction (Adult/Pediatric)"
  },
  {
    "id": "1015- Near Hanging (Adult/Pediatric)",
    "label": "1015- Near Hanging (Adult/Pediatric)",
    "value": "1015- Near Hanging (Adult/Pediatric)"
  },
  {
    "id": "1020- Anaphylaxis (Adult/Pediatric)",
    "label": "1020- Anaphylaxis (Adult/Pediatric)",
    "value": "1020- Anaphylaxis (Adult/Pediatric)"
  },
  {
    "id": "1025- Allergic Reaction (Adult/Pediatric)",
    "label": "1025- Allergic Reaction (Adult/Pediatric)",
    "value": "1025- Allergic Reaction (Adult/Pediatric)"
  },
  {
    "id": "1030- Sepsis (Adult/Pediatric)",
    "label": "1030- Sepsis (Adult/Pediatric)",
    "value": "1030- Sepsis (Adult/Pediatric)"
  },
  {
    "id": "1035- ALOC – NYD (Adult/Pediatric)",
    "label": "1035- ALOC – NYD (Adult/Pediatric)",
    "value": "1035- ALOC – NYD (Adult/Pediatric)"
  },
  {
    "id": "1040- Hypoglycemia (Adult)",
    "label": "1040- Hypoglycemia (Adult)",
    "value": "1040- Hypoglycemia (Adult)"
  },
  {
    "id": "1045- Overdose/Poisoning (Adult)",
    "label": "1045- Overdose/Poisoning (Adult)",
    "value": "1045- Overdose/Poisoning (Adult)"
  },
  {
    "id": "1050- Seizure (Adult/Pediatric)",
    "label": "1050- Seizure (Adult/Pediatric)",
    "value": "1050- Seizure (Adult/Pediatric)"
  },
  {
    "id": "1055- Burns - Fire/Flame (Adult/Pediatric)",
    "label": "1055- Burns - Fire/Flame (Adult/Pediatric)",
    "value": "1055- Burns - Fire/Flame (Adult/Pediatric)"
  },
  {
    "id": "1060- Chemical Splash/Burn (Adult/Pediatric)",
    "label": "1060- Chemical Splash/Burn (Adult/Pediatric)",
    "value": "1060- Chemical Splash/Burn (Adult/Pediatric)"
  },
  {
    "id": "1065- Electrocution/Electrical Burns/Lightning (Adult/Pediatric)",
    "label": "1065- Electrocution/Electrical Burns/Lightning (Adult/Pediatric)",
    "value": "1065- Electrocution/Electrical Burns/Lightning (Adult/Pediatric)"
  },
  {
    "id": "1070- Hyperglycemia",
    "label": "1070- Hyperglycemia",
    "value": "1070- Hyperglycemia"
  },
  {
    "id": "1075- Smoke/CO Inhalation (Adult/Pediatric)",
    "label": "1075- Smoke/CO Inhalation (Adult/Pediatric)",
    "value": "1075- Smoke/CO Inhalation (Adult/Pediatric)"
  },
  {
    "id": "1080- Cardiac Arrest (Adult)",
    "label": "1080- Cardiac Arrest (Adult)",
    "value": "1080- Cardiac Arrest (Adult)"
  },
  {
    "id": "1081- Return of Spontaneous Circulation (Adult/Pediatric)",
    "label": "1081- Return of Spontaneous Circulation (Adult/Pediatric)",
    "value": "1081- Return of Spontaneous Circulation (Adult/Pediatric)"
  },
  {
    "id": "1085- Acute Coronary Syndrome (Adult)",
    "label": "1085- Acute Coronary Syndrome (Adult)",
    "value": "1085- Acute Coronary Syndrome (Adult)"
  },
  {
    "id": "1086- Chest Pain – Suspected Non-Ischemic Origin (Adult)",
    "label": "1086- Chest Pain – Suspected Non-Ischemic Origin (Adult)",
    "value": "1086- Chest Pain – Suspected Non-Ischemic Origin (Adult)"
  },
  {
    "id": "1090- Epistaxis (Adult/Pediatric)",
    "label": "1090- Epistaxis (Adult/Pediatric)",
    "value": "1090- Epistaxis (Adult/Pediatric)"
  },
  {
    "id": "1095- Foreign Body/Welders Flash/ Chemical Splash Eye (Adult/Pediatric)",
    "label": "1095- Foreign Body/Welders Flash/ Chemical Splash Eye (Adult/Pediatric)",
    "value": "1095- Foreign Body/Welders Flash/ Chemical Splash Eye (Adult/Pediatric)"
  },
  {
    "id": "1100- Ocular Trauma (Adult/Pediatric)",
    "label": "1100- Ocular Trauma (Adult/Pediatric)",
    "value": "1100- Ocular Trauma (Adult/Pediatric)"
  },
  {
    "id": "1110- Tooth Avulsion (Adult/Pediatric)",
    "label": "1110- Tooth Avulsion (Adult/Pediatric)",
    "value": "1110- Tooth Avulsion (Adult/Pediatric)"
  },
  {
    "id": "1115- Decompression Illness (Adult/Pediatric)",
    "label": "1115- Decompression Illness (Adult/Pediatric)",
    "value": "1115- Decompression Illness (Adult/Pediatric)"
  },
  {
    "id": "1120- Hyperthermia (Adult/Pediatric)",
    "label": "1120- Hyperthermia (Adult/Pediatric)",
    "value": "1120- Hyperthermia (Adult/Pediatric)"
  },
  {
    "id": "1125- Hypothermia (Adult/Pediatric)",
    "label": "1125- Hypothermia (Adult/Pediatric)",
    "value": "1125- Hypothermia (Adult/Pediatric)"
  },
  {
    "id": "1135- Near Drowning (Adult/Pediatric)",
    "label": "1135- Near Drowning (Adult/Pediatric)",
    "value": "1135- Near Drowning (Adult/Pediatric)"
  },
  {
    "id": "1140- Abdominal Pain/Flank Pain (Adult/Pediatric)",
    "label": "1140- Abdominal Pain/Flank Pain (Adult/Pediatric)",
    "value": "1140- Abdominal Pain/Flank Pain (Adult/Pediatric)"
  },
  {
    "id": "1145- Nausea/Vomiting/Diarrhea (Adult/Pediatric)",
    "label": "1145- Nausea/Vomiting/Diarrhea (Adult/Pediatric)",
    "value": "1145- Nausea/Vomiting/Diarrhea (Adult/Pediatric)"
  },
  {
    "id": "1150- PV Bleed/Threatened Abortion (Adult)",
    "label": "1150- PV Bleed/Threatened Abortion (Adult)",
    "value": "1150- PV Bleed/Threatened Abortion (Adult)"
  },
  {
    "id": "1155- Gross Hematuria (Adult/Pediatric)",
    "label": "1155- Gross Hematuria (Adult/Pediatric)",
    "value": "1155- Gross Hematuria (Adult/Pediatric)"
  },
  {
    "id": "1160- GI Bleed (Adult/Pediatric)",
    "label": "1160- GI Bleed (Adult/Pediatric)",
    "value": "1160- GI Bleed (Adult/Pediatric)"
  },
  {
    "id": "1165- Headache (Adult/Pediatric)",
    "label": "1165- Headache (Adult/Pediatric)",
    "value": "1165- Headache (Adult/Pediatric)"
  },
  {
    "id": "1170- Syncope (Adult/Pediatric)",
    "label": "1170- Syncope (Adult/Pediatric)",
    "value": "1170- Syncope (Adult/Pediatric)"
  },
  {
    "id": "1175- Vertigo/Dizziness (Adult/Pediatric)",
    "label": "1175- Vertigo/Dizziness (Adult/Pediatric)",
    "value": "1175- Vertigo/Dizziness (Adult/Pediatric)"
  },
  {
    "id": "1180- Weakness/Fatigue (Adult/Pediatric)",
    "label": "1180- Weakness/Fatigue (Adult/Pediatric)",
    "value": "1180- Weakness/Fatigue (Adult/Pediatric)"
  },
  {
    "id": "1186- Foreign Body Obstruction (Infant)",
    "label": "1186- Foreign Body Obstruction (Infant)",
    "value": "1186- Foreign Body Obstruction (Infant)"
  },
  {
    "id": "1200- Hypoglycemia (Pediatric)",
    "label": "1200- Hypoglycemia (Pediatric)",
    "value": "1200- Hypoglycemia (Pediatric)"
  },
  {
    "id": "1205- Overdose/Poisoning (Pediatric)",
    "label": "1205- Overdose/Poisoning (Pediatric)",
    "value": "1205- Overdose/Poisoning (Pediatric)"
  },
  {
    "id": "1220- Bradycardia (Pediatric)",
    "label": "1220- Bradycardia (Pediatric)",
    "value": "1220- Bradycardia (Pediatric)"
  },  
  {
    "id": "1225- Bradycardia (Adult)",
    "label": "1225- Bradycardia (Adult)",
    "value": "1225- Bradycardia (Adult)"
  },
  {
    "id": "1230- Stridor (Pediatric)",
    "label": "1230- Stridor (Pediatric)",
    "value": "1230- Stridor (Pediatric)"
  },
  {
    "id": "1235- Wheeze/Bronchospasm (Pediatric)",
    "label": "1235- Wheeze/Bronchospasm (Pediatric)",
    "value": "1235- Wheeze/Bronchospasm (Pediatric)"
  },
  {
    "id": "1255- Childbirth (Adult)",
    "label": "1255- Childbirth (Adult)",
    "value": "1255- Childbirth (Adult)"
  },
  {
    "id": "1260- Childbirth/Post Natal Mother Care (Adult)",
    "label": "1260- Childbirth/Post Natal Mother Care (Adult)",
    "value": "1260- Childbirth/Post Natal Mother Care (Adult)"
  },
  {
    "id": "1270- Neonatal Care (Infant)",
    "label": "1270- Neonatal Care (Infant)",
    "value": "1270- Neonatal Care (Infant)"
  },
  {
    "id": "1280- Pre-eclampsia/Eclampsia (Adult)",
    "label": "1280- Pre-eclampsia/Eclampsia (Adult)",
    "value": "1280- Pre-eclampsia/Eclampsia (Adult)"
  },
  {
    "id": "1285- Depressed/Suicidal (Adult/Pediatric)",
    "label": "1285- Depressed/Suicidal (Adult/Pediatric)",
    "value": "1285- Depressed/Suicidal (Adult/Pediatric)"
  },
  {
    "id": "1290- Violent/Agitated (Adult/Pediatric)",
    "label": "1290- Violent/Agitated (Adult/Pediatric)",
    "value": "1290- Violent/Agitated (Adult/Pediatric)"
  },
  {
    "id": "1300- Asthma / COPD (Adult)",
    "label": "1300- Asthma / COPD (Adult)",
    "value": "1300- Asthma / COPD (Adult)"
  },
  {
    "id": "1310- Pulmonary Edema – CHF (Adult)",
    "label": "1310- Pulmonary Edema – CHF (Adult)",
    "value": "1310- Pulmonary Edema – CHF (Adult)"
  },
  {
    "id": "1315- Respiratory Distress NYD (Adult/Pediatric)",
    "label": "1315- Respiratory Distress NYD (Adult/Pediatric)",
    "value": "1315- Respiratory Distress NYD (Adult/Pediatric)"
  },
  {
    "id": "1325- Non-Trauma Shock (Adult/Pediatric)",
    "label": "1325- Non-Trauma Shock (Adult/Pediatric)",
    "value": "1325- Non-Trauma Shock (Adult/Pediatric)"
  },
  {
    "id": "1330- Trauma Shock (Adult/Pediatric)",
    "label": "1330- Trauma Shock (Adult/Pediatric)",
    "value": "1330- Trauma Shock (Adult/Pediatric)"
  },
  {
    "id": "1335- Stroke/CVA/TIA (Adult/Pediatric)",
    "label": "1335- Stroke/CVA/TIA (Adult/Pediatric)",
    "value": "1335- Stroke/CVA/TIA (Adult/Pediatric)"
  },
  {
    "id": "1355- Abdominal Trauma (Adult/Pediatric)",
    "label": "1355- Abdominal Trauma (Adult/Pediatric)",
    "value": "1355- Abdominal Trauma (Adult/Pediatric)"
  },
  {
    "id": "1360- Amputation (Adult/Pediatric)",
    "label": "1360- Amputation (Adult/Pediatric)",
    "value": "1360- Amputation (Adult/Pediatric)"
  },
  {
    "id": "1365- Chest Trauma (Adult/Pediatric)",
    "label": "1365- Chest Trauma (Adult/Pediatric)",
    "value": "1365- Chest Trauma (Adult/Pediatric)"
  },
  {
    "id": "1370- Extremity Trauma (Adult/Pediatric)",
    "label": "1370- Extremity Trauma (Adult/Pediatric)",
    "value": "1370- Extremity Trauma (Adult/Pediatric)"
  },
  {
    "id": "1375- Head Injury (Adult/Pediatric)",
    "label": "1375- Head Injury (Adult/Pediatric)",
    "value": "1375- Head Injury (Adult/Pediatric)"
  },
  {
    "id": "1380- Pelvic Trauma (Adult/Pediatric)",
    "label": "1380- Pelvic Trauma (Adult/Pediatric)",
    "value": "1380- Pelvic Trauma (Adult/Pediatric)"
  },
  {
    "id": "1385- Spinal Injury (Adult/Pediatric)",
    "label": "1385- Spinal Injury (Adult/Pediatric)",
    "value": "1385- Spinal Injury (Adult/Pediatric)"
  },
  {
    "id": "1400- Non-traumatic Musculoskeletal Pain (Adult/Pediatric)",
    "label": "1400- Non-traumatic Musculoskeletal Pain (Adult/Pediatric)",
    "value": "1400- Non-traumatic Musculoskeletal Pain (Adult/Pediatric)"
  },
  {
    "id": "1410- Palpitation – Arrhythmia (Adult/Pediatric)",
    "label": "1410- Palpitation – Arrhythmia (Adult/Pediatric)",
    "value": "1410- Palpitation – Arrhythmia (Adult/Pediatric)"
  },
  {
    "id": "1415- Specific Complaint - Miscellaneous (Adult/Pediatric)",
    "label": "1415- Specific Complaint - Miscellaneous (Adult/Pediatric)",
    "value": "1415- Specific Complaint - Miscellaneous (Adult/Pediatric)"
  },
  {
    "id": "1420- Anxiety/Panic Attack (Adult/Pediatric)",
    "label": "1420- Anxiety/Panic Attack (Adult/Pediatric)",
    "value": "1420- Anxiety/Panic Attack (Adult/Pediatric)"
  },
  {
    "id": "1425- Infectious disease/Fever (Adult/Pediatric)",
    "label": "1425- Infectious disease/Fever (Adult/Pediatric)",
    "value": "1425- Infectious disease/Fever (Adult/Pediatric)"
  },
  {
    "id": "9990- No Patient Transported",
    "label": "9990- No Patient Transported",
    "value": "9990- No Patient Transported"
  },
  {
    "id": "9992- Transfers with no treatment",
    "label": "9992- Transfers with no treatment",
    "value": "9992- Transfers with no treatment"
  },
  {
    "id": "9993- Transfers with treatments",
    "label": "9993- Transfers with treatments",
    "value": "9993- Transfers with treatments"
  },
  {
    "id": "9994- Dead on Arrival",
    "label": "9994- Dead on Arrival",
    "value": "9994- Dead on Arrival"
  }
];

export const allergies = [
  {
    "id": "ACE inhibitors",
    "label": "ACE inhibitors",
    "value": "ACE inhibitors"
  },
  {
    "id": "Acetaminophen ",
    "label": "Acetaminophen ",
    "value": "Acetaminophen "
  },
  {
    "id": "Acetylsalicylic acid ",
    "label": "Acetylsalicylic acid ",
    "value": "Acetylsalicylic acid "
  },
  {
    "id": "Amoxicillin",
    "label": "Amoxicillin",
    "value": "Amoxicillin"
  },
  {
    "id": "Animals ",
    "label": "Animals ",
    "value": "Animals "
  },
  {
    "id": "Codeine",
    "label": "Codeine",
    "value": "Codeine"
  },
  {
    "id": "CT dye",
    "label": "CT dye",
    "value": "CT dye"
  },
  {
    "id": "Eggs",
    "label": "Eggs",
    "value": "Eggs"
  },
  {
    "id": "Environmental",
    "label": "Environmental",
    "value": "Environmental"
  },
  {
    "id": "Fish",
    "label": "Fish",
    "value": "Fish"
  },
  {
    "id": "Ibuprofen",
    "label": "Ibuprofen",
    "value": "Ibuprofen"
  },
  {
    "id": "Ipratropium Bromide",
    "label": "Ipratropium Bromide",
    "value": "Ipratropium Bromide"
  },
  {
    "id": "Latex",
    "label": "Latex",
    "value": "Latex"
  },
  {
    "id": "Milk",
    "label": "Milk",
    "value": "Milk"
  },
  {
    "id": "Morphine hydrochloride ",
    "label": "Morphine hydrochloride ",
    "value": "Morphine hydrochloride "
  },
  {
    "id": "Morphine sulfate",
    "label": "Morphine sulfate",
    "value": "Morphine sulfate"
  },
  {
    "id": "Naproxen ",
    "label": "Naproxen ",
    "value": "Naproxen "
  },
  {
    "id": "Nitroglycerin ",
    "label": "Nitroglycerin ",
    "value": "Nitroglycerin "
  },
  {
    "id": "Other\" please specify",
    "label": "Other\" please specify",
    "value": "Other\" please specify"
  },
  {
    "id": "Peanuts",
    "label": "Peanuts",
    "value": "Peanuts"
  },
  {
    "id": "Penicillin ",
    "label": "Penicillin ",
    "value": "Penicillin "
  },
  {
    "id": "Salbutamol",
    "label": "Salbutamol",
    "value": "Salbutamol"
  },
  {
    "id": "Shellfish",
    "label": "Shellfish",
    "value": "Shellfish"
  },
  {
    "id": "Sulfa drugs",
    "label": "Sulfa drugs",
    "value": "Sulfa drugs"
  },
  {
    "id": "Tree nuts",
    "label": "Tree nuts",
    "value": "Tree nuts"
  },
  {
    "id": "Wasp or bee stings",
    "label": "Wasp or bee stings",
    "value": "Wasp or bee stings"
  },
  {
    "id": "Wheat",
    "label": "Wheat",
    "value": "Wheat"
  }
];

export const medications = [
  {
    "id": "Acetaminophen ",
    "label": "Acetaminophen ",
    "value": "Acetaminophen "
  },
  {
    "id": "Acetylsalicylic acid ",
    "label": "Acetylsalicylic acid ",
    "value": "Acetylsalicylic acid "
  },
  {
    "id": "Allopurinol",
    "label": "Allopurinol",
    "value": "Allopurinol"
  },
  {
    "id": "Alprazolam",
    "label": "Alprazolam",
    "value": "Alprazolam"
  },
  {
    "id": "Amitriptyline hydrochloride",
    "label": "Amitriptyline hydrochloride",
    "value": "Amitriptyline hydrochloride"
  },
  {
    "id": "Amlodipine",
    "label": "Amlodipine",
    "value": "Amlodipine"
  },
  {
    "id": "Amoxicillin",
    "label": "Amoxicillin",
    "value": "Amoxicillin"
  },
  {
    "id": "Apixaban",
    "label": "Apixaban",
    "value": "Apixaban"
  },
  {
    "id": "Apripiprazole",
    "label": "Apripiprazole",
    "value": "Apripiprazole"
  },
  {
    "id": "Atenolol",
    "label": "Atenolol",
    "value": "Atenolol"
  },
  {
    "id": "Atorvastain",
    "label": "Atorvastain",
    "value": "Atorvastain"
  },
  {
    "id": "Azithromycin",
    "label": "Azithromycin",
    "value": "Azithromycin"
  },
  {
    "id": "Beclomethasone dipropionate ",
    "label": "Beclomethasone dipropionate ",
    "value": "Beclomethasone dipropionate "
  },
  {
    "id": "Benzydamine hydrochloride ",
    "label": "Benzydamine hydrochloride ",
    "value": "Benzydamine hydrochloride "
  },
  {
    "id": "Betahistine hydrochloride ",
    "label": "Betahistine hydrochloride ",
    "value": "Betahistine hydrochloride "
  },
  {
    "id": "Betamethasone",
    "label": "Betamethasone",
    "value": "Betamethasone"
  },
  {
    "id": "Bisoprolol fumarate",
    "label": "Bisoprolol fumarate",
    "value": "Bisoprolol fumarate"
  },
  {
    "id": "Budesonide ",
    "label": "Budesonide ",
    "value": "Budesonide "
  },
  {
    "id": "Buprenorphine ",
    "label": "Buprenorphine ",
    "value": "Buprenorphine "
  },
  {
    "id": "Buspirone hydrochloride ",
    "label": "Buspirone hydrochloride ",
    "value": "Buspirone hydrochloride "
  },
  {
    "id": "Candesartan cilexetil ",
    "label": "Candesartan cilexetil ",
    "value": "Candesartan cilexetil "
  },
  {
    "id": "Capagliflozin ",
    "label": "Capagliflozin ",
    "value": "Capagliflozin "
  },
  {
    "id": "Carbamazepine",
    "label": "Carbamazepine",
    "value": "Carbamazepine"
  },
  {
    "id": "Carbidopa ",
    "label": "Carbidopa ",
    "value": "Carbidopa "
  },
  {
    "id": "Cefadroxil ",
    "label": "Cefadroxil ",
    "value": "Cefadroxil "
  },
  {
    "id": "Cefixime",
    "label": "Cefixime",
    "value": "Cefixime"
  },
  {
    "id": "Cefuroxime ",
    "label": "Cefuroxime ",
    "value": "Cefuroxime "
  },
  {
    "id": "Celecoxib ",
    "label": "Celecoxib ",
    "value": "Celecoxib "
  },
  {
    "id": "Cephalexin",
    "label": "Cephalexin",
    "value": "Cephalexin"
  },
  {
    "id": "Cetirizine hydrochloride ",
    "label": "Cetirizine hydrochloride ",
    "value": "Cetirizine hydrochloride "
  },
  {
    "id": "Chlorhexidine gluconate ",
    "label": "Chlorhexidine gluconate ",
    "value": "Chlorhexidine gluconate "
  },
  {
    "id": "Cholecalciferol ",
    "label": "Cholecalciferol ",
    "value": "Cholecalciferol "
  },
  {
    "id": "Ciclesonide ",
    "label": "Ciclesonide ",
    "value": "Ciclesonide "
  },
  {
    "id": "Ciprofloxacin ",
    "label": "Ciprofloxacin ",
    "value": "Ciprofloxacin "
  },
  {
    "id": "Citalopram ",
    "label": "Citalopram ",
    "value": "Citalopram "
  },
  {
    "id": "Clarithromycin ",
    "label": "Clarithromycin ",
    "value": "Clarithromycin "
  },
  {
    "id": "Clindamycin ",
    "label": "Clindamycin ",
    "value": "Clindamycin "
  },
  {
    "id": "Clobazam ",
    "label": "Clobazam ",
    "value": "Clobazam "
  },
  {
    "id": "Clobetasol propionate ",
    "label": "Clobetasol propionate ",
    "value": "Clobetasol propionate "
  },
  {
    "id": "Clonazepam ",
    "label": "Clonazepam ",
    "value": "Clonazepam "
  },
  {
    "id": "Clonidine hydrochloride",
    "label": "Clonidine hydrochloride",
    "value": "Clonidine hydrochloride"
  },
  {
    "id": "Clopidogrel ",
    "label": "Clopidogrel ",
    "value": "Clopidogrel "
  },
  {
    "id": "Clotrimazole ",
    "label": "Clotrimazole ",
    "value": "Clotrimazole "
  },
  {
    "id": "Codeine phosphate",
    "label": "Codeine phosphate",
    "value": "Codeine phosphate"
  },
  {
    "id": "Colchicine",
    "label": "Colchicine",
    "value": "Colchicine"
  },
  {
    "id": "Cyanocobalamin (Vitamin B12)",
    "label": "Cyanocobalamin (Vitamin B12)",
    "value": "Cyanocobalamin (Vitamin B12)"
  },
  {
    "id": "Cyclobenzaprine hydrochloride ",
    "label": "Cyclobenzaprine hydrochloride ",
    "value": "Cyclobenzaprine hydrochloride "
  },
  {
    "id": "Denosumab ",
    "label": "Denosumab ",
    "value": "Denosumab "
  },
  {
    "id": "Desogestrel",
    "label": "Desogestrel",
    "value": "Desogestrel"
  },
  {
    "id": "Dexamethasone ",
    "label": "Dexamethasone ",
    "value": "Dexamethasone "
  },
  {
    "id": "Dexlansoprazole ",
    "label": "Dexlansoprazole ",
    "value": "Dexlansoprazole "
  },
  {
    "id": "Diazepam ",
    "label": "Diazepam ",
    "value": "Diazepam "
  },
  {
    "id": "Diclofenac sodium ",
    "label": "Diclofenac sodium ",
    "value": "Diclofenac sodium "
  },
  {
    "id": "Diltiazem hydrochloride ",
    "label": "Diltiazem hydrochloride ",
    "value": "Diltiazem hydrochloride "
  },
  {
    "id": "Domperidone ",
    "label": "Domperidone ",
    "value": "Domperidone "
  },
  {
    "id": "Doxepin ",
    "label": "Doxepin ",
    "value": "Doxepin "
  },
  {
    "id": "Doxycycline ",
    "label": "Doxycycline ",
    "value": "Doxycycline "
  },
  {
    "id": "Doxylamine succinate ",
    "label": "Doxylamine succinate ",
    "value": "Doxylamine succinate "
  },
  {
    "id": "Duloxetine ",
    "label": "Duloxetine ",
    "value": "Duloxetine "
  },
  {
    "id": "Dutasteride ",
    "label": "Dutasteride ",
    "value": "Dutasteride "
  },
  {
    "id": "Empagliflozin",
    "label": "Empagliflozin",
    "value": "Empagliflozin"
  },
  {
    "id": "Erythromycin ",
    "label": "Erythromycin ",
    "value": "Erythromycin "
  },
  {
    "id": "Escitalopram",
    "label": "Escitalopram",
    "value": "Escitalopram"
  },
  {
    "id": "Esomeprazole  ",
    "label": "Esomeprazole  ",
    "value": "Esomeprazole  "
  },
  {
    "id": "Estradiol",
    "label": "Estradiol",
    "value": "Estradiol"
  },
  {
    "id": "Ethinyl estradiol ",
    "label": "Ethinyl estradiol ",
    "value": "Ethinyl estradiol "
  },
  {
    "id": "Ezetimibe ",
    "label": "Ezetimibe ",
    "value": "Ezetimibe "
  },
  {
    "id": "Famotidine ",
    "label": "Famotidine ",
    "value": "Famotidine "
  },
  {
    "id": "Fenofibrate",
    "label": "Fenofibrate",
    "value": "Fenofibrate"
  },
  {
    "id": "Ferrous sulfate (Iron) ",
    "label": "Ferrous sulfate (Iron) ",
    "value": "Ferrous sulfate (Iron) "
  },
  {
    "id": "Fluconazole",
    "label": "Fluconazole",
    "value": "Fluconazole"
  },
  {
    "id": "Fluoxetine ",
    "label": "Fluoxetine ",
    "value": "Fluoxetine "
  },
  {
    "id": "Fluticasone",
    "label": "Fluticasone",
    "value": "Fluticasone"
  },
  {
    "id": "Formoterol fumarate",
    "label": "Formoterol fumarate",
    "value": "Formoterol fumarate"
  },
  {
    "id": "Furosemide ",
    "label": "Furosemide ",
    "value": "Furosemide "
  },
  {
    "id": "Fusidic acid ",
    "label": "Fusidic acid ",
    "value": "Fusidic acid "
  },
  {
    "id": "Gabapentin ",
    "label": "Gabapentin ",
    "value": "Gabapentin "
  },
  {
    "id": "Gliclazide ",
    "label": "Gliclazide ",
    "value": "Gliclazide "
  },
  {
    "id": "Hydrochlorothiazide ",
    "label": "Hydrochlorothiazide ",
    "value": "Hydrochlorothiazide "
  },
  {
    "id": "Hydrocortisone ",
    "label": "Hydrocortisone ",
    "value": "Hydrocortisone "
  },
  {
    "id": "Hydromorphone hydrochloride ",
    "label": "Hydromorphone hydrochloride ",
    "value": "Hydromorphone hydrochloride "
  },
  {
    "id": "Hyoscine butylbromide ",
    "label": "Hyoscine butylbromide ",
    "value": "Hyoscine butylbromide "
  },
  {
    "id": "Ibuprofen ",
    "label": "Ibuprofen ",
    "value": "Ibuprofen "
  },
  {
    "id": "Indapamide ",
    "label": "Indapamide ",
    "value": "Indapamide "
  },
  {
    "id": "Indomethacin ",
    "label": "Indomethacin ",
    "value": "Indomethacin "
  },
  {
    "id": "Insulin ",
    "label": "Insulin ",
    "value": "Insulin "
  },
  {
    "id": "Ipratropium bromide ",
    "label": "Ipratropium bromide ",
    "value": "Ipratropium bromide "
  },
  {
    "id": "Irbesartan ",
    "label": "Irbesartan ",
    "value": "Irbesartan "
  },
  {
    "id": "Isosorbide mononitrate ",
    "label": "Isosorbide mononitrate ",
    "value": "Isosorbide mononitrate "
  },
  {
    "id": "Ketorolac tromethamine ",
    "label": "Ketorolac tromethamine ",
    "value": "Ketorolac tromethamine "
  },
  {
    "id": "Labetalol hydrochloride",
    "label": "Labetalol hydrochloride",
    "value": "Labetalol hydrochloride"
  },
  {
    "id": "Lactulose ",
    "label": "Lactulose ",
    "value": "Lactulose "
  },
  {
    "id": "Lamotrigine ",
    "label": "Lamotrigine ",
    "value": "Lamotrigine "
  },
  {
    "id": "Lansoprazole ",
    "label": "Lansoprazole ",
    "value": "Lansoprazole "
  },
  {
    "id": "Levofloxacin ",
    "label": "Levofloxacin ",
    "value": "Levofloxacin "
  },
  {
    "id": "Levothyroxine sodium ",
    "label": "Levothyroxine sodium ",
    "value": "Levothyroxine sodium "
  },
  {
    "id": "Linagliptin ",
    "label": "Linagliptin ",
    "value": "Linagliptin "
  },
  {
    "id": "Lisdexamfetamine dimesylate",
    "label": "Lisdexamfetamine dimesylate",
    "value": "Lisdexamfetamine dimesylate"
  },
  {
    "id": "Lithium carbonate ",
    "label": "Lithium carbonate ",
    "value": "Lithium carbonate "
  },
  {
    "id": "Lorazepam ",
    "label": "Lorazepam ",
    "value": "Lorazepam "
  },
  {
    "id": "Medroxyprogesterone acetate ",
    "label": "Medroxyprogesterone acetate ",
    "value": "Medroxyprogesterone acetate "
  },
  {
    "id": "Meloxicam ",
    "label": "Meloxicam ",
    "value": "Meloxicam "
  },
  {
    "id": "Methadone hydrochloride ",
    "label": "Methadone hydrochloride ",
    "value": "Methadone hydrochloride "
  },
  {
    "id": "Methimazole ",
    "label": "Methimazole ",
    "value": "Methimazole "
  },
  {
    "id": "Methotrexate ",
    "label": "Methotrexate ",
    "value": "Methotrexate "
  },
  {
    "id": "Methylphenidate hydrochloride ",
    "label": "Methylphenidate hydrochloride ",
    "value": "Methylphenidate hydrochloride "
  },
  {
    "id": "Methylprednisolone acetate ",
    "label": "Methylprednisolone acetate ",
    "value": "Methylprednisolone acetate "
  },
  {
    "id": "Metoclopramide hydrochloride ",
    "label": "Metoclopramide hydrochloride ",
    "value": "Metoclopramide hydrochloride "
  },
  {
    "id": "Metoprolol tartrate ",
    "label": "Metoprolol tartrate ",
    "value": "Metoprolol tartrate "
  },
  {
    "id": "Metronidazole ",
    "label": "Metronidazole ",
    "value": "Metronidazole "
  },
  {
    "id": "Midazolam ",
    "label": "Midazolam ",
    "value": "Midazolam "
  },
  {
    "id": "Mirabegron ",
    "label": "Mirabegron ",
    "value": "Mirabegron "
  },
  {
    "id": "Mirtazapine",
    "label": "Mirtazapine",
    "value": "Mirtazapine"
  },
  {
    "id": "Mometasone furoate ",
    "label": "Mometasone furoate ",
    "value": "Mometasone furoate "
  },
  {
    "id": "Morphine hydrochloride ",
    "label": "Morphine hydrochloride ",
    "value": "Morphine hydrochloride "
  },
  {
    "id": "Morphine sulfate",
    "label": "Morphine sulfate",
    "value": "Morphine sulfate"
  },
  {
    "id": "Moxifloxacin",
    "label": "Moxifloxacin",
    "value": "Moxifloxacin"
  },
  {
    "id": "Mupirocin ",
    "label": "Mupirocin ",
    "value": "Mupirocin "
  },
  {
    "id": "Naltrexone hydrochloride ",
    "label": "Naltrexone hydrochloride ",
    "value": "Naltrexone hydrochloride "
  },
  {
    "id": "Naproxen ",
    "label": "Naproxen ",
    "value": "Naproxen "
  },
  {
    "id": "Nifedipine ",
    "label": "Nifedipine ",
    "value": "Nifedipine "
  },
  {
    "id": "Nitrofurantoin ",
    "label": "Nitrofurantoin ",
    "value": "Nitrofurantoin "
  },
  {
    "id": "Nitroglycerin ",
    "label": "Nitroglycerin ",
    "value": "Nitroglycerin "
  },
  {
    "id": "Norfloxacin",
    "label": "Norfloxacin",
    "value": "Norfloxacin"
  },
  {
    "id": "Nortriptyline ",
    "label": "Nortriptyline ",
    "value": "Nortriptyline "
  },
  {
    "id": "Olanzapine ",
    "label": "Olanzapine ",
    "value": "Olanzapine "
  },
  {
    "id": "Olmesartan medoxomil ",
    "label": "Olmesartan medoxomil ",
    "value": "Olmesartan medoxomil "
  },
  {
    "id": "Olodaterol ",
    "label": "Olodaterol ",
    "value": "Olodaterol "
  },
  {
    "id": "Omeprazole ",
    "label": "Omeprazole ",
    "value": "Omeprazole "
  },
  {
    "id": "Ondansetron ",
    "label": "Ondansetron ",
    "value": "Ondansetron "
  },
  {
    "id": "Other: please specify",
    "label": "Other: please specify",
    "value": "Other: please specify"
  },
  {
    "id": "Oxazepam ",
    "label": "Oxazepam ",
    "value": "Oxazepam "
  },
  {
    "id": "Oxycodone hydrochloride",
    "label": "Oxycodone hydrochloride",
    "value": "Oxycodone hydrochloride"
  },
  {
    "id": "Pantoprazole ",
    "label": "Pantoprazole ",
    "value": "Pantoprazole "
  },
  {
    "id": "Paroxetine ",
    "label": "Paroxetine ",
    "value": "Paroxetine "
  },
  {
    "id": "Penicillin ",
    "label": "Penicillin ",
    "value": "Penicillin "
  },
  {
    "id": "Perindopril erbumine ",
    "label": "Perindopril erbumine ",
    "value": "Perindopril erbumine "
  },
  {
    "id": "Phenytoin sodium ",
    "label": "Phenytoin sodium ",
    "value": "Phenytoin sodium "
  },
  {
    "id": "Pravastatin sodium ",
    "label": "Pravastatin sodium ",
    "value": "Pravastatin sodium "
  },
  {
    "id": "Prazosin ",
    "label": "Prazosin ",
    "value": "Prazosin "
  },
  {
    "id": "Prednisolone ",
    "label": "Prednisolone ",
    "value": "Prednisolone "
  },
  {
    "id": "Prednisolone acetate ",
    "label": "Prednisolone acetate ",
    "value": "Prednisolone acetate "
  },
  {
    "id": "Prednisone ",
    "label": "Prednisone ",
    "value": "Prednisone "
  },
  {
    "id": "Pregabalin ",
    "label": "Pregabalin ",
    "value": "Pregabalin "
  },
  {
    "id": "Progesterone ",
    "label": "Progesterone ",
    "value": "Progesterone "
  },
  {
    "id": "Propranolol hydrochloride",
    "label": "Propranolol hydrochloride",
    "value": "Propranolol hydrochloride"
  },
  {
    "id": "Quetiapine ",
    "label": "Quetiapine ",
    "value": "Quetiapine "
  },
  {
    "id": "Rabeprazole sodium ",
    "label": "Rabeprazole sodium ",
    "value": "Rabeprazole sodium "
  },
  {
    "id": "Ramipril ",
    "label": "Ramipril ",
    "value": "Ramipril "
  },
  {
    "id": "Ranitidine ",
    "label": "Ranitidine ",
    "value": "Ranitidine "
  },
  {
    "id": "Risedronate sodium ",
    "label": "Risedronate sodium ",
    "value": "Risedronate sodium "
  },
  {
    "id": "Risperidone",
    "label": "Risperidone",
    "value": "Risperidone"
  },
  {
    "id": "Rivaroxaban ",
    "label": "Rivaroxaban ",
    "value": "Rivaroxaban "
  },
  {
    "id": "Rosuvastatin ",
    "label": "Rosuvastatin ",
    "value": "Rosuvastatin "
  },
  {
    "id": "Sacubitril ",
    "label": "Sacubitril ",
    "value": "Sacubitril "
  },
  {
    "id": "Salbutamol ",
    "label": "Salbutamol ",
    "value": "Salbutamol "
  },
  {
    "id": "Sertraline ",
    "label": "Sertraline ",
    "value": "Sertraline "
  },
  {
    "id": "Sildenafil ",
    "label": "Sildenafil ",
    "value": "Sildenafil "
  },
  {
    "id": "Sitagliptin ",
    "label": "Sitagliptin ",
    "value": "Sitagliptin "
  },
  {
    "id": "Spironolactone ",
    "label": "Spironolactone ",
    "value": "Spironolactone "
  },
  {
    "id": "Sucralfate",
    "label": "Sucralfate",
    "value": "Sucralfate"
  },
  {
    "id": "Sulfamethoxazole",
    "label": "Sulfamethoxazole",
    "value": "Sulfamethoxazole"
  },
  {
    "id": "Tamsulosin hydrochloride ",
    "label": "Tamsulosin hydrochloride ",
    "value": "Tamsulosin hydrochloride "
  },
  {
    "id": "Telmisartan",
    "label": "Telmisartan",
    "value": "Telmisartan"
  },
  {
    "id": "Terazosin",
    "label": "Terazosin",
    "value": "Terazosin"
  },
  {
    "id": "Terbutaline sulfate",
    "label": "Terbutaline sulfate",
    "value": "Terbutaline sulfate"
  },
  {
    "id": "Testosterone",
    "label": "Testosterone",
    "value": "Testosterone"
  },
  {
    "id": "Tiotropium ",
    "label": "Tiotropium ",
    "value": "Tiotropium "
  },
  {
    "id": "Topiramate ",
    "label": "Topiramate ",
    "value": "Topiramate "
  },
  {
    "id": "Tramadol hydrochloride",
    "label": "Tramadol hydrochloride",
    "value": "Tramadol hydrochloride"
  },
  {
    "id": "Trazodone hydrochloride ",
    "label": "Trazodone hydrochloride ",
    "value": "Trazodone hydrochloride "
  },
  {
    "id": "Umeclidinium ",
    "label": "Umeclidinium ",
    "value": "Umeclidinium "
  },
  {
    "id": "Valproic acid (divalproex sodium) ",
    "label": "Valproic acid (divalproex sodium) ",
    "value": "Valproic acid (divalproex sodium) "
  },
  {
    "id": "Venlafaxine ",
    "label": "Venlafaxine ",
    "value": "Venlafaxine "
  },
  {
    "id": "Verapamil hydrochloride ",
    "label": "Verapamil hydrochloride ",
    "value": "Verapamil hydrochloride "
  },
  {
    "id": "Vitamin D ",
    "label": "Vitamin D ",
    "value": "Vitamin D "
  },
  {
    "id": "Vortioxetine ",
    "label": "Vortioxetine ",
    "value": "Vortioxetine "
  },
  {
    "id": "Warfarin sodium ",
    "label": "Warfarin sodium ",
    "value": "Warfarin sodium "
  },
  {
    "id": "Zopiclone",
    "label": "Zopiclone",
    "value": "Zopiclone"
  }
];

export const pastMedicalHx = [
  {
    "id": "Arthritis",
    "label": "Arthritis",
    "value": "Arthritis"
  },
  {
    "id": "Asthma",
    "label": "Asthma",
    "value": "Asthma"
  },
  {
    "id": "Congestive Heart Failure (CHF)",
    "label": "Congestive Heart Failure (CHF)",
    "value": "Congestive Heart Failure (CHF)"
  },
  {
    "id": "Chronic Obstructive Pulmonary Disease (COPD)",
    "label": "Chronic Obstructive Pulmonary Disease (COPD)",
    "value": "Chronic Obstructive Pulmonary Disease (COPD)"
  },
  {
    "id": "Diabetes",
    "label": "Diabetes",
    "value": "Diabetes"
  },
  {
    "id": "Hypertension",
    "label": "Hypertension",
    "value": "Hypertension"
  },
  {
    "id": "Cancer",
    "label": "Cancer",
    "value": "Cancer"
  },
  {
    "id": "Atrial fibrillation",
    "label": "Atrial fibrillation",
    "value": "Atrial fibrillation"
  },
  {
    "id": "Angina",
    "label": "Angina",
    "value": "Angina"
  },
  {
    "id": "Myocardial infarction (MI)",
    "label": "Myocardial infarction (MI)",
    "value": "Myocardial infarction (MI)"
  },
  {
    "id": "Deep Vein Thrombosis",
    "label": "Deep Vein Thrombosis",
    "value": "Deep Vein Thrombosis"
  },
  {
    "id": "Pulmonary Fibrosis",
    "label": "Pulmonary Fibrosis",
    "value": "Pulmonary Fibrosis"
  },
  {
    "id": "Pulmonary Hypertension",
    "label": "Pulmonary Hypertension",
    "value": "Pulmonary Hypertension"
  },
  {
    "id": "Pneumonia",
    "label": "Pneumonia",
    "value": "Pneumonia"
  },
  {
    "id": "Cerebral Vascular Accident (CVA)",
    "label": "Cerebral Vascular Accident (CVA)",
    "value": "Cerebral Vascular Accident (CVA)"
  },
  {
    "id": "Transient Ischemic Attack (TIA)",
    "label": "Transient Ischemic Attack (TIA)",
    "value": "Transient Ischemic Attack (TIA)"
  },
  {
    "id": "Cirrhosis of the Liver",
    "label": "Cirrhosis of the Liver",
    "value": "Cirrhosis of the Liver"
  },
  {
    "id": "Dementia",
    "label": "Dementia",
    "value": "Dementia"
  },
  {
    "id": "Pancreatitis",
    "label": "Pancreatitis",
    "value": "Pancreatitis"
  },
  {
    "id": "Pulmonary Embolism",
    "label": "Pulmonary Embolism",
    "value": "Pulmonary Embolism"
  },
  {
    "id": "Renal Failure",
    "label": "Renal Failure",
    "value": "Renal Failure"
  },
  {
    "id": "Fracture",
    "label": "Fracture",
    "value": "Fracture"
  },
  {
    "id": "Depression",
    "label": "Depression",
    "value": "Depression"
  },
  {
    "id": "Anxiety",
    "label": "Anxiety",
    "value": "Anxiety"
  },
  {
    "id": "Diverticulitis",
    "label": "Diverticulitis",
    "value": "Diverticulitis"
  },
  {
    "id": "Peripheral Vascular Disease (PVD)",
    "label": "Peripheral Vascular Disease (PVD)",
    "value": "Peripheral Vascular Disease (PVD)"
  },
  {
    "id": "Seizure Disorder",
    "label": "Seizure Disorder",
    "value": "Seizure Disorder"
  },
  {
    "id": "Muscular-skeletal Disorder",
    "label": "Muscular-skeletal Disorder",
    "value": "Muscular-skeletal Disorder"
  },
  {
    "id": "Appendectomy",
    "label": "Appendectomy",
    "value": "Appendectomy"
  },
  {
    "id": "Cholecystectomy",
    "label": "Cholecystectomy",
    "value": "Cholecystectomy"
  },
  {
    "id": "Hysterectomy",
    "label": "Hysterectomy",
    "value": "Hysterectomy"
  },
  {
    "id": "Mastectomy",
    "label": "Mastectomy",
    "value": "Mastectomy"
  },
  {
    "id": "Heart Valve Replacement",
    "label": "Heart Valve Replacement",
    "value": "Heart Valve Replacement"
  },
  {
    "id": "Coronary Artery Bypass Graft (CABG)",
    "label": "Coronary Artery Bypass Graft (CABG)",
    "value": "Coronary Artery Bypass Graft (CABG)"
  },
  {
    "id": "Hip Replacement",
    "label": "Hip Replacement",
    "value": "Hip Replacement"
  },
  {
    "id": "Knee Replacement",
    "label": "Knee Replacement",
    "value": "Knee Replacement"
  },
  {
    "id": "Abdominal Aneurysm Repair (AAA) Repair",
    "label": "Abdominal Aneurysm Repair (AAA) Repair",
    "value": "Abdominal Aneurysm Repair (AAA) Repair"
  },
  {
    "id": "Thoracic Aneurism Repair",
    "label": "Thoracic Aneurism Repair",
    "value": "Thoracic Aneurism Repair"
  },
  {
    "id": "Back Surgery",
    "label": "Back Surgery",
    "value": "Back Surgery"
  },
  {
    "id": "COVID",
    "label": "COVID",
    "value": "COVID"
  },
  {
    "id": "Blind ",
    "label": "Blind ",
    "value": "Blind "
  },
  {
    "id": "Deaf ",
    "label": "Deaf ",
    "value": "Deaf "
  },
  {
    "id": "Syncope",
    "label": "Syncope",
    "value": "Syncope"
  },
  {
    "id": "Vertigo",
    "label": "Vertigo",
    "value": "Vertigo"
  },
  {
    "id": "Pacemaker",
    "label": "Pacemaker",
    "value": "Pacemaker"
  },
  {
    "id": "Implantable Cardiac Defibrillator",
    "label": "Implantable Cardiac Defibrillator",
    "value": "Implantable Cardiac Defibrillator"
  },
  {
    "id": "Atrophy",
    "label": "Atrophy",
    "value": "Atrophy"
  },
  {
    "id": "Amputation",
    "label": "Amputation",
    "value": "Amputation"
  },
  {
    "id": "Gastric Reflux",
    "label": "Gastric Reflux",
    "value": "Gastric Reflux"
  },
  {
    "id": "Mental Health Conditions ",
    "label": "Mental Health Conditions ",
    "value": "Mental Health Conditions "
  },
  {
    "id": "Kyphosis",
    "label": "Kyphosis",
    "value": "Kyphosis"
  },
  {
    "id": "Scoliosis",
    "label": "Scoliosis",
    "value": "Scoliosis"
  },
  {
    "id": "Neuropathy",
    "label": "Neuropathy",
    "value": "Neuropathy"
  },
  {
    "id": "Urinary infections",
    "label": "Urinary infections",
    "value": "Urinary infections"
  },
  {
    "id": "Kidney stones",
    "label": "Kidney stones",
    "value": "Kidney stones"
  },
  {
    "id": "Sexually transmitted infection (STI)",
    "label": "Sexually transmitted infection (STI)",
    "value": "Sexually transmitted infection (STI)"
  },
  {
    "id": "Human Immunodeficiency Virus (HIV)",
    "label": "Human Immunodeficiency Virus (HIV)",
    "value": "Human Immunodeficiency Virus (HIV)"
  },
  {
    "id": "Hernia",
    "label": "Hernia",
    "value": "Hernia"
  },
  {
    "id": "Hernia repair",
    "label": "Hernia repair",
    "value": "Hernia repair"
  },
  {
    "id": "Obesity",
    "label": "Obesity",
    "value": "Obesity"
  },
  {
    "id": "Fistula",
    "label": "Fistula",
    "value": "Fistula"
  },
  {
    "id": "Wound: Surgical",
    "label": "Wound: Surgical",
    "value": "Wound: Surgical"
  },
  {
    "id": "Wound: Diabetic",
    "label": "Wound: Diabetic",
    "value": "Wound: Diabetic"
  },
  {
    "id": "Wound: Burn",
    "label": "Wound: Burn",
    "value": "Wound: Burn"
  },
  {
    "id": "Wound: Infected wound",
    "label": "Wound: Infected wound",
    "value": "Wound: Infected wound"
  },
  {
    "id": "Wound: Pressure Injury",
    "label": "Wound: Pressure Injury",
    "value": "Wound: Pressure Injury"
  },
  {
    "id": "Substance Abuse",
    "label": "Substance Abuse",
    "value": "Substance Abuse"
  },
  {
    "id": "Muscular Dystrophy",
    "label": "Muscular Dystrophy",
    "value": "Muscular Dystrophy"
  },
  {
    "id": "Alzheimer's",
    "label": "Alzheimer's",
    "value": "Alzheimer's"
  },
  {
    "id": "Multiple Sclerosis",
    "label": "Multiple Sclerosis",
    "value": "Multiple Sclerosis"
  },
  {
    "id": "Intellectual Disability",
    "label": "Intellectual Disability",
    "value": "Intellectual Disability"
  },
  {
    "id": "Spina Bifida",
    "label": "Spina Bifida",
    "value": "Spina Bifida"
  },
  {
    "id": "Dysphasia",
    "label": "Dysphasia",
    "value": "Dysphasia"
  },
  {
    "id": "Parkinson's",
    "label": "Parkinson's",
    "value": "Parkinson's"
  },
  {
    "id": "Cellulitis",
    "label": "Cellulitis",
    "value": "Cellulitis"
  },
  {
    "id": "Cerebral Palsy",
    "label": "Cerebral Palsy",
    "value": "Cerebral Palsy"
  },
  {
    "id": "Cystic Fibrosis",
    "label": "Cystic Fibrosis",
    "value": "Cystic Fibrosis"
  },
  {
    "id": "Inflammatory Bowel Disease",
    "label": "Inflammatory Bowel Disease",
    "value": "Inflammatory Bowel Disease"
  },
  {
    "id": "Downs syndrome",
    "label": "Downs syndrome",
    "value": "Downs syndrome"
  },
  {
    "id": "Traumatic Brain Injury",
    "label": "Traumatic Brain Injury",
    "value": "Traumatic Brain Injury"
  },
  {
    "id": "Lymphedema",
    "label": "Lymphedema",
    "value": "Lymphedema"
  },
  {
    "id": "Tuberculosis",
    "label": "Tuberculosis",
    "value": "Tuberculosis"
  },
  {
    "id": "Anemia",
    "label": "Anemia",
    "value": "Anemia"
  },
  {
    "id": "Schizophrenia",
    "label": "Schizophrenia",
    "value": "Schizophrenia"
  },
  {
    "id": "Osteoporosis",
    "label": "Osteoporosis",
    "value": "Osteoporosis"
  },
  {
    "id": "Bipolar Disorder",
    "label": "Bipolar Disorder",
    "value": "Bipolar Disorder"
  },
  {
    "id": "Cognitive-communication disorder",
    "label": "Cognitive-communication disorder",
    "value": "Cognitive-communication disorder"
  },
  {
    "id": "Apraxia of speech",
    "label": "Apraxia of speech",
    "value": "Apraxia of speech"
  },
  {
    "id": "Spinal cord injury",
    "label": "Spinal cord injury",
    "value": "Spinal cord injury"
  },
  {
    "id": "Fibromyalgia",
    "label": "Fibromyalgia",
    "value": "Fibromyalgia"
  },
  {
    "id": "Auto immune disease",
    "label": "Auto immune disease",
    "value": "Auto immune disease"
  },
  {
    "id": "Chronic pain syndrome",
    "label": "Chronic pain syndrome",
    "value": "Chronic pain syndrome"
  },
  {
    "id": "Encephalopathy",
    "label": "Encephalopathy",
    "value": "Encephalopathy"
  },
  {
    "id": "Tendinitis",
    "label": "Tendinitis",
    "value": "Tendinitis"
  },
  {
    "id": "Spinal stenosis",
    "label": "Spinal stenosis",
    "value": "Spinal stenosis"
  },
  {
    "id": "Sciatica",
    "label": "Sciatica",
    "value": "Sciatica"
  },
  {
    "id": "Quadriplegia",
    "label": "Quadriplegia",
    "value": "Quadriplegia"
  },
  {
    "id": "Paraplegic",
    "label": "Paraplegic",
    "value": "Paraplegic"
  },
  {
    "id": "Herniated Disc",
    "label": "Herniated Disc",
    "value": "Herniated Disc"
  },
  {
    "id": "Interstitial Lung Disease",
    "label": "Interstitial Lung Disease",
    "value": "Interstitial Lung Disease"
  },
  {
    "id": "Bronchitis",
    "label": "Bronchitis",
    "value": "Bronchitis"
  },
  {
    "id": "Emphysema",
    "label": "Emphysema",
    "value": "Emphysema"
  },
  {
    "id": "Respiratory syncytial virus (RSV)",
    "label": "Respiratory syncytial virus (RSV)",
    "value": "Respiratory syncytial virus (RSV)"
  },
  {
    "id": "Amyotrophic lateral sclerosis (ALS)",
    "label": "Amyotrophic lateral sclerosis (ALS)",
    "value": "Amyotrophic lateral sclerosis (ALS)"
  },
  {
    "id": "Coronary artery disease",
    "label": "Coronary artery disease",
    "value": "Coronary artery disease"
  },
  {
    "id": "Irritable bowel syndrome",
    "label": "Irritable bowel syndrome",
    "value": "Irritable bowel syndrome"
  },
  {
    "id": "Autism",
    "label": "Autism",
    "value": "Autism"
  },
  {
    "id": "Crohn's disease",
    "label": "Crohn's disease",
    "value": "Crohn's disease"
  },
  {
    "id": "Ulcerative Colitis",
    "label": "Ulcerative Colitis",
    "value": "Ulcerative Colitis"
  },
  {
    "id": "Celiac disease",
    "label": "Celiac disease",
    "value": "Celiac disease"
  },
  {
    "id": "Genetic disease",
    "label": "Genetic disease",
    "value": "Genetic disease"
  },
  {
    "id": "Neurological Disorder",
    "label": "Neurological Disorder",
    "value": "Neurological Disorder"
  },
  {
    "id": "Kidney Disease",
    "label": "Kidney Disease",
    "value": "Kidney Disease"
  },
  {
    "id": "Other:",
    "label": "Other:",
    "value": "Other:"
  }
];

export const estTimeOfInjuryProtocols = [
  "1050",
  "1055",
  "1060",
  "1065",
  "1085",
  "1100",
  "1335",
  "1355",
  "1360",
  "1365",
  "1375",
  "1380",
  "1385",
];

export const belongingsOptions = [
  {
    "id": "Bag/Luggage",
    "label": "Bag/Luggage",
    "value": "Bag/Luggage"
  },
  {
    "id": "Cane",
    "label": "Cane",
    "value": "Cane"
  },
  {
    "id": "Cash",
    "label": "Cash",
    "value": "Cash"
  },
  {
    "id": "Cell Phone",
    "label": "Cell Phone",
    "value": "Cell Phone"
  },
  {
    "id": "Clothing",
    "label": "Clothing",
    "value": "Clothing"
  },
  {
    "id": "Dentures",
    "label": "Dentures",
    "value": "Dentures"
  },
  {
    "id": "Glasses",
    "label": "Glasses",
    "value": "Glasses"
  },
  {
    "id": "Health Card",
    "label": "Health Card",
    "value": "Health Card"
  },
  {
    "id": "Jewelry",
    "label": "Jewelry",
    "value": "Jewelry"
  },
  {
    "id": "Medications",
    "label": "Medications",
    "value": "Medications"
  },
  {
    "id": "Purse",
    "label": "Purse",
    "value": "Purse"
  },
  {
    "id": "Walker",
    "label": "Walker",
    "value": "Walker"
  },
  {
    "id": "Wallet",
    "label": "Wallet",
    "value": "Wallet"
  },
];

export const belongingsOptionsGivenTo = [
  {
    "id": "Select",
    "label": "Select",
    "value": "Select"
  },
  {
    "id": "Patient",
    "label": "Patient",
    "value": "Patient"
  },
  {
    "id": "Police",
    "label": "Police",
    "value": "Police"
  },
  {
    "id": "Receiving facility staff",
    "label": "Receiving facility staff",
    "value": "Receiving facility staff"
  },
  {
    "id": "Family",
    "label": "Family",
    "value": "Family"
  },
  {
    "id": "Friend",
    "label": "Friend",
    "value": "Friend"
  },
  {
    "id": "Other",
    "label": "Other",
    "value": "Other"
  }
];

export const attendingPractitionerPrimaryStations = [
  { "id": "Select", "label": "Select", "value": "Select" },
  { "id": "161", "label": "Riverview", "value": "Riverview" },
  { "id": "165", "label": "Hillsborough", "value": "Hillsborough" },
  { "id": "166", "label": "Petitcodiac", "value": "Petitcodiac" },
  { "id": "167", "label": "Riverside-Albert", "value": "Riverside-Albert" },
  { "id": "168", "label": "Salisbury", "value": "Salisbury" },
  { "id": "127", "label": "Deer Island", "value": "Deer Island" },
  { "id": "130", "label": "Black's Harbour", "value": "Black's Harbour" },
  { "id": "131", "label": "Grand Manan", "value": "Grand Manan" },
  { "id": "132", "label": "Lepreau", "value": "Lepreau" },
  { "id": "185", "label": "Fredericton", "value": "Fredericton" },
  { "id": "116", "label": "Lameque", "value": "Lameque" },
  { "id": "117", "label": "Shippagan", "value": "Shippagan" },
  { "id": "122", "label": "Tracadie", "value": "Tracadie" },
  { "id": "115", "label": "Caraquet", "value": "Caraquet" },
  { "id": "120", "label": "Bathurst", "value": "Bathurst" },
  { "id": "134", "label": "Quispamsis", "value": "Quispamsis" },
  { "id": "135", "label": "Grandbay-Westfield", "value": "Grandbay-Westfield" },
  { "id": "141", "label": "Kingston Peninsula", "value": "Kingston Peninsula" },
  { "id": "140", "label": "Hampton", "value": "Hampton" },
  { "id": "142", "label": "St. Martins", "value": "St. Martins" },
  { "id": "143", "label": "Sussex", "value": "Sussex" },
  { "id": "136", "label": "Saint John", "value": "Saint John" },
  { "id": "171", "label": "Florenceville", "value": "Florenceville" },
  { "id": "172", "label": "Perth Andover", "value": "Perth Andover" },
  { "id": "173", "label": "Plaster Rock", "value": "Plaster Rock" },
  { "id": "174", "label": "Tobique", "value": "Tobique" },
  { "id": "110", "label": "Belledune", "value": "Belledune" },
  { "id": "111", "label": "Campbellton", "value": "Campbellton" },
  { "id": "112", "label": "Dalhousie", "value": "Dalhousie" },
  { "id": "155", "label": "Cap-Pele", "value": "Cap-Pele" },
  { "id": "156", "label": "Port Elgin", "value": "Port Elgin" },
  { "id": "157", "label": "Sackville", "value": "Sackville" },
  { "id": "158", "label": "Shediac", "value": "Shediac" },
  { "id": "186", "label": "Keswick", "value": "Keswick" },
  { "id": "190", "label": "Boiestown", "value": "Boiestown" },
  { "id": "192", "label": "Doaktown", "value": "Doaktown" },
  { "id": "195", "label": "Stanley", "value": "Stanley" },
  { "id": "146", "label": "Baie-Ste-Anne", "value": "Baie-Ste-Anne" },
  { "id": "121", "label": "Neguac", "value": "Neguac" },
  { "id": "149", "label": "Rogersville", "value": "Rogersville" },
  { "id": "150", "label": "Ford's Mills", "value": "Ford's Mills" },
  { "id": "147", "label": "Blackville", "value": "Blackville" },
  { "id": "148", "label": "Miramichi", "value": "Miramichi" },
  { "id": "180", "label": "Fredericton Junction", "value": "Fredericton Junction" },
  { "id": "181", "label": "Harvey", "value": "Harvey" },
  { "id": "182", "label": "McAdam", "value": "McAdam" },
  { "id": "183", "label": "Nackawic", "value": "Nackawic" },
  { "id": "101", "label": "Edmundston", "value": "Edmundston" },
  { "id": "102", "label": "Saint-Anne", "value": "Saint-Anne" },
  { "id": "103", "label": "Saint-Francois", "value": "Saint-Francois" },
  { "id": "105", "label": "Grand Falls", "value": "Grand Falls" },
  { "id": "106", "label": "Kedgwick", "value": "Kedgwick" },
  { "id": "107", "label": "Saint-Leonard", "value": "Saint-Leonard" },
  { "id": "108", "label": "Saint-Quentin", "value": "Saint-Quentin" },
  { "id": "126", "label": "Campobello", "value": "Campobello" },
  { "id": "128", "label": "St. Andrews", "value": "St. Andrews" },
  { "id": "129", "label": "St. Stephen", "value": "St. Stephen" },
  { "id": "151", "label": "Bouctouche", "value": "Bouctouche" },
  { "id": "152", "label": "Elsipogtog", "value": "Elsipogtog" },
  { "id": "153", "label": "Rexton", "value": "Rexton" },
  { "id": "175", "label": "Dow Settlement", "value": "Dow Settlement" },
  { "id": "176", "label": "Hartland", "value": "Hartland" },
  { "id": "177", "label": "Woodstock", "value": "Woodstock" },
  { "id": "187", "label": "Oromocto", "value": "Oromocto" },
  { "id": "191", "label": "Chipman", "value": "Chipman" },
  { "id": "193", "label": "Jemseg", "value": "Jemseg" },
  { "id": "194", "label": "Minto", "value": "Minto" },
  { "id": "160", "label": "Moncton", "value": "Moncton" }
];

export const assistingPersonnelPositions = [
  {
    "id": "RN",
    "label": "RN",
    "value": "RN"
  },
  {
    "id": "RT",
    "label": "RT",
    "value": "RT"
  },
  {
    "id": "LPN",
    "label": "LPN",
    "value": "LPN"
  },
  {
    "id": "MD",
    "label": "MD",
    "value": "MD"
  },
  {
    "id": "PSW",
    "label": "PSW",
    "value": "PSW"
  },
  {
    "id": "AMC",
    "label": "AMC",
    "value": "AMC"
  },
  {
    "id": "EMT",
    "label": "EMT",
    "value": "EMT"
  },
  {
    "id": "PCP",
    "label": "PCP",
    "value": "PCP"
  },
  {
    "id": "ACP",
    "label": "ACP",
    "value": "ACP"
  },
  {
    "id": "Student",
    "label": "Student",
    "value": "Student"
  },
  {
    "id": "Other",
    "label": "Other",
    "value": "Other"
  }
];

export const verbalReportPositions = [
  {
    "id": "--",
    "label": "--",
    "value": "--"
  },
  {
    "id": "RN",
    "label": "RN",
    "value": "RN"
  },
  {
    "id": "RT",
    "label": "RT",
    "value": "RT"
  },
  {
    "id": "LPN",
    "label": "LPN",
    "value": "LPN"
  },
  {
    "id": "MD",
    "label": "MD",
    "value": "MD"
  },
  {
    "id": "PSW",
    "label": "PSW",
    "value": "PSW"
  },
  {
    "id": "AMC",
    "label": "AMC",
    "value": "AMC"
  },
  {
    "id": "Other",
    "label": "Other",
    "value": "Other"
  }
];

export const accessGivenTo = [
  {
    "id": "Select",
    "label": "Select",
    "value": "Select"
  },
  {
    "id": "Cambellton Regional Hospital",
    "label": "Cambellton Regional Hospital",
    "value": "Cambellton Regional Hospital"
  },
  {
    "id": "Chaleur Regional Hospital",
    "label": "Chaleur Regional Hospital",
    "value": "Chaleur Regional Hospital"
  },
  {
    "id": "Charlotte County Hospital",
    "label": "Charlotte County Hospital",
    "value": "Charlotte County Hospital"
  },
  {
    "id": "Dr. Everett Chalmers Hospital",
    "label": "Dr. Everett Chalmers Hospital",
    "value": "Dr. Everett Chalmers Hospital"
  },
  {
    "id": "Dr.George-L. Dumont University Hospital Centre",
    "label": "Dr.George-L. Dumont University Hospital Centre",
    "value": "Dr.George-L. Dumont University Hospital Centre"
  },
  {
    "id": "Edmundston Regional Hospital",
    "label": "Edmundston Regional Hospital",
    "value": "Edmundston Regional Hospital"
  },
  {
    "id": "Grand Falls General Hospital Inc.",
    "label": "Grand Falls General Hospital Inc.",
    "value": "Grand Falls General Hospital Inc."
  },
  {
    "id": "Grand Manan Hospital",
    "label": "Grand Manan Hospital",
    "value": "Grand Manan Hospital"
  },
  {
    "id": "Stella Maris de Kent Hospital",
    "label": "Stella Maris de Kent Hospital",
    "value": "Stella Maris de Kent Hospital"
  },
  {
    "id": "Hotel-Dieu Saint-Joseph",
    "label": "Hotel-Dieu Saint-Joseph",
    "value": "Hotel-Dieu Saint-Joseph"
  },
  {
    "id": "L'Hopital de Lameque",
    "label": "L'Hopital de Lameque",
    "value": "L'Hopital de Lameque"
  },
  {
    "id": "L'Hopital L'Enfant Jesus",
    "label": "L'Hopital L'Enfant Jesus",
    "value": "L'Hopital L'Enfant Jesus"
  },
  {
    "id": "L'Hotel Dieu St Joseph de Saint Quentin",
    "label": "L'Hotel Dieu St Joseph de Saint Quentin",
    "value": "L'Hotel Dieu St Joseph de Saint Quentin"
  },
  {
    "id": "Miramichi Regional Hospital",
    "label": "Miramichi Regional Hospital",
    "value": "Miramichi Regional Hospital"
  },
  {
    "id": "Oromocto Public Hospital",
    "label": "Oromocto Public Hospital",
    "value": "Oromocto Public Hospital"
  },
  {
    "id": "Restigouche Hospital Centre",
    "label": "Restigouche Hospital Centre",
    "value": "Restigouche Hospital Centre"
  },
  {
    "id": "Sackville Memorial Hospital",
    "label": "Sackville Memorial Hospital",
    "value": "Sackville Memorial Hospital"
  },
  {
    "id": "Saint John Regional Hospital",
    "label": "Saint John Regional Hospital",
    "value": "Saint John Regional Hospital"
  },
  {
    "id": "St.Joseph's Hospital",
    "label": "St.Joseph's Hospital",
    "value": "St.Joseph's Hospital"
  },
  {
    "id": "Sussex Health Center",
    "label": "Sussex Health Center",
    "value": "Sussex Health Center"
  },
  {
    "id": "The Moncton Hospital",
    "label": "The Moncton Hospital",
    "value": "The Moncton Hospital"
  },
  {
    "id": "Tracadie-Sheila Hospital",
    "label": "Tracadie-Sheila Hospital",
    "value": "Tracadie-Sheila Hospital"
  },
  {
    "id": "Upper River Valley Hospital",
    "label": "Upper River Valley Hospital",
    "value": "Upper River Valley Hospital"
  }
];

export const Status_In_Progress = "In Progress";
export const involvedVehiclesOptions = [
  {
    id: "car",
    label: "Car or SUV",
    value: false,
  },
  {
    id: "motorcycle",
    label: "Motorcycle",
    value: false,
  },
  {
    id: "truck",
    label: "Truck",
    value: false,
  },
  {
    id: "atv",
    label: "ATV",
    value: false,
  },
  {
    id: "bicycle",
    label: "Bicycle",
    value: false,
  },
  {
    id: "transportTruck",
    label: "Transport truck",
    value: false,
  },
  {
    id: "snowmobile",
    label: "Snowmobile",
    value: false,
  },
  {
    id: "other",
    label: "Other",
    value: false,
  },
];

export const protectiveDevicesOptions = [
  {
    id: "airbag",
    label: "Airbag deployed",
    value: false,
  },
  {
    id: "seatbelts",
    label: "Seatbelts",
    value: false,
  },
  {
    id: "carSeat",
    label: "Car seat",
    value: false,
  },
  {
    id: "boosterSeat",
    label: "Booster seat",
    value: false,
  },
  {
    id: "noRestraints",
    label: "No restraints",
    value: false,
  },
  {
    id: "helmet",
    label: "Helmet",
    value: false,
  },
  {
    id: "ejected",
    label: "Ejected",
    value: false,
  },
  {
    id: "unknown",
    label: "Unknown",
    value: false,
  },
];

export const impactOptions = [
  {
    id: "driver",
    label: "Driver side",
    value: false,
  },
  {
    id: "headOn",
    label: "Head on",
    value: false,
  },
  {
    id: "rear",
    label: "Rear end",
    value: false,
  },
  {
    id: "rollover",
    label: "Rollover",
    value: false,
  },
  {
    id: "passenger",
    label: "Passenger side",
    value: false,
  },
  {
    id: "tbone",
    label: "T-bone",
    value: false,
  },
  {
    id: "offRoad",
    label: "Off road",
    value: false,
  },
  {
    id: "unknown",
    label: "Unknown",
    value: false,
  },
];

export const injuryLocationOptions = [
  {
    "id": "Select",
    "label": "Select",
    "value": "Select"
  },
  {
    "id": "driver",
    "label": "Driver",
    "value": "Driver"
  },
  {
    "id": "frontPassenger",
    "label": "Front passenger",
    "value": "Front passenger"
  },
  {
    "id": "rearCenter",
    "label": "Rear center",
    "value": "Rear center"
  },
  {
    "id": "rearRight",
    "label": "Rear right",
    "value": "Rear right"
  },
  {
    "id": "rearLeft",
    "label": "Rear left",
    "value": "Rear left"
  },
  {
    "id": "pedestrian",
    "label": "Pedestrian",
    "value": "Pedestrian"
  },
  {
    "id": "bicyclist",
    "label": "Bicyclist",
    "value": "Bicyclist"
  },
  {
    "id": "Other",
    "label": "Other",
    "value": "Other"
  },
  {
    "id": "unknown",
    "label": "Unknown",
    "value": "Unknown",
  },
];

export const assaultInjuryOptions = [
  {
    id: "gsw",
    label: "GSW",
    value: false,
  },
  {
    id: "handgun",
    label: "Handgun",
    value: false,
  },
  {
    id: "longGun",
    label: "Long gun",
    value: false,
  },
  {
    id: "assault",
    label: "Assault",
    value: false,
  },
  {
    id: "range",
    label: "Range",
    value: false,
  },
  {
    id: "stabbing",
    label: "Stabbing",
    value: false,
  },
  {
    id: "#wounds",
    label: "# wounds",
    value: false,
  },
];

export const mechanismOptions = [
  {
    "id": "fall",
    "label": "Fall",
    "value": "Fall",
  },
  {
    "id": "downStairs",
    "label": "Down Stairs",
    "value": "Other"
  },
  {
    "id": "nearDrowning",
    "label": "Near drowning",
    "value": "Near drowning",
  },
  {
    "id": "sportInjury",
    "label": "Sport injury",
    "value": "Sport injury"
  },
  {
    "id": "crushInjury",
    "label": "Crush injury",
    "value": "Crush injury",
  },
];

export const burnOptions = [
  {
    "id": "Select",
    "label": "Select",
    "value": "Select"
  },
  {
    "id": "fire",
    "label": "Fire",
    "value": "Fire"
  },
  {
    "id": "inhalation",
    "label": "Inhalation",
    "value": "Inhalation"
  },
  {
    "id": "liquid",
    "label": "Liquid",
    "value": "Liquid"
  },
  {
    "id": "steam",
    "label": "Steam",
    "value": "Steam"
  },
  {
    "id": "electrical",
    "label": "Electrical",
    "value": "Electrical"
  },
  {
    "id": "chemicals",
    "label": "Chemicals",
    "value": "Chemicals"
  },
  {
    "id": "coldInjury",
    "label": "Cold injury",
    "value": "Cold injury"
  },
  {
    "id": "Other",
    "label": "Other",
    "value": "Other"
  },
];

export const splintingOptions = [
  {
    id: "cervicalCollar",
    label: "Cervical collar",
    value: false,
  },
  {
    id: "backboard",
    label: "Backboard",
    value: false,
  },
  {
    id: "traction",
    label: "Traction",
    value: false,
  },
  {
    id: "tpod",
    label: "TPOD",
    value: false,
  },
  {
    id: "extremity",
    label: "Extremity",
    value: false,
  },
  {
    id: "ked",
    label: "KED",
    value: false,
  },
  {
    id: "other",
    label: "Other",
    value: false,
  },
];

export const bleedingOptions = [
  {
    id: "pressure",
    label: "Pressure",
    value: false,
  },
  {
    id: "tourniquet",
    label: "Tourniquet",
    value: false,
  },
  {
    id: "dressing",
    label: "Dressing",
    value: false,
  },
  {
    id: "other",
    label: "Other",
    value: false,
  },
];

export const oxygenationDevices = [
  {
    "id": "Select",
    "label": "Select",
    "value": "Select"
  },
  {
    "id": "adultNasalCannula",
    "label": "Adult nasal cannula",
    "value": "Adult nasal cannula"
  },
  {
    "id": "adultNon-rebreather",
    "label": "Adult non-rebreather",
    "value": "Adult non-rebreather",
  },
  {
    "id": "pediatricNon-rebreather",
    "label": "Pediatric non-rebreather",
    "value": "Pediatric non-rebreather"
  },
  {
    "id": "InfantOxygenMask",
    "label": "Infant oxygen mask",
    "value": "Infant oxygen mask",
  },
];

export const ventilationDevices = [
  {
    "id": "Select",
    "label": "Select",
    "value": "Select"
  },
  {
    "id": "adultBMV",
    "label": "Adult BMV",
    "value": "Adult BMV"
  },
  {
    "id": "pediatricBVM",
    "label": "Pediatric BVM",
    "value": "Pediatric BVM",
  },
  {
    "id": "infantBVM",
    "label": "Infant BVM",
    "value": "Infant BVM"
  },
  {
    "id": "pocketMask",
    "label": "Pocket mask",
    "value": "Pocket mask",
  },
];

export const airwayOptions = [
  {
    id: "suction",
    label: "Suction",
    value: false,
  },
  {
    id: "opa",
    label: "OPA",
    value: false,
  },
  {
    id: "npa",
    label: "NPA",
    value: false,
  },
  {
    id: "cpap",
    label: "CPAP",
    value: false,
  },
  {
    id: "magill",
    label: "Magill",
    value: false,
  },
];

export const airwayACPOptions = [
  {
    id: "peep",
    label: "PEEP",
    value: false,
  },
  {
    id: "needleThoracostomy",
    label: "Needle thoracostomy",
    value: false,
  },
  {
    id: "cricothyrotomy",
    label: "Cricothyrotomy",
    value: false,
  },
];

export const locationThoracostomyOptions = [
  {
    "id": "Select",
    "label": "Select",
    "value": "Select"
  },
  {
    "id": "rightSideMidclavicular",
    "label": "Right side midclavicular",
    "value": "Right side midclavicular"
  },
  {
    "id": "leftSideMidclavicular",
    "label": "Left side midclavicular",
    "value": "Left side midclavicular",
  },
];

export const cricothyrotomyOptions = [
  {
    "id": "Select",
    "label": "Select",
    "value": "Select"
  },
  {
    "id": "pediatric",
    "label": "Pediatric",
    "value": "Pediatric"
  },
  {
    "id": "adult",
    "label": "Adult",
    "value": "Adult",
  },
];

export const airwayDeviceOptions = [
  { id: "IGel", label: "IGel", value: "IGel" },
  { id: "ET tube", label: "ET tube", value: "ET tube" }
];

export const iGelSizes = [
  { id: '2', label: '2' },
  { id: '2.5', label: '2.5' },
  { id: '3', label: '3' },
  { id: '4', label: '4' }
];

export const etTubeSizes = [
  { id: '2.5', label: '2.5' },
  { id: '3', label: '3' },
  { id: '3.5', label: '3.5' },
  { id: '4', label: '4' },
  { id: '4.5', label: '4.5' },
  { id: '5', label: '5' },
  { id: '5.5', label: '5.5' },
  { id: '6', label: '6' },
  { id: '6.5', label: '6.5' },
  { id: '7', label: '7' },
  { id: '7.5', label: '7.5' },
  { id: '8', label: '8' },
  { id: '8.5', label: '8.5' }
];

export const successOptions = [
  { id: "Yes", label: "Yes" },
  { id: "No", label: "No" }
];

export const viewOptions = [
  { id: "DL", label: "DL" },
  { id: "VL", label: "VL" }
];
