import React from 'react';
import { Input, Button } from '@salesforce/design-system-react';
import PCRComboBox from "../../../common/PCRComboBox";
import { assistingPersonnelPositions } from "../../../../data/constants";
import "css/transferOfCare.css";

const TOCAssistingPersonnelItem = ({
  index,
  item,
  onChange,
  onRemove,
  onAdd,
  isLast,
  disableRemove
}) => {
  return (
    <div className="slds-grid slds-gutters" style={{ alignItems: 'center' }}>
      <div className="slds-col slds-size_1-of-3">
        <Input
          id={`assistingPersonnelName-${index}`}
          label="Name"
          value={item.assistingPersonnelName}
          onChange={(e) => onChange(index, { assistingPersonnelName: e.target.value })}
        />
      </div>
      <div className="slds-col slds-size_1-of-3">
        <PCRComboBox
          id={`assistingPersonnelPosition-${index}`}
          label="Position"
          options={assistingPersonnelPositions}
          value={item.assistingPersonnelPosition}
          placeholder="--"
          onChange={(val) => onChange(index, { assistingPersonnelPosition: val })}
          onSelect={(val) => {
            const selectedValue = val.assistingPersonnelPosition || Object.values(val)[0];
            onChange(index, { assistingPersonnelPosition: selectedValue });
          }}
          selection={item.assistingPersonnelPosition ? [{ id: item.assistingPersonnelPosition, label: item.assistingPersonnelPosition }] : []}
          name="assistingPersonnelPosition"
        />
      </div>
      {item.assistingPersonnelPosition === "Other" && (
        <div className="slds-col slds-size_1-of-4" style={{ paddingLeft: '1rem' }}>
          <Input
            id={`assistingPersonnelOther-${index}`}
            label=" "
            placeholder="Please Specify"
            value={item.assistingPersonnelOther || ""}
            onChange={(e) => onChange(index, { assistingPersonnelOther: e.target.value })}
            style={{ marginBottom: '0' }}
          />
        </div>
      )}
      <div className="slds-col slds-size_1-of-12" style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem' }}>
        {!disableRemove && (
          <Button
            iconCategory="utility"
            iconName="delete"
            variant="icon"
            className="slds-icon-text-error"
            size="small"
            onClick={() => {
              if (window.confirm("Warning: Row Deletion\n\nYou are about to remove a row. Are you sure you want to proceed?")) {
                onRemove(index);
              }
            }}
          />
        )}
        {isLast && (
          <Button
            iconCategory="utility"
            iconName="add"
            variant="icon"
            className="slds-icon-text-success"
            onClick={onAdd}
            size="small"
            style={{ marginLeft: '0.5rem' }}
          />
        )}
      </div>
    </div>
  );
};

export default TOCAssistingPersonnelItem;