import React from 'react';
import UsersTable from '../components/UsersTable';
import { useUsers } from '../context/UserContext';

const DebugPage = () => {
    const { state } = useUsers();
    console.log('state in DebugPage >>>', state);
  return (
    <div>
      <h1>Debug Page</h1>
      <UsersTable />
    </div>
  );
};

export default DebugPage;