import React, { useContext, useEffect, useState } from "react";
import { Button, Combobox, Input } from '@salesforce/design-system-react';
import PCRTimePicker from "components/common/PCRTimePicker";
import { useTranslation } from "react-i18next";
import "css/comboBox.css";
import PCRComboBox from "components/common/PCRComboBox";
import { PCRContext, evaluateError } from "../../../../../context/PCRContext";
import PCRTextInput from "components/common/PCRTextInput";
import { db } from "../../../../../storage/idb";
import { vascularItem as vascularItemBlank } from "../../../../../context/PCRContext";
import { pupilOptions, eyeOptions, verbalOptions, motorOptions } from "data/constants";


const PCRVascularItem = ({ pcr, index, onRemove, onChange, onAdd, isLast, vascularFromParent }) => {
  const { t } = useTranslation();

  const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);

  // console.log('vascularFromParent >>>', vascularFromParent);
  // console.log('vascularItemBlank >>>', vascularItemBlank);

  const [vascular, setVascular] = useState(vascularFromParent || vascularItemBlank);

  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(false);

  useEffect(() => {
    setIsAnyFieldFilled(Object.values(vascular).some(field => field !== ''));
  }, [vascular]);


  const updateVascularItemContext = (field, value) => {
    if (value !== undefined) {
      const newVascular = { ...vascular, [field]: value };
      setVascular(newVascular);
      onChange(index, newVascular);
    } else {
      console.warn(`Received undefined value for field: ${field}`);
    }
  };

  const validateNumeric = (value, min, max) => {
    const num = parseFloat(value);
    return !isNaN(num) && (min === undefined || num >= min) && (max === undefined || num <= max);
  };

  const validateGCS = (value) => {
    return ['1', '2', '3', '4', '5', '6', 'NT', 'NE'].includes(value?.toUpperCase());
  };

  const onSceneOptions = [
    { label: '--', value: '--' },
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
  ]

  const typeOptions = [
    { label: '--', value: '--' },
    { label: 'IV', value: 'IV' },
    { label: 'IO', value: 'IO' },
    { label: 'EJ', value: 'EJ' }
  ]

  const successfulOptions = [
    { label: '--', value: '--' },
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
  ]

  const salineLockOptions = [
    { label: '--', value: '--' },
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
  ]

  const flowRateUnitOptions = [
    { label: '--', value: '--' },
    { label: 'Right', value: 'Right' },
    { label: 'Left', value: 'Left' }
  ]


  return (
    <div className="slds-grid slds-gutters">
      {/* const vascularItem = {
  id: '',
  time: '',
  onScene: '',  
  site: '',
  type: '',  
  gauge: '',
  successful: '',
  salineLock: '',
  flowRate: '',
  mLInfused: '',
  initial: '',
  idNumber: ''
} */}
      <div className="slds-col">
        <span>ID:</span>
        <span>{vascular.id}</span>
      </div>
      <div className="slds-col">
        <PCRTimePicker
          assistiveText={{ label: t("time") }}
          id="time-id"
          className="bor-rad ml10"
          placeholder="HH:MM"
          value={vascular.time || ""}
          name="time"
          label={t("time")}
          onChange={(newValue) => {
            if (newValue && newValue.time !== undefined) {
              updateVascularItemContext('time', newValue.time);
            }
          }}
          disabled={false}
          key={vascular?.time}
        />
      </div>
      <div className="slds-col">
        <PCRComboBox
          id="on-scene-combo-box"
          options={onSceneOptions}
          onChange={(value) => updateVascularItemContext('onScene', value)}
          name={"onScene"}
          label={"On scene?"}
          required
          value={vascular.onScene}
        />
      </div>
      <div className="slds-col">
        <Input
          label="Site"
          value={vascular.site}
          onChange={(e) => updateVascularItemContext('site', e.target.value)}
          errorText={!validateNumeric(vascular.site, 0, 100) && 'Invalid format'}
        />
      </div>
      <div className="slds-col">
        <PCRComboBox
          id="type-combo-box"
          options={typeOptions}
          onChange={(value) => updateVascularItemContext('type', value)}
          name={"type"}
          label={"Type"}
          required
          value={vascular.type}
        />
      </div>
      <div className="slds-col">
        <Input
          label="Gauge"
          value={vascular.gauge}
          onChange={(e) => updateVascularItemContext('gauge', e.target.value)}
          errorText={!validateNumeric(vascular.gauge, 0) && 'Invalid format'}
        />
      </div>
      <div className="slds-col">
        <PCRComboBox
          id="successful-combo-box"
          options={successfulOptions}
          onChange={(value) => updateVascularItemContext('successful', value)}
          name={"successful"}
          label={"Successful"}
          required
          value={vascular.successful}
        />
      </div>

      <div className="slds-col">
        <PCRComboBox
          id="saline-lock-combo-box"
          options={salineLockOptions}
          onChange={(value) => updateVascularItemContext('salineLock', value)}
          name={"salineLock"}
          label={"Saline lock"}
          required
          value={vascular.salineLock}
        />
      </div>

      <div className="slds-col">
        {/* subgrid 1-of-2--> */}
        <div class="slds-grid slds-wrap">

          {/* left flowrate input */}
          <div class="slds-col slds-size_1-of-2">
            <Input
              label="Flow rate"
              value={vascular.flowRate}
              onChange={(e) => updateVascularItemContext('flowRate', e.target.value)}
              errorText={!validateNumeric(vascular.flowRate, 0) && 'Invalid format'}
            />
          </div>

          {/* right flow rate options */}
          <div class="slds-col slds-size_1-of-2">
            <PCRComboBox
              id="flow-rate-combo-box"
              options={flowRateUnitOptions}
              onChange={(value) => updateVascularItemContext('flowRateUnit', value)}
              name={"flowRateUnit"}
              label={" "}
              required
              value={vascular.flowRateUnit}
            />
          </div>
        </div>
      </div>

      <div className="slds-col">
        <Input
          label="mL infused"
          value={vascular.mLInfused}
          onChange={(e) => updateVascularItemContext('mLInfused', e.target.value)}
          errorText={!validateNumeric(vascular.mLInfused, 0) && 'Invalid format'}
        />
      </div>

      <div className="slds-col">
        <Input
          label="Initial"
          value={vascular.initial}
          onChange={(e) => updateVascularItemContext('initial', e.target.value)}
          errorText={!validateNumeric(vascular.initial, 0) && 'Invalid format'}
        />
      </div>

      <div className="slds-col">
        <Input
          label="ID#"
          value={vascular.idNumber}
          onChange={(e) => updateVascularItemContext('idNumber', e.target.value)}
          errorText={!validateNumeric(vascular.idNumber, 0) && 'Invalid format'}
        />
      </div>

      <div className="slds-col">
        <div className="vascular-item-button-group slds-grid slds-gutters">
          {/* delete button */}

          <div className="slds-col">
            <Button
              iconCategory="utility"
              iconName="delete"
              variant="icon"
              className="vascular-delete slds-icon-text-error"
              onClick={() => onRemove(index)}
              size="small"
            />
          </div>

          {/* add button */}
          {isLast && (
            <div className="slds-col">
              <Button
                iconCategory="utility"
                iconName="add"
                variant="icon"
                className="vascular-add slds-icon-text-success"
                onClick={onAdd}
                size="small"
              />
            </div>
          )}
        </div>
      </div>


      <div className="slds-col">
        <Button
          iconCategory="utility"
          iconName="graph"
          variant="icon"
          className="vascular-chart slds-icon-text-warning"
          onClick={() => console.log('vascular chart button clicked')}
          size="small"
        />
      </div>

    </div>
  );
};

export default PCRVascularItem;