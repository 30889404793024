import axios from "axios";
import { APP_CONFIG } from "../configs/config.js";


const URL = APP_CONFIG.SF_URL;

export const get = async (path, headers = {}) => {
  try {
    const sf_access_token = localStorage.getItem("sf_access_token");
    const response = await axios.get(`${URL}/${path}`, {
      headers: {
        Authorization: `Bearer ${sf_access_token}`,
        ...headers,
      },
    });
    return response;
  } catch (e) {
    return e?.response || {};
  }
};

export const post = async (path, headers = {}, body) => {
  try {
    const sf_access_token = localStorage.getItem("sf_access_token");
    const response = await axios.post(`${URL}/${path}`, body, {
      headers: {
        Authorization: `Bearer ${sf_access_token}`,
        ...headers,
      },
    });
    return response;
  } catch (e) {
    return e?.response || {};
  }
};
