import React, { useState } from "react";
import { Combobox, Icon } from "@salesforce/design-system-react";
import "../../css/comboBox.css";

const PCRComboBox = ({
  id,
  onChange,
  onRequestRemoveSelectedOption,
  onSelect,
  label,
  required,
  options,
  value,
  errorText = "",
  menuItemVisibleLength = 7,
  filterAttribute = "label",
  name = "",
  selectAttribute = "label",
  saveOnBlur = true,
  lowerCaseRequired = true,
  placeholder = "select",
}) => {
  const [searchLocIsOpen, setSearchLocIsOpen] = useState(false);
  const onBlur = () => {
    
    if (saveOnBlur) {
      if (!value) {
        onSelect({
          [name]: "",
        });
      } else {
        if (options.length) {
          const isMatchFound = options.some(
            (o) => o?.[filterAttribute] === value
          );
          if (!isMatchFound) {
            onSelect({
              [name]: "",
            });
          }
        }
      }
      setSearchLocIsOpen(false);
    }
  };

  return (
    <div className="combo-box-wrapper" id="pcr-combo-box">
      <Combobox
        id={id}
        isOpen={searchLocIsOpen}
        events={{
          onChange: ({ target: { value } }) => onChange(value),
          onRequestRemoveSelectedOption,
          onSelect: (event, data) => {
            onSelect({
              [name]: data?.selection[0]?.[selectAttribute] || "",
            });
            setSearchLocIsOpen(false);
          },
          onFocus: () => setSearchLocIsOpen(true),
          onBlur,
        }}
        labels={{
          label,
          placeholder: placeholder,
        }}
        menuItemVisibleLength={menuItemVisibleLength}
        options={
          lowerCaseRequired
            ? (typeof value === "string" ? value?.toLowerCase() : "")
              ? options?.filter((op) =>
                  op?.[filterAttribute]
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase())
                )
              : options
            : options
        }
        value={value}
        required={required}
        errorText={errorText}
        name={name}
        placeHolder=""
      />
      {
        <div className={label ? "combo-box-close df" : "combo-box-nolabel df"}>
          {value && (
            <span
              onClick={() => {
                onSelect({
                  [name]: "",
                });
              }}
              style={{
                paddingRight: ".5rem",
                paddingLeft: ".5rem",
                cursor: "pointer",
              }}
            >
              <Icon
                assistiveText={{ label: "Clear Text" }}
                category="utility"
                name="close"
                size="xx-small"
              />
            </span>
          )}

          {searchLocIsOpen ? (
            <span
              onClick={() => {
                setSearchLocIsOpen(false);
              }}
            >
              <Icon
                assistiveText={{ label: "Down chevron" }}
                category="utility"
                name="chevronup"
                size="xx-small"
              />
            </span>
          ) : (
            <span
              onClick={() => {
                setSearchLocIsOpen(true);
              }}
            >
              <Icon
                assistiveText={{ label: "Down chevron" }}
                category="utility"
                name="chevrondown"
                size="xx-small"
              />
            </span>
          )}
        </div>
      }
    </div>
  );
};

export default PCRComboBox;
