import { Tabs, TabsPanel } from "@salesforce/design-system-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Landing from "../pages/Landing";
import Home from "../pages/Home";
import "../css/userTabs.css";

const UserTabs = () => {
  const { t } = useTranslation();
  return (
    <Tabs id="user-tabs">
      <TabsPanel label={t("home")}>
        <Landing />
      </TabsPanel>
      <TabsPanel label="PCRs">
        <Home />
      </TabsPanel>
      {/* <TabsPanel label={t("tasks")}>Tasks</TabsPanel> */}
    </Tabs>
  );
};

export default UserTabs;
