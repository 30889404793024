import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NetworkIndicator from "./NetworkIndicator";
import UserProfiles from "./UserProfiles";
import { supportedLocales } from "../data/constants";
import "../css/header.css";

const Header = () => {
  const { i18n, t } = useTranslation();
  const isTokenAvailable = localStorage.getItem("sf_access_token");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [selectedLocale, setSelectedLocale] = React.useState(
    supportedLocales.EN
  );

  const toggleLocale = () => {
    setSelectedLocale(
      selectedLocale === supportedLocales.EN
        ? supportedLocales.FR
        : supportedLocales.EN
    );
  };

  useEffect(() => {
    changeLanguage(selectedLocale);
    localStorage.setItem("lang", selectedLocale);
  }, [selectedLocale]);

  return (
    <div className="header zI100">
      <div className="header-left-section df">
        <div className="header-logo-container df">
          <div className="header-anb-logo"></div>
          <div className="header-ccnb-logo"></div>
        </div>
      </div>
      <div className="header-right-section jcc pa df fdc r0 b0">
        {isTokenAvailable && <UserProfiles />}
        <div className="zI20 link pr10 headerLocale" onClick={toggleLocale}>
          {selectedLocale === supportedLocales.EN
            ? t("french")
            : t("english")}
        </div>
        <div>
          <NetworkIndicator />
        </div>
      </div>
    </div>
  );
};

export default Header;
